import React, { Component } from "react";
import config from "../../utils/config";
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {  Button } from "react-bootstrap";
import LeafButton from "../../assets/leafbtn_img.png";
import backtbn from "../../assets/backbtn.png";
import CommaUp from "../../assets/comma_up.svg";
import CommaDown from "../../assets/comma_down.svg";
import Feedback_1 from "../../assets/feedback_1.png";
import ArrowDouble from "../../assets/arrow_double.svg";
import BgGrassland from "../../assets/bg_grassland.png";
import BgMangrove from "../../assets/bg_mangrove.png";
import Sirini from "../../assets/sirini.png";
import Rajesh from "../../assets/rajesh.png";

class Repository extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.mounted = true;
    }
    componentWillUnmount() {
        document.body.classList.remove('repository_bg');
    }
    componentDidMount() {
        document.body.classList.add('repository_bg');
    }
    goBack() {
        window.history.go(-2);
    }
    render() {
        return (
            <>
                {/* <div>vfvv</div> */}
                <Button className="backbtn" onClick={this.goBack}>
                    <img src={backtbn} alt={backtbn} />
                    <span>Back</span>
                </Button>
                <div className="morelrpadd_cont commonfirstpadd_cont paddingbotm pd-100">
                    <div className="treeties_container">
                        <h1><span className="greenunderline">Treeties Knowledge Repository</span></h1>
                        <p>Dive deep into our knowledge repository with insights that guide your growth<br/> and nurture the horizons of what’s possible.</p>
                        {/* <p className="fs-6">Scroll to Get Started  <img className="ms-2 " src={ArrowDouble} /></p> */}
                    </div>
                </div>

                <div className="morelrpadd_cont keyachiev_cont paddingbotm pd-100">
                    <div className="treeties_container">
                        <h1><span className="greenunderline">Snapshots of Success: </span> Explore <br/> Treeties Projects</h1>
                       <p >Explore our gallery of ongoing and completed projects. Witness the transformative power of Treeties as we turn possibilities into realities.</p>

                     
                    </div>
                </div>
                <div className="morelrpadd_cont keyachiev_cont paddingbotm pd-100">
                    <div className="treeties_container">
                        <div className="content-box  bg-trans bg-cover" style={{backgroundImage:`url(${BgGrassland})`}}>
                          <h2 className="treeties_heading">Grassland restoration through removal of invasive species - Banni, Gujarat</h2>
                          <p>Restoring grasslands to their native state can help improve soil<br /> health, support local wildlife populations, and promote<br /> sustainable land use practices.</p>
                          <Button className="leafbtn_cont pb-5">
                            <img src={LeafButton} alt="leafbtn" />
                            <span>Learn more </span>
                           </Button>
                        </div>
                    </div>
                </div>

                <div className="morelrpadd_cont keyachiev_cont paddingbotm pd-100">
                    <div className="treeties_container">
                        <div className="content-box  bg-trans bg-cover" style={{backgroundImage:`url(${BgMangrove})`}}>
                          <h2 className="treeties_heading">Mangrove restoration - Kerala</h2>
                          <p>Mangroves are important as it helps protect the coast from erosion and storm surges, supports the local fishing industry, and provides habitat for numerous species of plants and animals. Additionally, mangroves can act as carbon sinks, helping to mitigate the effects of climate change.</p>
                          <Button className="leafbtn_cont pb-5">
                            <img src={LeafButton} alt="leafbtn" />
                            <span>Learn more </span>
                           </Button>
                        </div>
                    </div>
                </div>

                <div className="morelrpadd_cont keyachiev_cont paddingbotm pd-100">
                    <div className="treeties_container">
                      

                     <div className="theme-feedback-slider mt-5">
                    
                     <h2 className="treeties_heading">
                        <sup><img src={CommaUp}/></sup>Lorem ipsum dolor sit amet consectetur. Odio quis cras rhoncus ullamcorper enim loborti.<sub><img src={CommaDown}/></sub>
                        </h2>
                        <div className="partner-profile d-flex align-items-center ">
                            <div className="partner-image rounded-circle me-4">
                                <img src={Sirini}/>
                            </div>
                            <div className="about-partner">
                         <h4 className="greenunderline greentxt">- Srini Srinivasan </h4>
                         <h4 className="treeties_subheading">(Business Dev, Sponsor & Network Mgt, Governance.)</h4>
                        
                            </div>
                        </div>
                        {/* <Button className="leafbtn_cont pb-5">
                            <img src={LeafButton} alt="leafbtn" />
                            <span>View more </span>
                        </Button> */}
                     </div>
                    </div>
                </div>

                <div className="morelrpadd_cont keyachiev_cont paddingbotm pd-100">
                    <div className="treeties_container">
                      

                     <div className="theme-feedback-slider mt-5">
                    
                     <h2 className="treeties_heading">
                        <sup><img src={CommaUp}/></sup>Lorem ipsum dolor sit amet consectetur. Odio quis cras rhoncus ullamcorper enim loborti.<sub><img src={CommaDown}/></sub>
                        </h2>
                        <div className="partner-profile d-flex align-items-center ">
                            <div className="partner-image rounded-circle me-4">
                                <img src={Rajesh}/>
                            </div>
                            <div className="about-partner">
                         <h4 className="greenunderline greentxt">- Rajesh Patel </h4>
                         <h4 className="treeties_subheading">(Climate Science, Technology Platform Mgt.)</h4>
                         
                            </div>
                        </div>
                        {/* <Button className="leafbtn_cont pb-5">
                            <img src={LeafButton} alt="leafbtn" />
                            <span>View more </span>
                        </Button> */}
                     </div>
                    </div>
                </div>
                
                <div className="morelrpadd_cont keyachiev_cont paddingbotm pd-100">
                    <div className="treeties_container">
                        <h1 className="greenunderline">Our Thought Garden</h1>
                        <p className="mb-5">Nourish the seeds of your intellectual growth with the latest blogs and<br /> articles from the world of sustainability, environmental restoration, and<br /> everything in between.</p>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="content-box bg-color"></div>
                            </div>
                            <div className="col-sm-6">
                                <div className="content-box bg-color"></div>
                            </div>
                            <div className="col-sm-6">
                                <div className="content-box  bg-color"></div>
                            </div>
                            <div className="col-sm-6">
                                <div className="content-box bg-color"></div>
                            </div>
                       </div>
                    </div>
                </div>

                <div className="morelrpadd_cont keyachiev_cont paddingbotm pd-100">
                    <div className="treeties_container">
                    <h1><span className="greenunderline">The Treeties Bulletin:</span> News &<br /> Updates</h1>
                       <p className="mb-5">Tap into the pulse of Treeties with our news and updates. Remain in the loop as we continue to grow, innovate, and inspire.</p>
                       <div className="row">
                        <div className="col-sm-6">
                        <div className="content-box  bg-color" >
                         
                          
                         </div>
                        </div>
                        <div className="col-sm-6">
                        <div className="content-box  bg-color" >
                         
                          
                         </div>
                        </div>
                        <div className="col-sm-6">
                        <div className="content-box  bg-color" >
                         
                          
                         </div>
                        </div>
                        <div className="col-sm-6">
                        <div className="content-box  bg-color" >
                         
                          
                         </div>
                        </div>
                       </div>
                       
                        </div>
                        </div>
              

              
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
        registrationData: state.registration.registrationData
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Repository);

