import { useEffect, useState } from "react";
import config from "../../../../utils/config";
import LocationMapImg from "../mediaImages/locationmap_img.png";
import Landparcel1 from "../mediaImages/Landparcel1.png";
import Landparcel2 from "../mediaImages/Landparcel2.png";
import Waterbodies1 from "../mediaImages/Waterbodies1.png";
import Waterbodies2 from "../mediaImages/Waterbodies2.png";

export default function LocationViewReport(props) {
  const id = window.location.href.split("/")[4];
  const projectId = id.split(":")[1];
  const [landParcelsList, setlandParcelsList] = useState([]);
  const [rowWaterBodyList, setrowWaterBodyList] = useState([]);
  const [typeWaterDropdownList, settypeWaterDropdownList] = useState([]);
  const [selectedLands, setSelectedLands] = useState([]);
  const [selectedWater, setSelectedWater] = useState([]);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (projectId) {
      GetProjectLocation(projectId);
      GetAllCommonMasters();
    }
  }, [projectId]);

  const GetProjectLocation = (ProjectId) => {
    let projId = { ProjectId: ProjectId };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectLocation`;
    fetch(url, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(projId),
      timeout: 5000,
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        if (responseData !== null) {
          setlandParcelsList(responseData.landParcels);
          setrowWaterBodyList(responseData.waterBodies);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetAllCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetAllCommonMasters`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 5000,
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data;
        if (responseData.length > 0) {
          var typeWater = responseData.filter(
            (x) => x.type === "Type of Water Body"
          );
          settypeWaterDropdownList(typeWater);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const landPolygon = (option) => {
    let obj = {
      id: "",
      path: [],
      landDetails: [],
      options: {
        strokeWeight: 2,
        strokeOpacity: 1,
        strokeColor: "#FFFF00",
        fillOpacity: 0,
        fillColor: "#c43c39",
      },
    };

    obj["id"] = option?.landParcelId;
    obj["path"] = option?.locationAltitude;
    obj["landDetails"] = option;
    return obj;
  };

  const waterPolygon = (water) => {
    let obj = {
      id: "",
      path: [],
      waterDetails: [],
      options: {
        strokeWeight: 2,
        strokeOpacity: 1,
        strokeColor: "#0000ff",
        fillOpacity: 0,
        fillColor: "#85b66f",
      },
    };

    obj["id"] = water?.waterBodyId;
    obj["path"] = water?.locationAltitude;
    obj["waterDetails"] = water;
    return obj;
  };

  const handleLandParcelsSelection = (isSelected, option) => {
    let list = [...landParcelsList];
    list = list.map((item) => {
      if (item.landParcelId === option.landParcelId) {
        item.isSelected = isSelected;
      }
      return item;
    });
    setlandParcelsList(list);

    if (isSelected) {
      let polygonData = landPolygon(option);
      setSelectedLands((prevSelectedLands) => [
        ...prevSelectedLands,
        polygonData,
      ]);
    } else {
      setSelectedLands((prevSelectedLands) =>
        prevSelectedLands.filter((item) => item.id !== option.landParcelId)
      );
    }
  };

  const handleWaterBodiesSelection = (isSelected, option) => {
    let list = [...rowWaterBodyList];
    list = list.map((item) => {
      if (item.waterBodyId === option.waterBodyId) {
        item.isSelected = isSelected;
      }
      return item;
    });
    setrowWaterBodyList(list);

    if (isSelected) {
      let polygonData = waterPolygon(option);
      setSelectedWater((prevSelectedWater) => [
        ...prevSelectedWater,
        polygonData,
      ]);
    } else {
      setSelectedWater((prevSelectedWater) =>
        prevSelectedWater.filter((item) => item.id !== option.waterBodyId)
      );
    }
  };

  const renderLandParcelsRows = () => {
    return landParcelsList.map((eachLandParcels, i) => {
      return (
        <tr key={i}>
          <td>{(i += 1)}</td>
          <td>{eachLandParcels.title}</td>
          <td>{eachLandParcels.areaInHectares}</td>
        </tr>
      );
    });
  };

  const renderWaterBodiesRows = () => {
    return rowWaterBodyList.map((eachWaterBody, i) => {
      return (
        <tr key={i}>
          <td>{(i += 1)}</td>
          <td>{eachWaterBody.title}</td>
          <td>
            {
              typeWaterDropdownList.filter(
                (ss) => ss.mainGuId === eachWaterBody.typeWaterBody
              )[0]?.description
            }
          </td>
          <td>{eachWaterBody.areaInHectares}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <div class="prosumrprt_acordcontent">
        <div class="row prosumcordtabscont_wrap partshpreportcont_wrap locationreportcont_wrap">
          <div class="col-12">
            <div class="tabs-content locationrpt_tabscontent">
              <div class="partshpreport_heading">Land Parcels</div>
              <div class="executivepatners_trust">
                {landParcelsList && landParcelsList.length > 0 ? (
                  landParcelsList.map((eachLandParcels, i) => (
                    <div class="institutionaltabs_content" key={i}>
                      <div className="eachLandParcelscontent_wrap">
                        <div>
                          <span>Title</span>
                          <span>:&nbsp; {eachLandParcels?.title}</span>
                        </div>
                        <div>
                          <span>Area (In Hectares)</span>
                          <span>:&nbsp; {eachLandParcels?.areaInHectares}</span>
                        </div>
                      </div>
                      {/* <div className="locationpdf_mapcont">
                        <img
                          src={`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/LocationDocument/${eachLandParcels.imageUrl}`}
                          alt="LocationMapImg"
                        />
                      </div> */}
                      <div className="locationpdf_mapcont">
                        <img src={Landparcel1} alt="LocationMapImg" />
                      </div>
                      {/* <div className="locationpdf_mapcont">
                        <img src={Landparcel2} alt="LocationMapImg" />
                      </div> */}
                    </div>
                  ))
                ) : (
                  <div className="norecodfoundprosum_cont">No Record Found</div>
                )}
              </div>
            </div>

            <div class="tabs-content locationrpt_tabscontent">
              <div class="partshpreport_heading">Water Bodies</div>
              <div class="executivepatners_trust">
                {rowWaterBodyList && rowWaterBodyList.length > 0 ? (
                  rowWaterBodyList.map((eachWaterBody, i) => (
                    <div class="institutionaltabs_content" key={i}>
                      <div className="eachLandParcelscontent_wrap">
                        <div>
                          <span>Title</span>
                          <span>:&nbsp; {eachWaterBody.title}</span>
                        </div>
                        <div>
                          <span>Area (In Hectares)</span>
                          <span>:&nbsp; {eachWaterBody.areaInHectares}</span>
                        </div>
                      </div>
                      {/* <div className="locationpdf_mapcont">
                        <img
                          src={`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/LocationDocument/${eachWaterBody.imageUrl}`}
                          alt="LocationMapImg"
                        />
                      </div> */}
                      <div className="locationpdf_mapcont">
                        <img src={Waterbodies1} alt="LocationMapImg" />
                      </div>
                      {/* <div className="locationpdf_mapcont">
                        <img src={Waterbodies2} alt="LocationMapImg" />
                      </div> */}
                    </div>
                  ))
                ) : (
                  <div className="norecodfoundprosum_cont">No Record Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="prosumacordtbls_wrap locationaccordscont_wrap">
        <h3 className="locationtbls_heading">Land Parcels</h3>
        <div className="prosumacordinertbl_wrap">
          <table className="prosumacord_inertbl">
            <thead>
              <tr>
                <th>SN</th>
                <th>Title</th>
                <th>Area (In Hectares)</th>
              </tr>
            </thead>
            <tbody>{renderLandParcelsRows()}</tbody>
          </table>
        </div>

        <h3 className="locationtbls_heading">Water Bodies</h3>
        <div className="prosumacordinertbl_wrap">
          <table className="prosumacord_inertbl">
            <thead>
              <tr>
                <th>SN</th>
                <th>Title</th>
                <th>Type Water Body</th>
                <th>Area (In Hectares)</th>
              </tr>
            </thead>
            <tbody>{renderWaterBodiesRows()}</tbody>
          </table>
        </div>
      </div> */}
    </>
  );
}
