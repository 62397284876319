import React, { Component } from "react";
import { Router, navigate } from "@reach/router";
import { globalHistory } from "@reach/router";
import { connect } from "react-redux";
import NGODashboard from "./Home/NGODashboard";
import Login from "./Login/Login";
import ForgetPassword from "./Login/Forgetpassword";
import ResetPassword from "./Login/Resetpassword";
import GrowAlarm from "./Views/GrowHead/growalarms";
import Register from "./Login/register";
import Projectslisting from "./Views/NGOManager/projects";
import Projectdetails from "./Views/NGOManager/projectdetails";
import PageNotFound from "./Common/PageNotFound/PageNotFound";
import ApplicationList from "./Views/GrowHead/ApplicationList";
import ApproveGroundSamplingData from "./Views/GrowHead/ApproveGroundSamplingData";
import GrowHead from "./Views/GrowHead/GrowHead";
import ScheduleMeeting from "./ScheduleMeeting";
import ProjectsList from "./Views/GrowHead/ProjectsList";
import FieldOfficerList from "./Views/GrowHead/FieldOfficerList";
import LayerMaster from "./Views/GrowHead/LayerMaster";
import Co2EmissionCalculator from "./Co2Calculator/Co2EmissionCalculator";
import queryString from "query-string";
import { store } from "./../App";
import TreeTypeMst from "./Views/GrowHead/TreeTypeMst";
import PaymentList from "./Views/GrowHead/PaymentList";
import Payment from "./Payment/Payment";
import SuccessMessage from "./Message/SuccessMessage";
import FailureMessage from "./Message/FailureMessage";
import Fundmappingwithprojects from "./Fundmappingwithprojects/Fundmappingwithprojects";
import HomePageNew from "./Home/HomePageNew";
import EnquiryDetails from "./EnquiryDetails/EnquiryDetails";
import AboutUs from "./Home/About";
import OurExperience from "./Home/OurExperience";
import EpicData from "./Views/GrowHead/EpicData";
import ContactUs from "./Home/ContactUs";
import Partners from "./Home/Partners";
import Repository from "./Home/Repository";
import Platform from "./Home/Platform";
import PrivacyPolicy from "./Home/PrivacyPolicy";
import TermsConditions from "./Home/TermsConditions";
import RefunCancellationPolicy from "./Home/RefundCancellationPolicy";
import TreeTiesAddProjectForms1Spo from "./EcosystemRestorationProject/TreeTiesAddProjectForms1Spo";
import TreeTiesAddProjectForms1Par1 from "./EcosystemRestorationProject/TreeTiesAddProjectForms1Par1";
import TreeTiesAddProjectForms1Loc from "./EcosystemRestorationProject/TreeTiesAddProjectForms1Loc";
import TreeTiesAddProjectForms1Cos from "./EcosystemRestorationProject/TreeTiesAddProjectForms1Cos";
import TreeTiesAddProjectForms1Ris from "./EcosystemRestorationProject/TreeTiesAddProjectForms1Ris";
import TreeTiesAddProjectForms1Obj from "./EcosystemRestorationProject/TreeTiesAddProjectForms1Obj";
import TreeTiesAddProjectForms1Sit from "./EcosystemRestorationProject/TreeTiesAddProjectForms1Sit";
import TreeTiesAddProjectForms1Own from "./EcosystemRestorationProject/TreeTiesAddProjectForms1Own";
import TreeTiesAddProjectForms1Lay from "./EcosystemRestorationProject/TreeTiesAddProjectForms1Lay";
import TreeTiesAddProjectForms1Colla from "./EcosystemRestorationProject/TreeTiesAddProjectForms1Colla";
import TreeTiesAddProjectForms1ImageUpload from "./EcosystemRestorationProject/TreeTiesAddProjectForms1ImageUpload";
import TreeTiesAddProjectForms1WaterParameter from "./EcosystemRestorationProject/TreeTiesAddProjectForms1WaterParameter";

import Profile from "./Views/NGOManager/Profile";
import { Redirect } from "@reach/router";
import ProjectDetailsNew1st from "./Common/NewMapDesign/ProjectDetailsNew1st";
import ProjectSummaryReport from "./Common/NewMapDesign/Reports/NewProjectSummaryReport";
import DashboardNew from "./Dashboard/DashboardNew";
import ProjectManagementDashboard from "./Dashboard/AsanaDashboard";
import OurApproach from "./Home/OurApproach";
import UploadTimeSeriesImages from "./Views/GrowHead/UploadTimeSeriesImages";
import AdminDashboard from "./Dashboard/AdminDashboard";
import TreeSpeciesList from "./Views/GrowHead/TreeSpeciesList";
import ZoneTypeList from "./Views/GrowHead/ZoneTypeList";
import LifeFormList from "./Views/GrowHead/LifeFormList";
import AddPointOfContact from "./Views/GrowHead/AddPointOfContact";
import PartnershipsMasterData from "./Views/GrowHead/PartnershipsMasterData";
import TreesSpeciesMasterData from "./Views/GrowHead/TreesSpeciesMasterData";
import AddDashboardURL from "./Views/NGOManager/AddDashboardURL";

// Monitor changes to the history & mange the routes
// globalHistory.listen(x => {
//     userAuthenticationRouting();
// })
const userAuthenticationRouting = () => {
  let currentPath = globalHistory.location.pathname;
  let loggedIn = store.getState().user.userData !== null;
  if (
    currentPath.includes("/ResetPassword") ||
    currentPath.includes("/ScheduleMeeting") ||
    currentPath.includes("/Co2EmissionCalculator") ||
    currentPath.includes("/Payment") ||
    currentPath.includes("/SuccessMessage") ||
    currentPath.includes("/FailureMessage") ||
    currentPath.includes("/home") ||
    currentPath.includes("/Registration") ||
    currentPath.includes("/aboutus") ||
    currentPath.includes("/platform") ||
    currentPath.includes("/contactus") ||
    currentPath.includes("/partners") ||
    currentPath.includes("/repository") ||
    currentPath.includes("/ourexperience") ||
    currentPath.includes("/privacypolicy") ||
    currentPath.includes("/termsconditions") ||
    currentPath.includes("/refundcancellationpolicy") ||
    currentPath.includes("/ourapproach") ||
    currentPath.includes("/register") ||
    currentPath.includes("/Forgetpassword") ||
    currentPath.includes("/GrowSuccessMessage") ||
    currentPath.includes("/GrowFailMessage") ||
    currentPath.includes("/NGODashboardNew") ||
    currentPath.includes("/projectsummaryreport")
  ) {
    navigate(currentPath);
  } else {
    // if (loggedIn) {
    //     navigate('/');
    // }
    // else {
    //     // Redirect the user to login page if he has not logged in And is trying to access other pages
    //     navigate(`/login`);
    // }
    if (!loggedIn) {
      // Redirect the user to login page if he has not logged in And is trying to access other pages
      if (currentPath.includes("/login")) navigate("/login");
      else {
        var arr = currentPath.split("/");
        var stringPath = "/" + arr[1];
        let list = [];
        if (loggedIn) {
          const state = store.getState();
          const userMenuData = state?.user?.userMenuData;
          if (userMenuData) {
            list = userMenuData.filter(
              (menuItem) =>
                menuItem?.mainMenu?.route?.toLowerCase() ===
                  stringPath.toLowerCase() ||
                menuItem?.subMenus?.some(
                  (subMenu) =>
                    subMenu?.route?.toLowerCase() === stringPath.toLowerCase()
                )
            );
          }
        }
        if (list.length === 0 && currentPath !== "/") navigate("/PageNotFound");
        else navigate(`/`);
      }
    } else {
      if (currentPath.includes("/login")) {
        let UserInfo = store.getState().user.userData;
        if (UserInfo.roleId == 1) {
          // Grow Master Admin
          navigate("/AdminDashboard");
          navigate("/ProjectManagementDashboard");
        } else if (UserInfo.roleId == 2) {
          // NGO Admin
          navigate("/NGODashboardNew");
        } else if (UserInfo.roleId == 3) {
          // Consumer
          navigate("/home");
        }
      } else {
        var arr = currentPath.split("/");
        var stringPath = "/" + arr[1];
        let list = [];
        if (loggedIn) {
          const state = store.getState();
          const userMenuData = state?.user?.userMenuData;
          if (userMenuData) {
            list = userMenuData.filter(
              (menuItem) =>
                menuItem?.mainMenu?.route?.toLowerCase() ===
                  stringPath.toLowerCase() ||
                menuItem?.subMenus?.some(
                  (subMenu) =>
                    subMenu?.route?.toLowerCase() === stringPath.toLowerCase()
                )
            );
          }
        }
        //currentPath!== null
        if (
          currentPath.includes("/projectdetails") ||
          currentPath.includes("/Epicdata") ||
          currentPath.includes("/TreeTiesAddProjectForms1Par1") ||
          currentPath.includes("/TreeTiesAddProjectForms1Loc") ||
          currentPath.includes("/TreeTiesAddProjectForms1Ris") ||
          currentPath.includes("/TreeTiesAddProjectForms1Obj") ||
          currentPath.includes("/TreeTiesAddProjectForms1Cos") ||
          currentPath.includes("/TreeTiesAddProjectForms1Sit") ||
          currentPath.includes("/TreeTiesAddProjectForms1Spo") ||
          currentPath.includes("/TreeTiesAddProjectForms1Own") ||
          currentPath.includes("/TreeTiesAddProjectForms1Lay") ||
          currentPath.includes("/TreeTiesAddProjectForms1Colla") ||
          currentPath.includes("/TreeTiesAddProjectForms1ImageUpload") ||
          currentPath.includes("TreeTiesAddProjectForms1WaterParameter") ||
          currentPath.includes("/Profile") ||
          currentPath.includes("/projectsummaryreport") ||
          currentPath.includes("AddPointOfContact") ||
          currentPath.includes("PartnershipsMasterData") ||
          currentPath.includes("TreesSpeciesMasterData") ||
          currentPath.includes("AddDashboardURL")
        ) {
          navigate(currentPath);
        } else {
          if (list.length === 0 && currentPath !== "/")
            navigate("/PageNotFound");
          else navigate(currentPath);
        }
      }
    }
  }
};
//const userMenuData = store.getState().user.userMenuData;
const NgoUserRoute = ({ Component: Component, ...rest }) => {
  //let currentPath = globalHistory.location.pathname;
  let loggedIn = store.getState().user.userData !== null;
  let UserInfo = store.getState().user.userData;
  if (!loggedIn) {
    return <Redirect from="" to="/pageNotFound" noThrow />;
  } else {
    if (UserInfo.roleId == 2) {
      // NGO User
      return <Component {...rest} />;
    } else {
      return <Redirect from="" to="/pageNotFound" noThrow />;
    }
  }
};
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = { id: "" };
    this.mounted = true;
  }
  componentDidMount() {
    userAuthenticationRouting();
  }

  render() {
    return (
      <Router>
        <HomePageNew path="/" />
        <HomePageNew path="home" />
        <Login path="login" />
        <ForgetPassword path="Forgetpassword" />
        <ResetPassword path="ResetPassword/:id" />
        <GrowAlarm path="growalarms" />
        <Register path="register" />
        <Projectslisting path="projects" />
        <Projectdetails path="projectdetails/:id/:index" />
        <ProjectDetailsNew1st path="projectdetailsnew/:id/:index" />
        <PageNotFound path="PageNotFound" />
        <ApplicationList path="ApplicationList" />
        <GrowHead path="GrowHead" />
        <ScheduleMeeting path="ScheduleMeeting/:id" />
        <ProjectsList path="ProjectsList" />
        <FieldOfficerList path="FieldOfficerList" />
        <LayerMaster path="LayerMaster" />
        <TreeTypeMst path="TreeTypeMst" />
        <TreeSpeciesList path="TreeSpeciesList" />
        <ZoneTypeList path="ZoneTypeList" />
        <LifeFormList path="LifeFormList" />
        <Co2EmissionCalculator path="Co2EmissionCalculator/:id" />
        <Co2EmissionCalculator path="GrowCalculator/:id" />
        <Co2EmissionCalculator path="GrowCalculator" />
        <Payment path="Payment" />
        <PaymentList path="PaymentList" />
        <SuccessMessage path="SuccessMessage" />
        <SuccessMessage path="GrowSuccessMessage" />
        <FailureMessage path="FailureMessage" />
        <FailureMessage path="GrowFailMessage" />
        <Fundmappingwithprojects path="Fundmappingwithprojects" />
        <NGODashboard path="NGODashboard" />
        <DashboardNew path="NGODashboardNew" />
        <AdminDashboard path="AdminDashboard" />
        <ProjectManagementDashboard path="ProjectManagementDashboard" />
        <EnquiryDetails path="Registration/:type" />
        <AboutUs path="aboutus" />
        <ContactUs path="contactus" />
        <Partners path="partners" />
        <Repository path="repository" />
        <Platform path="platform" />
        <PrivacyPolicy path="privacypolicy" />
        <TermsConditions path="termsconditions" />
        <OurApproach path="ourapproach" />
        <ProjectSummaryReport path="projectsummaryreport/:id/:index" />
        <RefunCancellationPolicy path="refundcancellationpolicy" />
        <TreeTiesAddProjectForms1Par1 path="TreeTiesAddProjectForms1Par1/:id" />
        <TreeTiesAddProjectForms1Cos path="TreeTiesAddProjectForms1Cos/:id" />
        <TreeTiesAddProjectForms1Spo path="TreeTiesAddProjectForms1Spo/:id" />
        <TreeTiesAddProjectForms1Ris path="TreeTiesAddProjectForms1Ris/:id" />
        <TreeTiesAddProjectForms1Obj path="TreeTiesAddProjectForms1Obj/:id" />
        <TreeTiesAddProjectForms1Sit path="TreeTiesAddProjectForms1Sit/:id" />
        <TreeTiesAddProjectForms1Obj
          path="TreeTiesAddProjectForms1Obj"
          Component={TreeTiesAddProjectForms1Obj}
        />
        <TreeTiesAddProjectForms1Loc
          path="TreeTiesAddProjectForms1Loc/:id"
          Component={TreeTiesAddProjectForms1Loc}
        />
        <TreeTiesAddProjectForms1Own path="TreeTiesAddProjectForms1Own/:id" />
        <TreeTiesAddProjectForms1Lay
          path="TreeTiesAddProjectForms1Lay/:id"
          Component={TreeTiesAddProjectForms1Lay}
        />
        <TreeTiesAddProjectForms1Colla
          path="TreeTiesAddProjectForms1Colla/:id"
          Component={TreeTiesAddProjectForms1Colla}
        />
        <TreeTiesAddProjectForms1ImageUpload
          path="TreeTiesAddProjectForms1ImageUpload/:id"
          Component={TreeTiesAddProjectForms1ImageUpload}
        />
        <TreeTiesAddProjectForms1WaterParameter
          path="TreeTiesAddProjectForms1WaterParameter/:id"
          Component={TreeTiesAddProjectForms1WaterParameter}
        />
        <NgoUserRoute path="Profile" Component={Profile} />

        <OurExperience
          path="ourexperience"
          className={
            this.state.ourexperience === true ? "" : "experiencepagebgcont"
          }
        />
        <EpicData path="Epicdata" Component={EpicData} />
        <UploadTimeSeriesImages path="UploadTimeSeriesImages" />
        <ApproveGroundSamplingData path="ApproveGroundData" />
        <AddPointOfContact path="AddPointOfContact" />
        <PartnershipsMasterData path="PartnershipsMasterData" />
        <TreesSpeciesMasterData path="TreesSpeciesMasterData" />
        <AddDashboardURL path="AddDashboardURL" />
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.user.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserData: (data) => dispatch({ type: "SET_USER_DETAILS", data: data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
