import config from "../../../../utils/config";

async function S3GeoJsonData(projectName, data) {
  try {
    let response = await fetch(
      `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/` +
        projectName +
        "/" +
        data
    );
    let responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
  }
}
export default S3GeoJsonData;
