import { useState, useEffect } from "react";
import React, { Component } from "react";
import "react-circular-progressbar/dist/styles.css";
import modaltreeimg from "../../../assets/treedetail-img.jpg";
import config from "../../../utils/config";
import { globalHistory } from "@reach/router";
import "react-datepicker/dist/react-datepicker.css";
import ToastNotification from "./../../Common/ToastNotification/ToastNotification";
import GoogleMapNew from "./GoogleMapNew";
import { useDispatch, useSelector } from "react-redux";
import project from "../../../Reducers/Project";

export default function ProjectDetailsNew1st(props) {
  const [ProjectId, setProjectId] = useState("");
  const [ProjectDetail, setProjectDetail] = useState(null);
  const [ProjectIndex, setProjectIndex] = useState(1);
  const [isshowMapView, setIsshowMapView] = useState(true);
  const [TreeItem, setTreeItem] = useState(null);
  const [GoogleMapApiKey, setGoogleMapApiKey] = useState("");
  const [isLoaded, setIsLoader] = useState(false);
  const [iframeguid, setIframeGuid] = useState(false);
  const reduxState = useSelector((state) => state.project);
  const dispatch = useDispatch();

  useEffect(() => {
    project(
      reduxState?.landParcelData,
      dispatch({ type: "SET_LANDPARCEL_DETAILS", data: [] })
    );
  }, []);

  useEffect(() => {
    project(
      reduxState?.waterBodiesData,
      dispatch({ type: "SET_WATERBODIES_DETAILS", data: [] })
    );
  }, []);
  useEffect(() => {
    project(
      reduxState?.filterSpeciesData,
      dispatch({ type: "SET_FILTER_SPECIES", data: [] })
    );
  }, []);

  useEffect(() => {
    let currentPath = globalHistory.location.pathname;
    let Id = currentPath.split(":")[1].replace("/", "");
    let Index = currentPath.split(":")[2].replace("/", "");
    setProjectId(Id);
    setProjectIndex(Index);
    GetProjectDetailById(Id);
    GetGlobalSettings("Google Map");
  }, []);

  const GetGlobalSettings = (GroupName) => {
    let UserDetail = {
      GroupName: GroupName,
    };
    let uri = `${config.API_HOST_URL}/api/projects/GetGlobalGeoupValue`;
    let fetchOptions = {
      method: "POST",
      body: JSON.stringify(UserDetail),
      headers: {
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch(uri, fetchOptions)
      .then((response) => {
        return response.json().then((data) => {
          if (data.success) {
            let responseData = data.data;
            setGoogleMapApiKey(MapKeyInArray(responseData, "GoogleMapApiKey"));
          } else {
            let errors = {};
            errors["message"] = data.message;
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const MapKeyInArray = (responseData, keyname) => {
    let myArray = responseData.find((obj) => obj.key === keyname);
    if (myArray) {
      return myArray.value;
    }
  };

  const GetProjectDetailById = (ProjectId) => {
    let UserDetail = {
      ProjectId: ProjectId,
    };
    let uri = `${config.API_HOST_URL}/api/projects/GetProjectDetailById`;
    let fetchOptions = {
      method: "POST",
      body: JSON.stringify(UserDetail),
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch(uri, fetchOptions)
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => {
            if (data.success) {
              setProjectDetail(data.data);
              // getIframedata();
            } else {
              let errors = {};
              errors["message"] = data.message;
              // setErrors(errors);
              setProjectDetail([]);
            }
          });
        } else {
          return response.json().then((data) => {
            let errors = {};
            errors["message"] = data.message;
            //setErrors(errors);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // setErrorMsg(error);
        //setIsLoader(false);
      });
  };

  const getIframedata = () => {
    var iframeguid;
    let url = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/${ProjectDetail.projectName}/${ProjectDetail.projectLocationFile}`;
    fetch(url)
      .then(function (res) {
        return res.json();
      })
      .then((data) => {
        var result = JSON.stringify(data);
        iframeguid = btoa(JSON.stringify(result));
        // setIframeGuid(iframeguid);
      })
      .catch(function (err) {
        console.log(err, " error");
      });
  };

  return (
    <>
      {ProjectDetail && isshowMapView && (
        <div className="viewmap">
          {/* {ProjectDetail.isShowLayersOnMap === true && ( */}
          <GoogleMapNew
            projectIndex={ProjectIndex}
            projectDetail={ProjectDetail}
            locationCords={ProjectDetail.locationCords}
            projectTreeType={TreeItem}
            GoogleMapApiKey={GoogleMapApiKey}
          ></GoogleMapNew>
          {/* )} */}
        </div>
      )}
    </>
  );
}
