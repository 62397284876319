import React from "react";
import { Polygon } from "@react-google-maps/api";
export default function ProjectBoundary({ locationCords }) {
  console.log("locationCords", locationCords);
  const options = {
    strokeWeight: 2,
    strokeOpacity: 1,
    strokeColor: "#0000ff", //"#0000ff", //"#0cc618",
    fillOpacity: 0,
    fillColor: "#333d72", //"#0cc618",
  };

  const ConvertToLatLong = () => {
    let latLongArray = [];
    let eachlatLong = {};
    let latArray = [];
    let lngArray = [];

    for (var i = 0; i < locationCords.length; i++) {
      if (i % 2 == 0) {
        eachlatLong.lng = locationCords[i];
        lngArray.push(locationCords[i]);
      } else {
        eachlatLong.lat = locationCords[i];
        latArray.push(locationCords[i]);
        latLongArray.push(eachlatLong);
        eachlatLong = {};
      }
    }

    if (latArray.length !== lngArray.length) {
      console.log("Invalid Polygon Location Cords in database.");
    }

    return latLongArray;
  };

  return (
    <>
      <Polygon paths={ConvertToLatLong()} options={options} />;
    </>
  );
}
