import React, { Component } from "react";
import StickyHeader from "react-sticky-header";
import "react-sticky-header/styles.css";

import { connect } from "react-redux";
import { navigate } from "@reach/router";
import Routes from "../../Routes";
import FrontEndHeader from "./FrontEndHeader";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Layout/Footer";

class FrontEndLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  logout = () => {
    this.props.clearUserData();
    navigate("/login");
  };

  componentDidMount() {}
  render() {
    let currentPath = window.location.pathname;
    return (
      <div className="layout_cont">
        {!currentPath.includes("/ResetPassword") &&
        !currentPath.includes("/ScheduleMeeting") &&
        !currentPath.includes("/Payments") &&
        !currentPath.includes("/SuccessMessage") &&
        !currentPath.includes("/FailureMessage") &&
        !currentPath.includes("/Registration") ? (
          <div>
            <StickyHeader
              header={<FrontEndHeader logout={this.logout} />}
            ></StickyHeader>
            <Sidebar />
            {currentPath.includes("/NGODashboardNew") ? (
              <div className="main-panel powerbidashboard_wrap">
                <Routes />
              </div>
            ) : (
              <div className="main-panel">
                <Routes />
              </div>
            )}
            {!currentPath.includes(
              "/projectdetailsnew",
              "/projectsummaryreport"
            ) ? (
              <div className="wrapper footer_container">
                <Footer />
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div>
            <div className="main-panel">
              <Routes />
            </div>
            <div className="wrapper footer_container">
              <Footer />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.user.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearUserData: () => dispatch({ type: "CLEAR_USER_DETAILS" }),
    setReducer: (data, type) =>
      dispatch({
        type: type,
        data: data,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontEndLayout);
