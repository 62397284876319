import React, { Component } from "react";
import Select from 'react-select';
import { Checkbox, Container, Item } from "semantic-ui-react";
import config from "../../utils/config";
import { Scrollbars } from 'react-custom-scrollbars';
import Pagination from "react-js-pagination";
import { left } from "@patternfly/react-core/dist/js/helpers/Popper/thirdparty/popper-core";
class Fundmappingwithprojects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            Ngo: "",
            Project: "",
            WebSite: "",
            NgoList: [],
            ProjectList: [],
            selectedNgoOption: null,
            selectedProjectOption: null,
            selectedWebSiteOption: null,
            WebSiteName: [],
            WebsiteGuid: "",
            WebSiteList: [],
            checked: false,
            List: [],
            Count: 0,
            activePage: 1,
            pagesize: 10,
            PageRange: 10,
            SearchText: '',
            SortBy: 'created_at',
            SortType: false,
            Up: "active",
            Down: "",
            NgoName: "",
            ProjectName: ""
        }
        this.mounted = true;

    }
    componentDidMount() {
        this.mounted = true;
        this.GetApprovedNgo();
        this.GetWebSiteList();
        this.GetFundMappingWithProjectList();
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    handleNgoChange = (selectedNgoOption) => {
        if (this.mounted) {
            this.setState({
                selectedNgoOption,
                NgoId: selectedNgoOption.value,
                NgoName: selectedNgoOption.label
            },
                () => this.GetApprovedProject()
            );
        }
    };
    handleProjectChange = (selectedProjectOption) => {
        if (this.mounted) {
            this.setState({
                selectedProjectOption,
                ProjectId: selectedProjectOption.value,
                ProjectName: selectedProjectOption.label

            },
                () => this.GetWebSiteList()
            );
        }
    };
    handleWebsiteSelect = (e, selectedWebSiteOption, index) => {
        if (this.mounted) {
            let ref = 'ref_' + index
            this.setState({
                WebsiteGuid: selectedWebSiteOption.value,
                WebSiteName: selectedWebSiteOption.label,
                checked: ref.checked
            }
            );
            if (e.target.checked) {
                var array = [...this.state.WebsiteGuid];
                array.push(selectedWebSiteOption.value);
                this.setState({ WebsiteGuid: array });
            }
            if (this.state.WebsiteGuid.length > 0 && !e.target.checked) {
                var array = [...this.state.WebsiteGuid];
                var index = array.indexOf(e.target.value, 1);
                if (index >= -1)
                    array.splice(index, 1);
                this.setState({ WebsiteGuid: array });
            }
        }
    };
    GetApprovedNgo = () => {
        if (this.mounted) {
            let uri = `${config.API_HOST_URL}/api/Co2Calculation/GetApprovedNgo/${false}`;
            let fetchOptions = {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };
            fetch(uri, fetchOptions).then((resp) => resp.json())
                .then(data => {
                    if (this.mounted) {
                        let Records = JSON.parse(data.NgoList);
                        this.setState({
                            NgoList: Records
                        });
                        if (Records.length == 1)
                            this.setState({ NgoId: Records[0].value, NgoName: Records[0].label });

                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
    GetApprovedProject = () => {
        if (this.mounted) {
            let uri = `${config.API_HOST_URL}/api/Fundmappingwithprojects/GetApprovedProject/${this.state.NgoId}`;
            let fetchOptions = {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };
            fetch(uri, fetchOptions).then((resp) => resp.json())
                .then(data => {
                    if (data.ProjectList != "null") {
                        if (this.mounted) {
                            let Records = JSON.parse(data.ProjectList);
                            this.setState({
                                ProjectList: Records
                            });
                            if (Records.length == 1)
                                this.setState({ ProjectId: Records[0].value, ProjectName: Records[0].label });
                        }
                    }
                    else {
                        if (this.mounted) {
                            this.setState({
                                ProjectList: [],
                                selectedProjectOption: null
                            })

                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        }
    }
    clearFields = () => {
        if (this.mounted) {
            this.setState({
                ProjectList: [],
                selectedNgoOption: null,
                selectedProjectOption: null,
                WebSiteName: [],
                WebsiteGuid: "",
                checked: false
            })
        }

    }
    validateForm = () => {
        let fields;
        fields = {
            Ngo: this.state.selectedNgoOption,
            Project: this.state.selectedProjectOption,
            WebSite: this.state.WebsiteGuid.length > 0 ? "WebSite" : ""
        }
        let errors = {};
        let formIsValid = true;
        if (!fields["Ngo"]) {
            formIsValid = false;
            errors["Ngo"] = "Please select Ngo.";
        }
        if (!fields["Project"]) {
            formIsValid = false;
            errors["Project"] = "Please select Project.";
        }
        if (!fields["WebSite"]) {
            formIsValid = false;
            errors["WebSite"] = "Please select WebSite.";
        }
        if (this.mounted) {
            this.setState({ errors: errors });
        }
        return formIsValid;
    }
    GetWebSiteList = () => {
        if (this.mounted) {
            let uri = `${config.API_HOST_URL}/api/Fundmappingwithprojects/GetWebSiteList`;
            let fetchOptions = {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };
            fetch(uri, fetchOptions).then((resp) => resp.json())
                .then(data => {
                    if (this.mounted) {
                        let Records = JSON.parse(data.WebSiteList);
                        this.setState({
                            WebSiteList: Records
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        }
    }
    handleSortClick = (SortBy) => {
        if (this.mounted) {
            let up = ""
            let down = "active"
            let sortType = this.state.SortType

            if (SortBy === this.state.SortBy)
                sortType = !this.state.SortType

            if (sortType) {
                up = "active"
                down = ""
            }
            this.setState({
                SortBy: SortBy,
                SortType: sortType,
                Up: up,
                Down: down
            }, function () { this.GetFundMappingWithProjectList(); })
        }
    }
    Fundmappingwithprojectsubmit = () => {
        if (this.validateForm()) {
            if (this.mounted) {
                let data = {
                    NgoId: this.state.NgoId,
                    NgoName: this.state.NgoName,
                    ProjectId: this.state.ProjectId,
                    ProjectName: this.state.ProjectName,
                    WebSiteGuid: this.state.WebsiteGuid.length > 0 ? this.state.WebsiteGuid : ""
                }
                let uri = `${config.API_HOST_URL}/api/Fundmappingwithprojects/Fundmappingwithprojectsubmit`;
                let fetchOptions = {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        Authorization: `Bearer ${this.props.token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                };
                fetch(uri, fetchOptions)
                    .then(response => {
                        return response.json().then(data => {
                            //let responseData = data.data;
                            this.GetFundMappingWithProjectList();
                        });
                    })
                    .catch(error => {
                        console.log(error);
                    });
                this.clearFields();

            }
        }
    }
    GetFundMappingWithProjectList = () => {
        if (this.mounted) {
            let SearchText = 'null'
            if (this.state.SearchText !== '')
                SearchText = this.state.SearchText
            let uri = `${config.API_HOST_URL}/api/Fundmappingwithprojects/GetFundMappingWithProjectList/${this.state.activePage}/${this.state.pagesize}/${SearchText}/${this.state.SortType}/${this.state.SortBy}`;
            let fetchOptions = {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };
            fetch(uri, fetchOptions).then((resp) => resp.json()).
                then(data => {
                    if (this.mounted) {
                        if (data !== 0) {
                            this.setState({
                                List: JSON.parse(data.List),
                                Count: data.Count
                            });
                        }
                        else {
                            alert(data.List)
                            this.setState({
                                List: [],
                                Count: 0
                            });
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        }

    }
    handleChange = (e) => {
        if (this.mounted) {
            this.setState({
                SearchText: e.target.value
            });
        }
    }
    handlePageChange = pageNumber => {
        if (this.mounted) {
            this.setState({ activePage: pageNumber }, function () {
                this.GetFundMappingWithProjectList();
            });
        }
    };
    handleSearchByEnter = e => {
        if (e.key === "Enter") {
            if (this.mounted) {
                this.setState(
                    {
                        SearchText: e.target.value,
                        activePage: 1
                    },
                    function () {
                        this.GetFundMappingWithProjectList();
                    }
                );
            }
        }
    };

    render() {
        const { selectedNgoOption } = this.state;
        const { selectedProjectOption } = this.state;
        const renderThumb = ({ style, ...props }) => {
            const thumbStyle = {
                backgroundColor: 'rgba(51, 51, 51, 1)'
            };
            return <div style={{ ...style, ...thumbStyle }} {...props} />;
        };
        const CustomScrollbars = props => (
            <Scrollbars
                renderThumbHorizontal={renderThumb}
                renderThumbVertical={renderThumb}
                {...props}
            />
        );

        return (
            <div className="fundmappro_container">
                <div className="fundmappro_cont">
                    <div className="form-group field halfwidth">
                        <label htmlFor="nameofngo">
                            Select Ngo
                        </label>
                        <Select
                            className="basic-single"
                            value={selectedNgoOption}
                            onChange={this.handleNgoChange}
                            options={this.state.NgoList}
                            placeholder="Select Ngo"
                            maxMenuHeight={200}
                        />
                        <div className="mandatory has-error">{this.state.errors.Ngo}</div>
                    </div>
                    <div className="form-group field halfwidth">
                        <label htmlFor="nameofngo">
                            Select Project
                        </label>
                        <Select
                            className="basic-single"
                            value={selectedProjectOption}
                            onChange={this.handleProjectChange}
                            options={this.state.ProjectList}
                            placeholder="Select Project"
                            menuPlacement="top"
                            maxMenuHeight={200}

                        />
                        <div className="mandatory has-error">{this.state.errors.Project}</div>
                    </div>
                    <div className="form-group field websitefieldcont">
                        <label htmlFor="nameofngo">
                            Select WebSite
                        </label>
                        <div className="websitescheckbxwrap">
                            {this.state.WebSiteList.map((item, index) => {
                                return (
                                    <div>
                                        <label>{item.label}</label>
                                        <input
                                            type="checkbox"
                                            name={item.label}
                                            value={item.value}
                                            onClick={(e) => this.handleWebsiteSelect(e, item, index)}
                                            checked={this.state.checked}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="mandatory has-error">{this.state.errors.WebSite}</div>
                    </div>
                    <div className="form-group field foundmapbtn_cont">
                        <button onClick={this.Fundmappingwithprojectsubmit}>SUBMIT </button>
                    </div>
                </div>
                <div className="growalarm_container applist_cont">
                    <Container>
                        <div className="growalarm_content">
                            <div className="commontblfltr_wrap">
                                <div className="showtxtnbtns_wrap paymentlistcont_wrap">
                                    <span className="showingtxt totalReport">Showing&nbsp;<strong> {this.state.Count > 10 && <>{(this.state.activePage - 1) * 10 + 1} - {this.state.Count < this.state.activePage * 10 ? this.state.Count : this.state.activePage * 10} of </>}{this.state.Count} </strong></span>

                                </div>
                            </div>
                            <div className="commontbl_wrap">
                                <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>

                                    <table className="commontbl_cont">
                                        <thead>
                                            <tr>
                                                <th>Ngo Name
                                                    <div className="sortArrow" onClick={() => this.handleSortClick("NgoName")} >
                                                        <i className={this.state.SortBy === "NgoName" ? `fas fa-sort-up ${this.state.Up}` : "fas fa-sort-up"} ></i>
                                                        <i className={this.state.SortBy === "NgoName" ? `fas fa-sort-down ${this.state.Down}` : "fas fa-sort-down"} ></i>
                                                    </div>
                                                </th>
                                                <th>Project Name
                                                    <div className="sortArrow" onClick={() => this.handleSortClick("ProjectName")} >
                                                        <i className={this.state.SortBy === "NgoName" ? `fas fa-sort-up ${this.state.Up}` : "fas fa-sort-up"} ></i>
                                                        <i className={this.state.SortBy === "NgoName" ? `fas fa-sort-down ${this.state.Down}` : "fas fa-sort-down"} ></i>
                                                    </div>
                                                </th>
                                                <th>WebSite Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="tblsearch_cont">
                                                <td colSpan="9">
                                                    <div className="tblsearch">
                                                        <i className="fa fa-search"></i><input autoFocus type="text" placeholder="Search by Ngo,Project" onKeyPress={this.handleSearchByEnter} value={this.state.SearchText} onChange={this.handleChange} />
                                                    </div>
                                                </td>
                                            </tr>
                                            {this.state.List.map((item, index) => (
                                                <tr>
                                                    <td>{item.NgoName}</td>
                                                    <td>{item.ProjectName}</td>
                                                    <td>
                                                        {item.WebSiteName}
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </CustomScrollbars>
                            </div>
                            <div className="wrapper">
                                {this.state.List ? this.state.Count > 0 ? (this.state.Count > this.state.pagesize &&
                                    <div className="paginationDiv">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.pagesize}
                                            totalItemsCount={this.state.Count}
                                            pageRangeDisplayed={this.state.PageRange}
                                            onChange={this.handlePageChange}
                                            nextPageText={<i className='fa fa-angle-right'></i>}
                                            prevPageText={<i className='fa fa-angle-left'></i>}
                                            lastPageText={<i className="fa fa-angle-double-right"></i>}
                                            firstPageText={<i className="fa fa-angle-double-left"></i>}
                                        />
                                    </div>) : <div>No Results Found</div> : <div></div>
                                }
                            </div>
                        </div>
                    </Container>
                </div>


            </div>



        )
    }
}
export default Fundmappingwithprojects