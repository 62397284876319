import React, { Component } from "react";
import config from "../../utils/config";
import { connect } from "react-redux";
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import ReactDOM from "react-dom";
import Co2CertificateAO from "../Co2Calculator/Certificate/Co2CertificateAO";
class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: "",
      razorpayKeyID: "",
      razorpaykeySecret: "",
      amount: this.props.Amount, //this amount will come from props.from parent component..
      description: "",
      currency: "",
      NgoId: this.props.NgoId,
      WebsiteGuid: this.props.WebsiteGuid,
      PrimaryColor: this.props.Color,
      CarbonEmissionInTons: this.props.CarbonEmissionInTons,
      ReturnUrl: this.props.ReturnUrl,
      ShareAmount: this.props.ShareAmount,
    };
    this.mounted = true;
  }
  componentDidMount() {
    this.mounted = true;
    //if (this.props.WebsiteGuid === "49e5a583-54e4-4634-a6c2-19f54b63748d") {
    this.setState({ amount: this.props.ShareAmount });
    //}
  }
  componentDidUpdate(prevProps) {
    //if (this.props.WebsiteGuid === "49e5a583-54e4-4634-a6c2-19f54b63748d") {
    if (this.props.ShareAmount !== prevProps.ShareAmount) {
      this.setState({ ShareAmount: this.props.ShareAmount });
      this.setState({ amount: this.props.ShareAmount });
    }
    if (this.props.CarbonEmissionInTons !== prevProps.CarbonEmissionInTons) {
      this.setState({
        CarbonEmissionInTons: this.props.CarbonEmissionInTons,
      });
    }
    // }
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  generatePDF = () => {
    const component = (
      <Co2CertificateAO
        Treecount={this.props.Treecount}
        Name={this.props.name}
      />
    ); // Render your React component
    const htmlContent = document.createElement("div"); // Create a temporary div element
    ReactDOM.render(component, htmlContent);

    const options = {
      margin: [0, 0], // Increase the margins (in millimeters)
      filename: "certificate.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      pagebreak: {
        mode: ["avoid-all", "css"],
        before: ".page-break-before",
        after: ".page-break-after",
        avoid: ".avoid-this-class",
      }, // Example for page mode
      jsPDF: {
        format: "a4",
        orientation: "landscape",
        unit: "mm",
        compressPDF: true,
        putOnlyUsedFonts: true,
        fontSize: 14,
      },
    };

    // Function to create a PDF using html2pdf and jsPDF
    html2pdf()
      .from(htmlContent)
      .set(options)
      .output("blob")
      .then((pdfBlob) => {
        const pdfFile = new File([pdfBlob], "certificate.pdf", {
          type: "application/pdf",
        });
        this.saveToS3Path(pdfFile);
      });
  };

  saveToS3Path = (pdfFile) => {
    let file = pdfFile;
    const formData = new FormData();
    formData.append("FormFiles", file);
    let uri =
      `${config.API_HOST_URL}/api/Payement/UploadCertificate/` +
      this.props.WebsiteGuid;
    let fetchOptions = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        Accept: "application/pdf",
        ContentType: "multipart/form-data",
      },
    };
    try {
      const response = fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = response.json();
      if (data.success) {
        return data;
      }
    } catch (error) {
      console.log("Request failed", error);
      return null;
    }
  };
  requestOrder = () => {
    if (this.CheckNutritionCost()) {
      let Data = {
        Amount: this.state.amount,
        WebsiteGuid: this.props.WebsiteGuid,
      };
      let uri = `${config.API_HOST_URL}/api/Payement/ProcessRequestOrder`;
      let fetchOptions = {
        method: "POST",
        body: JSON.stringify(Data),
        headers: {
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      fetch(uri, fetchOptions)
        .then((response) => {
          return response.json().then((data) => {
            if (this.mounted)
              this.setState(
                {
                  orderId: data.orderId,
                  description: data.description,
                  currency: data.currency,
                },
                function () {
                  this.generatePDF();
                  this.openCheckout();
                }
              );
          });
        })
        .then((error) => {
          console.log(error);
        });
    }
  };

  openCheckout = () => {
    let WebsiteGuid = this.props.WebsiteGuid;
    let ReturnUrl = this.props.ReturnUrl;
    let ReturnSuccessUrl =
      this.state.WebsiteGuid == "1bbbc1d4-1018-4755-aa12-1f1c70835804"
        ? `${ReturnUrl}GrowSuccessMessage`
        : `${ReturnUrl}SuccessMessage`;
    let CalculatorUrl =
      this.state.WebsiteGuid == "1bbbc1d4-1018-4755-aa12-1f1c70835804"
        ? "GrowCalculator"
        : "Co2EmissionCalculator";
    //let ReturnFailureUrl = `${config.WebsiteUrl}FailureMessage`
    let ReturnFailureUrl = "1bbbc1d4-1018-4755-aa12-1f1c70835804"
      ? `${ReturnUrl}GrowFailMessage`
      : `${ReturnUrl}FailureMessage`;
    let PrimaryColor = this.props.Color;
    let NgoId = this.props.NgoId;
    let CarbonEmissionInTons = this.props.CarbonEmissionInTons;
    let WebsiteName = this.props.WebsiteName;
    let NgoName = this.props.NgoName;
    let Token = this.props.token; //we can not use props inside handler that why its store in varaible and then passing this variable in handler.
    let firstname = this.props.name;
    let Amount = this.state.amount;
    let Email = this.props.emailid;
    let TreeCount = this.props.Treecount;
    let TotalDistance = this.props.TotalDistance;
    let pancard = this.props.pancard;
    let address = this.props.address;
    let WebSiteLogoName = this.props.WebSiteLogoName;
    let AOLogoName = this.props.AOLogoName;
    let Type = this.props.Type;
    let Make = this.props.Make;
    let Model = this.props.Model;
    let Series = this.props.Series;
    let DrivingMode = this.props.DrivingMode;
    let Mileage = this.props.Mileage;
    let FuelType = this.props.FuelType;
    let EngineFuelType = this.props.EngineFuelType;
    let DonationAmountorNoOfTrees = this.props.DonationAmountorNoOfTrees;
    let VehicleType = this.props.VehicleType;
    let EngineType = this.props.EngineType;
    var options = {
      key_id: this.state.razorpayKeyID,
      key_secret: this.state.razorpaykeySecret,
      amount: this.state.Amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: this.state.currency,
      //"name": "ranjeet",
      description: this.state.description,
      image: `${config.Amazon_WebService_CDNUrl}/websites/staticimages/${WebSiteLogoName}`,
      imageAO: `${config.Amazon_WebService_CDNUrl}/websites/staticimages/${AOLogoName}`,
      order_id: this.state.orderId,
      handler: function (response) {
        //alert(response.razorpay_payment_id);
        //alert(response.razorpay_order_id);
        //alert(response.razorpay_signature)
        let Data = {
          PaymentId: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          OrderId: response.razorpay_order_id,
          NgoId: NgoId,
          WebsiteGuid: WebsiteGuid,
          CarbonEmissionInTons: CarbonEmissionInTons,
          NgoName: NgoName,
          WebsiteName: WebsiteName,
          firstname: firstname,
          Amount: Amount,
          Email: Email,
          NoOfTree: TreeCount,
          TotalDistance: TotalDistance,
          pancard: pancard,
          address: address,
          WebSiteLogoName: WebSiteLogoName,
          AOLogoName: AOLogoName,
          Type: Type,
          Make: Make,
          Model: Model,
          Series: Series,
          DrivingMode: DrivingMode,
          Mileage: Mileage,
          JourneyFuelType: FuelType,
          EngineFuelType: EngineFuelType,
          DonationAmountorNoOfTrees: DonationAmountorNoOfTrees,
          VehicleType: VehicleType,
          EngineType: EngineType,
        };
        let uri = `${config.API_HOST_URL}/api/Payement/CompleteOrderProcess`;
        let fetchOptions = {
          method: "POST",
          body: JSON.stringify(Data),
          headers: {
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        };
        fetch(uri, fetchOptions)
          .then((response) => {
            return response.text().then((data) => {
              let redirecturl;
              if (JSON.parse(data) === "Success") {
                redirecturl = `${ReturnSuccessUrl}?Url=${ReturnUrl}${CalculatorUrl}/:${WebsiteGuid}&Color=${PrimaryColor}`;
              } else {
                redirecturl = `${ReturnFailureUrl}?Url=${ReturnUrl}${CalculatorUrl}/:${WebsiteGuid}&Color=${PrimaryColor}`;
              }
              window.location = redirecturl;
            });
          })
          .then((error) => {
            console.log(error);
          });
      },
      prefill: {
        name: this.props.name,
        email: this.props.emailid,
        contact: this.props.countryPhoneCode + this.props.mobileno,
      },

      theme: {
        color: this.state.PrimaryColor, //"#feb224"
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  CheckNutritionCost = () => {
    let errors = {};
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    if (this.state.amount === 0) {
      errors["nutritioncost"] = "Please calculate carbon footprint";
    } else if (this.state.NgoId === "") {
      errors["nutritioncost"] = "Please select ngo";
    }
    if (this.props.name == "") {
      errors["name"] = "Please enter name.";
    } else if (this.props.name == ".") {
      errors["name"] = "Please enter valid name.";
    }
    if (this.props.emailid == "") {
      errors["emailid"] = "Please enter email id.";
    } else if (regex.test(this.props.emailid) === false) {
      errors["emailid"] = "Please enter valid email id.";
    }
    if (this.props.mobileno == "") {
      errors["mobileno"] = "Please enter  mobile no.";
    }
    // else if (this.props.mobileno == ".") {
    //     errors["mobileno"] = "Please enter valid moblile no.";
    // }
    if (this.props.pancard != "") {
      if (!regpan.test(this.props.pancard)) {
        errors["pancard"] = "Please enter valid pan card no.";
      }
      //  else {
      //     errors["pancard"] = "Please enter valid pancard no."
      //     // invalid pan card number
      // }
    }

    if (Object.keys(errors).length > 0) this.props.parentCallback(errors);
    else {
      this.props.parentCallback(errors);
      return true;
    }
  };
  render() {
    const websitebackgroundcolorcodestyle = {
      backgroundColor: this.state.PrimaryColor,
      borderColor: this.state.PrimaryColor,
    };
    return (
      <div>
        {this.props.WebsiteGuid === "49e5a583-54e4-4634-a6c2-19f54b63748d" && (
          <button
            style={websitebackgroundcolorcodestyle}
            onClick={this.requestOrder}
          >
            Pay ₹ {this.state.ShareAmount} to neutralise now
          </button>
        )}
        {this.props.WebsiteGuid !== "49e5a583-54e4-4634-a6c2-19f54b63748d" && (
          <div>
            <button
              style={websitebackgroundcolorcodestyle}
              onClick={this.requestOrder}
            >
              Pay ₹ {this.state.amount} to neutralise now
            </button>
          </div>
        )}
        {/* <button style={websitebackgroundcolorcodestyle} onClick={this.requestOrder} >PAY INR {this.state.amount} TO NEUTRALISE NOW</button> */}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
