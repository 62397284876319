import { combineReducers } from "redux";
import configuration from "./Configuration";
import user from "./User";
import registration from "../Components/EnquiryDetails/EnquiryReducer";
import project from "./Project";

export default combineReducers({
  configuration,
  user,
  registration,
  project,
});
