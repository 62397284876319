const initialState = {
  waterBodiesData: null,
  landParcelData: null,
  treeData: null,
  filterSpeciesData: null,
};

export default function project(state = initialState, action) {
  switch (action.type) {
    case "SET_WATERBODIES_DETAILS":
      return { ...state, waterBodiesData: action.data };
    case "SET_LANDPARCEL_DETAILS":
      return { ...state, landParcelData: action.data };
    case "SET_TREEDATA_DETAILS":
      return { ...state, treeData: action.data };
    case "SET_FILTER_SPECIES":
      return { ...state, filterSpeciesData: action.data };
    default:
      return state;
  }
}
