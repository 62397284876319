import config from "../../../utils/config";

 async function  s3KmlData (data) {
  try {
    // let response = await fetch(`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/Goa%20Pilot%20Project/samplinggrid_50m.kml`);

    let response =  await fetch(`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/Goa%20Pilot%20Project/`+ data);
    return response;
   } catch(error) {
    console.error(error);
  }
}
export default s3KmlData