import React, { useState, useCallback, useRef, useEffect } from "react";
import { Modal, Button } from "@patternfly/react-core";
import config from "../../../utils/config";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import ToastNotification from "../ToastNotification/ToastNotification";
import UploadedImg from "././images/Adlay_Grasses.jpg";

export default function TreeDetails(props) {
  const id = window.location.href.split("/")[4];
  const projectId = id.split(":")[1];
  const [isModalOpen, setIsModalOpen] = useState(props.IsOpen);
  const [count, setCount] = useState(0);
  const [fileControl, setFileControl] = useState(null);
  const [fileNameControl, setFileNameControl] = useState("");
  const [uploadfile, setUploadfile] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const fileInputRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [layerMstId, setLayerMstId] = useState("");
  const [editMode, setEditMode] = useState(props.editMode);

  const [location, setLocation] = React.useState("");

  const [treeData, setTreeData] = useState({});
  const [treeImage, setTreeImageData] = useState({});
  const [formData, setFormData] = useState({});
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [addButton, setAddButton] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [uploadFile, setUploadFile] = useState([]);
  const [uploadingPhotoForValue, setUploadingPhotoForValue] = useState("");
  const [LifeFormDataList, setLifeFormDataList] = useState([]);
  const [ZoneTypeData, setZoneTypeData] = useState([]);
  const [imageAdded, setImageAdded] = useState(true);
  const [speciesData, setSpeciesData] = useState([]);
  useEffect(() => {
    setTreeData(props?.eachTreeData);
    console.log("datasss", props.eachTreeData);
  }, [props?.eachTreeData]);
  useEffect(() => {
    setTreeImageData(props?.speceiestreedata);
  }, [props?.speceiestreedata]);

  useEffect(() => {
    GetZoneType();
    GetLifeFormDetails();
  }, []);

  useEffect(() => {
    if (!treeData.videos) {
      setTreeData((prevTreeData) => ({
        ...prevTreeData,
        videos: [],
      }));
    }
  }, [treeData, setTreeData]);

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    //setTreeData((prevData) => ({ ...prevData, [name]: value }));
  };

  const GetLifeFormDetails = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetLifeForm`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setLifeFormDataList(data);
        } else {
          console.error("Error:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error in request", error);
      });
  };

  const GetZoneType = () => {
    debugger;
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetZoneType`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setZoneTypeData(data);
        } else {
          console.error("Error:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error in request", error);
      });
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      date_of_capture: "",
      nameOfObserver: "",
      gridId: "",
      tree_id: "",
      lifeForm: "",
      zoneType: "",
      latin_name: "",
      common_name: "",
      tree_Status: "",
      tree_health: "",
      tree_height: "",
      tree_girth: "",
      notesObservation: "",
      treePlantedby: "",
      planted_date: "",
      species_name: "",
    },
  });

  useEffect(() => {
    GetAllSpeciesData();
    if (treeData) {
      setValue("date_of_capture", treeData?.date_of_capture || "");
      setValue("nameOfObserver", treeData?.nameOfObserver || "");
      setValue("gridId", treeData?.gridId || "");
      setValue("tree_id", treeData?.tree_id || "");
      setValue("lifeForm", treeData?.lifeForm || "");
      setValue("zoneType", treeData?.zoneType || "");
      setValue("latin_name", treeData?.latin_name || "");
      setValue("common_name", treeData?.common_name || "");
      setValue("tree_Status", treeData?.tree_Status || "");
      setValue("tree_height", treeData?.tree_height || "");
      setValue("tree_health", treeData?.tree_health || "");
      setValue("tree_girth", treeData?.tree_girth || "");
      setValue("notesObservation", treeData?.notesObservation || "");
      setValue("treeguid", treeData?.treeguid);
      setValue("planted_date", treeData?.planted_date);
      setValue("treePlantedby", treeData?.treePlantedby || "");
      setValue("species_name", treeData?.species_name || "");
      setValue("imageTreeUpload", treeData?.imageTreeUpload);
      setValue("videos", treeData?.videos);
    }
  }, [treeData, setValue]);

  const onSubmit = async (data) => {
    //debugger;

    let data1 = {
      date_of_capture: data?.date_of_capture,
      nameOfObserver: data?.nameOfObserver,
      gridId: data?.gridId,
      tree_id: data?.tree_id,
      lifeForm: data?.lifeForm,
      zoneType: data?.zoneType,
      latin_name: data?.latin_name,
      common_name: data?.common_name,
      tree_Status: data?.tree_Status,
      tree_health: data?.tree_health,
      tree_height: data?.tree_height,
      tree_girth: data?.tree_girth,
      notesObservation: data?.notesObservation,
      treePlantedby: data?.treePlantedby,
      planted_date: data?.planted_date,
      species_name: data?.species_name,
      treeguid: data?.treeguid,
      imageTreeUpload: data?.imageTreeUpload,
      videos: data?.videos,
    };

    try {
      const response = await fetch(
        `${config?.API_HOST_URL}/api/Ecosystem/AddAllTreeDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data1),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to save form data");
      }
      const result = await response.json();
      if (result.success === true) {
        ToastNotification.ShowSuccess(result.message);
        props.GetAllTreeDetails(projectId, props.searchText);
        handleCancel();
      } else {
        ToastNotification.ShowError(result.message);
      }
    } catch (error) {
      console.error("Error saving form data:", error);
      ToastNotification.ShowError("Error updating tree details");
    }
  };

  const handleImageChange = async (file) => {
    const projectid = projectId;
    const ec5UUID = treeData?.ec5_uuid;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${config.API_HOST_URL}/api/TreeType/UploadDocument/${projectid}/${ec5UUID}`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Failed to upload image");
      }
      const result = await response.json();
      if (result.success === true) {
        setImageName(result.data);
        setUploadedImage(file);
        setAddButton(true);
        ToastNotification.ShowSuccess(result.message);
        document.getElementById("upload-message").innerHTML =
          "Image uploaded successfully!";
      } else {
        ToastNotification.ShowError(result.message);
        document.getElementById("upload-message").innerHTML =
          "Error uploading image!";
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      ToastNotification.ShowError("Error uploading image");
      document.getElementById("upload-message").innerHTML =
        "Error uploading image!";
    }
  };
  const handleVideoChange = async (file) => {
    const projectid = projectId;
    const ec5UUID = treeData?.ec5_uuid;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${config.API_HOST_URL}/api/TreeType/UploadDocument/${projectid}/${ec5UUID}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload video");
      }

      const result = await response.json();

      if (result.success === true) {
        ToastNotification.ShowSuccess(result.message);
        document.getElementById("upload-messageVideo").innerHTML =
          "Video uploaded successfully!";

        // Update the treeData.videos with the new video
        const updatedVideos = [...(treeData.videos || []), result.data];
        setTreeData((prevTreeData) => ({
          ...prevTreeData,
          videos: updatedVideos,
        }));
      } else {
        ToastNotification.ShowError(result.message);
        document.getElementById("upload-messageVideo").innerHTML =
          "Error uploading video!";
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      ToastNotification.ShowError("Error uploading video");
      document.getElementById("upload-messageVideo").innerHTML =
        "Error uploading video!";
    }
  };

  const handleImageUpload = (e) => {
    setUploadedImage(e.target.files[0]);
    setFileNameControl(e.target.files[0].name);
    setFileSelected(true);
  };

  const handleAddImage = () => {
    if (uploadedImage) {
      const newImageList = [...imageList, uploadedImage];
      setImageList(newImageList);
      setUploadedImages(newImageList);
      setUploadedImage(null);
      setFileNameControl("");
      setFileSelected(false);
    }
  };

  const handleModalToggle = useCallback(() => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  }, [setIsModalOpen]);

  const handleCancel = () => {
    clearAll();
    handleModalToggle(false);
  };

  const clearAll = () => {
    props?.setEditMode(false);
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return "";

    const dateParts = dateStr.split("/");
    if (dateParts.length === 3) {
      // dd/mm/yyyy format
      const day = dateParts[0].padStart(2, "0");
      const month = dateParts[1].padStart(2, "0");
      return `${dateParts[2]}-${month}-${day}`;
    }

    const dateParts2 = dateStr.split(" ");
    if (dateParts2.length === 2) {
      // yyyy-mm-dd hh:mm:ss format
      return dateParts2[0];
    }

    // yyyy-mm-dd format
    return dateStr;
  };

  const GetAllSpeciesData = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetAllSpeciesData`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let speciesNames = data
          .map((species) => species.speciesName)
          .filter((name) => name);
        setSpeciesData(speciesNames);
        console.log("species name", speciesNames);
        console.log("species name data", data);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };
  return (
    <>
      <Modal
        title="Update Tree Details"
        isOpen={isModalOpen}
        className="addproject_modal fieldofficer_modal layermaster_modal edittreedet_modal"
        actions={[
          <Button
            key="submit"
            className="modal_btn submit_btn"
            onClick={handleSubmit(onSubmit)}
          >
            {props?.editMode ? "Update" : "Submit"}
          </Button>,
          <Button
            key="cancel"
            className="modal_btn cancel_btn"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
        ]}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ul className="addproject_form">
              <li className="form-group">
                <label htmlFor="date_of_capture">Date of Capture:</label>
                <Controller
                  control={control}
                  name="date_of_capture"
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <input
                        type="date"
                        id="date_of_capture"
                        name="date_of_capture"
                        value={formatDate(treeData?.date_of_capture) || ""}
                        onChange={(event) => {
                          const newValue = event.target.value;
                          if (!newValue) {
                            setTreeData({ ...treeData, date_of_capture: null });
                            onChange(null);
                          } else {
                            setTreeData({
                              ...treeData,
                              date_of_capture: newValue,
                            });
                            onChange(newValue);
                          }
                        }}
                      />
                    );
                  }}
                />
              </li>

              <li className="form-group field">
                <Controller
                  control={control}
                  name="nameOfObserver"
                  rules={{
                    required: !treeData?.date_of_capture
                      ? "Date of capture required"
                      : false,
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="text"
                        name="nameOfObserver"
                        id="nameOfObserver"
                        placeholder="Name Of Observer"
                        value={treeData?.nameOfObserver}
                        onKeyDown={(event) => {
                          if (
                            event.key === "Backspace" ||
                            event.key === "Delete"
                          ) {
                            return;
                          }
                          if (!/^[a-zA-Z\s]$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          const newValue = event.target.value;

                          setTreeData({
                            ...treeData,
                            nameOfObserver: newValue,
                          });
                          onChange(newValue);
                        }}
                      />
                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="nameOfObserver">
                        <sup class="mandatory">*</sup>Name Of Observer
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="gridId"
                  rules={{
                    required: !treeData?.gridId ? "Grid Id Required" : false,
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="text"
                        name="gridId"
                        id="gridId"
                        placeholder="Grid Id"
                        value={treeData?.gridId}
                        onKeyDown={(event) => {
                          if (
                            event.key === "Backspace" ||
                            event.key === "Delete"
                          ) {
                            return;
                          }
                          if (!/^[a-zA-Z\s]$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          const newValue = event.target.value;
                          handleInputChange(event);
                          onChange(newValue);
                        }}
                      />
                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="gridId">
                        <sup class="mandatory">*</sup>Grid Id
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="tree_id"
                  rules={{
                    required: !treeData?.tree_id ? "Invalid tree Id" : false,
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="text"
                        name="tree_id"
                        id="tree_id"
                        placeholder="Tree Id"
                        value={treeData?.tree_id}
                        disabled
                        onKeyDown={(event) => {
                          if (
                            event.key === "Backspace" ||
                            event.key === "Delete"
                          ) {
                            return;
                          }
                          if (!/^[a-zA-Z\s]$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          const newValue = event.target.value;
                          handleInputChange(event);
                          onChange(newValue);
                        }}
                      />
                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="tree_id">
                        <sup class="mandatory">*</sup>Tree Id
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="lifeForm"
                  rules={{
                    required: !treeData?.lifeForm
                      ? "Please select lifeForm"
                      : false,
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <select
                        id="lifeForm"
                        name="lifeForm"
                        className="dropdown-130"
                        value={treeData?.lifeForm}
                        onChange={(event) => {
                          onChange(event.target.value);
                          treeData.lifeForm = event.target.value;
                        }}
                      >
                        <option value="">Select</option>
                        {LifeFormDataList.map((item) => (
                          <option
                            key={item.lifeFormGuid}
                            value={item.lifeFormGuid}
                            selected={item.lifeFormGuid === treeData?.lifeForm}
                          >
                            {item.lifeFormName}
                          </option>
                        ))}
                      </select>

                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="lifeForm">
                        <sup class="mandatory">*</sup>Life Form
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="zoneType"
                  rules={{
                    required: !treeData?.zoneType
                      ? "Please select Zone Type"
                      : false,
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <select
                        id="zoneType"
                        name="zoneType"
                        className="dropdown-130"
                        value={treeData?.zoneType}
                        onChange={(event) => {
                          onChange(event.target.value);
                          treeData.zoneType = event.target.value;
                        }}
                      >
                        <option value="">Select</option>
                        {ZoneTypeData.map((item) => (
                          <option
                            key={item.zoneGuid}
                            value={item.zoneName}
                            selected={item.zoneName === treeData?.zoneType}
                          >
                            {item.zoneName}
                          </option>
                        ))}
                      </select>

                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="zoneType">
                        <sup class="mandatory">*</sup>Zone Type
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="latin_name"
                  rules={{
                    required: !treeData?.latin_name
                      ? "Species Latin Name is required!"
                      : false,
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="text"
                        name="latin_name"
                        id="latin_name"
                        placeholder="Enter Species Latin Name"
                        value={treeData?.latin_name}
                        onChange={(event) => {
                          const newValue = event.target.value;

                          setTreeData({ ...treeData, latin_name: newValue });
                          handleInputChange(event);
                          onChange(newValue);
                        }}
                      />
                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="latin_name">
                        <sup class="mandatory">*</sup>Species Latin Name
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="common_name"
                  rules={{
                    required: !treeData?.common_name
                      ? "Species Common Name is required!"
                      : false,
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <select
                        id="common_name"
                        name="common_name"
                        className="dropdown-130"
                        value={value}
                        onChange={(event) => {
                          onChange(event.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        {speciesData.map((species, index) => (
                          <option
                            key={index}
                            value={species}
                            selected={treeData?.common_name === species}
                          >
                            {species}
                          </option>
                        ))}
                      </select>

                      {error && (
                        <div className="mandatory has-error">
                          {error.message}
                        </div>
                      )}
                      <label htmlFor="common_name">
                        <sup className="mandatory">*</sup>Species Common Name
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="tree_Status"
                  rules={{
                    required: !treeData?.tree_Status
                      ? "Tree Status is required!"
                      : false,
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <select
                        id="tree_Status"
                        name="tree_Status"
                        className="dropdown-130"
                        value={value}
                        onChange={(event) => {
                          onChange(event.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        <option
                          value="Alive"
                          selected={treeData?.tree_Status === "Alive"}
                        >
                          Alive
                        </option>
                        <option
                          value="Dead"
                          selected={treeData?.tree_Status === "Dead"}
                        >
                          Dead
                        </option>
                      </select>

                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="tree_Status">
                        <sup class="mandatory">*</sup>Tree Status
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="tree_health"
                  rules={{
                    required: !treeData?.tree_health
                      ? "Tree Health is required!"
                      : false,
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <select
                        id="tree_health"
                        name="tree_health"
                        className="dropdown-130"
                        value={value}
                        onChange={(event) => {
                          onChange(event.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        <option
                          value="Healthy"
                          selected={treeData?.tree_health === "Healthy"}
                        >
                          Healthy
                        </option>
                        <option
                          value="Unhealthy"
                          selected={treeData?.tree_health === "Unhealthy"}
                        >
                          Un-Healthy
                        </option>
                      </select>

                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="tree_health">
                        <sup class="mandatory">*</sup>Tree Health
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="tree_height"
                  rules={{
                    required: !treeData?.tree_height
                      ? "Height is required!"
                      : false,
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="text"
                        name="tree_height"
                        id="tree_height"
                        placeholder="Enter Height"
                        value={treeData?.tree_height}
                        onChange={(event) => {
                          const newValue = event.target.value;

                          setTreeData({ ...treeData, tree_height: newValue });
                          handleInputChange(event);
                          onChange(newValue);
                        }}
                      />
                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="tree_height">
                        <sup class="mandatory">*</sup>Height
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="tree_girth"
                  rules={{
                    required: !treeData?.tree_girth
                      ? "Girth is required!"
                      : false,
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="text"
                        name="tree_girth"
                        id="tree_girth"
                        placeholder="Enter Girth"
                        value={treeData?.tree_girth}
                        onChange={(event) => {
                          const newValue = event.target.value;

                          setTreeData({ ...treeData, tree_girth: newValue });
                          handleInputChange(event);
                          onChange(newValue);
                        }}
                      />
                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="tree_girth">
                        <sup class="mandatory">*</sup>Girth
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="treePlantedby"
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="text"
                        name="treePlantedby"
                        id="treePlantedby"
                        placeholder="Enter Tree Planted By"
                        value={treeData?.treePlantedby}
                        onChange={(event) => {
                          const newValue = event.target.value;

                          setTreeData({ ...treeData, treePlantedby: newValue });
                          handleInputChange(event);
                          onChange(newValue);
                        }}
                      />
                      <label for="treePlantedby">Tree Planted By</label>
                    </>
                  )}
                />
              </li>
              <li className="form-group">
                <label for="planted_date">Planted Date:</label>
                <Controller
                  control={control}
                  name="planted_date"
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <input
                      type="date"
                      id="planted_date"
                      name="planted_date"
                      value={
                        treeData?.planted_date
                          ? new Date(treeData.planted_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      onChange={(event) => {
                        const newValue = event.target.value;
                        if (!newValue) {
                          setTreeData({ ...treeData, planted_date: null });
                          onChange(null);
                        } else {
                          setTreeData({
                            ...treeData,
                            planted_date: newValue,
                          });
                          onChange(newValue);
                        }
                      }}
                    />
                  )}
                />
              </li>
              {/* Photo Uploading For */}
              <li className="form-group field">
                <Controller
                  control={control}
                  name="uploadingPhotoFor"
                  // rules={{
                  //   required:
                  //     !treeData?.imageTreeUpload ||
                  //     treeData?.imageTreeUpload.length === 0
                  //       ? "Please Select Photo Uploading For"
                  //       : false,
                  // }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <select
                        id="uploadingPhotoFor"
                        name="uploadingPhotoFor"
                        className="dropdown-130"
                        value={value}
                        onChange={(event) => {
                          setUploadingPhotoForValue(event.target.value);
                          setErrorMsg("");
                          onChange(event.target.value);
                        }}
                      >
                        <option value="">Select</option>
                        <option value="Fruit">Fruit</option>
                        <option value="Stem">Stem</option>
                        <option value="Leaves single">Leaves single</option>
                        <option value="Bark closeup">Bark closeup</option>
                        <option value="Under tree">Under tree</option>
                        <option value="Seed">Seed</option>
                      </select>

                      {error && (
                        <div className="mandatory has-error">
                          {error.message}
                        </div>
                      )}
                      <label htmlFor="uploadingPhotoFor">
                        Photo Uploading For
                      </label>
                    </>
                  )}
                />
              </li>
              {/* Uploader */}
              <li className="form-group inventory_cont">
                <label htmlFor="forinventory">
                  <sup className="mandatory"></sup>
                  Photos
                </label>

                <div className="input-group divUploadFile">
                  <div className="qq-upload-drop-area">
                    <span>Drop files here to upload</span>
                  </div>
                  <div className="qq-upload-button">
                    Drag and Drop Files
                    <span className="redMandat">
                      Upload file type {".jpg, .jpeg, .png"}
                    </span>
                  </div>
                  <input
                    type="file"
                    id="Photos"
                    name="Photos"
                    accept=".jpg, .jpeg, .png"
                    onChange={(event) => {
                      if (!uploadingPhotoForValue) {
                        setErrorMsg("Please Select Photo Uploading For");
                        return;
                      }
                      const file = event.target.files[0];
                      const fileType = file.type;
                      if (!fileType.match("image")) {
                        setErrorMsg("Only image files are allowed");
                        return;
                      }
                      handleImageChange(file);
                      setImageAdded(false);
                    }}
                  />
                  <p id="upload-message" className="treedetmodal_uploadmsg">
                    {imageAdded ? null : "Image uploaded successfully!"}
                  </p>
                  <a
                    className="treedetmodal_addbtn"
                    onClick={() => {
                      const updatedImageTreeUpload = [
                        ...(treeData.imageTreeUpload || []),
                        { name: uploadingPhotoForValue, imageName: imageName },
                      ];
                      setTreeData((prevTreeData) => ({
                        ...prevTreeData,
                        imageTreeUpload: updatedImageTreeUpload,
                      }));
                      setImageAdded(true);
                    }}
                  >
                    {imageAdded ? null : "Add Image"}
                  </a>
                </div>

                {/* Uploaded Images Table */}
                {treeData?.imageTreeUpload &&
                  treeData.imageTreeUpload.length > 0 && (
                    <div className="uploadedimgtbl_cont">
                      <table>
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Uploading Image For</th>
                            <th>Uploaded Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {treeData.imageTreeUpload.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>
                              <td>
                                <img
                                  src={`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/TreesImages/${projectId}/${treeData.ec5_uuid}/${item.imageName}`}
                                  alt="Uploaded"
                                />
                              </td>
                              <td>
                                <a
                                  onClick={() => {
                                    const updatedImageTreeUpload = [
                                      ...treeData.imageTreeUpload,
                                    ];
                                    updatedImageTreeUpload.splice(index, 1);
                                    setTreeData((prevTreeData) => ({
                                      ...prevTreeData,
                                      imageTreeUpload: updatedImageTreeUpload,
                                    }));
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    height="24px"
                                  >
                                    <path d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z" />
                                  </svg>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                {/* Error Message */}
                {errorMsg && (
                  <div className="mandatory has-error"> {errorMsg}</div>
                )}
                <span>{fileControl ? fileNameControl : ""}</span>
              </li>
              <li className="form-group inventory_cont">
                <label for="forinventory">
                  <sup className="mandatory"></sup>
                  Videos
                </label>

                <div className="input-group divUploadFile">
                  <div className="qq-upload-drop-area">
                    <span>Drop files here to upload</span>
                  </div>
                  <div className="qq-upload-button">
                    Drag and Drop Files
                    <span className="redMandat">
                      Upload file type {".mp4, .mov, .avi, .wmv"}
                    </span>
                  </div>
                  <input
                    type="file"
                    id="Videos"
                    name="Videos"
                    accept=".mp4, .mov, .avi, .wmv"
                    defaultValue=""
                    onChange={(event) => {
                      const file = event.target.files[0];
                      const fileType = file.type;
                      if (!fileType.match("video")) {
                        setErrorMsg("Only video files are allowed");
                        return;
                      }
                      handleVideoChange(file);
                    }}
                    ref={fileInputRef}
                  />
                  <p id="upload-messageVideo">
                    {imageAdded ? null : "Video uploaded successfully!"}
                  </p>
                </div>
                <div>
                  {treeData?.videos && treeData.videos.length > 0 && (
                    <div className="uploadedimgtbl_cont">
                      <table>
                        <thead>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Video Name</th>
                            <th>Uploaded Video</th>
                          </tr>
                        </thead>
                        <tbody>
                          {treeData.videos.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item}</td>
                              <td>
                                <img
                                  src={`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/TreesImages/${projectId}/${treeData.ec5_uuid}/${item}`}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {/* <ul id="fileList" className="ul-project-files">
                    {uploadfile &&
                      uploadfile?.map((item, index) => (
                        <li class="file-list-li" key={index}>
                          <i class="fa fa-check"></i>
                          {item.name}
                          <a class="remove-files-list" id={index}>
                            Remove
                          </a>
                        </li>
                      ))}
                  </ul> */}
                </div>
                {errorMsg && <div class="mandatory has-error"> {errorMsg}</div>}
                <span>{fileControl ? fileNameControl : ""}</span>
              </li>
              <li className="form-group field">
                <Controller
                  control={control}
                  name="notesObservation"
                  rules={{
                    required: "Observation Note is required!",
                  }}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        type="text"
                        name="notesObservation"
                        id="notesObservation"
                        placeholder="Enter Observation Note"
                        value={treeData?.notesObservation}
                        onChange={(event) => {
                          const newValue = event.target.value;

                          setTreeData({
                            ...treeData,
                            notesObservation: newValue,
                          });
                          handleInputChange(event);
                          onChange(newValue);
                        }}
                      />
                      {error && (
                        <div class="mandatory has-error">{error.message}</div>
                      )}
                      <label for="notesObservation">
                        <sup class="mandatory">*</sup>Observation Note
                      </label>
                    </>
                  )}
                />
              </li>
              <li className="form-group field">
                <label for="selectlocation">Location:</label>

                {treeData?.coordinates?.geometry?.coordinates && (
                  <>
                    <p>
                      Latitude:{" "}
                      <span>
                        {treeData.coordinates.geometry.coordinates[1]}
                      </span>
                    </p>
                    <p>
                      Longitude:{" "}
                      <span>
                        {treeData.coordinates.geometry.coordinates[0]}
                      </span>
                    </p>
                  </>
                )}
              </li>
            </ul>
          </form>
        </div>
      </Modal>
    </>
  );
}
