import { useEffect, useState } from "react";
import config from "../../../utils/config";
import EyeIconUncheck from "./icons/eyeiconuncheck.svg";
import EyeIconcheck from "./icons/eyeiconcheck.svg";
import { useDispatch, useSelector } from "react-redux";
import project from "../../../Reducers/Project";

export default function LocationView(props) {
  const id = window.location.href.split("/")[4];
  const projectId = id.split(":")[1];
  const [landParcelsList, setlandParcelsList] = useState([]);
  const [rowWaterBodyList, setrowWaterBodyList] = useState([]);
  const [typeWaterDropdownList, settypeWaterDropdownList] = useState([]);
  const reduxState = useSelector((state) => state.project);
  const [selectedLands, setSelectedLands] = useState([]);
  const [selectedWater, setSelectedWater] = useState([]);
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false); // New state for toggling rows visibility
  const [showMoreWB, setShowMoreWB] = useState(false);
  useEffect(() => {
    if (reduxState.landParcelData) {
      setSelectedLands(reduxState.landParcelData);
    }
  }, [reduxState.landParcelData]);

  useEffect(() => {
    if (reduxState.waterBodiesData) {
      setSelectedWater(reduxState.waterBodiesData);
    }
  }, [reduxState.waterBodiesData]);

  useEffect(() => {
    if (projectId) {
      GetProjectLocation(projectId);
      GetAllCommonMasters();
    }
  }, [projectId]);

  // All the data be taken from Project Location
  const GetProjectLocation = (ProjectId) => {
    let projId = { ProjectId: ProjectId };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectLocation`;
    fetch(url, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(projId),
      timeout: 5000, // Timeout in milliseconds
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        if (responseData !== null) {
          setlandParcelsList(responseData.landParcels);
          setrowWaterBodyList(responseData.waterBodies);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetAllCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetAllCommonMasters`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 5000, // Timeout in milliseconds
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data;
        if (responseData.length > 0) {
          var typeWater = responseData.filter(
            (x) => x.type === "Type of Water Body"
          );
          settypeWaterDropdownList(typeWater);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const landPolygon = (option) => {
    let obj = {
      id: "",
      path: [],
      landDetails: [],
      options: {
        strokeWeight: 2,
        strokeOpacity: 1,
        strokeColor: "#FFFF00", //"#0cc618",
        fillOpacity: 0,
        fillColor: "#c43c39", //"#333d72", //"#0cc618",
      },
    };

    obj["id"] = option?.landParcelId;
    obj["path"] = option?.locationAltitude;
    obj["landDetails"] = option;
    return obj;
  };

  const waterPolygon = (water) => {
    let obj = {
      id: "",
      path: [],
      waterDetails: [],
      options: {
        strokeWeight: 2,
        strokeOpacity: 1,
        strokeColor: "#02D8E9", //"#0cc618",
        fillOpacity: 0,
        fillColor: "#85b66f", //"#0cc618",
      },
    };

    obj["id"] = water?.waterBodyId;
    obj["path"] = water?.locationAltitude;
    obj["waterDetails"] = water;
    return obj;
  };

  const handleLandParcelsSelection = (isSelected, option) => {
    let reduxLand = [...selectedLands] || [];
    let list = [...landParcelsList];
    list = list.map((item) => {
      if (item.landParcelId === option.landParcelId) {
        item.isSelected = isSelected;
      }
      return item;
    });
    setlandParcelsList(list);

    if (isSelected) {
      let polygonData = landPolygon(option);
      reduxLand?.push(polygonData);
      console.log("redux land..", reduxLand);
      project(
        reduxState?.landParcelData,
        dispatch({ type: "SET_LANDPARCEL_DETAILS", data: reduxLand })
      );
    } else {
      let index = reduxLand.findIndex(
        (item) => item.id === option.landParcelId
      );
      if (index !== -1) {
        reduxLand.splice(index, 1);
      }
      project(
        reduxState?.landParcelData,
        dispatch({ type: "SET_LANDPARCEL_DETAILS", data: reduxLand })
      );
    }
  };

  const handleWaterBodiesSelection = (isSelected, option) => {
    let reduxWater = [...selectedWater] || [];
    let list = [...rowWaterBodyList];
    list = list.map((item) => {
      if (item.waterBodyId === option.waterBodyId) {
        item.isSelected = isSelected;
      }
      return item;
    });
    setrowWaterBodyList(list);

    if (isSelected) {
      let polygonData = waterPolygon(option);
      reduxWater?.push(polygonData);
      project(
        reduxState?.waterBodiesData,
        dispatch({ type: "SET_WATERBODIES_DETAILS", data: reduxWater })
      );
    } else {
      let index = reduxWater.findIndex(
        (item) => item.id === option.waterBodyId
      );
      if (index !== -1) {
        reduxWater.splice(index, 1);
      }
      project(
        reduxState?.waterBodiesData,
        dispatch({ type: "SET_WATERBODIES_DETAILS", data: reduxWater })
      );
    }
  };

  const renderLandParcelsRows = () => {
    if (showMore) {
      return landParcelsList.map((eachLandParcels, i) => {
        return (
          <tr key={i}>
            <td>{(i += 1)}</td>
            <td>{eachLandParcels?.title}</td>
            <td>{eachLandParcels?.areaInHectares}</td>
            <td>
              {" "}
              <div>
                <img
                  src={
                    eachLandParcels.isSelected ? EyeIconcheck : EyeIconUncheck
                  }
                  alt={eachLandParcels.isSelected ? "Selected" : "Unselected"}
                  className={`eye-icon ${
                    eachLandParcels.isSelected ? "Selected" : "Unselected"
                  }`}
                  onClick={(e) =>
                    handleLandParcelsSelection(
                      !eachLandParcels.isSelected,
                      eachLandParcels
                    )
                  }
                />
              </div>{" "}
            </td>
          </tr>
        );
      });
    } else {
      return landParcelsList.slice(0, 3).map((eachLandParcels, i) => {
        return (
          <tr key={i}>
            <td>{(i += 1)}</td>
            <td>{eachLandParcels?.title}</td>
            <td>{eachLandParcels?.areaInHectares}</td>
            <td>
              {" "}
              <div>
                <img
                  src={
                    eachLandParcels.isSelected ? EyeIconcheck : EyeIconUncheck
                  }
                  alt={eachLandParcels.isSelected ? "Selected" : "Unselected"}
                  className={`eye-icon ${
                    eachLandParcels.isSelected ? "Selected" : "Unselected"
                  }`}
                  onClick={(e) =>
                    handleLandParcelsSelection(
                      !eachLandParcels.isSelected,
                      eachLandParcels
                    )
                  }
                />
              </div>{" "}
            </td>
          </tr>
        );
      });
    }
  };

  const renderWaterBodiesRows = () => {
    if (showMoreWB) {
      return rowWaterBodyList?.map((eachWaterBody, i) => {
        return (
          <tr key={i}>
            <td>{(i += 1)}</td>
            <td>{eachWaterBody?.title}</td>
            <td>
              {
                typeWaterDropdownList.filter(
                  (ss) => ss.mainGuId === eachWaterBody.typeWaterBody
                )[0]?.description
              }
            </td>
            <td>{eachWaterBody?.areaInHectares}</td>
            <td>
              {" "}
              <div>
                <img
                  src={eachWaterBody.isSelected ? EyeIconcheck : EyeIconUncheck}
                  alt={eachWaterBody.isSelected ? "Selected" : "Unselected"}
                  className={`eye-icon ${
                    eachWaterBody.isSelected ? "Selected" : "Unselected"
                  }`}
                  onClick={(e) =>
                    handleWaterBodiesSelection(
                      !eachWaterBody.isSelected,
                      eachWaterBody
                    )
                  }
                />
              </div>{" "}
            </td>
          </tr>
        );
      });
    } else {
      return rowWaterBodyList?.slice(0, 3).map((eachWaterBody, i) => {
        return (
          <tr key={i}>
            <td>{(i += 1)}</td>
            <td>{eachWaterBody?.title}</td>
            <td>
              {
                typeWaterDropdownList.filter(
                  (ss) => ss.mainGuId === eachWaterBody.typeWaterBody
                )[0]?.description
              }
            </td>
            <td>{eachWaterBody?.areaInHectares}</td>
            <td>
              {" "}
              <div>
                <img
                  src={eachWaterBody.isSelected ? EyeIconcheck : EyeIconUncheck}
                  alt={eachWaterBody.isSelected ? "Selected" : "Unselected"}
                  className={`eye-icon ${
                    eachWaterBody.isSelected ? "Selected" : "Unselected"
                  }`}
                  onClick={(e) =>
                    handleWaterBodiesSelection(
                      !eachWaterBody.isSelected,
                      eachWaterBody
                    )
                  }
                />
              </div>{" "}
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <>
      <div className="prosumacordtbls_wrap locationaccordscont_wrap">
        <h3 className="locationtbls_heading">Land Parcels</h3>
        <div className="prosumacordinertbl_wrap">
          {landParcelsList.length > 0 ? (
            <table className="prosumacord_inertbl">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Title</th>
                  <th>Area (In Hectares)</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{renderLandParcelsRows()}</tbody>
            </table>
          ) : (
            <div className="norecodfoundprosum_cont">No Record Found</div>
          )}
          {landParcelsList.length > 3 && (
            <button
              onClick={() => setShowMore(!showMore)}
              className={`viewmore ${showMore ? "viewmoreless_cont" : ""}`}
            >
              {showMore ? "View Less" : "View More"}
            </button>
          )}
        </div>

        <h3 className="locationtbls_heading">Water Bodies</h3>
        <div className="prosumacordinertbl_wrap">
          {renderWaterBodiesRows()?.length > 0 ? (
            <table className="prosumacord_inertbl">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Title</th>
                  <th>Type Water Body</th>
                  <th>Area (In Hectares)</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{renderWaterBodiesRows()}</tbody>
            </table>
          ) : (
            <div className="norecodfoundprosum_cont">No Record Found</div>
          )}
          {rowWaterBodyList?.length > 3 && (
            <button
              onClick={() => setShowMoreWB(!showMoreWB)}
              className={`viewmore ${showMoreWB ? "viewmoreless_cont" : ""}`}
            >
              {showMoreWB ? "View Less" : "View More"}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
