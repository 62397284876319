import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../utils/config";
import { navigate } from "@reach/router";
import "../EcosystemRestorationProject/global.css";
import "../EcosystemRestorationProject/ecosystabsall.css";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import Header from "../EcosystemCommon/Header";
import LeftNavbar from "../EcosystemCommon/LeftNavbar";
import { globalHistory } from "@reach/router";
import moment from "moment";
import ToastNotification from "../Common/ToastNotification/ToastNotification";

class TreeTiesAddProjectForms1Sit extends Component {
  constructor(props) {
    super(props);
    this.siteRef = React.createRef();
    this.state = {
      fields: {},
      errors: {},
      IsLoader: false,
      SiteDetailsId: "",
      EcoRegion: "",

      ProjectId: "",
      commonMastersList: [],
      selectedTargetEcosystemType: "",
      selectedEcosystemSubType: "",

      Year_of_Reference: "",
      Sources: "",
      Remarks: "",
      pre_Dis_Microbes_Id: "",
      microbesType: "",
      microbesSpecies: " ",
      microbesNumber_Population: " ",
      microbesInvasive_Specie: " ",
      microbesOrigin: " ",
      microbesSpec_Recom_Restoration: " ",
      microbesProd_per_Year: " ",
      ListObjMicrobes: [
        {
          pre_Dis_Microbes_Id: "",
          microbesType: "",
          microbesSpecies: "",
          microbesNumber_Population: " ",
          MicrobesInvasive_Specie: " ",
          microbesOrigin: " ",
          microbesSpec_Recom_Restoration: " ",
          microbesProd_per_Year: " ",
        },
      ],
      pre_Dis_Fauna_Id: "",
      faunaType: "",
      faunaSpecies: " ",
      faunaNumber_Population: " ",
      faunaInvasive_Specie: " ",
      faunaOrigin: " ",
      faunaSpec_Recom_Restoration: " ",
      faunaProd_per_Year: " ",
      ListObjFauna: [
        {
          Pre_Dis_Fauna_Id: "",
          faunaType: "",
          faunaSpecies: " ",
          faunaNumber_Population: " ",
          faunaInvasive_Specie: " ",
          faunaOrigin: " ",
          faunaSpec_Recom_Restoration: " ",
          faunaProd_per_Year: " ",
        },
      ],

      pre_Dis_Flora_Id: "",
      floraType: "",
      floraSpecies: "",
      floraNumber_Population: " ",
      floraInvasive_Specie: " ",
      floraOrigin: " ",
      floraSpec_Recom_Restoration: " ",
      floraProd_per_Year: " ",
      ListObjFlora: [
        {
          pre_Dis_Flora_Id: "",
          floraType: "",
          floraSpecies: "",
          floraNumber_Population: " ",
          floraInvasive_Specie: " ",
          floraOrigin: " ",
          floraSpec_Recom_Restoration: " ",
          floraProd_per_Year: " ",
        },
      ],

      ClimateType: "",
      Avg_Temp_Summer: "",
      Avg_Temp_Winter: "",
      Air_Quality_Index: "",
      Humidity: "",
      Avg_Yearly_Rainfall: "",
      Avg_Wind_Speed: "",
      Avg_No_Hours_Sunlight_Avail_Summer: "",
      Avg_No_Hours_Sunlight_Avail_Winter: "",

      Turbidity: "",
      Temperature: "",
      Color: "",
      Taste_Odor: "",
      WElectrical_Conductivity: "",
      PH: "",
      Hardness: "",
      Dissolved_Oxygen: "",
      Biochem_Oxygen_Demand: "",
      Chem_Oxygen_Demand: "",

      ToxicInorganicSName: "",
      ToxicInorganicDescription: "",

      ToxicOrganicSName: "",
      ToxicOrganicDescription: "",

      RadioactiveSuName: "",
      Description: "",

      Soil_Textures: "",
      Soil_Structure: "",
      Soil_Porosity: "",
      Soil_pH: "",
      Nitrate: "",
      Phosphate: "",
      Potassium: "",
      SElectrical_Conductivity: "",
      Soil_Color: "",
      Soil_Moisture_Level: "",
      Organic_Carbon: "",

      Tot_Carbon_Offset_Capacity: "",

      No_Families: "",
      Avg_Family_Size: "",
      Gender: "",
      Literacy_Rate_Overall: "",
      Literacy_Rate_Female: "",
      Avg_Annual_Inc_Family_Farming: "",
      Avg_Annual_Inc_Family_Other_Sources: "",
      // Disturbance Details

      disturbances_Occurred_Id: "",
      type_Cause: "",
      exact_Cause: "",
      frequency: "",
      start_Month: "",
      end_Month: "",
      ListDisturbancesOccurred: [
        {
          disturbances_Occurred_Id: "",
          type_Cause: "",
          exact_Cause: "",
          frequency: "",
          start_Month: "",
          end_Month: "",
        },
      ],
      disturbanceImpactList_Id: "",
      impact_Severity: "",
      area_Impacted: "",
      land_Parcels_Impacted: "",
      water_Bodies_Impacted: "",
      ListDisturbanceImpact: [
        {
          disturbanceImpactList_Id: "",
          impact_Severity: "",
          area_Impacted: "",
          land_Parcels_Impacted: "",
          water_Bodies_Impacted: "",
        },
      ],
      tot_Area_Impacted: "",
      scale: "",
      disturbanceImpact_Id: "",

      noLandParcels: "",
      totAreaLandParcels: "",
      noWaterBodies: "",
      totAreaWaterBodies: "",
      landParcelsList: [],
      waterBodiesList: [],
      ProjectLocationList: [],

      //Post Disturbance
      Post_Year_of_Reference: "",
      Post_Sources: "",
      Post_Remarks: "",
      post_Dis_Flora_Id: "",
      post_floraType: "",
      post_floraSpecies: "",
      post_floraNumber_Population: " ",
      post_floraInvasive_Specie: " ",
      post_floraOrigin: " ",
      post_floraSpec_Recom_Restoration: " ",
      post_floraProd_per_Year: " ",
      post_ListObjFlora: [
        {
          post_Dis_Flora_Id: "",
          post_floraType: "",
          post_floraSpecies: "",
          post_floraNumber_Population: " ",
          post_floraInvasive_Specie: " ",
          post_floraOrigin: " ",
          post_floraSpec_Recom_Restoration: " ",
          post_floraProd_per_Year: " ",
        },
      ],
      post_Dis_Fauna_Id: "",
      post_faunaType: "",
      post_faunaSpecies: "",
      post_faunaNumber_Population: " ",
      post_faunaInvasive_Specie: " ",
      post_faunaOrigin: " ",
      post_faunaSpec_Recom_Restoration: " ",
      post_faunaProd_per_Year: " ",
      post_ListObjFauna: [
        {
          post_Dis_Fauna_Id: "",
          post_faunaType: "",
          post_faunaSpecies: "",
          post_faunaNumber_Population: " ",
          post_faunaInvasive_Specie: " ",
          post_faunaOrigin: " ",
          post_faunaSpec_Recom_Restoration: " ",
          post_faunaProd_per_Year: " ",
        },
      ],

      post_Dis_Microbes_Id: "",
      post_microbesType: "",
      post_microbesSpecies: "",
      post_microbesNumber_Population: " ",
      post_microbesInvasive_Specie: " ",
      post_microbesOrigin: " ",
      post_microbesSpec_Recom_Restoration: " ",
      post_microbesProd_per_Year: " ",
      post_ListObjmicrobes: [
        {
          post_Dis_Microbes_Id: "",
          post_microbesType: "",
          post_microbesSpecies: "",
          post_microbesNumber_Population: " ",
          post_microbesInvasive_Specie: " ",
          post_microbesOrigin: " ",
          post_microbesSpec_Recom_Restoration: " ",
          post_microbesProd_per_Year: " ",
        },
      ],
      postClimateType: "",
      postAvg_Temp_Summer: "",
      postAvg_Temp_Winter: "",
      postAir_Quality_Index: "",
      postHumidity: "",
      postAvg_Yearly_Rainfall: "",
      postAvg_Wind_Speed: "",
      postAvg_No_Hours_Sunlight_Avail_Summer: "",
      postAvg_No_Hours_Sunlight_Avail_Winter: "",

      // Post Water
      post_Water_Id: "",
      post_Grid_No: "",
      post_Turbidity: "",
      post_Temperature: "",
      post_Color: "",
      post_Taste_Odor: "",
      post_Electrical_Conductivity: "",
      post_PH: "",
      post_Hardness: "",
      post_Dissolved_Oxygen: "",
      post_Biochem_Oxygen_Demand: "",
      post_Chem_Oxygen_Demand: "",
      post_Toxic_Inorganic_SubName: "",
      post_Toxic_Inorganic_Description: "",
      post_Toxic_Organic_SubName: "",
      post_Toxic_Organic_Description: "",
      post_Radioactive_SubName: "",
      post_Radioactive_Description: "",

      post_ListObjWater: [
        {
          post_Water_Id: "",
          post_Grid_No: "",
          post_Turbidity: "",
          post_Temperature: "",
          post_Color: "",
          post_Taste_Odor: "",
          post_Electrical_Conductivity: "",
          post_PH: "",
          post_Hardness: "",
          post_Dissolved_Oxygen: "",
          post_Biochem_Oxygen_Demand: "",
          post_Chem_Oxygen_Demand: "",
          post_Toxic_Inorganic_SubName: "",
          post_Toxic_Inorganic_Description: "",
          post_Toxic_Organic_SubName: "",
          post_Toxic_Organic_Description: "",
          post_Radioactive_SubName: "",
          post_Radioactive_Description: "",
        },
      ],

      //Post Soil
      post_Soil_Id: "",
      post_Grid_No: "",
      post_Soil_Textures: "",
      post_Soil_Structure: "",
      post_Soil_Porosity: "",
      post_Soil_pH: "",
      post_Nitrate: "",
      post_Phosphate: "",
      post_Potassium: "",
      post_SElectrical_Conductivity: "",
      post_Soil_Color: "",
      post_Soil_Moisture_Level: "",
      post_Organic_Carbon: "",
      post_ListObjSoil: [
        {
          post_Soil_Id: "",
          post_Grid_No: "",
          post_Soil_Textures: "",
          post_Soil_Structure: "",
          post_Soil_Porosity: "",
          post_Soil_pH: "",
          post_Nitrate: "",
          post_Phosphate: "",
          post_Potassium: "",
          post_SElectrical_Conductivity: "",
          post_Soil_Color: "",
          post_Soil_Moisture_Level: "",
          post_Organic_Carbon: "",
        },
      ],
      gridNoList: [],
      // Post social
      postTot_Carbon_Offset_Capacity: "",

      postNo_Families: "",
      postAvg_Family_Size: "",
      postGender: "",
      postLiteracy_Rate_Overall: "",
      postLiteracy_Rate_Female: "",
      postAvg_Annual_Inc_Family_Farming: "",
      postAvg_Annual_Inc_Family_Other_Sources: "",
    };
    this.state.ListObjFlora.splice(0, 1);
    this.state.ListObjFauna.splice(0, 1);
    this.state.ListObjMicrobes.splice(0, 1);
    this.state.ListDisturbancesOccurred.splice(0, 1);
    this.state.ListDisturbanceImpact.splice(0, 1);
    this.handleGoalType = this.handleGoalType.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleScopeRestoration = this.handleScopeRestoration.bind(this);
    this.handleTargetEcosystemType = this.handleTargetEcosystemType.bind(this);
    this.handleTargetEcosystemSubType =
      this.handleTargetEcosystemSubType.bind(this);
    this.handleEcoRegion = this.handleEcoRegion.bind(this);

    this.handleType = this.handleType.bind(this);
    this.handleSpicies = this.handleSpicies.bind(this);
    this.handleNumberPopulation = this.handleNumberPopulation.bind(this);
    this.handleInvasiveSpecie = this.handleInvasiveSpecie.bind(this);
    this.handleOrigin = this.handleOrigin.bind(this);
    this.handleSpecRecomRestoration =
      this.handleSpecRecomRestoration.bind(this);
    this.handleProdperYear = this.handleProdperYear.bind(this);

    this.handleTypeFauna = this.handleTypeFauna.bind(this);
    this.handleSpiciesFauna = this.handleSpiciesFauna.bind(this);
    this.handleNumberPopulationFauna =
      this.handleNumberPopulationFauna.bind(this);
    this.handleInvasiveSpecieFauna = this.handleInvasiveSpecieFauna.bind(this);
    this.handleOriginFauna = this.handleOriginFauna.bind(this);
    this.handleSpecRecomRestorationFauna =
      this.handleSpecRecomRestorationFauna.bind(this);
    this.handleProdperYearFauna = this.handleProdperYearFauna.bind(this);

    this.handleTypeMicrobes = this.handleTypeMicrobes.bind(this);
    this.handleSpiciesMicrobes = this.handleSpiciesMicrobes.bind(this);
    this.handleNumberPopulationMicrobes =
      this.handleNumberPopulationMicrobes.bind(this);
    this.handleInvasiveSpecieMicrobes =
      this.handleInvasiveSpecieMicrobes.bind(this);
    this.handleOriginMicrobes = this.handleOriginMicrobes.bind(this);
    this.handleSpecRecomRestorationMicrobes =
      this.handleSpecRecomRestorationMicrobes.bind(this);
    this.handleProdperYearMicrobes = this.handleProdperYearMicrobes.bind(this);

    this.handleClimateType = this.handleClimateType.bind(this);

    //water
    this.handleTurbidity = this.handleTurbidity.bind(this);
    this.handleHardness = this.handleHardness.bind(this);
    this.handleWElectricalConductivity =
      this.handleWElectricalConductivity.bind(this);

    //soil

    this.handleSoilTextures = this.handleSoilTextures.bind(this);
    this.handleSoilStructure = this.handleSoilStructure.bind(this);
    this.handleSoilPorosity = this.handleSoilPorosity.bind(this);

    //Disturbance Details
    this.handleTypeofCause = this.handleTypeofCause.bind(this);
    this.handleExactCause = this.handleExactCause.bind(this);
    this.handleFrequency = this.handleFrequency.bind(this);
    this.handleStartMonth = this.handleStartMonth.bind(this);
    this.handleEndMonth = this.handleEndMonth.bind(this);
    this.handleImpactSeverity = this.handleImpactSeverity.bind(this);
    this.handleLandParcelsImpacted = this.handleLandParcelsImpacted.bind(this);
    this.handlewaterBodiesImpacted = this.handlewaterBodiesImpacted.bind(this);

    //Post Disturbance

    this.handlePostDate = this.handlePostDate.bind(this);
    this.handlePostFloraType = this.handlePostFloraType.bind(this);
    this.handlePostSpicies = this.handlePostSpicies.bind(this);
    this.handlePostSpecRecomRestoration =
      this.handlePostSpecRecomRestoration.bind(this);
    this.handlePostOrigin = this.handlePostOrigin.bind(this);
    this.handlePostInvasiveSpecie = this.handlePostInvasiveSpecie.bind(this);
    this.state.post_ListObjFlora.splice(0, 1);

    this.handlePostFaunaType = this.handlePostFaunaType.bind(this);
    this.handlePostFaunaSpicies = this.handlePostFaunaSpicies.bind(this);
    this.handlePostFaunaSpecRecomRestoration =
      this.handlePostFaunaSpecRecomRestoration.bind(this);
    this.handlePosFaunatOrigin = this.handlePosFaunatOrigin.bind(this);
    this.handlePostFaunaInvasiveSpecie =
      this.handlePostFaunaInvasiveSpecie.bind(this);
    this.state.post_ListObjFauna.splice(0, 1);

    this.handlePostmicrobesType = this.handlePostmicrobesType.bind(this);
    this.handlePostmicrobesSpicies = this.handlePostmicrobesSpicies.bind(this);
    this.handlePostmicrobesSpecRecomRestoration =
      this.handlePostmicrobesSpecRecomRestoration.bind(this);
    this.handlePosmicrobestOrigin = this.handlePosmicrobestOrigin.bind(this);
    this.handlePostmicrobesInvasiveSpecie =
      this.handlePostmicrobesInvasiveSpecie.bind(this);
    this.state.post_ListObjmicrobes.splice(0, 1);
    this.handlePostClimateType = this.handlePostClimateType.bind(this);
    //Post Water

    this.handlePostTurbidity = this.handlePostTurbidity.bind(this);
    this.handlePostWElectricalConductivity =
      this.handlePostWElectricalConductivity.bind(this);
    this.handlePostHardness = this.handlePostHardness.bind(this);
    this.state.post_ListObjWater.splice(0, 1);
    //Post Soil Bind
    this.handlePostSoilTextures = this.handlePostSoilTextures.bind(this);
    this.handlePostSoilStructure = this.handlePostSoilStructure.bind(this);
    this.handlePostSoilPorosity = this.handlePostSoilPorosity.bind(this);
    this.state.post_ListObjSoil.splice(0, 1);
    this.handlePostSoilGridNo = this.handlePostSoilGridNo.bind(this);

    this.mounted = true;
  }
  componentDidMount() {
    let currentPath = globalHistory.location.pathname;
    let Id = currentPath.split(":")[1].replace("/", "");
    this.setState({ ProjectId: Id });
    this.GetSiteDetails(Id);
    this.GetCommonMasters();
    this.GetProjectLocation(Id);
    this.GetAllGridNo();
  }
  gotoPre = () => {
    if (document.getElementById("preId")) {
      document.getElementById("preId").scrollIntoView({ behavior: "smooth" });
    }
  };

  gotoDis = () => {
    if (document.getElementById("disId")) {
      document.getElementById("disId").scrollIntoView({ behavior: "smooth" });
    }
  };
  gotoPost = () => {
    if (document.getElementById("postId")) {
      document.getElementById("postId").scrollIntoView({ behavior: "smooth" });
    }
  };

  GetProjectLocation = (ProjectId) => {
    let projId = { ProjectId: ProjectId };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectLocation`;
    fetch(url, {
      method: "Post",
      body: JSON.stringify(projId),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        if (responseData !== null) {
          this.setState({
            ProjectLocationList: responseData,
            landParcelsList: responseData.landParcels,
            waterBodiesList: responseData.waterBodies,

            noLandParcels: responseData.noLandParcels,
            totAreaLandParcels: responseData.totAreaLandParcels,
            noWaterBodies: responseData.noWaterBodies,
            totAreaWaterBodies: responseData.totAreaWaterBodies,
          });
          console.log("landParcelsList", this.state.landParcelsList);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  handleDate(event) {
    if (this.mounted) this.setState({ Year_of_Reference: event.target.value });
  }

  handlePostDate(event) {
    this.setState({ Post_Year_of_Reference: event.target.value });
  }

  // Disturbance
  handleTypeofCause(event) {
    if (event.target.name == "type_Cause")
      this.setState({ type_Cause: event.target.value });
  }
  handleExactCause(event) {
    if (event.target.name == "exact_Cause")
      this.setState({ exact_Cause: event.target.value });
  }
  handleFrequency(event) {
    if (event.target.name == "frequency")
      this.setState({ frequency: event.target.value });
  }
  handleStartMonth(event) {
    if (this.mounted) this.setState({ start_Month: event.target.value });
  }
  handleEndMonth(event) {
    if (this.mounted) this.setState({ end_Month: event.target.value });
  }

  handleLandParcelsImpacted(event) {
    if (event.target.name == "land_Parcels_Impacted")
      this.setState({ land_Parcels_Impacted: event.target.value });
  }
  handlewaterBodiesImpacted(event) {
    if (event.target.name == "water_Bodies_Impacted")
      this.setState({ water_Bodies_Impacted: event.target.value });
  }
  handleDisturbancesOccurred = (e) => {
    e.preventDefault();
    if (this.ValidateDisturbancesOccurred()) {
      if (this.mounted) {
        debugger;

        if (this.state.disturbances_Occurred_Id == "") {
          let newItem = {
            type_Cause: this.state.type_Cause,
            exact_Cause: this.state.exact_Cause,
            frequency: this.state.frequency,
            start_Month: this.state.start_Month,
            end_Month: this.state.end_Month,
          };

          console.log(newItem);
          let { ListDisturbancesOccurred } = this.state;
          ListDisturbancesOccurred.push(newItem);
          this.setState({
            ListDisturbancesOccurred: ListDisturbancesOccurred,
          });
          console.log(
            "ListDisturbancesOccurred",
            this.state.ListDisturbancesOccurred
          );
          this.ClearDisturbancesOccurred();
        } else {
          const newState = this.state.ListDisturbancesOccurred.map((obj) => {
            if (
              obj.disturbances_Occurred_Id ===
              this.state.disturbances_Occurred_Id
            ) {
              return {
                ...obj,
                disturbances_Occurred_Id: this.state.disturbances_Occurred_Id,
                type_Cause: this.state.type_Cause,
                exact_Cause: this.state.exact_Cause,
                frequency: this.state.frequency,
                start_Month: this.state.start_Month,
                end_Month: this.state.end_Month,
              };
            }
            return obj;
          });
          this.setState({ ListDisturbancesOccurred: newState });
          this.ClearDisturbancesOccurred();
        }
      }
    }
  };
  ClearDisturbancesOccurred = (e) => {
    this.setState({
      disturbances_Occurred_Id: "",
      type_Cause: "",
      exact_Cause: "",
      frequency: "",
      start_Month: "",
      end_Month: "",
    });
  };
  ValidateDisturbancesOccurred = (e) => {
    let fields;
    fields = {
      type_Cause: this.state.type_Cause,
      exact_Cause: this.state.exact_Cause,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["type_Cause"]) {
      formIsValid = false;
      // // errors["type_Cause"] = "Enter TypeCause";
      // // errors["type_Cause"] = ToastNotification.ShowError("Enter  TypeCause");
      ToastNotification.ShowError("Select Type Cause");
    }

    if (!fields["exact_Cause"]) {
      formIsValid = false;
      // errors["exact_Cause"] = "Enter  ExactCause";
      // errors["exact_Cause"] = ToastNotification.ShowError("Enter  ExactCause");
      ToastNotification.ShowError("Select Exact Cause");
    }
    // if (this.mounted) {
    //   this.setState({ errors: errors });
    // }
    return formIsValid;
  };

  deleteDisturbancesOccurred = (i) => {
    this.state.ListDisturbancesOccurred.splice(i, 1);
    this.setState({
      ListDisturbancesOccurred: this.state.ListDisturbancesOccurred,
    });
  };
  editDisturbancesOccurred = (i) => {
    this.setState({
      disturbances_Occurred_Id:
        this.state.ListDisturbancesOccurred[i].disturbances_Occurred_Id,
      type_Cause: this.state.ListDisturbancesOccurred[i].type_Cause,
      exact_Cause: this.state.ListDisturbancesOccurred[i].exact_Cause,
      frequency: this.state.ListDisturbancesOccurred[i].frequency,
      start_Month: this.state.ListDisturbancesOccurred[i].start_Month,
      end_Month: this.state.ListDisturbancesOccurred[i].end_Month,
    });
  };

  //Disturbance Impact

  handleImpactSeverity(event) {
    if (event.target.name == "impact_Severity")
      this.setState({ impact_Severity: event.target.value });
  }
  handleDisturbanceImpact = (e) => {
    e.preventDefault();
    if (this.ValidateDisturbanceImpact()) {
      if (this.mounted) {
        //debugger

        if (this.state.disturbanceImpactList_Id == "") {
          let newItem = {
            impact_Severity: this.state.impact_Severity,
            area_Impacted: this.state.area_Impacted,
            land_Parcels_Impacted: this.state.land_Parcels_Impacted,
            water_Bodies_Impacted: this.state.water_Bodies_Impacted,
          };
          console.log(newItem);
          let { ListDisturbanceImpact } = this.state;
          ListDisturbanceImpact.push(newItem);
          this.setState(
            {
              ListDisturbanceImpact: ListDisturbanceImpact,
            },
            () => {
              this.AutoGenerate();
            }
          );
          //  //For 1st auto generate
          //   let totalAreaImpacted = 0;

          //    this.state.ListDisturbanceImpact.forEach((element) => {
          //      if (element.area_Impacted) {
          //        totalAreaImpacted = totalAreaImpacted +parseInt(element.area_Impacted) ;
          //      }
          //    });
          //    this.setState({ tot_Area_Impacted: totalAreaImpacted });

          // // for 2nd auto generate
          // let totalLandArea=0;
          // this.state.landParcelsList.forEach((element) => {
          //     if (element.areaInHectares) {
          //       totalLandArea = totalLandArea +parseInt(element.areaInHectares) ;
          //     }
          //   });
          //   let totalWaterArea=0;
          //   this.state.landParcelsList.forEach((element) => {
          //       if (element.areaInHectares) {
          //         totalWaterArea = totalWaterArea +parseInt(element.areaInHectares) ;
          //       }
          //     });
          //     let TotalArea=0;

          //   TotalArea=totalLandArea+totalWaterArea;
          //   console.log("TotalArea",TotalArea)
          // let TotalProjectArea=totalAreaImpacted/TotalArea
          // console.log("TotalProjectArea",TotalProjectArea)

          //    this.setState({ scale: TotalProjectArea});

          this.ClearDisturbanceImpact();
        } else {
          const newState = this.state.ListDisturbanceImpact.map((obj) => {
            if (
              obj.disturbanceImpactList_Id ===
              this.state.disturbanceImpactList_Id
            ) {
              return {
                ...obj,
                disturbanceImpactList_Id: this.state.disturbanceImpactList_Id,
                impact_Severity: this.state.impact_Severity,
                area_Impacted: this.state.area_Impacted,
                land_Parcels_Impacted: this.state.land_Parcels_Impacted,
                water_Bodies_Impacted: this.state.water_Bodies_Impacted,
              };
            }
            return obj;
          });
          this.setState({ ListDisturbanceImpact: newState }, () => {
            this.AutoGenerate();
          });

          this.ClearDisturbanceImpact();
        }
      }
    }
  };

  AutoGenerate = () => {
    //For 1st auto generate
    let totalAreaImpacted = 0;

    this.state.ListDisturbanceImpact.forEach((element) => {
      if (element.area_Impacted) {
        totalAreaImpacted = totalAreaImpacted + parseInt(element.area_Impacted);
      }
    });
    this.setState({ tot_Area_Impacted: totalAreaImpacted });

    // for 2nd auto generate
    let totalLandArea = 0;
    this.state.landParcelsList.forEach((element) => {
      if (element.areaInHectares) {
        totalLandArea = totalLandArea + parseInt(element.areaInHectares);
      }
    });
    let totalWaterArea = 0;
    this.state.landParcelsList.forEach((element) => {
      if (element.areaInHectares) {
        totalWaterArea = totalWaterArea + parseInt(element.areaInHectares);
      }
    });
    let TotalArea = 0;

    TotalArea = totalLandArea + totalWaterArea;
    console.log("TotalArea", TotalArea);
    let TotalProjectArea = totalAreaImpacted / TotalArea;
    console.log("TotalProjectArea", TotalProjectArea);

    this.setState({ scale: TotalProjectArea });
  };
  ClearDisturbanceImpact = (e) => {
    this.setState({
      disturbanceImpactList_Id: "",
      impact_Severity: "",
      area_Impacted: "",
      land_Parcels_Impacted: "",
      water_Bodies_Impacted: "",
    });
  };
  ValidateDisturbanceImpact = (e) => {
    let fields;
    fields = {
      impact_Severity: this.state.impact_Severity,
      area_Impacted: this.state.area_Impacted,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["impact_Severity"]) {
      formIsValid = false;
      // errors["impact_Severity"] = "Enter ImpactSeverity";
      // errors["impact_Severity"] = ToastNotification.ShowError(
      //   "Enter  ImpactSeverity"
      // );
      ToastNotification.ShowError("Select Impact Severity");
    }

    if (!fields["area_Impacted"]) {
      formIsValid = false;
      // errors["area_Impacted"] = "Enter Area Impacted";
      // errors["area_Impacted"] = ToastNotification.ShowError(
      //   "Enter Area Impacted"
      // );
      ToastNotification.ShowError("Select Area Impacted");
    }
    // if (this.mounted) {
    //   this.setState({ errors: errors });
    // }
    return formIsValid;
  };

  deleteDisturbanceImpact = (i) => {
    this.state.ListDisturbanceImpact.splice(i, 1);
    this.setState({ ListDisturbanceImpact: this.state.ListDisturbanceImpact });
  };
  editDisturbanceImpact = (i) => {
    this.setState({
      disturbanceImpactList_Id:
        this.state.ListDisturbanceImpact[i].disturbanceImpactList_Id,
      impact_Severity: this.state.ListDisturbanceImpact[i].impact_Severity,
      area_Impacted: this.state.ListDisturbanceImpact[i].area_Impacted,
      land_Parcels_Impacted:
        this.state.ListDisturbanceImpact[i].land_Parcels_Impacted,
      water_Bodies_Impacted:
        this.state.ListDisturbanceImpact[i].water_Bodies_Impacted,
    });
  };

  //soil

  handleSoilTextures(event) {
    this.setState({ Soil_Textures: event.target.value });
  }
  handleSoilStructure(event) {
    this.setState({ Soil_Structure: event.target.value });
  }
  handleSoilPorosity(event) {
    this.setState({ Soil_Porosity: event.target.value });
  }

  //water
  handleTurbidity(event) {
    this.setState({ Turbidity: event.target.value });
  }
  handleHardness(event) {
    this.setState({ Hardness: event.target.value });
  }
  handleWElectricalConductivity(event) {
    this.setState({ WElectrical_Conductivity: event.target.value });
  }
  handleGoalType(event) {
    this.setState({ Goal_Type: event.target.value });
  }

  handleScopeRestoration(event) {
    this.setState({ Scope_Restoration: event.target.value });
  }

  handleEcoRegion(event) {
    this.setState({ EcoRegion: event.target.value });
  }
  handleProdperYearMicrobes(event) {
    this.setState({ microbesProd_per_Year: event.target.value });
  }
  handleSpecRecomRestorationMicrobes(event) {
    this.setState({ microbesSpec_Recom_Restoration: event.target.value });
  }
  handleOriginMicrobes(event) {
    this.setState({ microbesOrigin: event.target.value });
  }
  handleInvasiveSpecieMicrobes(event) {
    this.setState({ microbesInvasive_Specie: event.target.value });
  }
  handleTypeMicrobes(event) {
    if (event.target.name == "ddmicrobestype")
      this.setState({ microbesType: event.target.value });
  }
  handleSpiciesMicrobes(event) {
    if (event.target.name == "ddMicrobesSpecies")
      this.setState({ microbesSpecies: event.target.value });
  }
  handleNumberPopulationMicrobes(event) {
    this.setState({ microbesNumber_Population: event.target.value });
  }
  handleMicrobes = (e) => {
    e.preventDefault();
    if (this.validateMicrobes()) {
      if (this.mounted) {
        if (this.state.pre_Dis_Microbes_Id == "") {
          let newItem = {
            // pre_Dis_Microbes_Id:this.state.pre_Dis_Microbes_Id,
            microbesType: this.state.microbesType,
            microbesSpecies: this.state.microbesSpecies,
            microbesNumber_Population: this.state.microbesNumber_Population,
            microbesInvasive_Specie: this.state.microbesInvasive_Specie,
            microbesOrigin: this.state.microbesOrigin,
            microbesSpec_Recom_Restoration:
              this.state.microbesSpec_Recom_Restoration,
            microbesProd_per_Year: this.state.microbesProd_per_Year,
          };
          let { ListObjMicrobes } = this.state;
          ListObjMicrobes.push(newItem);
          this.setState({
            ListObjMicrobes: ListObjMicrobes,
          });

          this.ClearMicrobes();
        } else {
          const newState = this.state.ListObjMicrobes.map((obj) => {
            if (obj.pre_Dis_Microbes_Id === this.state.pre_Dis_Microbes_Id) {
              return {
                ...obj,
                pre_Dis_Microbes_Id: this.state.pre_Dis_Microbes_Id,
                microbesType: this.state.microbesType,
                microbesSpecies: this.state.microbesSpecies,
                microbesNumber_Population: this.state.microbesNumber_Population,
                microbesInvasive_Specie: this.state.microbesInvasive_Specie,
                microbesOrigin: this.state.microbesOrigin,
                microbesSpec_Recom_Restoration:
                  this.state.microbesSpec_Recom_Restoration,
                microbesProd_per_Year: this.state.microbesProd_per_Year,
              };
            }
            return obj;
          });
          this.setState({ ListObjMicrobes: newState });
          this.ClearMicrobes();
        }
      }
    }
  };
  ClearMicrobes = (e) => {
    this.setState({
      pre_Dis_Microbes_Id: "",
      microbesType: "",
      microbesSpecies: "",
      microbesNumber_Population: "",
      microbesInvasive_Specie: "",
      microbesOrigin: "",
      microbesSpec_Recom_Restoration: "",
      microbesProd_per_Year: "",
    });
  };
  handleProdperYearFauna(event) {
    this.setState({ faunaProd_per_Year: event.target.value });
  }
  handleSpecRecomRestorationFauna(event) {
    this.setState({ faunaSpec_Recom_Restoration: event.target.value });
  }
  handleOriginFauna(event) {
    this.setState({ faunaOrigin: event.target.value });
  }
  handleInvasiveSpecieFauna(event) {
    this.setState({ faunaInvasive_Specie: event.target.value });
  }
  handleTypeFauna(event) {
    if (event.target.name == "faunatype")
      this.setState({ faunaType: event.target.value });
  }
  handleSpiciesFauna(event) {
    if (event.target.name == "ddFaunaSpicies")
      this.setState({ faunaSpecies: event.target.value });
  }
  handleNumberPopulationFauna(event) {
    this.setState({ faunaNumber_Population: event.target.value });
  }
  handleFauna = (e) => {
    e.preventDefault();
    if (this.validateFauna()) {
      if (this.mounted) {
        //debugger
        if (this.state.pre_Dis_Fauna_Id == "") {
          let newItem = {
            // Pre_Dis_Fauna_Id:this.state.Pre_Dis_Fauna_Id,
            faunaType: this.state.faunaType,
            faunaSpecies: this.state.faunaSpecies,
            faunaType: this.state.faunaType,
            faunaSpecies: this.state.faunaSpecies,
            faunaNumber_Population: this.state.faunaNumber_Population,
            faunaInvasive_Specie: this.state.faunaInvasive_Specie,
            faunaOrigin: this.state.faunaOrigin,
            faunaSpec_Recom_Restoration: this.state.faunaSpec_Recom_Restoration,
            faunaProd_per_Year: this.state.faunaProd_per_Year,
          };
          let { ListObjFauna } = this.state;
          console.log("ListObjFauna", ListObjFauna);
          ListObjFauna.push(newItem);
          this.setState({
            ListObjFauna: ListObjFauna,
          });
          this.ClearFauna();
        } else {
          const newState = this.state.ListObjFauna.map((obj) => {
            if (obj.pre_Dis_Fauna_Id === this.state.pre_Dis_Fauna_Id) {
              return {
                ...obj,

                pre_Dis_Fauna_Id: this.state.pre_Dis_Fauna_Id,
                faunaType: this.state.faunaType,
                faunaSpecies: this.state.faunaSpecies,
                faunaType: this.state.faunaType,
                faunaSpecies: this.state.faunaSpecies,
                faunaNumber_Population: this.state.faunaNumber_Population,
                faunaInvasive_Specie: this.state.faunaInvasive_Specie,
                faunaOrigin: this.state.faunaOrigin,
                faunaSpec_Recom_Restoration:
                  this.state.faunaSpec_Recom_Restoration,
                faunaProd_per_Year: this.state.faunaProd_per_Year,
              };
            }
            return obj;
          });
          this.setState({ ListObjFauna: newState });
          this.ClearFauna();
        }
      }
    }
  };

  ClearFauna = (e) => {
    this.setState({
      pre_Dis_Fauna_Id: "",
      faunaType: "",
      faunaSpecies: "",
      faunaNumber_Population: "",
      faunaInvasive_Specie: "",
      faunaOrigin: "",
      faunaSpec_Recom_Restoration: "",
      faunaProd_per_Year: "",
    });
  };
  handleProdperYear(event) {
    this.setState({ floraProd_per_Year: event.target.value });
  }
  handleSpecRecomRestoration(event) {
    this.setState({ floraSpec_Recom_Restoration: event.target.value });
  }
  handleOrigin(event) {
    this.setState({ floraOrigin: event.target.value });
  }
  handleInvasiveSpecie(event) {
    this.setState({ floraInvasive_Specie: event.target.value });
  }
  handleType(event) {
    if (event.target.name == "floratype")
      this.setState({ floraType: event.target.value });
  }
  handleSpicies(event) {
    const value = event.target.value;
    if (event.target.name == "ddFloraSpicies")
      this.setState({ floraSpecies: event.target.value });
  }
  handleNumberPopulation(event) {
    this.setState({ floraNumber_Population: event.target.value });
  }
  handleFlora = (e) => {
    e.preventDefault();
    if (this.validateFlora()) {
      if (this.mounted) {
        //debugger
        if (this.state.pre_Dis_Flora_Id == "") {
          let newItem = {
            // pre_Dis_Flora_Id: this.state.pre_Dis_Flora_Id,
            floraType: this.state.floraType,
            floraSpecies: this.state.floraSpecies,
            floraNumber_Population: this.state.floraNumber_Population,
            floraInvasive_Specie: this.state.floraInvasive_Specie,
            floraOrigin: this.state.floraOrigin,
            floraSpec_Recom_Restoration: this.state.floraSpec_Recom_Restoration,
            floraProd_per_Year: this.state.floraProd_per_Year,
          };
          console.log(newItem);
          let { ListObjFlora } = this.state;
          ListObjFlora.push(newItem);
          this.setState({
            ListObjFlora: ListObjFlora,
          });
          console.log("floranew", this.state.ListObjFlora);
          this.ClearFlora();
        } else {
          const newState = this.state.ListObjFlora.map((obj) => {
            if (obj.pre_Dis_Flora_Id === this.state.pre_Dis_Flora_Id) {
              return {
                ...obj,
                pre_Dis_Flora_Id: this.state.pre_Dis_Flora_Id,
                floraType: this.state.floraType,
                floraSpecies: this.state.floraSpecies,
                floraNumber_Population: this.state.floraNumber_Population,
                floraInvasive_Specie: this.state.floraInvasive_Specie,
                floraOrigin: this.state.floraOrigin,
                floraSpec_Recom_Restoration:
                  this.state.floraSpec_Recom_Restoration,
                floraProd_per_Year: this.state.floraProd_per_Year,
              };
            }
            return obj;
          });
          this.setState({ ListObjFlora: newState });
          this.ClearFlora();
        }
      }
    }
  };
  ClearFlora = (e) => {
    this.setState({
      pre_Dis_Flora_Id: "",
      floraType: "",
      floraSpecies: "",
      floraNumber_Population: "",
      floraInvasive_Specie: "",
      floraOrigin: "",
      floraSpec_Recom_Restoration: "",
      floraProd_per_Year: "",
    });
  };

  handleClimateType(event) {
    this.setState({ ClimateType: event.target.value });
  }
  GetCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMasters`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        var listOfMicrobes = data
          .filter((x) => x.type === "Microbes")
          .map((x) => x.description);

        this.setState({
          commonMastersList: data,
          ListofMicrobes: listOfMicrobes,
        });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };
  handleTargetEcosystemType(event) {
    const value = event.target.value;

    if (event.target.name == "tetype")
      this.setState({ selectedTargetEcosystemType: event.target.value });
  }
  handleTargetEcosystemSubType(event) {
    const value = event.target.value;
    if (event.target.name == "ddlsubtype")
      this.setState({ selectedEcosystemSubType: event.target.value });
  }
  handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (this.mounted) {
      this.setState({ [name]: value });
    }
  };
  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value.replace(/[^0-9]/g, "");
    this.setState({ [name]: value });
  };
  handleAlphaInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    if (value === "" || alphanumericRegex.test(value)) {
      this.setState({ [name]: value });
    }
  };
  deleteFlora = (i) => {
    this.state.ListObjFlora.splice(i, 1);
    this.setState({ ListObjFlora: this.state.ListObjFlora });
  };
  deleteFauna = (i) => {
    this.state.ListObjFauna.splice(i, 1);
    this.setState({ ListObjFauna: this.state.ListObjFauna });
  };
  deleteMicrobes = (i) => {
    this.state.ListObjMicrobes.splice(i, 1);
    this.setState({ ListObjMicrobes: this.state.ListObjMicrobes });
  };
  editFlora = (i) => {
    this.setState({
      pre_Dis_Flora_Id: this.state.ListObjFlora[i].pre_Dis_Flora_Id,
      floraType: this.state.ListObjFlora[i].floraType,
      floraSpecies: this.state.ListObjFlora[i].floraSpecies,
      floraNumber_Population: this.state.ListObjFlora[i].floraNumber_Population,
      floraOrigin: this.state.ListObjFlora[i].floraOrigin,
      floraSpec_Recom_Restoration:
        this.state.ListObjFlora[i].floraSpec_Recom_Restoration,
      floraProd_per_Year: this.state.ListObjFlora[i].floraProd_per_Year,
      floraInvasive_Specie: this.state.ListObjFlora[i].floraInvasive_Specie,
    });
  };
  editMicrobes = (i) => {
    this.setState({
      pre_Dis_Microbes_Id: this.state.ListObjMicrobes[i].pre_Dis_Microbes_Id,
      microbesType: this.state.ListObjMicrobes[i].microbesType,
      microbesSpecies: this.state.ListObjMicrobes[i].microbesSpecies,
      microbesNumber_Population:
        this.state.ListObjMicrobes[i].microbesNumber_Population,
      microbesOrigin: this.state.ListObjMicrobes[i].microbesOrigin,
      microbesSpec_Recom_Restoration:
        this.state.ListObjMicrobes[i].microbesSpec_Recom_Restoration,
      microbesProd_per_Year:
        this.state.ListObjMicrobes[i].microbesProd_per_Year,
      microbesInvasive_Specie:
        this.state.ListObjMicrobes[i].microbesInvasive_Specie,
    });
  };
  editFauna = (i) => {
    this.setState({
      pre_Dis_Fauna_Id: this.state.ListObjFauna[i].pre_Dis_Fauna_Id,
      faunaType: this.state.ListObjFauna[i].faunaType,
      faunaSpecies: this.state.ListObjFauna[i].faunaSpecies,
      faunaNumber_Population: this.state.ListObjFauna[i].faunaNumber_Population,
      faunaOrigin: this.state.ListObjFauna[i].faunaOrigin,
      faunaSpec_Recom_Restoration:
        this.state.ListObjFauna[i].faunaSpec_Recom_Restoration,
      faunaProd_per_Year: this.state.ListObjFauna[i].faunaProd_per_Year,
      faunaInvasive_Specie: this.state.ListObjFauna[i].faunaInvasive_Specie,
    });
  };

  // Post Disturbance
  handlePostFloraType(event) {
    if (event.target.name == "postfloraType")
      this.setState({ post_floraType: event.target.value });
  }

  handlePostSpicies(event) {
    if (event.target.name == "postfloraSpecies")
      this.setState({ post_floraSpecies: event.target.value });
  }

  handlePostSpecRecomRestoration(event) {
    this.setState({ post_floraSpec_Recom_Restoration: event.target.value });
  }

  handlePostOrigin(event) {
    this.setState({ post_floraOrigin: event.target.value });
  }

  handlePostInvasiveSpecie(event) {
    this.setState({ post_floraInvasive_Specie: event.target.value });
  }

  handlePostFlora = (e) => {
    e.preventDefault();
    if (this.validatePostFlora()) {
      if (this.mounted) {
        //debugger
        if (this.state.post_Dis_Flora_Id == "") {
          let newItem = {
            post_floraType: this.state.post_floraType,
            post_floraSpecies: this.state.post_floraSpecies,
            post_floraNumber_Population: this.state.post_floraNumber_Population,
            post_floraInvasive_Specie: this.state.post_floraInvasive_Specie,
            post_floraOrigin: this.state.post_floraOrigin,
            post_floraSpec_Recom_Restoration:
              this.state.post_floraSpec_Recom_Restoration,
            post_floraProd_per_Year: this.state.post_floraProd_per_Year,
          };
          console.log(newItem);
          let { post_ListObjFlora } = this.state;
          post_ListObjFlora.push(newItem);
          this.setState({
            post_ListObjFlora: post_ListObjFlora,
          });
          //console.log("Postfloranew", this.state.post_ListObjFlora)
          this.ClearPostFlora();
        } else {
          const newState = this.state.post_ListObjFlora.map((obj) => {
            if (obj.post_Dis_Flora_Id === this.state.post_Dis_Flora_Id) {
              return {
                ...obj,
                post_Dis_Flora_Id: this.state.post_Dis_Flora_Id,
                post_floraType: this.state.post_floraType,
                post_floraSpecies: this.state.post_floraSpecies,
                post_floraNumber_Population:
                  this.state.post_floraNumber_Population,
                post_floraInvasive_Specie: this.state.post_floraInvasive_Specie,
                post_floraOrigin: this.state.post_floraOrigin,
                post_floraSpec_Recom_Restoration:
                  this.state.post_floraSpec_Recom_Restoration,
                post_floraProd_per_Year: this.state.post_floraProd_per_Year,
              };
            }
            return obj;
          });
          this.setState({ post_ListObjFlora: newState });
          this.ClearPostFlora();
        }
      }
    }
  };
  ClearPostFlora = (e) => {
    this.setState({
      post_Dis_Flora_Id: "",
      post_floraType: "",
      post_floraSpecies: "",
      post_floraNumber_Population: "",
      post_floraInvasive_Specie: "",
      post_floraOrigin: "",
      post_floraSpec_Recom_Restoration: "",
      post_floraProd_per_Year: "",
    });
  };

  validatePostFlora = (e) => {
    let fields;
    fields = {
      post_floraType: this.state.post_floraType,
      post_floraSpecies: this.state.post_floraSpecies,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["post_floraType"]) {
      formIsValid = false;
      // errors["post_floraType"] = "Enter Post FloraType";
      // errors["post_floraType"] = ToastNotification.ShowError(
      //   "Enter Post  FloraType"
      // );
      ToastNotification.ShowError("Select Post Flora Type");
    }

    if (!fields["post_floraSpecies"]) {
      formIsValid = false;
      // errors["post_floraSpecies"] = "Enter Post FloraSpecies";
      // errors["post_floraSpecies"] = ToastNotification.ShowError(
      //   "Enter Post FloraSpecies"
      // );
      ToastNotification.ShowError("Select Post Flora Species");
    }
    // if (this.mounted) {
    //   this.setState({ errors: errors });
    // }
    return formIsValid;
  };
  deletePostFlora = (i) => {
    this.state.post_ListObjFlora.splice(i, 1);
    this.setState({ post_ListObjFlora: this.state.post_ListObjFlora });
  };
  editPostFlora = (i) => {
    this.setState({
      post_Dis_Flora_Id: this.state.post_ListObjFlora[i].post_Dis_Flora_Id,
      post_floraType: this.state.post_ListObjFlora[i].post_floraType,
      post_floraSpecies: this.state.post_ListObjFlora[i].post_floraSpecies,
      post_floraNumber_Population:
        this.state.post_ListObjFlora[i].post_floraNumber_Population,
      post_floraOrigin: this.state.post_ListObjFlora[i].post_floraOrigin,
      post_floraSpec_Recom_Restoration:
        this.state.post_ListObjFlora[i].post_floraSpec_Recom_Restoration,
      post_floraProd_per_Year:
        this.state.post_ListObjFlora[i].post_floraProd_per_Year,
      post_floraInvasive_Specie:
        this.state.post_ListObjFlora[i].post_floraInvasive_Specie,
    });
  };
  // Post Disturbance  Fauna
  handlePostFaunaType(event) {
    if (event.target.name == "postfaunaType")
      this.setState({ post_faunaType: event.target.value });
  }

  handlePostFaunaSpicies(event) {
    if (event.target.name == "postfaunaSpecies")
      this.setState({ post_faunaSpecies: event.target.value });
  }

  handlePostFaunaSpecRecomRestoration(event) {
    this.setState({ post_faunaSpec_Recom_Restoration: event.target.value });
  }

  handlePosFaunatOrigin(event) {
    this.setState({ post_faunaOrigin: event.target.value });
  }

  handlePostFaunaInvasiveSpecie(event) {
    this.setState({ post_faunaInvasive_Specie: event.target.value });
  }

  handlePostFauna = (e) => {
    e.preventDefault();
    if (this.validatePostFauna()) {
      if (this.mounted) {
        //debugger
        if (this.state.post_Dis_Fauna_Id == "") {
          let newItem = {
            post_faunaType: this.state.post_faunaType,
            post_faunaSpecies: this.state.post_faunaSpecies,
            post_faunaNumber_Population: this.state.post_faunaNumber_Population,
            post_faunaInvasive_Specie: this.state.post_faunaInvasive_Specie,
            post_faunaOrigin: this.state.post_faunaOrigin,
            post_faunaSpec_Recom_Restoration:
              this.state.post_faunaSpec_Recom_Restoration,
            post_faunaProd_per_Year: this.state.post_faunaProd_per_Year,
          };
          console.log(newItem);
          let { post_ListObjFauna } = this.state;
          post_ListObjFauna.push(newItem);
          this.setState({
            post_ListObjFauna: post_ListObjFauna,
          });
          // console.log("Postfaunanew", this.state.post_ListObjFauna)
          this.ClearPostFauna();
        } else {
          const newState = this.state.post_ListObjFauna.map((obj) => {
            if (obj.post_Dis_Fauna_Id === this.state.post_Dis_Fauna_Id) {
              return {
                ...obj,
                post_Dis_Fauna_Id: this.state.post_Dis_Fauna_Id,
                post_faunaType: this.state.post_faunaType,
                post_faunaSpecies: this.state.post_faunaSpecies,
                post_faunaNumber_Population:
                  this.state.post_faunaNumber_Population,
                post_faunaInvasive_Specie: this.state.post_faunaInvasive_Specie,
                post_faunaOrigin: this.state.post_faunaOrigin,
                post_faunaSpec_Recom_Restoration:
                  this.state.post_faunaSpec_Recom_Restoration,
                post_faunaProd_per_Year: this.state.post_faunaProd_per_Year,
              };
            }
            return obj;
          });
          this.setState({ post_ListObjFauna: newState });

          this.ClearPostFauna();
        }
      }
    }
  };
  ClearPostFauna = (e) => {
    this.setState({
      post_Dis_Fauna_Id: "",
      post_faunaType: "",
      post_faunaSpecies: "",
      post_faunaNumber_Population: "",
      post_faunaInvasive_Specie: "",
      post_faunaOrigin: "",
      post_faunaSpec_Recom_Restoration: "",
      post_faunaProd_per_Year: "",
    });
  };

  validatePostFauna = (e) => {
    let fields;
    fields = {
      post_faunaType: this.state.post_faunaType,
      post_faunaSpecies: this.state.post_faunaSpecies,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["post_faunaType"]) {
      formIsValid = false;
      // errors["post_faunaType"] = "Enter Post FaunaType";
      // errors["post_faunaType"] = ToastNotification.ShowError(
      //   "Enter Post  FaunaType"
      // );
      ToastNotification.ShowError("Select Post Fauna Type");
    }

    if (!fields["post_faunaSpecies"]) {
      formIsValid = false;
      // errors["post_faunaSpecies"] = "Enter Post aunaSpecies";
      // errors["post_faunaSpecies"] = ToastNotification.ShowError(
      //   "Enter Post FaunaSpecies"
      // );
      ToastNotification.ShowError("Select Post Fauna Species");
    }
    // if (this.mounted) {
    //   this.setState({ errors: errors });
    // }
    return formIsValid;
  };
  deletePostFauna = (i) => {
    this.state.post_ListObjFauna.splice(i, 1);
    this.setState({ post_ListObjFauna: this.state.post_ListObjFauna });
  };
  editPostFauna = (i) => {
    this.setState({
      post_Dis_Fauna_Id: this.state.post_ListObjFauna[i].post_Dis_Fauna_Id,
      post_faunaType: this.state.post_ListObjFauna[i].post_faunaType,
      post_faunaSpecies: this.state.post_ListObjFauna[i].post_faunaSpecies,
      post_faunaNumber_Population:
        this.state.post_ListObjFauna[i].post_faunaNumber_Population,
      post_faunaOrigin: this.state.post_ListObjFauna[i].post_faunaOrigin,
      post_faunaSpec_Recom_Restoration:
        this.state.post_ListObjFauna[i].post_faunaSpec_Recom_Restoration,
      post_faunaProd_per_Year:
        this.state.post_ListObjFauna[i].post_faunaProd_per_Year,
      post_faunaInvasive_Specie:
        this.state.post_ListObjFauna[i].post_faunaInvasive_Specie,
    });
  };

  // Post Disturbance  microbes
  handlePostmicrobesType(event) {
    if (event.target.name == "postmicrobesType")
      this.setState({ post_microbesType: event.target.value });
  }

  handlePostmicrobesSpicies(event) {
    if (event.target.name == "postmicrobesSpecies")
      this.setState({ post_microbesSpecies: event.target.value });
  }

  handlePostmicrobesSpecRecomRestoration(event) {
    this.setState({ post_microbesSpec_Recom_Restoration: event.target.value });
  }

  handlePosmicrobestOrigin(event) {
    this.setState({ post_microbesOrigin: event.target.value });
  }

  handlePostmicrobesInvasiveSpecie(event) {
    this.setState({ post_microbesInvasive_Specie: event.target.value });
  }

  handlePostmicrobes = (e) => {
    e.preventDefault();
    if (this.validatePostmicrobes()) {
      if (this.mounted) {
        debugger;
        if (this.state.post_Dis_Microbes_Id == "") {
          let newItem = {
            post_microbesType: this.state.post_microbesType,
            post_microbesSpecies: this.state.post_microbesSpecies,
            post_microbesNumber_Population:
              this.state.post_microbesNumber_Population,
            post_microbesInvasive_Specie:
              this.state.post_microbesInvasive_Specie,
            post_microbesOrigin: this.state.post_microbesOrigin,
            post_microbesSpec_Recom_Restoration:
              this.state.post_microbesSpec_Recom_Restoration,
            post_microbesProd_per_Year: this.state.post_microbesProd_per_Year,
          };
          console.log(newItem);
          let { post_ListObjmicrobes } = this.state;
          post_ListObjmicrobes.push(newItem);
          this.setState({
            post_ListObjmicrobes: post_ListObjmicrobes,
          });
          //console.log("Postmicrobesnew", this.state.post_ListObjmicrobes)
          this.ClearPostmicrobes();
        } else {
          const newState = this.state.post_ListObjmicrobes.map((obj) => {
            if (obj.post_Dis_Microbes_Id === this.state.post_Dis_Microbes_Id) {
              return {
                ...obj,
                post_Dis_Microbes_Id: this.state.post_Dis_Microbes_Id,
                post_microbesType: this.state.post_microbesType,
                post_microbesSpecies: this.state.post_microbesSpecies,
                post_microbesNumber_Population:
                  this.state.post_microbesNumber_Population,
                post_microbesInvasive_Specie:
                  this.state.post_microbesInvasive_Specie,
                post_microbesOrigin: this.state.post_microbesOrigin,
                post_microbesSpec_Recom_Restoration:
                  this.state.post_microbesSpec_Recom_Restoration,
                post_microbesProd_per_Year:
                  this.state.post_microbesProd_per_Year,
              };
            }
            return obj;
          });

          this.ClearPostmicrobes();
          this.setState({ post_ListObjmicrobes: newState });
          console.log("Postmicrobesnew", this.state.post_ListObjmicrobes);
        }
      }
    }
  };
  ClearPostmicrobes = (e) => {
    this.setState({
      post_Dis_Microbes_Id: "",
      post_microbesType: "",
      post_microbesSpecies: "",
      post_microbesNumber_Population: "",
      post_microbesInvasive_Specie: "",
      post_microbesOrigin: "",
      post_microbesSpec_Recom_Restoration: "",
      post_microbesProd_per_Year: "",
    });
  };

  validatePostmicrobes = (e) => {
    let fields;
    fields = {
      post_microbesType: this.state.post_microbesType,
      post_microbesSpecies: this.state.post_microbesSpecies,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["post_microbesType"]) {
      formIsValid = false;
      // errors["post_microbesType"] = "Enter Post MicrobesType";
      // errors["post_microbesType"] = ToastNotification.ShowError(
      //   "Enter Post  MicrobesType"
      // );
      ToastNotification.ShowError("Select Post Microbes Type");
    }

    if (!fields["post_microbesSpecies"]) {
      formIsValid = false;
      // errors["post_microbesSpecies"] = "Enter Post MicrobesType";
      // errors["post_microbesSpecies"] = ToastNotification.ShowError(
      //   "Enter Post MicrobesType"
      // );
      ToastNotification.ShowError("Select Post Microbes Species");
    }
    // if (this.mounted) {
    //   this.setState({ errors: errors });
    // }
    return formIsValid;
  };
  deletePostmicrobes = (i) => {
    this.state.post_ListObjmicrobes.splice(i, 1);
    this.setState({ post_ListObjmicrobes: this.state.post_ListObjmicrobes });
  };
  editPostmicrobes = (i) => {
    this.setState({
      post_Dis_Microbes_Id:
        this.state.post_ListObjmicrobes[i].post_Dis_Microbes_Id,
      post_microbesType: this.state.post_ListObjmicrobes[i].post_microbesType,
      post_microbesSpecies:
        this.state.post_ListObjmicrobes[i].post_microbesSpecies,
      post_microbesNumber_Population:
        this.state.post_ListObjmicrobes[i].post_microbesNumber_Population,
      post_microbesOrigin:
        this.state.post_ListObjmicrobes[i].post_microbesOrigin,
      post_microbesSpec_Recom_Restoration:
        this.state.post_ListObjmicrobes[i].post_microbesSpec_Recom_Restoration,
      post_microbesProd_per_Year:
        this.state.post_ListObjmicrobes[i].post_microbesProd_per_Year,
      post_microbesInvasive_Specie:
        this.state.post_ListObjmicrobes[i].post_microbesInvasive_Specie,
    });
  };

  handlePostClimateType(event) {
    this.setState({ postClimateType: event.target.value });
  }
  // Post Water
  handlePostTurbidity(event) {
    this.setState({ post_Turbidity: event.target.value });
  }

  handlePostWElectricalConductivity(event) {
    this.setState({ post_WElectrical_Conductivity: event.target.value });
  }

  handlePostHardness(event) {
    this.setState({ post_Hardness: event.target.value });
  }
  handlePostWater = (e) => {
    e.preventDefault();
    if (this.validatePostWater()) {
      if (this.mounted) {
        //debugger
        if (this.state.post_Water_Id == "") {
          let newItem = {
            // post_Water_Id: this.state.post_Water_Id,
            post_Grid_No: this.state.post_Grid_No,
            post_Turbidity: this.state.post_Turbidity,
            post_Temperature: this.state.post_Temperature,
            post_Color: this.state.post_Color,
            post_Taste_Odor: this.state.post_Taste_Odor,
            post_WElectrical_Conductivity:
              this.state.post_WElectrical_Conductivity,
            post_PH: this.state.post_PH,
            post_Hardness: this.state.post_Hardness,
            post_Dissolved_Oxygen: this.state.post_Dissolved_Oxygen,
            post_Biochem_Oxygen_Demand: this.state.post_Biochem_Oxygen_Demand,
            post_Chem_Oxygen_Demand: this.state.post_Chem_Oxygen_Demand,
            post_Toxic_Inorganic_SubName:
              this.state.post_Toxic_Inorganic_SubName,
            post_Toxic_Inorganic_Description:
              this.state.post_Toxic_Inorganic_Description,
            post_Toxic_Organic_SubName: this.state.post_Toxic_Organic_SubName,
            post_Toxic_Organic_Description:
              this.state.post_Toxic_Organic_Description,
            post_Radioactive_SubName: this.state.post_Radioactive_SubName,
            post_Radioactive_Description:
              this.state.post_Radioactive_Description,
          };
          console.log(newItem);
          let { post_ListObjWater } = this.state;
          post_ListObjWater.push(newItem);
          this.setState({
            post_ListObjWater: post_ListObjWater,
          });
          console.log("PostWaternew", this.state.post_ListObjWater);
          this.ClearPostWater();
        } else {
          const newState = this.state.post_ListObjWater.map((obj) => {
            if (obj.post_Water_Id === this.state.post_Water_Id) {
              return {
                ...obj,
                post_Water_Id: this.state.post_Water_Id,
                post_Grid_No: this.state.post_Grid_No,
                post_Turbidity: this.state.post_Turbidity,
                post_Temperature: this.state.post_Temperature,
                post_Color: this.state.post_Color,
                post_Taste_Odor: this.state.post_Taste_Odor,
                post_WElectrical_Conductivity:
                  this.state.post_WElectrical_Conductivity,
                post_PH: this.state.post_PH,
                post_Hardness: this.state.post_Hardness,
                post_Dissolved_Oxygen: this.state.post_Dissolved_Oxygen,
                post_Biochem_Oxygen_Demand:
                  this.state.post_Biochem_Oxygen_Demand,
                post_Chem_Oxygen_Demand: this.state.post_Chem_Oxygen_Demand,
                post_Toxic_Inorganic_SubName:
                  this.state.post_Toxic_Inorganic_SubName,
                post_Toxic_Inorganic_Description:
                  this.state.post_Toxic_Inorganic_Description,
                post_Toxic_Organic_SubName:
                  this.state.post_Toxic_Organic_SubName,
                post_Toxic_Organic_Description:
                  this.state.post_Toxic_Organic_Description,
                post_Radioactive_SubName: this.state.post_Radioactive_SubName,
                post_Radioactive_Description:
                  this.state.post_Radioactive_Description,
              };
            }
            return obj;
          });
          this.setState({ post_ListObjWater: newState });
          this.ClearPostWater();
        }
      }
    }
  };

  ClearPostWater = (e) => {
    this.setState({
      post_Water_Id: "",
      post_Grid_No: "",
      post_Turbidity: "",
      post_Temperature: "",
      post_Color: "",
      post_Taste_Odor: "",
      post_WElectrical_Conductivity: "",
      post_PH: "",
      post_Hardness: "",
      post_Dissolved_Oxygen: "",
      post_Biochem_Oxygen_Demand: "",
      post_Chem_Oxygen_Demand: "",
      post_Toxic_Inorganic_SubName: "",
      post_Toxic_Inorganic_Description: "",
      post_Toxic_Organic_SubName: "",
      post_Toxic_Organic_Description: "",
      post_Radioactive_SubName: "",
      post_Radioactive_Description: "",
    });
  };

  validatePostWater = (e) => {
    let fields;
    fields = {
      post_Grid_No: this.state.post_Grid_No,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["post_Grid_No"]) {
      formIsValid = false;
      // errors["post_Grid_No"] = "Enter Post GridNo";
      // errors["post_Grid_No"] = ToastNotification.ShowError("Enter Post GridNo");
      ToastNotification.ShowError("Select Post Grid No");
    }

    // if (this.mounted) {
    //   this.setState({ errors: errors });
    // }
    return formIsValid;
  };
  deletePostWater = (i) => {
    this.state.post_ListObjWater.splice(i, 1);
    this.setState({ post_ListObjWater: this.state.post_ListObjWater });
  };
  editPostWater = (i) => {
    this.setState({
      post_Water_Id: this.state.post_ListObjWater[i].post_Water_Id,
      post_Grid_No: this.state.post_ListObjWater[i].post_Grid_No,
      post_Turbidity: this.state.post_ListObjWater[i].post_Turbidity,
      post_Temperature: this.state.post_ListObjWater[i].post_Temperature,
      post_Color: this.state.post_ListObjWater[i].post_Color,
      post_Taste_Odor: this.state.post_ListObjWater[i].post_Taste_Odor,
      post_WElectrical_Conductivity:
        this.state.post_ListObjWater[i].post_WElectrical_Conductivity,
      post_PH: this.state.post_ListObjWater[i].post_PH,
      post_Hardness: this.state.post_ListObjWater[i].post_Hardness,
      post_Dissolved_Oxygen:
        this.state.post_ListObjWater[i].post_Dissolved_Oxygen,
      post_Biochem_Oxygen_Demand:
        this.state.post_ListObjWater[i].post_Biochem_Oxygen_Demand,
      post_Chem_Oxygen_Demand:
        this.state.post_ListObjWater[i].post_Chem_Oxygen_Demand,
      post_Toxic_Inorganic_SubName:
        this.state.post_ListObjWater[i].post_Toxic_Inorganic_SubName,
      post_Toxic_Inorganic_Description:
        this.state.post_ListObjWater[i].post_Toxic_Inorganic_Description,
      post_Toxic_Organic_SubName:
        this.state.post_ListObjWater[i].post_Toxic_Organic_SubName,
      post_Toxic_Organic_Description:
        this.state.post_ListObjWater[i].post_Toxic_Organic_Description,
      post_Radioactive_SubName:
        this.state.post_ListObjWater[i].post_Radioactive_SubName,
      post_Radioactive_Description:
        this.state.post_ListObjWater[i].post_Radioactive_Description,
    });
  };

  // Post soil function

  handlePostSoilTextures(event) {
    this.setState({ post_Soil_Textures: event.target.value });
  }
  handlePostSoilStructure(event) {
    this.setState({ post_Soil_Structure: event.target.value });
  }
  handlePostSoilPorosity(event) {
    this.setState({ post_Soil_Porosity: event.target.value });
  }
  handlePostSoilGridNo(event) {
    this.setState({ post_Grid_No: event.target.value });
  }

  handlePostSoil = (e) => {
    e.preventDefault();
    if (this.validatePostSoil()) {
      if (this.mounted) {
        // debugger
        if (this.state.post_Soil_Id == "") {
          let newItem = {
            post_Soil_Id: this.state.post_Soil_Id,
            post_Grid_No: this.state.post_Grid_No,
            post_Soil_Textures: this.state.post_Soil_Textures,
            post_Soil_Structure: this.state.post_Soil_Structure,
            post_Soil_Porosity: this.state.post_Soil_Porosity,
            post_Soil_pH: this.state.post_Soil_pH,
            post_Nitrate: this.state.post_Nitrate,
            post_Phosphate: this.state.post_Phosphate,
            post_Potassium: this.state.post_Potassium,
            post_SElectrical_Conductivity:
              this.state.post_SElectrical_Conductivity,
            post_Soil_Color: this.state.post_Soil_Color,
            post_Soil_Moisture_Level: this.state.post_Soil_Moisture_Level,
            post_Organic_Carbon: this.state.post_Organic_Carbon,
          };
          console.log(newItem);
          let { post_ListObjSoil } = this.state;
          post_ListObjSoil.push(newItem);
          this.setState({
            post_ListObjSoil: post_ListObjSoil,
          });
          console.log("PostSoilnew", this.state.post_ListObjSoil);
          this.ClearPostSoil();
        } else {
          const newState = this.state.post_ListObjSoil.map((obj) => {
            if (obj.post_Soil_Id === this.state.post_Soil_Id) {
              return {
                ...obj,
                post_Soil_Id: this.state.post_Soil_Id,
                post_Grid_No: this.state.post_Grid_No,
                post_Soil_Textures: this.state.post_Soil_Textures,
                post_Soil_Structure: this.state.post_Soil_Structure,
                post_Soil_Porosity: this.state.post_Soil_Porosity,
                post_Soil_pH: this.state.post_Soil_pH,
                post_Nitrate: this.state.post_Nitrate,
                post_Phosphate: this.state.post_Phosphate,
                post_Potassium: this.state.post_Potassium,
                post_SElectrical_Conductivity:
                  this.state.post_SElectrical_Conductivity,
                post_Soil_Color: this.state.post_Soil_Color,
                post_Soil_Moisture_Level: this.state.post_Soil_Moisture_Level,
                post_Organic_Carbon: this.state.post_Organic_Carbon,
              };
            }
            return obj;
          });
          this.setState({ post_ListObjSoil: newState });
          console.log("ListSoil", this.state.post_ListObjSoil);
          this.ClearPostSoil();
        }
      }
    }
  };
  ClearPostSoil = (e) => {
    this.setState({
      post_Soil_Id: "",
      post_Grid_No: "",
      post_Soil_Textures: "",
      post_Soil_Structure: "",
      post_Soil_Porosity: "",
      post_Soil_pH: "",
      post_Nitrate: "",
      post_Phosphate: "",
      post_Potassium: "",
      post_SElectrical_Conductivity: "",
      post_Soil_Color: "",
      post_Soil_Moisture_Level: "",
      post_Organic_Carbon: "",
    });
  };

  validatePostSoil = (e) => {
    let fields;
    fields = {
      post_Organic_Carbon: this.state.post_Organic_Carbon,
      post_Grid_No: this.state.post_Grid_No,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["post_Grid_No"]) {
      formIsValid = false;
      ToastNotification.ShowError("Select Post Grid No.");
    }
    if (!fields["post_Organic_Carbon"]) {
      formIsValid = false;
      // errors["post_Organic_Carbon"] = "Enter Post OrganicCarbon";
      // errors["post_Organic_Carbon"] = ToastNotification.ShowError(
      //   "Enter Post OrganicCarbon"
      // );
      ToastNotification.ShowError("Enter Post Organic Carbon");
    }
    // if (this.mounted) {
    //   this.setState({ errors: errors });
    // }
    return formIsValid;
  };
  deletePostSoil = (i) => {
    this.state.post_ListObjSoil.splice(i, 1);
    this.setState({ post_ListObjSoil: this.state.post_ListObjSoil });
  };
  editPostSoil = (i) => {
    this.setState({
      post_Soil_Id: this.state.post_ListObjSoil[i].post_Soil_Id,
      post_Grid_No: this.state.post_ListObjSoil[i].post_Grid_No,
      post_Soil_Textures: this.state.post_ListObjSoil[i].post_Soil_Textures,
      post_Soil_Structure: this.state.post_ListObjSoil[i].post_Soil_Structure,
      post_Soil_Porosity: this.state.post_ListObjSoil[i].post_Soil_Porosity,
      post_Soil_pH: this.state.post_ListObjSoil[i].post_Soil_pH,
      post_Nitrate: this.state.post_ListObjSoil[i].post_Nitrate,
      post_Phosphate: this.state.post_ListObjSoil[i].post_Phosphate,
      post_Potassium: this.state.post_ListObjSoil[i].post_Potassium,
      post_SElectrical_Conductivity:
        this.state.post_ListObjSoil[i].post_SElectrical_Conductivity,
      post_Soil_Color: this.state.post_ListObjSoil[i].post_Soil_Color,
      post_Soil_Moisture_Level:
        this.state.post_ListObjSoil[i].post_Soil_Moisture_Level,
      post_Organic_Carbon: this.state.post_ListObjSoil[i].post_Organic_Carbon,
    });
  };

  // Records from ProjectTreeTypeGridno
  GetAllGridNo = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetAllGridNo`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data;
        this.setState({ gridNoList: responseData });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  // All the data be taken from Project Objective
  GetSiteDetails = (ProjectId) => {
    let projId = { ProjectId: ProjectId };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetSiteDetails`;
    fetch(url, {
      method: "Post",
      body: JSON.stringify(projId),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        //debugger
        console.log("SiteDetailsData", responseData);

        //  let newdisturbances_Occurred={
        //   disturbances_Occurred_Id:responseData.disturbance_Details.disturbances_Occurred.disturbances_Occurred_Id,
        //         type_Cause:responseData.disturbance_Details.disturbances_Occurred.type_Cause,
        //         exact_Cause:responseData.disturbance_Details.disturbances_Occurred.exact_Cause,
        //         frequency:responseData.disturbance_Details.disturbances_Occurred.frequency,
        //         start_Month:(responseData.disturbance_Details.disturbances_Occurred.start_Month).slice(0, -1).split('T')[0],
        //         end_Month:(responseData.disturbance_Details.disturbances_Occurred.end_Month).slice(0, -1).split('T')[0],

        //  }

        this.setState({
          ProjectId: responseData.projectId,
          SiteDetailsId: responseData.siteDetailsId,
          ListObjFlora: responseData.pre_DisturbanceDetails.pre_Dis_Flora,
          ListObjFauna: responseData.pre_DisturbanceDetails.pre_Dis_Fauna,
          ListObjMicrobes: responseData.pre_DisturbanceDetails.pre_Dis_Microbes,

          EcoRegion: responseData.ecoRegion,

          selectedTargetEcosystemType: responseData.ecosystemType,
          selectedEcosystemSubType: responseData.ecosystemSubType,
          Year_of_Reference:
            responseData.pre_DisturbanceDetails.pre_Dis_Ref_YearOfReference !=
            null
              ? responseData.pre_DisturbanceDetails.pre_Dis_Ref_YearOfReference
                  .slice(0, -1)
                  .split("T")[0]
              : "",
          Sources: responseData.pre_DisturbanceDetails.pre_Dis_Ref_Sources,
          Remarks: responseData.pre_DisturbanceDetails.pre_Dis_Ref_Remarks,
          ClimateType: responseData.pre_DisturbanceDetails.climate.climateType,
          Avg_Temp_Summer:
            responseData.pre_DisturbanceDetails.climate.avg_Temp_Summer,
          Avg_Temp_Winter:
            responseData.pre_DisturbanceDetails.climate.avg_Temp_Winter,
          Air_Quality_Index:
            responseData.pre_DisturbanceDetails.climate.air_Quality_Index,
          Humidity: responseData.pre_DisturbanceDetails.climate.humidity,
          Avg_Yearly_Rainfall:
            responseData.pre_DisturbanceDetails.climate.avg_Yearly_Rainfall,
          Avg_Wind_Speed:
            responseData.pre_DisturbanceDetails.climate.avg_Wind_Speed,
          Avg_No_Hours_Sunlight_Avail_Summer:
            responseData.pre_DisturbanceDetails.climate
              .avg_No_Hours_Sunlight_Avail_Summer,
          Avg_No_Hours_Sunlight_Avail_Winter:
            responseData.pre_DisturbanceDetails.climate
              .avg_No_Hours_Sunlight_Avail_Winter,

          Turbidity: responseData.pre_DisturbanceDetails.water.turbidity,
          Temperature: responseData.pre_DisturbanceDetails.water.temperature,
          Color: responseData.pre_DisturbanceDetails.water.color,
          Taste_Odor: responseData.pre_DisturbanceDetails.water.taste_Odor,
          WElectrical_Conductivity:
            responseData.pre_DisturbanceDetails.water.electrical_Conductivity,
          PH: responseData.pre_DisturbanceDetails.water.ph,
          Hardness: responseData.pre_DisturbanceDetails.water.hardness,
          Dissolved_Oxygen:
            responseData.pre_DisturbanceDetails.water.dissolved_Oxygen,
          Biochem_Oxygen_Demand:
            responseData.pre_DisturbanceDetails.water.biochem_Oxygen_Demand,
          Chem_Oxygen_Demand:
            responseData.pre_DisturbanceDetails.water.chem_Oxygen_Demand,

          ToxicInorganicSName:
            responseData.pre_DisturbanceDetails.water.toxic_Inorganic_Subs
              .toxicInorganicSName,
          ToxicInorganicDescription:
            responseData.pre_DisturbanceDetails.water.toxic_Inorganic_Subs
              .toxicInorganicDescription,

          ToxicOrganicSName:
            responseData.pre_DisturbanceDetails.water.toxic_Organic_Subs
              .toxicOrganicSName,
          ToxicOrganicDescription:
            responseData.pre_DisturbanceDetails.water.toxic_Organic_Subs
              .toxicOrganicDescription,

          RadioactiveSuName:
            responseData.pre_DisturbanceDetails.water.radioactive_Subs
              .radioactiveSuName,
          Description:
            responseData.pre_DisturbanceDetails.water.radioactive_Subs
              .description,
          Tot_Carbon_Offset_Capacity:
            responseData.pre_DisturbanceDetails.tot_Carbon_Offset_Capacity,
          Soil_Textures: responseData.pre_DisturbanceDetails.soil.soil_Textures,
          Soil_Structure:
            responseData.pre_DisturbanceDetails.soil.soil_Structure,
          Soil_Porosity: responseData.pre_DisturbanceDetails.soil.soil_Porosity,
          Soil_pH: responseData.pre_DisturbanceDetails.soil.soil_pH,
          Nitrate: responseData.pre_DisturbanceDetails.soil.nitrate,
          Phosphate: responseData.pre_DisturbanceDetails.soil.phosphate,
          Potassium: responseData.pre_DisturbanceDetails.soil.potassium,
          SElectrical_Conductivity:
            responseData.pre_DisturbanceDetails.soil.electrical_Conductivity,
          Soil_Color: responseData.pre_DisturbanceDetails.soil.soil_Color,
          Soil_Moisture_Level:
            responseData.pre_DisturbanceDetails.soil.soil_Moisture_Level,
          Organic_Carbon:
            responseData.pre_DisturbanceDetails.soil.organic_Carbon,

          No_Families: responseData.pre_DisturbanceDetails.social.no_Families,
          Avg_Family_Size:
            responseData.pre_DisturbanceDetails.social.avg_Family_Size,
          Gender: responseData.pre_DisturbanceDetails.social.gender,
          Literacy_Rate_Overall:
            responseData.pre_DisturbanceDetails.social.literacy_Rate_Overall,
          Literacy_Rate_Female:
            responseData.pre_DisturbanceDetails.social.literacy_Rate_Female,
          Avg_Annual_Inc_Family_Farming:
            responseData.pre_DisturbanceDetails.social
              .avg_Annual_Inc_Family_Farming,
          Avg_Annual_Inc_Family_Other_Sources:
            responseData.pre_DisturbanceDetails.social
              .avg_Annual_Inc_Family_Other_Sources,

          //Disturbance Details

          // ListDisturbancesOccurred:newdisturbances_Occurred,
          ListDisturbancesOccurred:
            responseData.disturbance_Details.disturbances_Occurred,
          ListDisturbanceImpact:
            responseData.disturbance_Details.disturbanceImpact
              .disturbanceImpactList,
          tot_Area_Impacted:
            responseData.disturbance_Details.disturbanceImpact
              .tot_Area_Impacted,
          scale: responseData.disturbance_Details.disturbanceImpact.scale,

          // Post Disturbance
          Post_Year_of_Reference:
            responseData.post_DisturbanceDetails.post_Dis_Ref_YearOfReference !=
            null
              ? responseData.post_DisturbanceDetails.post_Dis_Ref_YearOfReference
                  .slice(0, -1)
                  .split("T")[0]
              : "",
          Post_Sources:
            responseData.post_DisturbanceDetails.post_Dis_Ref_Sources,
          Post_Remarks:
            responseData.post_DisturbanceDetails.post_Dis_Ref_Remarks,
          post_ListObjFlora:
            responseData.post_DisturbanceDetails.post_Dis_Flora,
          post_ListObjmicrobes:
            responseData.post_DisturbanceDetails.post_Dis_Microbes,
          post_ListObjFauna:
            responseData.post_DisturbanceDetails.post_Dis_Fauna,

          postClimateType:
            responseData.post_DisturbanceDetails.climate.climateType,
          postAvg_Temp_Summer:
            responseData.post_DisturbanceDetails.climate.avg_Temp_Summer,
          postAvg_Temp_Winter:
            responseData.post_DisturbanceDetails.climate.avg_Temp_Winter,
          postAir_Quality_Index:
            responseData.post_DisturbanceDetails.climate.air_Quality_Index,
          postHumidity: responseData.post_DisturbanceDetails.climate.humidity,
          postAvg_Yearly_Rainfall:
            responseData.post_DisturbanceDetails.climate.avg_Yearly_Rainfall,
          postAvg_Wind_Speed:
            responseData.post_DisturbanceDetails.climate.avg_Wind_Speed,
          postAvg_No_Hours_Sunlight_Avail_Summer:
            responseData.post_DisturbanceDetails.climate
              .avg_No_Hours_Sunlight_Avail_Summer,
          postAvg_No_Hours_Sunlight_Avail_Winter:
            responseData.post_DisturbanceDetails.climate
              .avg_No_Hours_Sunlight_Avail_Winter,

          postTot_Carbon_Offset_Capacity:
            responseData.post_DisturbanceDetails
              .post_Dis_Tot_Carbon_Offset_Capacity,

          postNo_Families:
            responseData.post_DisturbanceDetails.social.no_Families,
          postAvg_Family_Size:
            responseData.post_DisturbanceDetails.social.avg_Family_Size,
          postGender: responseData.post_DisturbanceDetails.social.gender,
          postLiteracy_Rate_Overall:
            responseData.post_DisturbanceDetails.social.literacy_Rate_Overall,
          postLiteracy_Rate_Female:
            responseData.post_DisturbanceDetails.social.literacy_Rate_Female,
          postAvg_Annual_Inc_Family_Farming:
            responseData.post_DisturbanceDetails.social
              .avg_Annual_Inc_Family_Farming,
          postAvg_Annual_Inc_Family_Other_Sources:
            responseData.post_DisturbanceDetails.social
              .avg_Annual_Inc_Family_Other_Sources,
          //Post Water
          post_ListObjWater: responseData.post_DisturbanceDetails.water,
          post_ListObjSoil: responseData.post_DisturbanceDetails.soil,
        });
        console.log("V", this.state.ListObjFlora);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  Save = (e) => {
    e.preventDefault();
    // if (this.validateForm()) {
    if (this.mounted) {
      let Pre_Dis_FloraList = [];
      this.state.ListObjFlora.forEach((element) => {
        let newItem = {
          pre_Dis_Flora_Id: element.pre_Dis_Flora_Id,
          FloraType: element.floraType,
          FloraSpecies: element.floraSpecies,
          floraNumber_Population: element.floraNumber_Population,
          floraInvasive_Specie: element.floraInvasive_Specie,
          floraOrigin: element.floraOrigin,
          floraSpec_Recom_Restoration: element.floraSpec_Recom_Restoration,
          floraProd_per_Year: element.floraProd_per_Year,
        };
        Pre_Dis_FloraList.push(newItem);
      });
      let Pre_Dis_FaunaList = [];
      this.state.ListObjFauna.forEach((element) => {
        let newFauna = {
          pre_Dis_Fauna_Id: element.pre_Dis_Fauna_Id,
          faunaType: element.faunaType,
          faunaSpecies: element.faunaSpecies,
          faunaNumber_Population: element.faunaNumber_Population,
          faunaInvasive_Specie: element.faunaInvasive_Specie,
          faunaOrigin: element.faunaOrigin,
          faunaSpec_Recom_Restoration: element.faunaSpec_Recom_Restoration,
          faunaProd_per_Year: element.faunaProd_per_Year,
        };
        Pre_Dis_FaunaList.push(newFauna);
      });
      let Pre_Dis_MicrobesList = [];
      this.state.ListObjMicrobes.forEach((element) => {
        let newMicrobes = {
          pre_Dis_Microbes_Id: element.pre_Dis_Microbes_Id,
          microbesType: element.microbesType,
          microbesSpecies: element.microbesSpecies,
          microbesNumber_Population: element.microbesNumber_Population,
          microbesInvasive_Specie: element.microbesInvasive_Specie,
          microbesOrigin: element.microbesOrigin,
          microbesSpec_Recom_Restoration:
            element.microbesSpec_Recom_Restoration,
          microbesProd_per_Year: element.microbesProd_per_Year,
        };
        Pre_Dis_MicrobesList.push(newMicrobes);
      });
      let newClimate = {
        ClimateType: this.state.ClimateType,
        Avg_Temp_Summer: this.state.Avg_Temp_Summer,
        Avg_Temp_Winter: this.state.Avg_Temp_Winter,
        Air_Quality_Index: this.state.Air_Quality_Index,
        Humidity: this.state.Humidity,
        Avg_Yearly_Rainfall: this.state.Avg_Yearly_Rainfall,
        Avg_Wind_Speed: this.state.Avg_Wind_Speed,
        Avg_No_Hours_Sunlight_Avail_Summer:
          this.state.Avg_No_Hours_Sunlight_Avail_Summer,
        Avg_No_Hours_Sunlight_Avail_Winter:
          this.state.Avg_No_Hours_Sunlight_Avail_Winter,
      };

      let newToxic_Inorganic_Subs = {
        ToxicInorganicSName: this.state.ToxicInorganicSName,
        ToxicInorganicDescription: this.state.ToxicInorganicDescription,
      };
      let newToxic_Organic_Subs = {
        ToxicOrganicSName: this.state.ToxicOrganicSName,
        ToxicOrganicDescription: this.state.ToxicOrganicDescription,
      };
      let newRadioactive_Subs = {
        RadioactiveSuName: this.state.RadioactiveSuName,
        Description: this.state.Description,
      };
      let newWater = {
        Turbidity: this.state.Turbidity,
        Temperature: this.state.Temperature,
        Color: this.state.Color,
        Taste_Odor: this.state.Taste_Odor,
        Electrical_Conductivity: this.state.WElectrical_Conductivity,
        PH: this.state.PH,
        Hardness: this.state.Hardness,
        Dissolved_Oxygen: this.state.Dissolved_Oxygen,
        Biochem_Oxygen_Demand: this.state.Biochem_Oxygen_Demand,
        Chem_Oxygen_Demand: this.state.Chem_Oxygen_Demand,
        Toxic_Inorganic_Subs: newToxic_Inorganic_Subs,
        Toxic_Organic_Subs: newToxic_Organic_Subs,
        Radioactive_Subs: newRadioactive_Subs,
      };
      let newSoil = {
        Soil_Textures: this.state.Soil_Textures,
        Soil_Structure: this.state.Soil_Structure,
        Soil_Porosity: this.state.Soil_Porosity,
        Soil_pH: this.state.Soil_pH,
        Nitrate: this.state.Nitrate,
        Phosphate: this.state.Phosphate,
        Potassium: this.state.Potassium,
        Electrical_Conductivity: this.state.SElectrical_Conductivity,
        Soil_Color: this.state.Soil_Color,
        Soil_Moisture_Level: this.state.Soil_Moisture_Level,
        Organic_Carbon: this.state.Organic_Carbon,
      };

      let newSocial = {
        No_Families: this.state.No_Families,
        Avg_Family_Size: this.state.Avg_Family_Size,
        Gender: this.state.Gender,
        Literacy_Rate_Overall: this.state.Literacy_Rate_Overall,
        Literacy_Rate_Female: this.state.Literacy_Rate_Female,
        Avg_Annual_Inc_Family_Farming: this.state.Avg_Annual_Inc_Family_Farming,
        Avg_Annual_Inc_Family_Other_Sources:
          this.state.Avg_Annual_Inc_Family_Other_Sources,
      };

      let newPreDisturbanceDetails = {
        Pre_Dis_Ref_YearOfReference:
          this.state.Year_of_Reference != ""
            ? new Date(this.state.Year_of_Reference).toUTCString()
            : "",
        Pre_Dis_Ref_Sources: this.state.Sources,
        Pre_Dis_Ref_Remarks: this.state.Remarks,
        Pre_Dis_Flora: Pre_Dis_FloraList,
        Pre_Dis_Fauna: Pre_Dis_FaunaList,
        Pre_Dis_Microbes: Pre_Dis_MicrobesList,
        Climate: newClimate,
        Water: newWater,
        Soil: newSoil,

        Tot_Carbon_Offset_Capacity: this.state.Tot_Carbon_Offset_Capacity,

        Social: newSocial,
      };
      let Post_Dis_FloraList = [];
      this.state.post_ListObjFlora.forEach((element) => {
        let newItem = {
          Post_Dis_Flora_Id: element.post_Dis_Flora_Id,
          Post_floraType: element.post_floraType,

          Post_floraSpecies: element.post_floraSpecies,
          Post_floraNumber_Population: element.post_floraNumber_Population,
          Post_floraInvasive_Specie: element.post_floraInvasive_Specie,
          Post_floraOrigin: element.post_floraOrigin,
          Post_floraSpec_Recom_Restoration:
            element.post_floraSpec_Recom_Restoration,
          Post_floraProd_per_Year: element.post_floraProd_per_Year,
        };
        Post_Dis_FloraList.push(newItem);
      });
      let Post_Dis_FaunaList = [];
      this.state.post_ListObjFauna.forEach((element) => {
        let newItem = {
          Post_Dis_Fauna_Id: element.post_Dis_Fauna_Id,
          Post_faunaType: element.post_faunaType,

          Post_faunaSpecies: element.post_faunaSpecies,
          Post_faunaNumber_Population: element.post_faunaNumber_Population,
          Post_faunaInvasive_Specie: element.post_faunaInvasive_Specie,
          Post_faunaOrigin: element.post_faunaOrigin,
          Post_faunaSpec_Recom_Restoration:
            element.post_faunaSpec_Recom_Restoration,
          Post_faunaProd_per_Year: element.post_faunaProd_per_Year,
        };
        Post_Dis_FaunaList.push(newItem);
      });
      let Post_Dis_microbesList = [];
      this.state.post_ListObjmicrobes.forEach((element) => {
        let newItem = {
          Post_Dis_Microbes_Id: element.post_Dis_Microbes_Id,
          Post_microbesType: element.post_microbesType,

          Post_microbesSpecies: element.post_microbesSpecies,
          Post_microbesNumber_Population:
            element.post_microbesNumber_Population,
          Post_microbesInvasive_Specie: element.post_microbesInvasive_Specie,
          Post_microbesOrigin: element.post_microbesOrigin,
          Post_microbesSpec_Recom_Restoration:
            element.post_microbesSpec_Recom_Restoration,
          Post_microbesProd_per_Year: element.post_microbesProd_per_Year,
        };
        Post_Dis_microbesList.push(newItem);
      });
      let newPostClimate = {
        ClimateType: this.state.postClimateType,
        Avg_Temp_Summer: this.state.postAvg_Temp_Summer,
        Avg_Temp_Winter: this.state.postAvg_Temp_Winter,
        Air_Quality_Index: this.state.postAir_Quality_Index,
        Humidity: this.state.postHumidity,
        Avg_Yearly_Rainfall: this.state.postAvg_Yearly_Rainfall,
        Avg_Wind_Speed: this.state.postAvg_Wind_Speed,
        Avg_No_Hours_Sunlight_Avail_Summer:
          this.state.postAvg_No_Hours_Sunlight_Avail_Summer,
        Avg_No_Hours_Sunlight_Avail_Winter:
          this.state.postAvg_No_Hours_Sunlight_Avail_Winter,
      };
      let newPostSocial = {
        No_Families: this.state.postNo_Families,
        Avg_Family_Size: this.state.postAvg_Family_Size,
        Gender: this.state.postGender,
        Literacy_Rate_Overall: this.state.postLiteracy_Rate_Overall,
        Literacy_Rate_Female: this.state.postLiteracy_Rate_Female,
        Avg_Annual_Inc_Family_Farming:
          this.state.postAvg_Annual_Inc_Family_Farming,
        Avg_Annual_Inc_Family_Other_Sources:
          this.state.postAvg_Annual_Inc_Family_Other_Sources,
      };

      let Post_Dis_WaterList = [];
      this.state.post_ListObjWater.forEach((element) => {
        let newItem = {
          Post_Water_Id: element.post_Water_Id,
          Post_Grid_No: element.post_Grid_No,
          Post_Turbidity: element.post_Turbidity,
          Post_Temperature: element.post_Temperature,
          Post_Color: element.post_Color,
          Post_Taste_Odor: element.post_Taste_Odor,
          Post_WElectrical_Conductivity: element.post_WElectrical_Conductivity,
          Post_PH: element.post_PH,
          Post_Hardness: element.post_Hardness,
          Post_Dissolved_Oxygen: element.post_Dissolved_Oxygen,
          Post_Biochem_Oxygen_Demand: element.post_Biochem_Oxygen_Demand,
          Post_Chem_Oxygen_Demand: element.post_Chem_Oxygen_Demand,
          Post_Toxic_Inorganic_SubName: element.post_Toxic_Inorganic_SubName,
          Post_Toxic_Inorganic_Description:
            element.post_Toxic_Inorganic_Description,
          Post_Toxic_Organic_SubName: element.post_Toxic_Organic_SubName,
          Post_Toxic_Organic_Description:
            element.post_Toxic_Organic_Description,
          Post_Radioactive_SubName: element.post_Radioactive_SubName,
          Post_Radioactive_Description: element.post_Radioactive_Description,
        };
        Post_Dis_WaterList.push(newItem);
      });

      let Post_Dis_SoilList = [];
      this.state.post_ListObjSoil.forEach((element) => {
        let newItem = {
          Post_Soil_Id: element.post_Soil_Id,
          Post_Grid_No: element.post_Grid_No,
          Post_Soil_Textures: element.post_Soil_Textures,
          Post_Soil_Structure: element.post_Soil_Structure,
          Post_Soil_Porosity: element.post_Soil_Porosity,
          Post_Soil_pH: element.post_Soil_pH,
          Post_Nitrate: element.post_Nitrate,
          Post_Phosphate: element.post_Phosphate,
          Post_Potassium: element.post_Potassium,
          Post_SElectrical_Conductivity: element.post_SElectrical_Conductivity,
          Post_Soil_Color: element.post_Soil_Color,
          Post_Soil_Moisture_Level: element.post_Soil_Moisture_Level,
          Post_Organic_Carbon: element.post_Organic_Carbon,
        };
        Post_Dis_SoilList.push(newItem);
      });
      let newPostDisturbanceDetails = {
        Post_Dis_Ref_YearOfReference:
          this.state.Post_Year_of_Reference != ""
            ? new Date(this.state.Post_Year_of_Reference).toUTCString()
            : "",
        Post_Dis_Ref_Sources: this.state.Post_Sources,
        Post_Dis_Ref_Remarks: this.state.Post_Remarks,
        Post_Dis_Flora: Post_Dis_FloraList,
        Post_Dis_Fauna: Post_Dis_FaunaList,
        Post_Dis_Microbes: Post_Dis_microbesList,
        Climate: newPostClimate,
        Water: Post_Dis_WaterList,
        Soil: Post_Dis_SoilList,

        Post_Dis_Tot_Carbon_Offset_Capacity:
          this.state.postTot_Carbon_Offset_Capacity,

        Social: newPostSocial,
      };
      let newDisturbancesOccurredList = [];
      this.state.ListDisturbancesOccurred.forEach((element) => {
        let newDisturbancesOccurred = {
          Disturbances_Occurred_Id: element.disturbances_Occurred_Id,
          Type_Cause: element.type_Cause,
          Exact_Cause: element.exact_Cause,
          Frequency: element.frequency,
          Start_Month: element.start_Month,
          End_Month: element.end_Month,
        };
        newDisturbancesOccurredList.push(newDisturbancesOccurred);
      });
      let newDisturbanceImpactList = [];
      this.state.ListDisturbanceImpact.forEach((element) => {
        let newDisturbanceImpact = {
          DisturbanceImpactList_Id: element.disturbanceImpactList_Id,
          Impact_Severity: element.impact_Severity,
          Area_Impacted: element.area_Impacted,
          Land_Parcels_Impacted: element.land_Parcels_Impacted,
          Water_Bodies_Impacted: element.water_Bodies_Impacted,
        };
        newDisturbanceImpactList.push(newDisturbanceImpact);
      });
      let newDisturbanceImpact = {
        DisturbanceImpact_Id: this.state.disturbanceImpact_Id,
        DisturbanceImpactList: newDisturbanceImpactList,
        Tot_Area_Impacted: this.state.tot_Area_Impacted,
        Scale: this.state.scale,
      };
      let newDisturbanceDetails = {
        Disturbances_Occurred: newDisturbancesOccurredList,
        DisturbanceImpact: newDisturbanceImpact,
      };
      console.log("NewPreDisturbanceDetails", newPreDisturbanceDetails);

      let data = {
        ProjectId: this.state.ProjectId,
        SiteDetailsId: this.state.SiteDetailsId,
        EcoRegion: this.state.EcoRegion,
        EcosystemType: this.state.selectedTargetEcosystemType,
        EcosystemSubType: this.state.selectedEcosystemSubType,
        Pre_DisturbanceDetails: newPreDisturbanceDetails,
        Disturbance_Details: newDisturbanceDetails,
        Post_DisturbanceDetails: newPostDisturbanceDetails,
      };
      console.log("data", data);
      let url = `${config.API_HOST_URL}/api/Ecosystem/AddSiteDetails`;
      fetch(url, {
        method: "POST",
        headers: {
          WebsiteGuid: this.props.websiteGUID,
          LanguageGuid: this.props.languageGUID,
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },

        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((resp) => {
          if (resp.success === true) {
            //this.clearFields();
            ToastNotification.ShowSuccess(resp.message);
            this.Nextpage();
          } else {
            ToastNotification.ShowError(resp.message);
          }
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    }
    // }
  };
  // This function do validate the Site Details Form
  validateForm = (e) => {
    let fields;
    fields = {
      organic_Carbon: this.state.Organic_Carbon,
    };
    let errors = {};
    let formIsValid = true;
    var pattern = RegExp(/^[a-z0-9]+$/i);

    // if (!fields["organic_Carbon"])
    if (!pattern.test(fields["organic_Carbon"])) {
      formIsValid = false;
      errors["organic_Carbon"] = "Enter organic Carbon";
      errors["organic_Carbon"] = ToastNotification.ShowError(
        "Enter organic Carbon"
      );
    }

    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  };
  // This function do validate Microbes
  validateMicrobes = (e) => {
    let fields;
    fields = {
      microbesType: this.state.microbesType,
      // microbesSpecies:this.state.microbesSpecies,
    };
    let errors = {};
    let formIsValid = true;

    if (!fields["microbesType"]) {
      formIsValid = false;
      // errors["microbesType"] = "Enter  MicrobesType";
      // errors["microbesType"] = ToastNotification.ShowError(
      //   "Enter  MicrobesType"
      // );
      ToastNotification.ShowError("Select Microbes Type");
    }

    //   if (!fields["microbesSpecies"]) {
    //     formIsValid = false;
    //     errors["microbesSpecies"] = "Enter MicrobesSpecies";
    // }

    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  };
  // This function do validate Fauna
  validateFauna = (e) => {
    let fields;
    fields = {
      faunaType: this.state.faunaType,
      //  faunaSpecies:this.state.faunaSpecies,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["faunaType"]) {
      formIsValid = false;

      // errors["faunaType"] = "Enter  FaunaType";
      // errors["faunaType"] = ToastNotification.ShowError("Enter  FaunaType");
      ToastNotification.ShowError("Select Fauna Type");
    }
    //   if (!fields["faunaSpecies"]) {
    //     formIsValid = false;
    //     errors["faunaSpecies"] = "Enter FaunaSpecies";

    // }

    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  };
  // This function do validate Flora
  validateFlora = (e) => {
    let fields;
    fields = {
      floraType: this.state.floraType,
      floraSpecies: this.state.floraSpecies,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["floraType"]) {
      formIsValid = false;
      // errors["floraType"] = "Enter Flora Type";
      // errors["floraType"] = ToastNotification.ShowError("Enter Flora Type");
      ToastNotification.ShowError("Select Flora Type");
    }

    if (!fields["floraSpecies"]) {
      formIsValid = false;
      // errors["floraSpecies"] = "Enter Flora Species";
      // errors["floraSpecies"] = ToastNotification.ShowError(
      //   "Enter Flora Species"
      // );
      ToastNotification.ShowError("Select Flora Species");
    }
    // if (this.mounted) {
    //   this.setState({ errors: errors });
    // }
    return formIsValid;
  };

  Nextpage = () => {
    navigate("/TreeTiesAddProjectForms1Obj/:" + this.state.ProjectId, {
      state: { activeMenu: 7 },
    });
  };

  Back = () => {
    navigate("/TreeTiesAddProjectForms1Own/:" + this.state.ProjectId, {
      state: { activeMenu: 5 },
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return (
      <>
        <div className="treeties-addproject-forms1-sit ecosysobjtab_cont">
          <div className="main-content-area3">
            <div className="middle-content-area3">
              <div className="container-18">
                <ProjectSummary />
              </div>
              <div className="ecosys_middlecont">
                <LeftTabMenuContainer
                  activeMenu={this.props.location.state?.activeMenu}
                  activesubSite={this.props.location.state?.activesubSite}
                  onPre={this.gotoPre}
                  onDis={this.gotoDis}
                  onPost={this.gotoPost}
                />
                <div className="ecosys_rightcont">
                  <div className="hero-title7 pb-4">
                    <b className="project-summary7">General</b>
                  </div>
                  <div className="input-field-text10 pb-4">
                    <div className="content-title-text51">
                      <div className="hero-title6">
                        <div className="project-summary6">Eco Region</div>
                      </div>
                      <select
                        className="dropdown-18"
                        value={this.state.EcoRegion}
                        onChange={this.handleEcoRegion}
                      >
                        <option value="text">Select</option>
                        {this.state.commonMastersList
                          .filter((x) => x.type === "Eco Region")
                          .map((item) => (
                            <option value={item.mainGuId}>
                              {item.description}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="content-title-text51">
                      <div className="hero-title6">
                        <div className="project-summary6">Ecosystem Type</div>
                      </div>

                      <select
                        className="dropdown-18"
                        value={this.state.selectedTargetEcosystemType}
                        onChange={this.handleTargetEcosystemType}
                        ref="selectedGroup"
                        name="tetype"
                      >
                        <option value="" selected="selected">
                          Select{" "}
                        </option>
                        {this.state.commonMastersList
                          .filter((x) => x.type === "ProjectType")
                          .map((item) => (
                            <option value={item.mainGuId}>
                              {item.description}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="content-title-text51">
                      <div className="hero-title6">
                        <div className="project-summary6">
                          Ecosystem Sub Type
                        </div>
                      </div>

                      <select
                        className="dropdown-18"
                        value={this.state.selectedEcosystemSubType}
                        onChange={this.handleTargetEcosystemSubType}
                        ref="selectedGroup"
                        name="ddlsubtype"
                      >
                        <option value="" selected="selected">
                          Select
                        </option>

                        {this.state.commonMastersList
                          .filter(
                            (x) =>
                              x.type === "ProjectSubType" &&
                              x.mainGuId ===
                                this.state.selectedTargetEcosystemType
                          )
                          .map((item) => (
                            <option value={item.subGuId}>
                              {item.description}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div ref={this.siteRef} id="preId">
                    <div className="hero-title7">
                      <b className="project-summary7">
                        Pre Disturbance Details
                      </b>
                    </div>
                    <div className="hero-title7">
                      <b className="project-summary7">Reference</b>
                    </div>
                    <div className="input-field-text10 pb-4">
                      <div className="content-title-text65">
                        <div className="hero-title8">
                          <div className="project-summary8">
                            Year of Reference
                          </div>
                        </div>

                        <input
                          type="date"
                          name="Year_of_Reference"
                          value={this.state.Year_of_Reference}
                          onChange={(event) => this.handleDate(event)}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                      <div className="content-title-text69">
                        <div className="hero-title8">
                          <div className="project-summary8">Sources</div>
                        </div>
                        <input
                          className="text-input-field-37"
                          required
                          pattern="[a-zA-Z0-9]+"
                          type="text"
                          name="Sources"
                          placeholder="Enter Sources"
                          value={this.state.Sources}
                          onChange={(event) => this.handleAlphaInput(event)}
                        />
                      </div>
                      <div className="content-title-text69">
                        <div className="hero-title8">
                          <div className="project-summary8">Remarks</div>
                        </div>
                        <input
                          className="text-input-field-37"
                          required
                          pattern="[a-zA-Z0-9]+"
                          type="text"
                          name="Remarks"
                          placeholder="Enter Remarks"
                          value={this.state.Remarks}
                          onChange={(event) => this.handleAlphaInput(event)}
                        />
                      </div>
                    </div>
                    <div className="text-container pt-4">
                      <div className="flora-details">Flora Details</div>
                    </div>
                    <table className="newTable">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Spicies</th>
                          <th>No. of Population</th>
                          <th>Invasive?</th>
                          <th>Origin</th>
                          <th>Recommended for Restoration</th>
                          <th>Produce/Year</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.floraType}
                                onChange={this.handleType}
                                name="floratype"
                              >
                                <option value="">Select </option>
                                {this.state.commonMastersList
                                  .filter((x) => x.type === "Flora")
                                  .map((item) => (
                                    <option value={item.mainGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.floraType}
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.floraSpecies}
                                onChange={this.handleSpicies}
                                name="ddFloraSpicies"
                              >
                                <option value="">Select </option>

                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.type === "FloraSubType" &&
                                      x.mainGuId === this.state.floraType
                                  )
                                  .map((item) => (
                                    <option value={item.subGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.floraSpecies}
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[0-9]"
                                  type="text"
                                  maxLength={50}
                                  placeholder="Enter No. of Population"
                                  name="floraNumber_Population"
                                  value={this.state.floraNumber_Population}
                                  onChange={(event) => this.handleInput(event)}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.floraInvasive_Specie}
                                onChange={this.handleInvasiveSpecie}
                              >
                                <option value="">Select </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.floraOrigin}
                                onChange={this.handleOrigin}
                              >
                                <option value="">Select </option>
                                <option value="Native">Native</option>
                                <option value="Foreign">Foreign</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.floraSpec_Recom_Restoration}
                                onChange={this.handleSpecRecomRestoration}
                              >
                                <option value="">Select </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[0-9]"
                                  type="text"
                                  maxLength={50}
                                  placeholder="Enter Produce per Year"
                                  name="floraProd_per_Year"
                                  value={this.state.floraProd_per_Year}
                                  onChange={(event) => this.handleInput(event)}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="dropdown-113">
                              <button onClick={this.handleFlora}> Add</button>
                            </div>
                          </td>
                        </tr>
                        {this.state.ListObjFlora &&
                          this.state.ListObjFlora.map((eachItem, i) => (
                            <tr key={i}>
                              <td>
                                {" "}
                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.mainGuId === eachItem.floraType &&
                                      x.type === "Flora"
                                  )
                                  .map((item) => item.description)}
                              </td>

                              <td>
                                {" "}
                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.subGuId === eachItem.floraSpecies &&
                                      x.type === "FloraSubType"
                                  )
                                  .map((item) => item.description)}
                              </td>

                              <td>
                                {" "}
                                <div>
                                  {eachItem.floraNumber_Population}{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  {" "}
                                  {eachItem.floraInvasive_Specie}{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>{eachItem.floraOrigin} </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  {" "}
                                  {eachItem.floraSpec_Recom_Restoration}{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div> {eachItem.floraProd_per_Year} </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  <a>
                                    <i
                                      className="fas fa-edit"
                                      onClick={() => this.editFlora(i)}
                                    ></i>
                                  </a>
                                  <a>
                                    <i
                                      className="fas fa-trash"
                                      onClick={() => this.deleteFlora(i)}
                                    ></i>
                                  </a>
                                </div>{" "}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="text-container pt-4">
                      <div className="flora-details"> Fauna Details</div>
                    </div>
                    <table className="newTable">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Spicies</th>
                          <th>No. of Population</th>
                          <th>Invasive?</th>
                          <th>Origin</th>
                          <th>Recommended for Restoration</th>
                          <th>Produce/Year</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.faunaType}
                                onChange={this.handleTypeFauna}
                                name="faunatype"
                              >
                                <option value="">Select </option>
                                {this.state.commonMastersList
                                  .filter((x) => x.type === "Fauna")
                                  .map((item) => (
                                    <option value={item.mainGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.faunaType}
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.faunaSpecies}
                                onChange={this.handleSpiciesFauna}
                                name="ddFaunaSpicies"
                              >
                                <option value="">Select</option>

                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.type === "FaunaSubType" &&
                                      x.mainGuId === this.state.faunaType
                                  )
                                  .map((item) => (
                                    <option value={item.subGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[0-9]"
                                  type="text"
                                  maxLength={50}
                                  name="faunaNumber_Population"
                                  placeholder="Enter FaunaNumber Population"
                                  value={this.state.faunaNumber_Population}
                                  onChange={(event) => this.handleInput(event)}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.faunaInvasive_Specie}
                                onChange={this.handleInvasiveSpecieFauna}
                              >
                                <option value="">Select </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.faunaOrigin}
                                onChange={this.handleOriginFauna}
                              >
                                <option value="">Select </option>
                                <option value="Native">Native</option>
                                <option value="Foreign">Foreign</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.faunaSpec_Recom_Restoration}
                                onChange={this.handleSpecRecomRestorationFauna}
                              >
                                <option value="">Select </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[0-9]"
                                  type="text"
                                  maxLength={50}
                                  placeholder="Enter Produce per Year"
                                  name="faunaProd_per_Year"
                                  value={this.state.faunaProd_per_Year}
                                  onChange={(event) => this.handleInput(event)}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="dropdown-113">
                              <button onClick={this.handleFauna}> Add</button>
                            </div>
                          </td>
                        </tr>
                        {this.state.ListObjFauna.map((eachItem, i) => (
                          <tr key={i}>
                            <td>
                              {" "}
                              {this.state.commonMastersList
                                .filter(
                                  (x) =>
                                    x.mainGuId === eachItem.faunaType &&
                                    x.type === "Fauna"
                                )
                                .map((item) => item.description)}
                            </td>

                            <td>
                              {" "}
                              {this.state.commonMastersList
                                .filter(
                                  (x) =>
                                    x.subGuId === eachItem.faunaSpecies &&
                                    x.type === "FaunaSubType"
                                )
                                .map((item) => item.description)}
                            </td>

                            <td>
                              {" "}
                              <div>{eachItem.faunaNumber_Population} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div> {eachItem.faunaInvasive_Specie} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>{eachItem.faunaOrigin} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>
                                {" "}
                                {eachItem.faunaSpec_Recom_Restoration}{" "}
                              </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div> {eachItem.faunaProd_per_Year} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>
                                <a>
                                  <i
                                    className="fas fa-edit"
                                    onClick={() => this.editFauna(i)}
                                  ></i>
                                </a>
                                <a>
                                  <i
                                    className="fas fa-trash"
                                    onClick={() => this.deleteFauna(i)}
                                  ></i>
                                </a>
                              </div>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="text-container pt-4">
                      <div className="flora-details">Microbes Details</div>
                    </div>
                    <table className="newTable">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Spicies</th>
                          <th>No. of Population</th>
                          <th>Invasive?</th>
                          <th>Origin</th>
                          <th>Recommended for Restoration</th>
                          <th>Produce/Year</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.microbesType}
                                onChange={this.handleTypeMicrobes}
                                name="ddmicrobestype"
                              >
                                <option value="">Select </option>
                                {this.state.commonMastersList
                                  .filter((x) => x.type === "Microbes")
                                  .map((item) => (
                                    <option value={item.mainGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.microbesType}
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.microbesSpecies}
                                onChange={this.handleSpiciesMicrobes}
                                name="ddMicrobesSpecies"
                              >
                                <option value="">Select </option>

                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.type === "MicrobesSubType" &&
                                      x.mainGuId === this.state.microbesType
                                  )
                                  .map((item) => (
                                    <option value={item.subGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[0-9]"
                                  type="text"
                                  maxLength={50}
                                  name="microbesNumber_Population"
                                  placeholder="Enter MicrobesNumber Population"
                                  value={this.state.microbesNumber_Population}
                                  onChange={(event) => this.handleInput(event)}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.microbesInvasive_Specie}
                                onChange={this.handleInvasiveSpecieMicrobes}
                              >
                                <option value="">Select </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.microbesOrigin}
                                onChange={this.handleOriginMicrobes}
                              >
                                <option value="">Select </option>
                                <option value="Native">Native</option>
                                <option value="Foreign">Foreign</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={
                                  this.state.microbesSpec_Recom_Restoration
                                }
                                onChange={
                                  this.handleSpecRecomRestorationMicrobes
                                }
                              >
                                <option value="">Select </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[0-9]"
                                  type="text"
                                  maxLength={50}
                                  placeholder="Enter Produce per Year"
                                  name="microbesProd_per_Year"
                                  value={this.state.microbesProd_per_Year}
                                  onChange={(event) => this.handleInput(event)}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="dropdown-113">
                              <button onClick={this.handleMicrobes}>
                                {" "}
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                        {this.state.ListObjMicrobes.map((eachItem, i) => (
                          <tr key={i}>
                            <td>
                              {" "}
                              {this.state.commonMastersList
                                .filter(
                                  (x) =>
                                    x.mainGuId === eachItem.microbesType &&
                                    x.type === "Microbes"
                                )
                                .map((item) => item.description)}
                            </td>

                            <td>
                              {" "}
                              {this.state.commonMastersList
                                .filter(
                                  (x) =>
                                    x.subGuId === eachItem.microbesSpecies &&
                                    x.type === "MicrobesSubType"
                                )
                                .map((item) => item.description)}
                            </td>

                            <td>
                              {" "}
                              <div>
                                {eachItem.microbesNumber_Population}{" "}
                              </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>
                                {" "}
                                {eachItem.microbesInvasive_Specie}{" "}
                              </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>{eachItem.microbesOrigin} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>
                                {" "}
                                {eachItem.microbesSpec_Recom_Restoration}{" "}
                              </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div> {eachItem.microbesProd_per_Year} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>
                                <a>
                                  <i
                                    className="fas fa-edit"
                                    onClick={() => this.editMicrobes(i)}
                                  ></i>
                                </a>
                                <a>
                                  <i
                                    className="fas fa-trash"
                                    onClick={() => this.deleteMicrobes(i)}
                                  ></i>
                                </a>
                              </div>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="land-parcels-container columnlapacont pt-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">Climate</b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Climate Type
                                  </div>
                                </div>
                                <select
                                  className="dropdown-113"
                                  value={this.state.ClimateType}
                                  onChange={this.handleClimateType}
                                >
                                  <option value="text">Select</option>
                                  <option value="Equable">Equable</option>
                                  <option value="Extreme">Extreme</option>
                                  <option value="Neither Equable Nor Extreme">
                                    Neither Equable Nor Extreme
                                  </option>
                                </select>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Air Quality Index
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[0-9]+"
                                  type="number"
                                  min="0"
                                  max="1000"
                                  name="Air_Quality_Index"
                                  value={this.state.Air_Quality_Index}
                                  // onChange={(event) =>this.handleAlphaInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 1000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        1000
                                      );
                                    }
                                    if (value.length > 4) {
                                      value = value.slice(0, 4);
                                    }
                                    this.setState({
                                      Air_Quality_Index: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Avg. Wind Speed (km/hr)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="1000"
                                  name="Avg_Wind_Speed"
                                  value={this.state.Avg_Wind_Speed}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 1000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        1000
                                      );
                                    }
                                    if (value.length > 4) {
                                      value = value.slice(0, 4);
                                    }
                                    this.setState({
                                      Avg_Wind_Speed: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Avg. Temp. Range in Summer
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="Avg_Temp_Summer"
                                  value={this.state.Avg_Temp_Summer}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2).toString();
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 100)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        100
                                      );
                                      value = parseFloat(value).toFixed(2);
                                    }
                                    this.setState({ Avg_Temp_Summer: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Humidity
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="Humidity"
                                  value={this.state.Humidity}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 100);
                                    }
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    this.setState({ Humidity: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Avg. No of Hrs. of Sunlight Availability in
                                    Summer
                                  </div>
                                </div>

                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="12"
                                  name="Avg_No_Hours_Sunlight_Avail_Summer"
                                  placeholder="Enter  Avg. No of Hrs. of Sunlight Availability in Summer"
                                  value={
                                    this.state
                                      .Avg_No_Hours_Sunlight_Avail_Summer
                                  }
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 12)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 12);
                                    }
                                    if (value.length > 2) {
                                      value = value.slice(0, 2);
                                    }
                                    this.setState({
                                      Avg_No_Hours_Sunlight_Avail_Summer: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                  // onChange={(event) => this.handleInput(event)}
                                />
                              </div>
                            </div>
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Avg. Temp. Range in Winter
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="Avg_Temp_Winter"
                                  value={this.state.Avg_Temp_Winter}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2).toString();
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 100)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        100
                                      );
                                      value = parseFloat(value).toFixed(2);
                                    }
                                    this.setState({ Avg_Temp_Winter: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                  // onChange={(event) =this.handleAlphaInput(event) }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Avg. Yearly Rainfall (mm)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100000"
                                  name="Avg_Yearly_Rainfall"
                                  value={this.state.Avg_Yearly_Rainfall}
                                  // onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        100000
                                      );
                                    }
                                    if (value.length > 6) {
                                      value = value.slice(0, 6);
                                    }
                                    this.setState({
                                      Avg_Yearly_Rainfall: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Avg. No of Hrs. of Sunlight Availability in
                                    Winter
                                  </div>
                                </div>
                                <div className="content-title-text69">
                                  <input
                                    className="text-input-field-37"
                                    type="number"
                                    min="0"
                                    name="Avg_No_Hours_Sunlight_Avail_Winter"
                                    placeholder="Enter Avg. No of Hrs. of Sunlight Availability in Winter"
                                    value={
                                      this.state
                                        .Avg_No_Hours_Sunlight_Avail_Winter
                                    }
                                    //onChange={(event) => this.handleInput(event)}
                                    onInput={(e) => {
                                      let value = e.target.value;
                                      if (
                                        value !== "" &&
                                        (value < 0 || value > 12)
                                      ) {
                                        value = Math.min(
                                          Math.max(value, 0),
                                          12
                                        );
                                      }
                                      if (value.length > 2) {
                                        value = value.slice(0, 2);
                                      }
                                      this.setState({
                                        Avg_No_Hours_Sunlight_Avail_Winter:
                                          value,
                                      });
                                    }}
                                    onKeyDown={(e) => {
                                      ["e", "E", "+", "-"].includes(e.key) &&
                                        e.preventDefault();
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="land-parcels-container columnlapacont pt-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">Water</b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Turbidity
                                  </div>
                                </div>
                                <select
                                  className="dropdown-113"
                                  value={this.state.Turbidity}
                                  onChange={this.handleTurbidity}
                                >
                                  <option value="text">Select</option>
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Low">Low</option>
                                </select>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Temperature (Degree Celsius)
                                  </div>
                                </div>

                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="Temperature"
                                  value={this.state.Temperature}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2);
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 100)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        100
                                      );
                                      value = parseFloat(value).toFixed(2);
                                    }
                                    this.setState({ Temperature: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">Color</div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="Color"
                                  value={this.state.Color}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Chemical Oxygen Demand (COD) (in mgL)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="1000"
                                  name="Chem_Oxygen_Demand"
                                  value={this.state.Chem_Oxygen_Demand}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 1000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        1000
                                      );
                                    }
                                    if (value.length > 4) {
                                      value = value.slice(0, 4);
                                    }
                                    this.setState({
                                      Chem_Oxygen_Demand: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Taste and Odor
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="Taste_Odor"
                                  value={this.state.Taste_Odor}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>

                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Electrical Conductivity
                                  </div>
                                </div>
                                <select
                                  className="dropdown-117"
                                  value={this.state.WElectrical_Conductivity}
                                  onChange={this.handleWElectricalConductivity}
                                >
                                  <option value="text">Select</option>
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Low">Low</option>
                                </select>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">pH</div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="14"
                                  name="PH"
                                  value={this.state.PH}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2);
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 14)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        14
                                      );
                                      value = parseFloat(value).toFixed(2);
                                    }
                                    this.setState({ PH: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                            <div className="column-112">
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Hardness
                                  </div>
                                </div>
                                <select
                                  className="dropdown-117"
                                  value={this.state.Hardness}
                                  onChange={this.handleHardness}
                                >
                                  <option value="text">Select</option>
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Low">Low</option>
                                </select>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Dissolved Oxygen (DO) (in mgL)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="Dissolved_Oxygen"
                                  value={this.state.Dissolved_Oxygen}
                                  //  onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2);
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 100)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        100
                                      );
                                      value = parseFloat(value).toFixed(2);
                                    }
                                    this.setState({ Dissolved_Oxygen: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Biochemical Oxygen Demand (BOD) (in mgL)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="1000"
                                  name="Biochem_Oxygen_Demand"
                                  value={this.state.Biochem_Oxygen_Demand}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 1000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        1000
                                      );
                                    }
                                    if (value.length > 4) {
                                      value = value.slice(0, 4);
                                    }
                                    this.setState({
                                      Biochem_Oxygen_Demand: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="land-parcels-container columnlapacont pt-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">
                              Water Toxic Inorganic Substances
                            </b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Substance Name
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="ToxicInorganicSName"
                                  value={this.state.ToxicInorganicSName}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Description
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="ToxicInorganicDescription"
                                  value={this.state.ToxicInorganicDescription}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="land-parcels-container columnlapacont pt-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">
                              Water Toxic Organic Substances
                            </b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Substance Name
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="ToxicOrganicSName"
                                  value={this.state.ToxicOrganicSName}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Description
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="ToxicOrganicDescription"
                                  value={this.state.ToxicOrganicDescription}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="land-parcels-container columnlapacont pt-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">
                              Water Radioactive Substances
                            </b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Substance Name
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="RadioactiveSuName"
                                  value={this.state.RadioactiveSuName}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Description
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="Description"
                                  value={this.state.Description}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="land-parcels-container columnlapacont pt-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">Soil</b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Soil Textures
                                  </div>
                                </div>
                                <select
                                  className="dropdown-113"
                                  value={this.state.Soil_Textures}
                                  onChange={this.handleSoilTextures}
                                >
                                  <option value="text">Select</option>
                                  <option value="Sand">Sand</option>
                                  <option value="Silt">Silt</option>
                                  <option value="Clay">Clay</option>
                                </select>
                              </div>
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Soil Structure
                                  </div>
                                </div>
                                <select
                                  className="dropdown-113"
                                  value={this.state.Soil_Structure}
                                  onChange={this.handleSoilStructure}
                                >
                                  <option value="text">Select</option>
                                  <option value="friable(crumbly)">
                                    friable (crumbly)
                                  </option>
                                  <option value="coarse and very firm clods">
                                    coarse and very firm clods
                                  </option>
                                  <option value="no structure">
                                    no structure
                                  </option>
                                </select>
                              </div>
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Soil Porosity
                                  </div>
                                </div>
                                <select
                                  className="dropdown-113"
                                  value={this.state.Soil_Porosity}
                                  onChange={this.handleSoilPorosity}
                                >
                                  <option value="text">Select</option>
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Low">Low</option>
                                </select>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Soil pH
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="14"
                                  name="Soil_pH"
                                  value={this.state.Soil_pH}
                                  onInput={(event) => {
                                    let value = event.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2);
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 14)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        14
                                      );
                                      value = parseFloat(value).toFixed(2);
                                    }
                                    this.setState({ Soil_pH: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                  // onChange={(event) =>
                                  //   this.handleUserInput(event)
                                  // }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Nitrate
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="Nitrate"
                                  value={this.state.Nitrate}
                                  // onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 100);
                                    }
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    this.setState({ Nitrate: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Phosphate
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="Phosphate"
                                  value={this.state.Phosphate}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 100);
                                    }
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    this.setState({ Phosphate: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Potassium
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100000"
                                  name="Potassium"
                                  value={this.state.Potassium}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        100000
                                      );
                                    }
                                    if (value.length > 6) {
                                      value = value.slice(0, 6);
                                    }
                                    this.setState({ Potassium: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Electrical Conductivity
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="SElectrical_Conductivity"
                                  value={this.state.SElectrical_Conductivity}
                                  // onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 100);
                                    }
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    this.setState({
                                      SElectrical_Conductivity: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>

                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Soil Color
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="Soil_Color"
                                  value={this.state.Soil_Color}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Soil Moisture (In %age)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="Soil_Moisture_Level"
                                  value={this.state.Soil_Moisture_Level}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2);
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 100)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        100
                                      );
                                      value = parseFloat(value).toFixed(2);
                                    }
                                    this.setState({
                                      Soil_Moisture_Level: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>

                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Organic Carbon
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="Organic_Carbon"
                                  value={this.state.Organic_Carbon}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                                <div className="mandatory has-error">
                                  {this.state.errors.organic_Carbon}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="land-parcels-container columnlapacont pt-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">
                              Carbon Offset Potential
                            </b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Total Carbon Offset Capacity (in Kg Eq. CO2)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  name="Tot_Carbon_Offset_Capacity"
                                  value={this.state.Tot_Carbon_Offset_Capacity}
                                  onChange={(event) => this.handleInput(event)}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="land-parcels-container columnlapacont pt-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">Social</b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    No. of Families
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100000"
                                  name="No_Families"
                                  value={this.state.No_Families}
                                  onInput={(event) => {
                                    let value = event.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        100000
                                      );
                                    }
                                    if (value.length > 6) {
                                      value = value.slice(0, 6);
                                    }
                                    this.setState({ No_Families: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                  // onChange={(event) =>
                                  //   this.handleUserInput(event)
                                  // }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Average Family Size
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100000"
                                  name="Avg_Family_Size"
                                  value={this.state.Avg_Family_Size}
                                  onInput={(event) => {
                                    let value = event.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        100000
                                      );
                                    }
                                    if (value.length > 6) {
                                      value = value.slice(0, 6);
                                    }
                                    this.setState({ Avg_Family_Size: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                  // onChange={(event) =>
                                  //   this.handleUserInput(event)
                                  // }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Gender Ratio (Female/Male)
                                  </div>
                                </div>
                                {/* <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  name="Gender"
                                  value={this.state.Gender}
                                  onChange={(event) =>
                                    this.handleUserInput(event)
                                  }
                                /> */}
                                <select
                                  className="dropdown-113"
                                  name="Gender"
                                  value={this.state.Gender}
                                  onChange={this.handleUserInput}
                                >
                                  <option value="">Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Literacy Rate - Overall
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="Literacy_Rate_Overall"
                                  value={this.state.Literacy_Rate_Overall}
                                  // onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 100);
                                    }
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    this.setState({
                                      Literacy_Rate_Overall: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Literacy Rate - Female
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  name="Literacy_Rate_Female"
                                  value={this.state.Literacy_Rate_Female}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 100);
                                    }
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    this.setState({
                                      Literacy_Rate_Female: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Average Annual Income per Family by Farming
                                    (for the land parcels included in this
                                    project)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  name="Avg_Annual_Inc_Family_Farming"
                                  value={
                                    this.state.Avg_Annual_Inc_Family_Farming
                                  }
                                  onChange={(event) => this.handleInput(event)}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Average Annual Income per Family by Other
                                    Sources
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  name="Avg_Annual_Inc_Family_Other_Sources"
                                  value={
                                    this.state
                                      .Avg_Annual_Inc_Family_Other_Sources
                                  }
                                  onChange={(event) => this.handleInput(event)}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div ref={this.siteRef} id="disId">
                    <div className="hero-title7 pt-4">
                      <b className="project-summary7"> Disturbance Details</b>
                    </div>
                    <div className="hero-title7">
                      <b className="project-summary7"> Disturbances Occurred</b>
                    </div>
                    <table className="newTable">
                      <thead>
                        <tr>
                          <th>Type of Cause</th>
                          <th>Exact Cause</th>
                          <th>Frequency</th>
                          <th>Start Month?</th>
                          <th>End Month</th>

                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.type_Cause}
                                onChange={this.handleTypeofCause}
                                name="type_Cause"
                              >
                                <option value="">Select </option>
                                {this.state.commonMastersList
                                  .filter((x) => x.type === "Type of Cause")
                                  .map((item) => (
                                    <option value={item.mainGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.type_Cause}
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.exact_Cause}
                                onChange={this.handleExactCause}
                                name="exact_Cause"
                              >
                                <option value="">Select </option>

                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.type === "Exact Cause" &&
                                      x.mainGuId === this.state.type_Cause
                                  )
                                  .map((item) => (
                                    <option value={item.subGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.exact_Cause}
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.frequency}
                                onChange={this.handleFrequency}
                                name="frequency"
                              >
                                <option value="">Select </option>
                                {this.state.commonMastersList
                                  .filter((x) => x.type === "Frequency")
                                  .map((item) => (
                                    <option value={item.mainGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </td>

                          <td>
                            <div>
                              {(this.state.frequency ==
                                "d5d9518f-0131-4f8a-86c2-cbc35bc8f524" ||
                                this.state.frequency ==
                                  "74d44c36-3e9a-4ec4-8fd9-2f7a959c23c1") && (
                                <input
                                  type="date"
                                  name="startdate"
                                  value={this.state.start_Month}
                                  onChange={(event) =>
                                    this.handleStartMonth(event)
                                  }
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                              )}
                            </div>
                          </td>
                          <td>
                            <div>
                              {(this.state.frequency ==
                                "d5d9518f-0131-4f8a-86c2-cbc35bc8f524" ||
                                this.state.frequency ==
                                  "74d44c36-3e9a-4ec4-8fd9-2f7a959c23c1") && (
                                <input
                                  type="date"
                                  name="enddate"
                                  value={this.state.end_Month}
                                  onChange={(event) =>
                                    this.handleEndMonth(event)
                                  }
                                  onKeyDown={(e) => e.preventDefault()}
                                />
                              )}
                            </div>
                          </td>

                          <td>
                            <div className="dropdown-113">
                              <button onClick={this.handleDisturbancesOccurred}>
                                {" "}
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                        {this.state.ListDisturbancesOccurred &&
                          this.state.ListDisturbancesOccurred.map(
                            (eachItem, i) => (
                              <tr key={i}>
                                <td>
                                  {" "}
                                  {this.state.commonMastersList
                                    .filter(
                                      (x) =>
                                        x.mainGuId === eachItem.type_Cause &&
                                        x.type === "Type of Cause"
                                    )
                                    .map((item) => item.description)}
                                </td>

                                <td>
                                  {" "}
                                  {this.state.commonMastersList
                                    .filter(
                                      (x) =>
                                        x.subGuId === eachItem.exact_Cause &&
                                        x.type === "Exact Cause"
                                    )
                                    .map((item) => item.description)}
                                </td>

                                <td>
                                  {" "}
                                  {this.state.commonMastersList
                                    .filter(
                                      (x) =>
                                        x.mainGuId === eachItem.frequency &&
                                        x.type === "Frequency"
                                    )
                                    .map((item) => item.description)}
                                </td>
                                <td>
                                  {" "}
                                  <div> {eachItem.start_Month} </div>{" "}
                                </td>
                                <td>
                                  {" "}
                                  <div>{eachItem.end_Month} </div>{" "}
                                </td>

                                <td>
                                  {" "}
                                  <div>
                                    <a>
                                      <i
                                        className="fas fa-edit"
                                        onClick={() =>
                                          this.editDisturbancesOccurred(i)
                                        }
                                      ></i>
                                    </a>
                                    <a>
                                      <i
                                        className="fas fa-trash"
                                        onClick={() =>
                                          this.deleteDisturbancesOccurred(i)
                                        }
                                      ></i>
                                    </a>
                                  </div>{" "}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                    <div className="hero-title7 pt-4">
                      <b className="project-summary7"> Disturbance Impact</b>
                    </div>
                    <table className="newTable">
                      <thead>
                        <tr>
                          <th>
                            Impact Severity (As a Collective Result of all
                            Disturbances Occurred)
                          </th>
                          <th>
                            Area Impacted (Including whole Project Area) (In
                            Hectare)
                          </th>
                          <th>Select Land Parcels Impacted</th>
                          <th>Select Water Bodies Impacted</th>

                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.impact_Severity}
                                onChange={this.handleImpactSeverity}
                                name="impact_Severity"
                              >
                                <option value="">Select </option>
                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.type ===
                                      "Impact Severity (As a Collective Result of all Disturbances Occurred)"
                                  )
                                  .map((item) => (
                                    <option value={item.mainGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.impact_Severity}
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  type="text"
                                  // min="0"
                                  name="area_Impacted"
                                  pattern="[0-9]*"
                                  value={this.state.area_Impacted}
                                  onChange={(event) => this.handleInput(event)}
                                  onKeyPress={(event) =>
                                    this.handleKeyPress(event)
                                  }
                                />
                              </div>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.area_Impacted}
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.land_Parcels_Impacted}
                                onChange={this.handleLandParcelsImpacted}
                                name="land_Parcels_Impacted"
                              >
                                <option value="">Select </option>
                                {this.state.landParcelsList.map((item) => (
                                  <option value={item.landParcelId}>
                                    {item.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.water_Bodies_Impacted}
                                onChange={this.handlewaterBodiesImpacted}
                                name="water_Bodies_Impacted"
                              >
                                <option value="">Select </option>
                                {this.state.waterBodiesList.map((item) => (
                                  <option value={item.waterBodyId}>
                                    {item.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </td>

                          <td>
                            <div className="dropdown-113">
                              <button onClick={this.handleDisturbanceImpact}>
                                {" "}
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                        {this.state.ListDisturbanceImpact &&
                          this.state.ListDisturbanceImpact.map(
                            (eachItem, i) => (
                              <tr key={i}>
                                <td>
                                  {" "}
                                  {this.state.commonMastersList
                                    .filter(
                                      (x) =>
                                        x.mainGuId ===
                                          eachItem.impact_Severity &&
                                        x.type ===
                                          "Impact Severity (As a Collective Result of all Disturbances Occurred)"
                                    )
                                    .map((item) => item.description)}
                                </td>
                                <td>
                                  {" "}
                                  <div>{eachItem.area_Impacted} </div>{" "}
                                </td>
                                <td>
                                  {" "}
                                  {this.state.landParcelsList
                                    .filter(
                                      (x) =>
                                        x.landParcelId ===
                                        eachItem.land_Parcels_Impacted
                                    )
                                    .map((item) => item.title)}
                                </td>
                                <td>
                                  {" "}
                                  {this.state.waterBodiesList
                                    .filter(
                                      (x) =>
                                        x.waterBodyId ===
                                        eachItem.water_Bodies_Impacted
                                    )
                                    .map((item) => item.title)}
                                </td>

                                <td>
                                  {" "}
                                  <div>
                                    <a>
                                      <i
                                        className="fas fa-edit"
                                        onClick={() =>
                                          this.editDisturbanceImpact(i)
                                        }
                                      ></i>
                                    </a>
                                    <a>
                                      <i
                                        className="fas fa-trash"
                                        onClick={() =>
                                          this.deleteDisturbanceImpact(i)
                                        }
                                      ></i>
                                    </a>
                                  </div>{" "}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                    <div className="content-title-text69 pt-4">
                      <div className="hero-title8">
                        <div className="project-summary8">
                          Total Area Impacted (Including all Severity Levels)
                        </div>
                      </div>
                      <input
                        className="text-input-field-37"
                        disabled
                        //pattern="[a-zA-Z0-9]+"
                        type="text"
                        name="tot_Area_Impacted"
                        value={this.state.tot_Area_Impacted}
                        //onChange={(event) => this.handleUserInput(event)}
                      />
                    </div>
                    <div className="content-title-text69 pt-4">
                      <div className="hero-title8">
                        <div className="project-summary8">
                          Scale (Percentage of Total Project Area Impacted)
                        </div>
                      </div>
                      <input
                        disabled
                        className="text-input-field-37"
                        // pattern="[a-zA-Z0-9]+"
                        type="text"
                        name="scale"
                        value={this.state.scale}
                        //onChange={(event) => this.handleUserInput(event)}
                      />
                    </div>
                  </div>
                  <div ref={this.siteRef} id="postId">
                    <div className="hero-title7 pt-4">
                      <b className="project-summary7">
                        Post Disturbance Details
                      </b>
                    </div>
                    <div className="hero-title7">
                      <b className="project-summary7">Reference</b>
                    </div>
                    <div className="input-field-text10">
                      <div className="content-title-text65">
                        <div className="hero-title8">
                          <div className="project-summary8">
                            Year of Reference
                          </div>
                        </div>

                        <input
                          type="date"
                          name="Post_Year_of_Reference"
                          value={this.state.Post_Year_of_Reference}
                          onChange={(event) => this.handlePostDate(event)}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                      <div className="content-title-text69">
                        <div className="hero-title8">
                          <div className="project-summary8">Sources</div>
                        </div>
                        <input
                          className="text-input-field-37"
                          required
                          pattern="[a-zA-Z0-9]+"
                          type="text"
                          name="Post_Sources"
                          placeholder="Enter Sources"
                          value={this.state.Post_Sources}
                          onChange={(event) => this.handleAlphaInput(event)}
                        />
                      </div>
                      <div className="content-title-text69">
                        <div className="hero-title8">
                          <div className="project-summary8">Remarks</div>
                        </div>
                        <input
                          className="text-input-field-37"
                          required
                          pattern="[a-zA-Z0-9]+"
                          type="text"
                          name="Post_Remarks"
                          placeholder="Enter Remarks"
                          value={this.state.Post_Remarks}
                          onChange={(event) => this.handleAlphaInput(event)}
                        />
                      </div>
                    </div>
                    <div className="text-container pt-4">
                      <div className="flora-details">Flora Details</div>
                    </div>
                    <table className="newTable">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Spicies</th>
                          <th>No. of Population</th>
                          <th>Invasive?</th>
                          <th>Origin</th>
                          <th>Recommended for Restoration</th>
                          <th>Produce/Year</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.post_floraType}
                                onChange={this.handlePostFloraType}
                                name="postfloraType"
                              >
                                <option value="">Select </option>
                                {this.state.commonMastersList
                                  .filter((x) => x.type === "Flora")
                                  .map((item) => (
                                    <option value={item.mainGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.post_floraType}
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.post_floraSpecies}
                                onChange={this.handlePostSpicies}
                                name="postfloraSpecies"
                              >
                                <option value="">Select </option>

                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.type === "FloraSubType" &&
                                      x.mainGuId === this.state.post_floraType
                                  )
                                  .map((item) => (
                                    <option value={item.subGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.post_floraSpecies}
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  required
                                  //pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  placeholder="Enter No. of Population"
                                  name="post_floraNumber_Population"
                                  value={this.state.post_floraNumber_Population}
                                  onChange={(event) => this.handleInput(event)}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.post_floraInvasive_Specie}
                                onChange={this.handlePostInvasiveSpecie}
                              >
                                <option value="">Select </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.post_floraOrigin}
                                onChange={this.handlePostOrigin}
                              >
                                <option value="">Select </option>
                                <option value="Native">Native</option>
                                <option value="Foreign">Foreign</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={
                                  this.state.post_floraSpec_Recom_Restoration
                                }
                                onChange={this.handlePostSpecRecomRestoration}
                              >
                                <option value="">Select </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  placeholder="Enter Produce per Year"
                                  name="post_floraProd_per_Year"
                                  value={this.state.post_floraProd_per_Year}
                                  onChange={(event) => this.handleInput(event)}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="dropdown-113">
                              <button onClick={this.handlePostFlora}>
                                {" "}
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                        {this.state.post_ListObjFlora &&
                          this.state.post_ListObjFlora.map((eachItem, i) => (
                            <tr key={i}>
                              <td>
                                {" "}
                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.mainGuId === eachItem.post_floraType &&
                                      x.type === "Flora"
                                  )
                                  .map((item) => item.description)}
                              </td>

                              <td>
                                {" "}
                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.subGuId ===
                                        eachItem.post_floraSpecies &&
                                      x.type === "FloraSubType"
                                  )
                                  .map((item) => item.description)}
                              </td>

                              <td>
                                {" "}
                                <div>
                                  {eachItem.post_floraNumber_Population}{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  {" "}
                                  {eachItem.post_floraInvasive_Specie}{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>{eachItem.post_floraOrigin} </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  {" "}
                                  {
                                    eachItem.post_floraSpec_Recom_Restoration
                                  }{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  {" "}
                                  {eachItem.post_floraProd_per_Year}{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  <a>
                                    <i
                                      className="fas fa-edit"
                                      onClick={() => this.editPostFlora(i)}
                                    ></i>
                                  </a>
                                  <a>
                                    <i
                                      className="fas fa-trash"
                                      onClick={() => this.deletePostFlora(i)}
                                    ></i>
                                  </a>
                                </div>{" "}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="text-container pt-4">
                      <div className="flora-details">Fauna Details</div>
                    </div>
                    <table className="newTable">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Spicies</th>
                          <th>No. of Population</th>
                          <th>Invasive?</th>
                          <th>Origin</th>
                          <th>Recommended for Restoration</th>
                          <th>Produce/Year</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.post_faunaType}
                                onChange={this.handlePostFaunaType}
                                name="postfaunaType"
                              >
                                <option value="">Select </option>
                                {this.state.commonMastersList
                                  .filter((x) => x.type === "Flora")
                                  .map((item) => (
                                    <option value={item.mainGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.post_faunaType}
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.post_faunaSpecies}
                                onChange={this.handlePostFaunaSpicies}
                                name="postfaunaSpecies"
                              >
                                <option value="">Select </option>

                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.type === "FloraSubType" &&
                                      x.mainGuId === this.state.post_faunaType
                                  )
                                  .map((item) => (
                                    <option value={item.subGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.post_faunaSpecies}
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  required
                                  // pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  placeholder="Enter No. of Population"
                                  name="post_faunaNumber_Population"
                                  value={this.state.post_faunaNumber_Population}
                                  onChange={(event) => this.handleInput(event)}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.post_faunaInvasive_Specie}
                                onChange={this.handlePostFaunaInvasiveSpecie}
                              >
                                <option value="">Select </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.post_faunaOrigin}
                                onChange={this.handlePosFaunatOrigin}
                              >
                                <option value="">Select </option>
                                <option value="Native">Native</option>
                                <option value="Foreign">Foreign</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={
                                  this.state.post_faunaSpec_Recom_Restoration
                                }
                                onChange={
                                  this.handlePostFaunaSpecRecomRestoration
                                }
                              >
                                <option value="">Select </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  placeholder="Enter Produce per Year"
                                  name="post_faunaProd_per_Year"
                                  value={this.state.post_faunaProd_per_Year}
                                  onChange={(event) => this.handleInput(event)}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="dropdown-113">
                              <button onClick={this.handlePostFauna}>
                                {" "}
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                        {this.state.post_ListObjFauna &&
                          this.state.post_ListObjFauna.map((eachItem, i) => (
                            <tr key={i}>
                              <td>
                                {" "}
                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.mainGuId === eachItem.post_faunaType &&
                                      x.type === "Flora"
                                  )
                                  .map((item) => item.description)}
                              </td>

                              <td>
                                {" "}
                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.subGuId ===
                                        eachItem.post_faunaSpecies &&
                                      x.type === "FloraSubType"
                                  )
                                  .map((item) => item.description)}
                              </td>

                              <td>
                                {" "}
                                <div>
                                  {eachItem.post_faunaNumber_Population}{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  {" "}
                                  {eachItem.post_faunaInvasive_Specie}{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>{eachItem.post_faunaOrigin} </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  {" "}
                                  {
                                    eachItem.post_faunaSpec_Recom_Restoration
                                  }{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  {" "}
                                  {eachItem.post_faunaProd_per_Year}{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  <a>
                                    <i
                                      className="fas fa-edit"
                                      onClick={() => this.editPostFauna(i)}
                                    ></i>
                                  </a>
                                  <a>
                                    <i
                                      className="fas fa-trash"
                                      onClick={() => this.deletePostFauna(i)}
                                    ></i>
                                  </a>
                                </div>{" "}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="text-container pt-4">
                      <div className="flora-details">Microbes Details</div>
                    </div>
                    <table className="newTable">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Spicies</th>
                          <th>No. of Population</th>
                          <th>Invasive?</th>
                          <th>Origin</th>
                          <th>Recommended for Restoration</th>
                          <th>Produce/Year</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.post_microbesType}
                                onChange={this.handlePostmicrobesType}
                                name="postmicrobesType"
                              >
                                <option value="">Select </option>
                                {this.state.commonMastersList
                                  .filter((x) => x.type === "Flora")
                                  .map((item) => (
                                    <option value={item.mainGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.post_microbesType}
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.post_microbesSpecies}
                                onChange={this.handlePostmicrobesSpicies}
                                name="postmicrobesSpecies"
                              >
                                <option value="">Select </option>

                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.type === "FloraSubType" &&
                                      x.mainGuId ===
                                        this.state.post_microbesType
                                  )
                                  .map((item) => (
                                    <option value={item.subGuId}>
                                      {item.description}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="mandatory has-error">
                              {this.state.errors.post_microbesSpecies}
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  required
                                  // pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  placeholder="Enter MicrobesNumber Population"
                                  name="post_microbesNumber_Population"
                                  value={
                                    this.state.post_microbesNumber_Population
                                  }
                                  onChange={(event) => this.handleInput(event)}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.post_microbesInvasive_Specie}
                                onChange={this.handlePostmicrobesInvasiveSpecie}
                              >
                                <option value="">Select </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={this.state.post_microbesOrigin}
                                onChange={this.handlePosmicrobestOrigin}
                              >
                                <option value="">Select </option>
                                <option value="Native">Native</option>
                                <option value="Foreign">Foreign</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <select
                                className="dropdown-113"
                                variant="flushed"
                                placeholder="Select"
                                backgroundColor="#e7f0ff"
                                borderColor="#d9d9d9"
                                focusBorderColor="#d9d9d9"
                                value={
                                  this.state.post_microbesSpec_Recom_Restoration
                                }
                                onChange={
                                  this.handlePostmicrobesSpecRecomRestoration
                                }
                              >
                                <option value="">Select </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  placeholder="Enter MicrobesProd per Year"
                                  name="post_microbesProd_per_Year"
                                  value={this.state.post_microbesProd_per_Year}
                                  onChange={(event) => this.handleInput(event)}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="dropdown-113">
                              <button onClick={this.handlePostmicrobes}>
                                {" "}
                                Add
                              </button>
                            </div>
                          </td>
                        </tr>
                        {this.state.post_ListObjmicrobes &&
                          this.state.post_ListObjmicrobes.map((eachItem, i) => (
                            <tr key={i}>
                              <td>
                                {" "}
                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.mainGuId ===
                                        eachItem.post_microbesType &&
                                      x.type === "Flora"
                                  )
                                  .map((item) => item.description)}
                              </td>

                              <td>
                                {" "}
                                {this.state.commonMastersList
                                  .filter(
                                    (x) =>
                                      x.subGuId ===
                                        eachItem.post_microbesSpecies &&
                                      x.type === "FloraSubType"
                                  )
                                  .map((item) => item.description)}
                              </td>

                              <td>
                                {" "}
                                <div>
                                  {eachItem.post_microbesNumber_Population}{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  {" "}
                                  {eachItem.post_microbesInvasive_Specie}{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>{eachItem.post_microbesOrigin} </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  {" "}
                                  {
                                    eachItem.post_microbesSpec_Recom_Restoration
                                  }{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  {" "}
                                  {eachItem.post_microbesProd_per_Year}{" "}
                                </div>{" "}
                              </td>
                              <td>
                                {" "}
                                <div>
                                  <a>
                                    <i
                                      className="fas fa-edit"
                                      onClick={() => this.editPostmicrobes(i)}
                                    ></i>
                                  </a>
                                  <a>
                                    <i
                                      className="fas fa-trash"
                                      onClick={() => this.deletePostmicrobes(i)}
                                    ></i>
                                  </a>
                                </div>{" "}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="land-parcels-container columnlapacont pt-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">Climate</b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Climate Type
                                  </div>
                                </div>
                                <select
                                  className="dropdown-113"
                                  value={this.state.postClimateType}
                                  onChange={this.handlePostClimateType}
                                >
                                  <option value="text">Select</option>
                                  <option value="Equable">Equable</option>
                                  <option value="Extreme">Extreme</option>
                                  <option value="Neither Equable Nor Extreme">
                                    Neither Equable Nor Extreme
                                  </option>
                                </select>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Air Quality Index
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  //pattern="[a-zA-Z0-9]+"
                                  type="number"
                                  min="0"
                                  max="1000"
                                  name="postAir_Quality_Index"
                                  value={this.state.postAir_Quality_Index}
                                  // onChange={(event) =>
                                  //   this.handleAlphaInput(event)
                                  // }
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 1000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        1000
                                      );
                                    }
                                    if (value.length > 4) {
                                      value = value.slice(0, 4);
                                    }
                                    this.setState({
                                      postAir_Quality_Index: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Avg. Wind Speed (km/hr)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="1000"
                                  name="postAvg_Wind_Speed"
                                  value={this.state.postAvg_Wind_Speed}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 1000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        1000
                                      );
                                    }
                                    if (value.length > 4) {
                                      value = value.slice(0, 4);
                                    }
                                    this.setState({
                                      postAvg_Wind_Speed: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Avg. Temp. Range in Summer
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  // pattern="[a-zA-Z0-9]+"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="postAvg_Temp_Summer"
                                  value={this.state.postAvg_Temp_Summer}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2);
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 100)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        100
                                      ).toString();
                                    }
                                    this.setState({
                                      postAvg_Temp_Summer: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Humidity
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="postHumidity"
                                  value={this.state.postHumidity}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 100);
                                    }
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    this.setState({
                                      postHumidity: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Avg. No of Hrs. of Sunlight Availability in
                                    Summer
                                  </div>
                                </div>

                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="12"
                                  name="postAvg_No_Hours_Sunlight_Avail_Summer"
                                  value={
                                    this.state
                                      .postAvg_No_Hours_Sunlight_Avail_Summer
                                  }
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 12)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 12);
                                    }
                                    if (value.length > 2) {
                                      value = value.slice(0, 2);
                                    }
                                    this.setState({
                                      postAvg_No_Hours_Sunlight_Avail_Summer:
                                        value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                  //onChange={(event) => this.handleInput(event)}
                                />
                              </div>
                            </div>
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Avg. Temp. Range in Winter
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  //pattern="[a-zA-Z0-9]+"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="postAvg_Temp_Winter"
                                  value={this.state.postAvg_Temp_Winter}
                                  //onChange={(event) => this.handleAlphaInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2);
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 100)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        100
                                      ).toString();
                                    }
                                    this.setState({
                                      postAvg_Temp_Winter: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Avg. Yearly Rainfall (mm)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100000"
                                  name="postAvg_Yearly_Rainfall"
                                  value={this.state.postAvg_Yearly_Rainfall}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        100000
                                      );
                                    }
                                    if (value.length > 6) {
                                      value = value.slice(0, 6);
                                    }
                                    this.setState({
                                      postAvg_Yearly_Rainfall: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Avg. No of Hrs. of Sunlight Availability in
                                    Winter
                                  </div>
                                </div>
                                <div className="content-title-text69">
                                  <input
                                    className="text-input-field-37"
                                    type="number"
                                    name="postAvg_No_Hours_Sunlight_Avail_Winter"
                                    value={
                                      this.state
                                        .postAvg_No_Hours_Sunlight_Avail_Winter
                                    }
                                    //onChange={(event) => this.handleInput(event)}
                                    onInput={(e) => {
                                      let value = e.target.value;
                                      if (
                                        value !== "" &&
                                        (value < 0 || value > 12)
                                      ) {
                                        value = Math.min(
                                          Math.max(value, 0),
                                          12
                                        );
                                      }
                                      if (value.length > 2) {
                                        value = value.slice(0, 2);
                                      }
                                      this.setState({
                                        postAvg_No_Hours_Sunlight_Avail_Winter:
                                          value,
                                      });
                                    }}
                                    onKeyDown={(e) => {
                                      ["e", "E", "+", "-"].includes(e.key) &&
                                        e.preventDefault();
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="land-parcels-container columnlapacont pt-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">
                              Carbon Offset Potential
                            </b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Total Carbon Offset Capacity (in Kg Eq. CO2)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  name="postTot_Carbon_Offset_Capacity"
                                  value={
                                    this.state.postTot_Carbon_Offset_Capacity
                                  }
                                  onChange={(event) => this.handleInput(event)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="land-parcels-container columnlapacont pt-4 pb-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">Water</b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Grid Id
                                  </div>
                                </div>
                                <select
                                  className="dropdown-113"
                                  name="post_Grid_No"
                                  value={this.state.post_Grid_No}
                                  onChange={this.handlePostSoilGridNo}
                                >
                                  <option value="">Select</option>
                                  {this.state.gridNoList.map(function (item) {
                                    return (
                                      <option value={item.gridGuid}>
                                        {item.gridNo}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Turbidity
                                  </div>
                                </div>
                                <select
                                  className="dropdown-113"
                                  value={this.state.post_Turbidity}
                                  onChange={this.handlePostTurbidity}
                                >
                                  <option value="text">Select</option>
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Low">Low</option>
                                </select>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Temperature (Degree Celsius)
                                  </div>
                                </div>

                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  name="post_Temperature"
                                  value={this.state.post_Temperature}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    // Allow only numbers and a single decimal point
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }

                                    // Limit to two decimal places
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2);
                                      }
                                    }

                                    // Ensure the value is within the range of 0 to 100
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 100)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        100
                                      );
                                      value = parseFloat(value).toFixed(2);
                                    }
                                    this.setState({ post_Temperature: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">Color</div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="post_Color"
                                  value={this.state.post_Color}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                            </div>
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Taste and Odor
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="post_Taste_Odor"
                                  value={this.state.post_Taste_Odor}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>

                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Electrical Conductivity
                                  </div>
                                </div>
                                <select
                                  className="dropdown-117"
                                  value={
                                    this.state.post_WElectrical_Conductivity
                                  }
                                  onChange={
                                    this.handlePostWElectricalConductivity
                                  }
                                >
                                  <option value="text">Select</option>
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Low">Low</option>
                                </select>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">pH</div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  name="post_PH"
                                  value={this.state.post_PH}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2);
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 14)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        14
                                      );
                                      value = parseFloat(value).toFixed(2);
                                    }
                                    this.setState({ post_PH: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Chemical Oxygen Demand (COD) (in mgL)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="1000"
                                  name="post_Chem_Oxygen_Demand"
                                  value={this.state.post_Chem_Oxygen_Demand}
                                  // onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 1000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        1000
                                      );
                                    }
                                    if (value.length > 4) {
                                      value = value.slice(0, 4);
                                    }
                                    this.setState({
                                      post_Chem_Oxygen_Demand: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                            <div className="column-112">
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Hardness
                                  </div>
                                </div>
                                <select
                                  className="dropdown-117"
                                  value={this.state.post_Hardness}
                                  onChange={this.handlePostHardness}
                                >
                                  <option value="text">Select</option>
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Low">Low</option>
                                </select>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Dissolved Oxygen (DO) (in mgL)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="post_Dissolved_Oxygen"
                                  value={this.state.post_Dissolved_Oxygen}
                                  // onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2);
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 100)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        100
                                      );
                                      value = parseFloat(value).toFixed(2);
                                    }
                                    this.setState({
                                      post_Dissolved_Oxygen: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Biochemical Oxygen Demand (BOD) (in mgL)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="1000"
                                  name="post_Biochem_Oxygen_Demand"
                                  value={this.state.post_Biochem_Oxygen_Demand}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 1000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        1000
                                      );
                                    }
                                    if (value.length > 4) {
                                      value = value.slice(0, 4);
                                    }
                                    this.setState({
                                      post_Biochem_Oxygen_Demand: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="column-content2 waterinnernestcol">
                            <div className="column-112">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Water: Toxic Inorganic Substance
                                </div>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Toxic Inorganic Substance Name
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="post_Toxic_Inorganic_SubName"
                                  value={
                                    this.state.post_Toxic_Inorganic_SubName
                                  }
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Toxic Inorganic Description
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="post_Toxic_Inorganic_Description"
                                  value={
                                    this.state.post_Toxic_Inorganic_Description
                                  }
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                            </div>
                            <div className="column-112">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Water: Toxic Organic Substance
                                </div>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Toxic Organic Substance Name
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="post_Toxic_Organic_SubName"
                                  value={this.state.post_Toxic_Organic_SubName}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Toxic Organic Description
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="post_Toxic_Organic_Description"
                                  value={
                                    this.state.post_Toxic_Organic_Description
                                  }
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                            </div>
                            <div className="column-112">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Water: Radioactive Substance
                                </div>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Radioactive Substance Name
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="post_Radioactive_SubName"
                                  value={this.state.post_Radioactive_SubName}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Radioactive Description
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="post_Radioactive_Description"
                                  value={
                                    this.state.post_Radioactive_Description
                                  }
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                              <div>
                                <button
                                  className="addbtnstyle"
                                  onClick={this.handlePostWater}
                                >
                                  {" "}
                                  Add{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-container pt-4">
                      <div className="flora-details">Water Details</div>
                    </div>
                    <table className="newTable nofieldstbl">
                      <thead>
                        <tr>
                          <th>Grid No</th>
                          <th>Turbidity</th>
                          <th>Color</th>
                          <th>Electrical Conductivity</th>

                          <th>Hardness</th>
                          <th>pH</th>

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.post_ListObjWater.map((eachItem, i) => (
                          <tr key={i}>
                            <td>
                              {" "}
                              <div>
                                {this.state.gridNoList
                                  .filter(
                                    (x) => x.gridGuid === eachItem.post_Grid_No
                                  )
                                  .map((item) => item.gridNo)}{" "}
                              </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div> {eachItem.post_Turbidity} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>{eachItem.post_Color} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>
                                {" "}
                                {eachItem.post_WElectrical_Conductivity}{" "}
                              </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div> {eachItem.post_Hardness} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div> {eachItem.post_PH} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>
                                <a>
                                  <i
                                    className="fas fa-edit"
                                    onClick={() => this.editPostWater(i)}
                                  ></i>
                                </a>
                                <a>
                                  <i
                                    className="fas fa-trash"
                                    onClick={() => this.deletePostWater(i)}
                                  ></i>
                                </a>
                              </div>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="land-parcels-container columnlapacont pt-4 pb-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">Soil</b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Grid Id
                                  </div>
                                </div>
                                <select
                                  className="dropdown-113"
                                  name="post_Grid_No"
                                  value={this.state.post_Grid_No}
                                  onChange={this.handlePostSoilGridNo}
                                >
                                  <option value="">Select</option>
                                  {this.state.gridNoList.map(function (item) {
                                    return (
                                      <option value={item.gridGuid}>
                                        {item.gridNo}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Soil Textures
                                  </div>
                                </div>
                                <select
                                  className="dropdown-113"
                                  value={this.state.post_Soil_Textures}
                                  onChange={this.handlePostSoilTextures}
                                >
                                  <option value="text">Select</option>
                                  <option value="Sand">Sand</option>
                                  <option value="Silt">Silt</option>
                                  <option value="Clay">Clay</option>
                                </select>
                              </div>
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Soil Structure
                                  </div>
                                </div>
                                <select
                                  className="dropdown-113"
                                  value={this.state.post_Soil_Structure}
                                  onChange={this.handlePostSoilStructure}
                                >
                                  <option value="text">Select</option>
                                  <option value="friable(crumbly)">
                                    friable (crumbly)
                                  </option>
                                  <option value="coarse and very firm clods">
                                    coarse and very firm clods
                                  </option>
                                  <option value="no structure">
                                    no structure
                                  </option>
                                </select>
                              </div>
                              <div className="content-title-text71">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Soil Porosity
                                  </div>
                                </div>
                                <select
                                  className="dropdown-113"
                                  value={this.state.post_Soil_Porosity}
                                  onChange={this.handlePostSoilPorosity}
                                >
                                  <option value="text">Select</option>
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Low">Low</option>
                                </select>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Soil pH
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="14"
                                  name="post_Soil_pH"
                                  value={this.state.post_Soil_pH}
                                  onInput={(event) => {
                                    let value = event.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2);
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 14)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        14
                                      );
                                      value = parseFloat(value).toFixed(2);
                                    }
                                    this.setState({ post_Soil_pH: value });
                                  }}
                                  // onChange={(event) =>
                                  //   this.handleUserInput(event)
                                  // }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Nitrate
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="post_Nitrate"
                                  value={this.state.post_Nitrate}
                                  // onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 100);
                                    }
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    this.setState({ post_Nitrate: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Phosphate
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  name="post_Phosphate"
                                  value={this.state.post_Phosphate}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 100);
                                    }
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    this.setState({ post_Phosphate: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Potassium
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  name="post_Potassium"
                                  value={this.state.post_Potassium}
                                  // onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        100000
                                      );
                                    }
                                    if (value.length > 6) {
                                      value = value.slice(0, 6);
                                    }
                                    this.setState({ post_Potassium: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Electrical Conductivity
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="post_SElectrical_Conductivity"
                                  value={
                                    this.state.post_SElectrical_Conductivity
                                  }
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 100);
                                    }
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    this.setState({
                                      post_SElectrical_Conductivity: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>

                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Soil Color
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  maxLength={50}
                                  name="post_Soil_Color"
                                  value={this.state.post_Soil_Color}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Soil Moisture (In %age)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="post_Soil_Moisture_Level"
                                  value={this.state.post_Soil_Moisture_Level}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (!/^(\d*\.?\d*)$/.test(value)) {
                                      return;
                                    }
                                    if (value.includes(".")) {
                                      const [integerPart, decimalPart] =
                                        value.split(".");
                                      if (decimalPart.length > 2) {
                                        value =
                                          integerPart +
                                          "." +
                                          decimalPart.slice(0, 2);
                                      }
                                    }
                                    if (
                                      value !== "" &&
                                      (parseFloat(value) < 0 ||
                                        parseFloat(value) > 100)
                                    ) {
                                      value = Math.min(
                                        Math.max(parseFloat(value), 0),
                                        100
                                      );
                                      value = parseFloat(value).toFixed(2);
                                    }
                                    this.setState({
                                      post_Soil_Moisture_Level: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>

                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Organic Carbon
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  required
                                  pattern="[a-zA-Z0-9]+"
                                  type="text"
                                  name="post_Organic_Carbon"
                                  value={this.state.post_Organic_Carbon}
                                  onChange={(event) =>
                                    this.handleAlphaInput(event)
                                  }
                                />
                                <div className="mandatory has-error">
                                  {this.state.errors.post_Organic_Carbon}
                                </div>
                              </div>
                              <div>
                                <button
                                  className="addbtnstyle"
                                  onClick={this.handlePostSoil}
                                >
                                  {" "}
                                  Add{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-container pt-4">
                      <div className="flora-details">Soil Details</div>
                    </div>
                    <table className="newTable nofieldstbl">
                      <thead>
                        <tr>
                          <th>Grid No</th>
                          <th>Soil Textures</th>
                          <th>Soil Structure</th>
                          <th>Soil Porosity</th>

                          <th>Electrical Conductivity</th>
                          <th>Soil Color</th>
                          {/* <th >
                                                            Organic Carbon
                                                        </th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.post_ListObjSoil.map((eachItem, i) => (
                          <tr key={i}>
                            <td>
                              {" "}
                              <div>
                                {this.state.gridNoList
                                  .filter(
                                    (x) => x.gridGuid === eachItem.post_Grid_No
                                  )
                                  .map((item) => item.gridNo)}{" "}
                              </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div> {eachItem.post_Soil_Textures} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>{eachItem.post_Soil_Structure} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div> {eachItem.post_Soil_Porosity} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>
                                {" "}
                                {eachItem.post_SElectrical_Conductivity}{" "}
                              </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div> {eachItem.post_Soil_Color} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>
                                <a>
                                  <i
                                    className="fas fa-edit"
                                    onClick={() => this.editPostSoil(i)}
                                  ></i>
                                </a>
                                <a>
                                  <i
                                    className="fas fa-trash"
                                    onClick={() => this.deletePostSoil(i)}
                                  ></i>
                                </a>
                              </div>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="land-parcels-container columnlapacont pt-4">
                      <div className="land-parecel-content-area">
                        <div className="left-content">
                          <div className="hero-title8">
                            <b className="flora-details">Social</b>
                          </div>
                          <div className="column-content2">
                            <div className="column-112">
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    No. of Families
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100000"
                                  name="postNo_Families"
                                  value={this.state.postNo_Families}
                                  onInput={(event) => {
                                    let value = event.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        100000
                                      );
                                    }
                                    if (value.length > 6) {
                                      value = value.slice(0, 6);
                                    }
                                    this.setState({ postNo_Families: value });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                  // onChange={(event) =>
                                  //   this.handleUserInput(event)
                                  // }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Average Family Size
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100000"
                                  name="postAvg_Family_Size"
                                  value={this.state.postAvg_Family_Size}
                                  onInput={(event) => {
                                    let value = event.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100000)
                                    ) {
                                      value = Math.min(
                                        Math.max(value, 0),
                                        100000
                                      );
                                    }
                                    if (value.length > 6) {
                                      value = value.slice(0, 6);
                                    }
                                    this.setState({
                                      postAvg_Family_Size: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                  // onChange={(event) =>
                                  //   this.handleUserInput(event)
                                  // }
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Gender Ratio (Female/Male)
                                  </div>
                                </div>
                                {/* <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  name="postGender"
                                  value={this.state.postGender}
                                  onChange={(event) =>
                                    this.handleUserInput(event)
                                  }
                                /> */}
                                <select
                                  className="dropdown-113"
                                  name="postGender"
                                  value={this.state.postGender}
                                  onChange={this.handleUserInput}
                                >
                                  <option value="">Select</option>
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Literacy Rate - Overall
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="postLiteracy_Rate_Overall"
                                  value={this.state.postLiteracy_Rate_Overall}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 100);
                                    }
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    this.setState({
                                      postLiteracy_Rate_Overall: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Literacy Rate - Female
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  max="100"
                                  name="postLiteracy_Rate_Female"
                                  value={this.state.postLiteracy_Rate_Female}
                                  //onChange={(event) => this.handleInput(event)}
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 100)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 100);
                                    }
                                    if (value.length > 3) {
                                      value = value.slice(0, 3);
                                    }
                                    this.setState({
                                      postLiteracy_Rate_Female: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Average Annual Income per Family by Farming
                                    (for the land parcels included in this
                                    project)
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  name="postAvg_Annual_Inc_Family_Farming"
                                  value={
                                    this.state.postAvg_Annual_Inc_Family_Farming
                                  }
                                  onChange={(event) => this.handleInput(event)}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                              <div className="content-title-text69">
                                <div className="hero-title8">
                                  <div className="project-summary8">
                                    Average Annual Income per Family by Other
                                    Sources
                                  </div>
                                </div>
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  name="postAvg_Annual_Inc_Family_Other_Sources"
                                  value={
                                    this.state
                                      .postAvg_Annual_Inc_Family_Other_Sources
                                  }
                                  onChange={(event) => this.handleInput(event)}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="action-buttons2 pt-4">
                    <button className="cta5">
                      <div className="back2" onClick={this.Back}>
                        Back
                      </div>
                    </button>
                    <button className="cta6" onClick={this.Save}>
                      <div className="save-continue3">{`Save & Continue`}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="right-navbar3">
              <button className="plus-circle3">plus-circle</button>
              <button className="notifications-bell3">notifications-bell</button>
              <button className="notifications-bell3">email</button>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(TreeTiesAddProjectForms1Sit);
