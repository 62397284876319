import React, { Component, useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import { useEffect } from "react";
import { map, split } from "lodash";
import { useSelector } from "react-redux";

export default function LayerMarker({
  markerData,
  center,
  selectedTree,
  imageName,
  speciesData,
}) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [filterDetails, setFilterDetails] = useState([]);
  const [selectedSpeices, setSelectedSpeices] = useState([]);
  const reduxState = useSelector((state) => state.project);

  const getDateInFormat = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split(" ");
    const capitalizedMonth = month.toUpperCase();

    return `${day} ${capitalizedMonth} ${year}`;
  };

  useEffect(() => {}, [selectedTree]);

  useEffect(() => {
    if (reduxState.filterSpeciesData) {
      setSelectedSpeices(reduxState.filterSpeciesData);
      SpeciesDetails(reduxState.filterSpeciesData);
    }
  }, [reduxState.filterSpeciesData]);

  const SpeciesDetails = (selectedLayer) => {
    let filterList = [];
    //let layerDetailsData = [];
    //let LayerSelectedMarkerData = [];
    let unslectedLayer = false;
    // const selectedSpecies = selectedSpeices.filter(
    //   (species) => species.isSelected
    // );
    let isFilterApplied = false;

    if (selectedLayer?.length) {
      selectedLayer?.map((item) => {
        unslectedLayer = true;
        let layerName = markerData.filter((x) =>
          x.source.includes(item.layerName)
        );
        if (layerName.length > 0) {
          item.speciesName.map((species) => {
            if (species.isSelected) {
              isFilterApplied = true;
              const filteredMarkerData = markerData.filter((x) =>
                x.common_name
                  ?.toLowerCase()
                  .includes(species.speciesName?.toLowerCase())
              );
              filterList = filterList.concat(filteredMarkerData);
              // layerDetailsData = filterList.filter(
              //   (x) => x.source === selectedSpeices[0].layerName
              // );
            }
          });
        }
      });
    }
    if (isFilterApplied) {
      setFilterDetails(filterList);
    } else if (reduxState.filterSpeciesData.length === 0) {
      setFilterDetails(markerData);
    } else if (
      isFilterApplied === false &&
      reduxState.filterSpeciesData.length > 0
    ) {
      setFilterDetails(filterList);
    }
  };
  const getSVG = (color) => {
    return `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="6" fill="${color}" stroke="black" />
            </svg>`;
  };
  const renderMarker = (eachMarker) => {
    let icon = { url: null, size: new window.google.maps.Size(24, 24) };
    icon.url = "#778899"; // set default color here
    let colour = null;
    let svgString = null;
    let markerIcon = null;
    speciesData?.map((species) => {
      if (
        species?.speciesName
          ?.toLowerCase()
          .includes(eachMarker?.common_name?.toLowerCase())
      ) {
        colour =
          species?.speciesColourCode !== "null"
            ? species?.speciesColourCode
            : "#50b041";
        svgString = getSVG(colour);
        markerIcon = `data:image/svg+xml,${encodeURIComponent(svgString)}`;
        icon = { url: markerIcon, size: new window.google.maps.Size(24, 24) };
      }
    });
    return icon;
  };

  return (
    <>
      {filterDetails?.length > 0 &&
        filterDetails?.map((eachMarker, index) => {
          let cord = {
            lat: eachMarker?.coordinates?.geometry?.coordinates?.[1],
            lng: eachMarker?.coordinates?.geometry?.coordinates?.[0],
          };
          // let selectedTreeData = eachMarker?.properties;
          //let img = imageName.replace(/['"]/g, "");
          return (
            <div key={index}>
              <Marker
                position={cord}
                icon={renderMarker(eachMarker)}
                onMouseOver={() => setSelectedIndex(index)}
                onMouseOut={() => setSelectedIndex(null)}
              >
                {selectedIndex == index && (
                  <InfoWindow position={cord} className="treesinfowindow_cont">
                    <div>
                      <div className="infwdtree_head">
                        <span>
                          {eachMarker?.common_name
                            ? eachMarker?.common_name
                            : "-"}
                        </span>
                      </div>
                      <div className="treesinfowindowitem_wrap">
                        <div className="treesinfowindow_item">
                          <span>Source </span>
                          <span>
                            : {eachMarker?.source ? eachMarker?.source : "-"}
                          </span>
                        </div>
                        <div className="treesinfowindow_item">
                          <span> Species</span>
                          <span>
                            :{" "}
                            {eachMarker?.common_name
                              ? eachMarker?.common_name
                              : "-"}
                            {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="12"
                          viewBox="0 0 11 12"
                          fill="none"
                        >
                          <path
                            d="M1.1 11.5789L0 10.4211L4.4 5.78947L0 1.15789L1.1 0L5.5 4.63158L9.9 0L11 1.15789L6.6 5.78947L11 10.4211L9.9 11.5789L5.5 6.94737L1.1 11.5789Z"
                            fill="#ADADAD"
                          />
                        </svg> */}
                          </span>
                        </div>
                        <div className="treesinfowindow_item">
                          <span>Height</span>
                          <span>
                            :{" "}
                            {eachMarker.tree_height
                              ? Number(eachMarker?.tree_height).toFixed(1)
                              : "-"}
                          </span>
                        </div>

                        <div className="treesinfowindow_item">
                          <span>Girth</span>
                          <span>
                            :{" "}
                            {eachMarker?.tree_girth &&
                            eachMarker?.tree_girth !== "NaN"
                              ? eachMarker?.tree_girth
                              : "-"}
                          </span>
                        </div>

                        <div className="treesinfowindow_item">
                          <span>Latitude </span>
                          <span>: {cord.lat?.toFixed(4)} </span>
                        </div>
                        <div className="treesinfowindow_item">
                          <span>Longitude</span>
                          <span>: {cord.lng?.toFixed(4)}</span>
                        </div>
                        {/* <div className="treesinfowindow_item">
                          <span>Carbon Sequestered</span>
                          <span>: 5.70 Kg</span>
                        </div> */}
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            </div>
          );
        })}
    </>
  );
}
