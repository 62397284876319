import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container, Row, Col } from "react-bootstrap";
import { Modal, Button, Form } from '@patternfly/react-core';
import config from "../../../utils/config";
import Loader from "../../Common/Loader/Loader";
import { globalHistory } from "@reach/router";
import ToastNotification from "./../../Common/ToastNotification/ToastNotification";
import { v4 as uuidv4 } from "uuid";
import { SketchPicker } from "react-color";
class AddZoneType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: props.IsOpen,
            errors: {},
            ZoneName: "",
            IsLoader: false,
            IsUpdate: props.AddOrUpdate === "Update" ? true : false,
            ZoneGuid: "",
            Applicationlist: props.data,
            files: [],
            userFiles: [],
            MaxImageSizeInMb: 0,
        }
        this.mounted = true;
        this.handleModalToggle = () => {
            this.setState(({ isModalOpen }) => ({
                isModalOpen: !isModalOpen
            }));
        };

    }
    componentDidMount() {
        this.mounted = true;
        if (this.state.IsUpdate === true) {
            this.setState({ ZoneGuid: this.state.Applicationlist.ZoneGuid, ZoneName: this.state.Applicationlist.ZoneName,IsUpdate: true });
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }
    mobileSidebarToggle = (e) => {
        document.documentElement.classList.toggle("nav-open");
        e.stopPropagation();
    };

    MapKeyInArray = (responseData, keyname) => {
        let myArray = responseData.find(obj => obj.key === keyname)
        if (myArray) {
            return myArray.value;
        }
    }

    // This function do validate the Registration Form
    validateForm = (e) => {
        let fields;
        fields = {
            ZoneName: this.state.ZoneName
        }
        let errors = {};
        let formIsValid = true;
        if (!fields["ZoneName"]) {
            formIsValid = false;
            errors["ZoneName"] = "Enter Zone Type Name.";
        }

        if (this.mounted) {
            this.setState({ errors: errors });
        }
        return formIsValid;
    }

    AddZoneType = (e) => {
        const newGuid = uuidv4();
        e.preventDefault();
        if (this.validateForm()) {
          if (this.mounted) {
            let formData = {
              ZoneGuid: this.state.IsUpdate ? this.state.ZoneGuid : newGuid,
              ZoneName: this.state.ZoneName
            };
            let uri = `${config.API_HOST_URL}/api/Ecosystem/AddZoneTypeData`;
            if (this.state.IsUpdate) {
              uri = `${config.API_HOST_URL}/api/Ecosystem/UpdateZoneTypeData`;
            }
            let fetchOptions = {
              method: "POST",
              body: JSON.stringify(formData),
              headers: {
                Authorization: `Bearer ${this.props.token}`,
                Accept: "application/json",
                "Content-Type": "application/json"
              }
            };
            this.setState({
              IsLoader: true
            });
            fetch(uri, fetchOptions)
              .then(response => {
                if (!response.ok) {
                  throw new Error(response.statusText);
                }
                return response.json();
              })
              .then(data => {
                if (data.success) {
                    this.setState({
                        IsLoader: false,
                        IsOpen: false,
                        Applicationlist: [],
                        ZoneName: ""
                    });
                    ToastNotification.ShowSuccess("Data saved successfully..");
                    this.handleModalToggle();
                    setTimeout(() => {
                        this.setState({
                        }, this.props.parentMethod)
                    }, 800);
                } else {
                    let errors = {};
                    errors["message"] = data.message;
                    if (this.mounted) {
                        this.setState({
                            errors: errors,
                            IsLoader: false
                        });
                    }
                }
              })
              .catch(error => {
                console.error("Error in request", error);
                this.setState({
                  IsLoader: false,
                  errorMsg: error.message
                });
              });
          }
        }
      }

    // This function will set the state of input fields and validate them when user enters the value in input boxes
   
   
    handleUserInput = (e) => {
        let errors = { ...this.state.errors };
        
        if (this.mounted) 
         {
            const value = e.target.value.replace(/^ /, "").replace(/[^A-Za-z ]/g, ""); // remove leading spaces and non-alphabet characters, allow spaces
             const newValue = value.replace(/\s{2,}/g, ' '); // replace multiple spaces with a single space
             errors["ZoneName"] = "";
             this.setState({ ZoneName: newValue,errors: errors});
         }

      }


    render() {
        const { isModalOpen } = this.state;
        return (
            <div>
                {this.state.IsLoader ? <Loader /> :
                    <div className="addpro_btn">
                        <Modal title={this.state.IsUpdate===true ? "Edit Zone Type":"Add Zone Type"}

                            isOpen={isModalOpen}
                            onClose={this.handleModalToggle}
                            className="addproject_modal"
                            actions={[
                                <Button key="submit" className="modal_btn submit_btn" onClick={this.AddZoneType}>Submit</Button>,
                                <Button key="cancel" className="modal_btn cancel_btn" onClick={this.handleModalToggle}>Cancel</Button>
                            ]}>
                            <div>
                                <ul className="addproject_form">
                                    <li className="form-group field">
                                         <input type="text" id="ZoneName" placeholder="Enter Tree Zone Name" name="ZoneName" value={this.state.ZoneName} onChange={(event) => this.handleUserInput(event)} /> 
                                         <label htmlFor="ZoneName">
                                         <sup className="mandatory">*</sup>&nbsp; Zone Type Name</label>
                                            <div className="mandatory has-error">{this.state.errors.ZoneName}</div> 
                                    </li>
                                </ul>
                            </div>
                        </Modal>
                    </div>
                }
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData
    };
}

export default connect(
    mapStateToProps
)(AddZoneType);