const initialState = {
    registrationData: false
}
export default function registration(state = initialState, action) {
    switch (action.type) {
        case 'SET_REGISTRATION_DETAILS':
            return { ...state, registrationData: action.data }
        default:
            return state
    }
};