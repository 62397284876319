import React, { Component } from "react";
import { connect } from 'react-redux';
import { navigate } from "@reach/router";
import growlogo from "../../assets/logo.png";
import loginbg from "../../assets/loginbg.jpg";
import config from "../../utils/config";
import { sha512 } from "js-sha512";
import { globalHistory } from "@reach/router"
class Resetpassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            UserId: '',
            oldpassword: '',
            newpassword: '',
            confirmpassword: '',
            passwordValid: false,
            formValid: false,
            fields: {},
            errors: {},
        };
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    componentDidMount() {
        let currentPath = globalHistory.location.pathname;
        let Id = currentPath.split(":")[1];
        this.setState({ UserId: Id });
        this.mounted = true;
        document.getElementById("year").innerHTML = new Date().getFullYear();
    }
    // This function will enable or disable the login button based on form validation
    validateForm() {
        let fields;
        fields = {
            UserId: this.state.UserId,
            newpassword: this.state.newpassword,
            confirmpassword: this.state.confirmpassword,
        }
        let errors = {};
        let formIsValid = true;

        if (!fields["newpassword"]) {
            formIsValid = false;
            errors["newpassword"] = "Enter your new password.";
        }

        if (this.state.newpassword.length < 6) {
            formIsValid = false;
            errors["newpassword"] = "New password required minimum 6 characters.";
        }

        if (!fields["confirmpassword"]) {
            formIsValid = false;
            errors["confirmpassword"] = "Enter your confirm password.";
        }

        if (this.state.newpassword !== this.state.confirmpassword) {
            formIsValid = false;
            errors["confirmpassword"] = "New password and confirm password does not match.";
        }
        if (this.mounted) {
            this.setState({ formValid: this.state.emailValid && this.state.passwordValid });
            this.setState({
                errors: errors
            });
        }
        return formIsValid;
    }

    ResetPassword = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            if (this.mounted) this.setState({ IsLoader: true })
            //let oldPwd = sha512(this.state.oldpassword);
            let newPwd = sha512(this.state.newpassword);
            let UserDetail;
            UserDetail = {
                UserId: this.state.UserId,
                NewPassword: newPwd
            }
            let uri = `${config.API_HOST_URL}/api/login/ResetPassword`;
            let fetchOptions = {
                method: "POST",
                body: JSON.stringify(UserDetail),
                headers: {
                    WebsiteGuid: this.props.websiteGUID,
                    LanguageGuid: this.props.languageGUID,
                    Authorization: `Bearer ${this.props.token}`,
                    "Content-Type": "application/json",
                    Accept: "application/json"
                }
            };

            fetch(uri, fetchOptions)
                .then(response => {
                    const contentType = response.headers.get("content-type");
                    let fieldValidationErrors = this.state.formErrors;
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json().then(data => {
                            if (data.success) {
                                let errors = {};
                                errors["successmessage"] = data.message;
                                if (this.mounted) {
                                    this.setState({
                                        errors: errors
                                    });
                                }

                            } else {
                                let errors = {};
                                errors["message"] = data.message;
                                if (this.mounted) {
                                    this.setState({
                                        errors: errors
                                    });
                                }
                            }
                        });
                    } else {
                        return response.json().then(data => {
                            let errors = {};
                            errors["message"] = data.message;
                            if (this.mounted) {
                                this.setState({
                                    errors: errors
                                });
                            }
                        });
                    }

                })
                .catch(error => {
                    if (this.mounted) {
                        console.log(error);
                        this.setState({
                            errorMsg: error,
                            IsLoader: false
                        })
                    }
                });
        }
    }

    // This function will set the state of input fields and validate them when user enters the value in input boxes
    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        if (this.mounted) {
            this.setState({ [name]: value }, () => { });
        }
    }

    render() {
        return (
            <div className="login_container">
                <div className="loginleft_cont">
                    <div className="loginform_cont">
                        <div className="growlogocont">
                            <div className="growlogo">
                                <img src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/companyLogo.png`} alt="growlogo" />
                            </div>
                        </div>
                        <form className="loginfrm_wrap">
                            <h1 className="section_heading">Sign in</h1>
                            <ul className="login_form">
                                <li>
                                    <input type="password" id="passwordreset" placeholder="Enter your new password" name="newpassword" value={this.state.newpassword} onChange={(event) => this.handleUserInput(event)} />
                                    <label for="passwordreset">Password</label>
                                    <div className="mandatory has-error">{this.state.errors.newpassword}</div>
                                </li>
                                <li>
                                    <input type="password" id="conpasswordreset" placeholder="Confirm your password" name="confirmpassword" value={this.state.confirmpassword} onChange={(event) => this.handleUserInput(event)} />
                                    <label for="conpasswordreset">Password</label>
                                    <div className="mandatory has-error">{this.state.errors.confirmpassword}</div>
                                </li>
                                <li className="form-group">
                                    <div className="mandatory has-error">{this.state.errors.message}</div>
                                    <div className="mandatory">{this.state.errors.successmessage}</div>
                                </li>
                                <li>
                                    <button className="signin_btn" type="submit" onClick={this.ResetPassword}>Reset Password</button>
                                </li>
                            </ul>
                        </form>
                        <div className="footertext">Copyright © <span id="year"></span> TreeTies. All rights reserved. Powered by Powerweave</div>
                    </div>
                </div>
                <div className="loginright_cont">
                    <img src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/login-bg-image.png`} alt="loginbg" />
                    <div className="logrghtimgtxt">46% of the world’s forest are already destroyed</div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
        UserId: state.user.UserId,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUserData: data => dispatch({ type: "SET_USER_DETAILS", data: data })
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Resetpassword);
