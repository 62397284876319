import { useEffect, useState } from "react";
import config from "../../../utils/config";
import MangotreeImg from "./mediaImages/Mangotree.png";
import WhaleImg from "./mediaImages/Bluewhale.png";
import BacteriaImg from "./mediaImages/Bacteria.png";
import TextureSoilImg from "./mediaImages/Texture_soil.png";
import ColorSoilImg from "./mediaImages/Color_soil.png";
import PorocitySoilImg from "./mediaImages/Porocity_soil.png";
import StructureSoilImg from "./mediaImages/Structure_soil.png";
import FamilyImg from "./mediaImages/Family.jpg";
import MaleImg from "./mediaImages/male.png";
import FemaleImg from "./mediaImages/Female.png";

export default function SiteDetailsView(props) {
  const [commonMastersList, setCommonMastersList] = useState([]);
  const [projectType, setProjectType] = useState([]);
  const [projectSubType, setProjectSubType] = useState([]);
  const [preDisturbanceDetails, setPreDisturbanceDetails] = useState([]);
  const [ListofMicrobes, setListofMicrobes] = useState([]);
  const [Flora, setFlora] = useState([]);
  const [FloraSpecies, setFloraSpecies] = useState([]);
  const [Fauna, setFauna] = useState([]);
  const [FaunaSpecies, setFaunaSpecies] = useState([]);
  const [Microbes, setMicrobes] = useState([]);
  const [MicrobesSpecies, setMicrobesSpecies] = useState([]);
  const [SiteDetailsData, setSiteDetailsData] = useState([]);
  const [EcoRegion, setEcoRegion] = useState([]);
  const [disturbanceDetails, setDisturbanceDetails] = useState([]);
  const [postDisturbanceDetails, setPostDisturbanceDetails] = useState([]);
  const [typeofCause, setTypeofCause] = useState([]);
  const [exactCause, setExactCause] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [impactSeverity, setimpactSeverity] = useState([]);
  const [landParcelsList, setLandParcelsList] = useState([]);
  const [waterBodiesList, setWaterBodiesList] = useState([]);
  const [postDisturbanceSoilDetails, setPostDisturbanceSoilDetails] = useState(
    []
  );
  const [dataaaa, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("Pre Disturbance Details");
  useEffect(() => {
    GetSiteDetails(props.projectId);
    GetCommonMasters();
    GetProjectLocation(props.projectId);
  }, [props.projectId]);

  const GetCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMasters`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data;
        var listOfMicrobes = data
          .filter((x) => x.type === "Microbes")
          .map((x) => x.description);
        setCommonMastersList(data);
        setListofMicrobes(listOfMicrobes);
        if (responseData.length > 0) {
          var projectType = responseData.filter(
            (x) => x.type === "ProjectType"
          );
          var projectSubType = responseData.filter(
            (x) => x.type === "ProjectSubType"
          );
          var flora = responseData.filter((x) => x.type === "Flora");
          var floraSpecies = responseData.filter(
            (x) => x.type === "FloraSubType"
          );
          var fauna = responseData.filter((x) => x.type === "Fauna");
          var faunaSpecies = responseData.filter(
            (x) => x.type === "FaunaSubType"
          );
          var microbes = responseData.filter((x) => x.type === "Microbes");
          var microbesSpecies = responseData.filter(
            (x) => x.type === "MicrobesSubType"
          );
          var EcoRegion = responseData.filter((x) => x.type === "Eco Region");
          var typeOfCause = responseData.filter(
            (x) => x.type === "Type of Cause"
          );
          var exactCause = responseData.filter((x) => x.type === "Exact Cause");
          var frequency = responseData.filter((x) => x.type === "Frequency");
          var impactSeverity = responseData.filter(
            (x) =>
              x.type ===
              "Impact Severity (As a Collective Result of all Disturbances Occurred)"
          );

          setFlora(flora);
          setFloraSpecies(floraSpecies);
          setFauna(fauna);
          setFaunaSpecies(faunaSpecies);
          setMicrobes(microbes);
          setMicrobesSpecies(microbesSpecies);
          setProjectType(projectType);
          setProjectSubType(projectSubType);
          setEcoRegion(EcoRegion);
          setTypeofCause(typeOfCause);
          setExactCause(exactCause);
          setFrequency(frequency);
          setimpactSeverity(impactSeverity);
        }
        setCommonMastersList(responseData);
        // console.log("setCommonMastersList", responseData);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetSiteDetails = (ProjectId) => {
    let projId = { ProjectId: ProjectId };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetSiteDetails`;
    fetch(url, {
      method: "Post",
      body: JSON.stringify(projId),
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        setSiteDetailsData(responseData);
        setPreDisturbanceDetails(responseData?.pre_DisturbanceDetails);
        setDisturbanceDetails(responseData?.disturbance_Details);
        setPostDisturbanceDetails(responseData?.post_DisturbanceDetails);
        setPostDisturbanceSoilDetails(
          responseData?.post_DisturbanceDetails?.soil
        );
        setData(responseData?.ecoRegion);
        //console.log("SiteDetailsData", responseData);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetProjectLocation = (ProjectId) => {
    let projId = { ProjectId: ProjectId };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectLocation`;
    fetch(url, {
      method: "Post",
      body: JSON.stringify(projId),
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        setLandParcelsList(responseData?.landParcels);
        setWaterBodiesList(responseData?.waterBodies);
        // console.log("prolist", responseData);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className="sitedetailsaccord_wrap">
        <div className="sitedetailsaccord_heading">
          <h3>General</h3>
        </div>
        <div className="prosummarylist">
          <div className="prosummarylist_item">
            <div className="produmitem_title">Eco Region </div>
            <div className="produmitem_content">
              :&nbsp;{""}
              {EcoRegion?.filter(
                (ss) => ss.mainGuId === SiteDetailsData?.ecoRegion
              )[0]?.description || "-"}
            </div>
          </div>
          <div className="prosummarylist_item">
            <div className="produmitem_title">Ecosystem Type</div>
            <div className="produmitem_content">
              :&nbsp;{""}
              {projectType?.filter(
                (ss) => ss.mainGuId === SiteDetailsData?.ecosystemType
              )[0]?.description || "-"}
            </div>
          </div>
          <div className="prosummarylist_item">
            <div className="produmitem_title">Ecosystem Sub Type</div>
            <div className="produmitem_content">
              :&nbsp;{""}
              {projectSubType?.filter(
                (ss) => ss.subGuId === SiteDetailsData?.ecosystemSubType
              ).length > 0
                ? projectSubType?.filter(
                    (ss) => ss.subGuId === SiteDetailsData?.ecosystemSubType
                  )[0]?.description
                : "-"}
            </div>
          </div>
        </div>
        <div className="sitedetailstabs_cont">
          <div className="tab-buttons">
            <button
              className={
                activeTab === "Pre Disturbance Details" ? "active" : ""
              }
              onClick={() => handleTabChange("Pre Disturbance Details")}
            >
              Pre Disturbance Details
            </button>
            <button
              className={
                activeTab === "Post-Disturbance Details" ? "active" : ""
              }
              onClick={() => handleTabChange("Post-Disturbance Details")}
            >
              Post-Disturbance Details
            </button>
            <button
              className={activeTab === "Disturbance Details" ? "active" : ""}
              onClick={() => handleTabChange("Disturbance Details")}
            >
              Disturbance Details
            </button>
          </div>
          {activeTab === "Pre Disturbance Details" && (
            <div className="tabs-content">
              <div className="yearsninfo_wrap">
                <div>
                  <span>Year of Reference</span>
                  <span>
                    {preDisturbanceDetails?.pre_Dis_Ref_YearOfReference
                      ? new Date(
                          preDisturbanceDetails?.pre_Dis_Ref_YearOfReference
                        )
                          .toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })
                          .toUpperCase()
                      : "-"}
                  </span>
                </div>

                <div>
                  <span>Sources</span>
                  <span>
                    {preDisturbanceDetails?.pre_Dis_Ref_Sources ?? "-"}
                  </span>
                </div>
                <div>
                  <span>Remarks</span>
                  <span>
                    {preDisturbanceDetails?.pre_Dis_Ref_Remarks ?? "-"}
                  </span>
                </div>
              </div>
              <div className="faflmicTypes_cont">
                <div className="floratype_cont">
                  <div className="faflmicTypes_heading">Flora Details</div>
                  <div className="floratypelist_wrap">
                    {preDisturbanceDetails?.pre_Dis_Flora &&
                    preDisturbanceDetails.pre_Dis_Flora.length > 0 ? (
                      preDisturbanceDetails.pre_Dis_Flora.map((item, i) => {
                        return (
                          <>
                            {FloraSpecies.filter(
                              (x) => x.subGuId === item.floraSpecies
                            ).map((flora) => {
                              return (
                                <div
                                  key={flora.subGuId}
                                  className="floratypelist_item"
                                >
                                  <div className="floratypelist_img">
                                    <img
                                      src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/SiteDetailsImages/Flora/${flora?.imageName}`}
                                      alt="Mangotree"
                                    />
                                  </div>
                                  <div className="floratypelist_content">
                                    <div>
                                      <span>
                                        Trees
                                        <abbr>:&nbsp;</abbr>
                                      </span>
                                      <span>{flora?.description}</span>
                                    </div>
                                    <div>
                                      <span>
                                        Population <abbr>:&nbsp;</abbr>
                                      </span>
                                      <span>
                                        {item?.floraNumber_Population
                                          ? item?.floraNumber_Population
                                          : "-"}
                                      </span>
                                    </div>
                                    <div>
                                      <span>
                                        Invasive <abbr>:&nbsp;</abbr>
                                      </span>
                                      <span>
                                        {item?.floraInvasive_Specie
                                          ? item?.floraInvasive_Specie
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        );
                      })
                    ) : (
                      <div className="norecodfoundprosum_cont">
                        No Record Found
                      </div>
                    )}
                  </div>
                </div>
                <div className="floratype_cont">
                  <div className="faflmicTypes_heading">Fauna Details</div>
                  <div className="floratypelist_wrap">
                    {preDisturbanceDetails?.pre_Dis_Fauna &&
                    preDisturbanceDetails.pre_Dis_Fauna.length > 0 ? (
                      preDisturbanceDetails.pre_Dis_Fauna.map((item2, i2) => {
                        return (
                          <>
                            {FaunaSpecies.filter(
                              (x) => x.subGuId === item2.faunaSpecies
                            ).map((fauna) => {
                              return (
                                <div className="floratypelist_item">
                                  <div className="floratypelist_img">
                                    <img
                                      src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/SiteDetailsImages/Fauna/${fauna?.imageName}`}
                                      alt="Mangotree"
                                    />
                                  </div>
                                  <div className="floratypelist_content">
                                    <div>
                                      <span>
                                        Trees
                                        <abbr>:&nbsp;</abbr>
                                      </span>
                                      <span>{fauna?.description} </span>
                                    </div>
                                    <div>
                                      <span>
                                        Population <abbr>:&nbsp;</abbr>
                                      </span>
                                      <span>
                                        {item2?.faunaNumber_Population
                                          ? item2?.faunaNumber_Population
                                          : "-"}
                                      </span>
                                    </div>
                                    <div>
                                      <span>
                                        Invasive <abbr>:&nbsp;</abbr>
                                      </span>
                                      <span>
                                        {item2?.faunaInvasive_Specie
                                          ? item2?.faunaInvasive_Specie
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        );
                      })
                    ) : (
                      <div className="norecodfoundprosum_cont">
                        No Record Found
                      </div>
                    )}
                  </div>
                </div>
                <div className="floratype_cont microbestype_cont">
                  <div className="faflmicTypes_heading">Microbes Details</div>
                  <div className="floratypelist_wrap">
                    {preDisturbanceDetails?.pre_Dis_Microbes &&
                    preDisturbanceDetails.pre_Dis_Microbes.length > 0 ? (
                      preDisturbanceDetails.pre_Dis_Microbes.map(
                        (item3, i3) => {
                          return (
                            <>
                              {MicrobesSpecies.filter(
                                (x) => x.subGuId === item3.microbesSpecies
                              ).map((microbes) => {
                                return (
                                  <div className="floratypelist_item">
                                    <div className="floratypelist_img">
                                      <img
                                        src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/SiteDetailsImages/Microbes/${microbes?.imageName}`}
                                        alt="Mangotree"
                                      />
                                    </div>
                                    <div className="floratypelist_content">
                                      <div>
                                        <span>
                                          Trees
                                          <abbr>:&nbsp;</abbr>
                                        </span>
                                        <span>{microbes?.description} </span>
                                      </div>
                                      <div>
                                        <span>
                                          Population <abbr>:&nbsp;</abbr>
                                        </span>
                                        <span>
                                          {item3?.microbesNumber_Population
                                            ? item3?.microbesNumber_Population
                                            : "-"}
                                        </span>
                                      </div>
                                      <div>
                                        <span>
                                          Invasive <abbr>:&nbsp;</abbr>
                                        </span>
                                        <span>
                                          {item3?.microbesInvasive_Specie
                                            ? item3?.microbesInvasive_Specie
                                            : "-"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          );
                        }
                      )
                    ) : (
                      <div className="norecodfoundprosum_cont">
                        No Record Found
                      </div>
                    )}
                  </div>
                </div>
                <div className="floratype_cont climatewatercontent_wrap">
                  <div className="faflmicTypes_heading">Climate</div>
                  <div className="climatedetails_cont">
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Climate</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl prosumartbltemp sitedet_climatetbl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  preDisturbanceDetails?.climate
                                    ?.climateType !== null &&
                                  preDisturbanceDetails?.climate
                                    ?.climateType !== undefined &&
                                  preDisturbanceDetails?.climate?.climateType
                                    .trim()
                                    .toLowerCase() === "equable"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                Equable
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.climate
                                    ?.climateType !== null &&
                                  preDisturbanceDetails?.climate
                                    ?.climateType !== undefined &&
                                  preDisturbanceDetails?.climate?.climateType
                                    .trim()
                                    .toLowerCase() ===
                                    "neither equable nor extreme"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                Neither Equable Nor Extreme
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.climate
                                    ?.climateType !== null &&
                                  preDisturbanceDetails?.climate
                                    ?.climateType !== undefined &&
                                  preDisturbanceDetails?.climate?.climateType
                                    .trim()
                                    .toLowerCase() === "extreme"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                Extreme
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************average temperature*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Average Temperature (°C)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl prosumartbltemp sitedet_avgtempbl">
                          <tbody>
                            <tr>
                              <td>°C</td>
                              {Array.from({ length: 8 }, (_, i) => {
                                const values = [
                                  -20, -10, 0, 10, 20, 30, 40, 50,
                                ];
                                const value = values[i];
                                let dbValue = 0;
                                if (
                                  preDisturbanceDetails?.climate
                                    ?.avg_Temp_Summer !== null &&
                                  preDisturbanceDetails?.climate
                                    ?.avg_Temp_Summer !== undefined
                                ) {
                                  dbValue = parseInt(
                                    preDisturbanceDetails?.climate
                                      ?.avg_Temp_Summer,
                                    10
                                  );
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      dbValue >= value &&
                                      dbValue <
                                        (i === 7 ? Infinity : values[i + 1])
                                        ? "activetype_td"
                                        : ""
                                    }
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Average Yearly Rainfall (in mm)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Average Yearly Rainfall (in mm)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_avgrainfalltbl">
                          <tbody>
                            <tr>
                              <td>mm</td>
                              {Array.from({ length: 8 }, (_, i) => {
                                const values = [
                                  10, 30, 50, 90, 120, 150, 200, 300,
                                ];
                                const value = values[i];
                                const dbValue =
                                  preDisturbanceDetails?.climate
                                    ?.avg_Yearly_Rainfall;
                                const isActive =
                                  dbValue !== null && dbValue !== undefined
                                    ? dbValue >= value &&
                                      dbValue <
                                        (i === 7 ? Infinity : values[i + 1])
                                    : false;
                                return (
                                  <td
                                    key={value}
                                    className={isActive ? "activetype_td" : ""}
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Humidity (%)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Humidity (%)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_humiditytbl">
                          <tbody>
                            <tr>
                              <td>%</td>
                              {Array.from({ length: 10 }, (_, i) => {
                                const value = (i + 1) * 10;
                                let dbValue = 0;
                                if (
                                  preDisturbanceDetails?.climate?.humidity !==
                                    null &&
                                  preDisturbanceDetails?.climate?.humidity !==
                                    undefined
                                ) {
                                  dbValue = parseInt(
                                    preDisturbanceDetails?.climate?.humidity,
                                    10
                                  );
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      dbValue >= value && dbValue < value + 10
                                        ? "activetype_td"
                                        : ""
                                    }
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Wind (km/h)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Wind (km/h)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_windkmhpbl">
                          <tbody>
                            <tr>
                              <td>km/h</td>
                              {Array.from({ length: 8 }, (_, i) => {
                                const values = [0, 10, 20, 30, 40, 50, 70, 100];
                                const value = values[i];
                                let highlighted = false;
                                if (
                                  preDisturbanceDetails?.climate
                                    ?.avg_Wind_Speed !== null &&
                                  preDisturbanceDetails?.climate
                                    ?.avg_Wind_Speed !== undefined
                                ) {
                                  const nearestValue = values.reduce(
                                    (prev, curr) => {
                                      return Math.abs(
                                        curr -
                                          preDisturbanceDetails.climate
                                            .avg_Wind_Speed
                                      ) <
                                        Math.abs(
                                          prev -
                                            preDisturbanceDetails.climate
                                              .avg_Wind_Speed
                                        )
                                        ? curr
                                        : prev;
                                    }
                                  );
                                  highlighted = value === nearestValue;
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      highlighted ? "activetype_td" : ""
                                    }
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Air Quality Index*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Air Quality Index</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_aqibl">
                          <tbody>
                            <tr>
                              <td>AQI</td>
                              <td
                                className={
                                  preDisturbanceDetails?.climate
                                    ?.air_Quality_Index >= 0 &&
                                  preDisturbanceDetails?.climate
                                    ?.air_Quality_Index <= 50
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0-50
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.climate
                                    ?.air_Quality_Index >= 51 &&
                                  preDisturbanceDetails?.climate
                                    ?.air_Quality_Index <= 100
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                51-100
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.climate
                                    ?.air_Quality_Index >= 101 &&
                                  preDisturbanceDetails?.climate
                                    ?.air_Quality_Index <= 200
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                101-200
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.climate
                                    ?.air_Quality_Index >= 201 &&
                                  preDisturbanceDetails?.climate
                                    ?.air_Quality_Index <= 300
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                201-300
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.climate
                                    ?.air_Quality_Index > 300
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                Beyond 300
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Avg. No of Hrs. of Sunlight Availability in Summer*****************/}
                    <div className="climatedetails_item lasttwoheading_maxwidth">
                      <div className="climatedetails_heading">
                        <h3>
                          Avg. No of Hrs. of Sunlight Availability in Summer
                        </h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_avghrsslightinsummerbl">
                          <tbody>
                            <tr>
                              <td>Hrs.</td>
                              {Array.from({ length: 12 }, (_, i) => {
                                const value = i + 1;
                                let highlighted = false;
                                if (
                                  preDisturbanceDetails?.climate
                                    ?.avg_No_Hours_Sunlight_Avail_Summer !==
                                    null &&
                                  preDisturbanceDetails?.climate
                                    ?.avg_No_Hours_Sunlight_Avail_Summer !==
                                    undefined
                                ) {
                                  highlighted =
                                    Math.abs(
                                      value -
                                        preDisturbanceDetails.climate
                                          .avg_No_Hours_Sunlight_Avail_Summer
                                    ) <= 0.5;
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      highlighted ? "activetype_td" : ""
                                    }
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Avg. No of Hrs. of Sunlight Availability in Winter*****************/}
                    <div className="climatedetails_item lasttwoheading_maxwidth">
                      <div className="climatedetails_heading">
                        <h3>
                          Avg. No of Hrs. of Sunlight Availability in Winter
                        </h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_avghrsslightinwinterbl">
                          <tbody>
                            <tr>
                              <td>Hrs.</td>
                              {Array.from({ length: 12 }, (_, i) => {
                                const value = i + 1;
                                let highlighted = false;
                                if (
                                  preDisturbanceDetails?.climate
                                    ?.avg_No_Hours_Sunlight_Avail_Winter !==
                                    null &&
                                  preDisturbanceDetails?.climate
                                    ?.avg_No_Hours_Sunlight_Avail_Winter !==
                                    undefined
                                ) {
                                  highlighted =
                                    value ===
                                    preDisturbanceDetails.climate
                                      .avg_No_Hours_Sunlight_Avail_Winter;
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      highlighted ? "activetype_td" : ""
                                    }
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="floratype_cont climatewatercontent_wrap">
                  <div className="faflmicTypes_heading">Water</div>
                  <div className="climatedetails_cont waterdetails_cont">
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Turbidity</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl water_turbiditytbl">
                          <tbody>
                            <tr>
                              {Array.from({ length: 4 }, (_, i) => {
                                const value = i * 25;
                                let highlighted = false;
                                if (
                                  preDisturbanceDetails?.water
                                    ?.turbidityValue !== null &&
                                  preDisturbanceDetails?.water
                                    ?.turbidityValue !== undefined
                                ) {
                                  if (
                                    i === 0 &&
                                    preDisturbanceDetails.water
                                      .turbidityValue >= 0 &&
                                    preDisturbanceDetails.water
                                      .turbidityValue <= 24
                                  ) {
                                    highlighted = true;
                                  } else if (
                                    i === 1 &&
                                    preDisturbanceDetails.water.turbidityValue >
                                      24 &&
                                    preDisturbanceDetails.water
                                      .turbidityValue <= 49
                                  ) {
                                    highlighted = true;
                                  } else if (
                                    i === 2 &&
                                    preDisturbanceDetails.water.turbidityValue >
                                      49 &&
                                    preDisturbanceDetails.water
                                      .turbidityValue <= 74
                                  ) {
                                    highlighted = true;
                                  } else if (
                                    i === 3 &&
                                    preDisturbanceDetails.water.turbidityValue >
                                      74
                                  ) {
                                    highlighted = true;
                                  }
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      highlighted ? "activetype_td" : ""
                                    }
                                  >
                                    {value}%
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont">
                          <span>High</span>
                          <span>Medium</span>
                          <span>Low</span>
                        </div>
                      </div>
                    </div>
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Taste and Odor</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl water_tastenodertbl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.taste_Odor_Value >= 0 &&
                                  preDisturbanceDetails?.water
                                    ?.taste_Odor_Value <= 50
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.taste_Odor_Value >= 51 &&
                                  preDisturbanceDetails?.water
                                    ?.taste_Odor_Value <= 100
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                50-100
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.taste_Odor_Value >= 101 &&
                                  preDisturbanceDetails?.water
                                    ?.taste_Odor_Value <= 200
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                200
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.taste_Odor_Value >= 201 &&
                                  preDisturbanceDetails?.water
                                    ?.taste_Odor_Value <= 300
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                300
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.taste_Odor_Value >= 301 &&
                                  preDisturbanceDetails?.water
                                    ?.taste_Odor_Value <= 400
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                400
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.taste_Odor_Value >= 401
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                500+
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont water_tastenodertblfoot">
                          <span>
                            High <br />
                            Purity
                          </span>
                          <span>
                            Relatively <br />
                            High <br />
                            Purity
                          </span>
                          <span>
                            General <br />
                            Purity
                          </span>
                          <span>
                            Lime <br />
                            scale
                          </span>
                          <span>
                            Rough <br />
                            Taste
                          </span>
                          <span>Harmful</span>
                        </div>
                      </div>
                    </div>
                    {/*****************Hardness******************* */}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Hardness</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <div className="waterhardnesstbls_wrap">
                          <div>mg/L & ppm</div>
                          <table class="prosumarytbl water_hardnesstbl">
                            <tbody>
                              <tr>
                                <td
                                  className={
                                    preDisturbanceDetails?.water
                                      ?.hardnessValue <= 75
                                      ? "activetype_td"
                                      : ""
                                  }
                                >
                                  High
                                </td>

                                <td
                                  className={
                                    preDisturbanceDetails?.water
                                      ?.hardnessValue >= 75 &&
                                    preDisturbanceDetails?.water
                                      ?.hardnessValue <= 150
                                      ? "activetype_td"
                                      : ""
                                  }
                                >
                                  75-150
                                </td>
                                <td
                                  className={
                                    preDisturbanceDetails?.water
                                      ?.hardnessValue >= 150 &&
                                    preDisturbanceDetails?.water
                                      ?.hardnessValue <= 300
                                      ? "activetype_td"
                                      : ""
                                  }
                                >
                                  150-300
                                </td>
                                <td
                                  className={
                                    preDisturbanceDetails?.water
                                      ?.hardnessValue >= 300
                                      ? "activetype_td"
                                      : ""
                                  }
                                >
                                  300+
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="waterhardnesstbls_wrap">
                          <div>mg/L & ppm</div>
                          <table class="prosumarytbl water_hardnesstbl">
                            <tbody>
                              <tr>
                                <td>0 - 4</td>
                                <td>4 - 8.5</td>
                                <td>8.5 - 17</td>
                                <td>17+</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="watertblunits_cont water_hardnesstblfoot">
                          <span>Soft</span>
                          <span>Slightly Hard</span>
                          <span>Hard</span>
                          <span>Very Hard</span>
                        </div>
                      </div>
                    </div>
                    {/*****************pH Scale******************* */}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>pH Scale</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl water_phscaletbl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 0
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 1
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                1
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 2
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                2
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 3
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                3
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 4
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                4
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 5
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                5
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 6
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                6
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 7
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                7
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 8
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                8
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 9
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                9
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 10
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                10
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 11
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                11
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 12
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                12
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 13
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                13
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water?.ph === 14
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                14
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont water_tastenodertblfoot">
                          <span>Acidic</span>
                          <span>Neutral</span>
                          <span>Alkaline</span>
                        </div>
                      </div>
                    </div>
                    {/**************average temperature*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Temperature (°C)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl prosumartbltemp sitedet_avgtempbl">
                          <tbody>
                            <tr>
                              <td>°C</td>
                              {Array.from({ length: 9 }, (_, i) => (
                                <td
                                  key={i}
                                  className={
                                    preDisturbanceDetails?.water
                                      ?.temperature !== null &&
                                    preDisturbanceDetails?.water
                                      ?.temperature !== "" &&
                                    Math.abs(
                                      preDisturbanceDetails?.water
                                        ?.temperature -
                                        (i * 10 - 20)
                                    ) < 5
                                      ? "activetype_td"
                                      : ""
                                  }
                                >
                                  {i * 10 - 20}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Biochemical Oxygen Demand (BOD) (in mgL)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Biochemical Oxygen Demand (BOD) (in mgL)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl water_hardnesstbl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.biochem_Oxygen_Demand >= 1 &&
                                  preDisturbanceDetails?.water
                                    ?.biochem_Oxygen_Demand <= 2
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                1 - 2
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.biochem_Oxygen_Demand >= 3 &&
                                  preDisturbanceDetails?.water
                                    ?.biochem_Oxygen_Demand <= 5
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                3 - 5
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.biochem_Oxygen_Demand >= 6 &&
                                  preDisturbanceDetails?.water
                                    ?.biochem_Oxygen_Demand <= 9
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                6 - 9
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.biochem_Oxygen_Demand >= 10
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                100 or more
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Dissolved Oxygen (DO) (in mgL)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Dissolved Oxygen (DO) (in mgL)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_aqibl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.dissolved_Oxygen >= 81 &&
                                  preDisturbanceDetails?.water
                                    ?.dissolved_Oxygen <= 100
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                81-100
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.dissolved_Oxygen >= 61 &&
                                  preDisturbanceDetails?.water
                                    ?.dissolved_Oxygen <= 80
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                61-80
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.dissolved_Oxygen >= 41 &&
                                  preDisturbanceDetails?.water
                                    ?.dissolved_Oxygen <= 60
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                41-60
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.dissolved_Oxygen >= 21 &&
                                  preDisturbanceDetails?.water
                                    ?.dissolved_Oxygen <= 40
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                21-40
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.dissolved_Oxygen >= 1 &&
                                  preDisturbanceDetails?.water
                                    ?.dissolved_Oxygen <= 20
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                1-20
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont water_tastenodertblfoot">
                          <span>Excellent</span>
                          <span>Fair</span>
                          <span>Very Poor</span>
                        </div>
                      </div>
                    </div>
                    {/**************Electrical Conductivity*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Electrical Conductivity</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_aqibl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.electrical_Conductivity === "Low"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                Low
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.electrical_Conductivity === "Medium"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                Medium
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.water
                                    ?.electrical_Conductivity === "High"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                High
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="floratype_cont climatewatercontent_wrap">
                  <div className="faflmicTypes_heading">
                    Water Toxic Substances
                  </div>
                  <div className="watertoxicsubstance_wrap">
                    <div className="watertoxicsubstance_cont">
                      <div className="watertoxicsubstance_left">Organic</div>
                      <div className="watertoxicsubstance_right">
                        <h3>Dioxins and furans</h3>
                        <p>
                          Most common form of water pollution. Originate from
                          domestic sewage, industrial effluents and farm wastes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="floratype_cont climatewatercontent_wrap soildetailscontent_wrap">
                  <div className="faflmicTypes_heading">Soil</div>
                  <div className="soilswrap">
                    <div>
                      <img src={TextureSoilImg} alt="Texture" />
                      Texture{" "}
                      <b>
                        {preDisturbanceDetails?.soil?.soil_Textures
                          ? preDisturbanceDetails?.soil?.soil_Textures
                          : "-"}
                      </b>
                    </div>
                    <div>
                      <img src={StructureSoilImg} alt="Structure" />
                      Structure{" "}
                      <b>
                        {preDisturbanceDetails?.soil?.soil_Structure
                          ? preDisturbanceDetails?.soil?.soil_Structure
                          : "-"}{" "}
                        (crumbly)
                      </b>
                    </div>
                    <div>
                      <img src={PorocitySoilImg} alt="Porocity" />
                      Porocity{" "}
                      <b>
                        {preDisturbanceDetails?.soil?.soil_Porosity
                          ? preDisturbanceDetails?.soil?.soil_Porosity
                          : "-"}
                      </b>
                    </div>
                    <div>
                      <img src={ColorSoilImg} alt="Color" />
                      Color{" "}
                      <b>
                        {preDisturbanceDetails?.soil?.soil_Color
                          ? preDisturbanceDetails?.soil?.soil_Color
                          : "-"}
                      </b>
                    </div>
                  </div>
                  <div className="climatedetails_cont waterdetails_cont">
                    {/*****************Soil pH******************* */}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Soil pH</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl water_phscaletbl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 0
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 1
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                1
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 2
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                2
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 3
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                3
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 4
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                4
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 5
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                5
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 6
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                6
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 7
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                7
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 8
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                8
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 9
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                9
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 10
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                10
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 11
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                11
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 12
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                12
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 13
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                13
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.soil_pH === 14
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                14
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont water_tastenodertblfoot">
                          <span>Acidic</span>
                          <span>Neutral</span>
                          <span>Alkaline</span>
                        </div>
                      </div>
                    </div>
                    {/**************Nitrate (mg/kg)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Nitrate (mg/kg)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_aqibl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.nitrate >= 0 &&
                                  preDisturbanceDetails?.soil?.nitrate <= 15
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0-15
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.nitrate >= 16 &&
                                  preDisturbanceDetails?.soil?.nitrate <= 40
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                16-40
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.nitrate >= 41 &&
                                  preDisturbanceDetails?.soil?.nitrate <= 50
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                40-50
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.nitrate >= 51
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                60+
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont water_tastenodertblfoot">
                          <span>Low</span>
                          <span>Medium</span>
                          <span>High</span>
                          <span>Very High</span>
                        </div>
                      </div>
                    </div>
                    {/**************Phosphate (PPA)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Phosphate (PPA)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_aqibl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.phosphate >= 0 &&
                                  preDisturbanceDetails?.soil?.phosphate <= 35
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0-35
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.phosphate >=
                                    36 &&
                                  preDisturbanceDetails?.soil?.phosphate <= 50
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                35-50
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.phosphate >=
                                    51 &&
                                  preDisturbanceDetails?.soil?.phosphate <= 80
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                50-80
                              </td>
                              <td
                                className={
                                  preDisturbanceDetails?.soil?.phosphate >= 81
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                80+
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont water_tastenodertblfoot">
                          <span>Low</span>
                          <span>Medium</span>
                          <span>High</span>
                          <span>Very High</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="floratype_cont climatewatercontent_wrap">
                  <div className="faflmicTypes_heading">Social</div>
                  <div className="socialwrap">
                    <div className="socialtop_cont">
                      <div className="socialtop_left">
                        <div>
                          <span className="social_counts">
                            {" "}
                            {preDisturbanceDetails?.social?.no_Families
                              ? preDisturbanceDetails?.social?.no_Families
                              : "-"}
                          </span>
                          <span className="social_content">
                            No. of Families
                          </span>
                        </div>
                        <div>
                          <span className="social_counts">
                            {" "}
                            {preDisturbanceDetails?.social?.avg_Family_Size
                              ? preDisturbanceDetails?.social?.avg_Family_Size
                              : "-"}
                          </span>
                          <span className="social_content">
                            Average Family Size
                          </span>
                        </div>
                        <div>
                          <span className="social_counts">
                            {" "}
                            {preDisturbanceDetails?.social
                              ?.avg_Annual_Inc_Family_Farming
                              ? preDisturbanceDetails?.social
                                  ?.avg_Annual_Inc_Family_Farming
                              : "-"}
                          </span>
                          <span className="social_content">
                            Average Annual Income per Family by Farming
                            <abbr>
                              (for the land parcels included in this project)
                            </abbr>
                          </span>
                        </div>
                        <div>
                          <span className="social_counts">
                            {" "}
                            {preDisturbanceDetails?.social
                              ?.avg_Annual_Inc_Family_Other_Sources
                              ? preDisturbanceDetails?.social
                                  ?.avg_Annual_Inc_Family_Other_Sources
                              : "-"}
                          </span>
                          <span className="social_content">
                            Average Annual Income per Family by Other Sources
                          </span>
                        </div>
                      </div>
                      <div className="socialtop_right">
                        <img src={FamilyImg} alt="FamilyImg" />
                      </div>
                    </div>
                    <div className="socialbot_cont">
                      <div className="socialbot_left">
                        <div className="socialbot_heading">Gender Ratio</div>
                        <div className="socialbotiner_left">
                          <img src={FemaleImg} alt="FamilyImg" />
                          <span className="socialbot_contenthead">Female</span>
                          <span className="socialbot_count">
                            {preDisturbanceDetails?.social?.gender
                              ? preDisturbanceDetails?.social?.gender
                              : "-"}
                          </span>
                        </div>
                        <div className="socialbotiner_right">
                          <img src={MaleImg} alt="FamilyImg" />
                          <span className="socialbot_contenthead">Male</span>
                          <span className="socialbot_count">
                            {preDisturbanceDetails?.social?.gender
                              ? preDisturbanceDetails?.social?.gender
                              : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="socialbot_right">
                        <div className="socialbot_heading">Literacy Rate</div>
                        <div className="socialbotiner_left">
                          <span className="socialbot_contenthead">Overall</span>
                          <span className="socialbot_count">
                            {" "}
                            {preDisturbanceDetails?.social
                              ?.literacy_Rate_Overall
                              ? preDisturbanceDetails?.social
                                  ?.literacy_Rate_Overall
                              : "-"}
                          </span>
                        </div>
                        <div className="socialbotiner_right">
                          <img src={FemaleImg} alt="FamilyImg" />
                          <span className="socialbot_contenthead">Female</span>
                          <span className="socialbot_count">
                            {" "}
                            {preDisturbanceDetails?.social?.literacy_Rate_Female
                              ? preDisturbanceDetails?.social
                                  ?.literacy_Rate_Female
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === "Post-Disturbance Details" && (
            <div className="tabs-content">
              <div className="yearsninfo_wrap">
                <div>
                  <span>Year of Reference </span>
                  <span>
                    {postDisturbanceDetails?.post_Dis_Ref_YearOfReference
                      ? new Date(
                          postDisturbanceDetails.post_Dis_Ref_YearOfReference
                        )
                          .toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })
                          .toUpperCase()
                      : "-"}
                  </span>
                </div>
                <div>
                  <span>Sources</span>
                  <span>
                    {postDisturbanceDetails?.post_Dis_Ref_Sources ?? "-"}
                  </span>
                </div>
                <div>
                  <span>Remarks</span>
                  <span>
                    {postDisturbanceDetails?.post_Dis_Ref_Remarks ?? "-"}
                  </span>
                </div>
              </div>
              <div className="faflmicTypes_cont">
                <div className="floratype_cont">
                  <div className="faflmicTypes_heading">Flora Details</div>
                  <div className="floratypelist_wrap">
                    {postDisturbanceDetails?.post_Dis_Flora &&
                    postDisturbanceDetails?.post_Dis_Flora.length > 0 ? (
                      postDisturbanceDetails.post_Dis_Flora.map((item, i) =>
                        FloraSpecies.filter(
                          (x) => x.subGuId === item.post_floraSpecies
                        ).map((flora) => (
                          <div
                            className="floratypelist_item"
                            key={`${i}_${flora.subGuId}`}
                          >
                            <div className="floratypelist_img">
                              <img
                                src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/SiteDetailsImages/Flora/${flora?.imageName}`}
                                alt="Mangotree"
                              />
                            </div>
                            <div className="floratypelist_content">
                              <div>
                                <span>
                                  Trees<abbr>:&nbsp;</abbr>
                                </span>
                                <span>{flora?.description}</span>
                              </div>
                              <div>
                                <span>
                                  Population<abbr>:&nbsp;</abbr>
                                </span>
                                <span>
                                  {item?.post_floraNumber_Population
                                    ? item?.post_floraNumber_Population
                                    : "-"}
                                </span>
                              </div>
                              <div>
                                <span>
                                  Invasive<abbr>:&nbsp;</abbr>
                                </span>
                                <span>
                                  {item?.post_floraInvasive_Specie
                                    ? item?.post_floraInvasive_Specie
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      )
                    ) : (
                      <div className="floratypelist_item norecodfoundprosum_cont">
                        No Record Found
                      </div>
                    )}
                  </div>
                </div>

                <div className="floratype_cont">
                  <div className="faflmicTypes_heading">Fauna Details</div>
                  <div className="floratypelist_wrap">
                    {postDisturbanceDetails?.post_Dis_Fauna &&
                    postDisturbanceDetails?.post_Dis_Fauna.length > 0 ? (
                      postDisturbanceDetails.post_Dis_Fauna.map((item2, i2) =>
                        FaunaSpecies.filter(
                          (x) => x.subGuId === item2.post_faunaSpecies
                        ).map((fauna) => (
                          <div
                            key={fauna.subGuId}
                            className="floratypelist_item"
                          >
                            <div className="floratypelist_img">
                              <img
                                src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/SiteDetailsImages/Fauna/${fauna?.imageName}`}
                                alt="Mangotree"
                              />
                            </div>
                            <div className="floratypelist_content">
                              <div>
                                <span>
                                  Trees
                                  <abbr>:&nbsp;</abbr>
                                </span>
                                <span>{fauna?.description}</span>
                              </div>
                              <div>
                                <span>
                                  Population <abbr>:&nbsp;</abbr>
                                </span>
                                <span>
                                  {item2?.post_faunaNumber_Population
                                    ? item2?.post_faunaNumber_Population
                                    : "-"}
                                </span>
                              </div>
                              <div>
                                <span>
                                  Invasive <abbr>:&nbsp;</abbr>
                                </span>
                                <span>
                                  {item2?.post_faunaInvasive_Specie
                                    ? item2?.post_faunaInvasive_Specie
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      )
                    ) : (
                      <div className="norecodfoundprosum_cont">
                        No Record Found
                      </div>
                    )}
                  </div>
                </div>

                <div className="floratype_cont microbestype_cont">
                  <div className="faflmicTypes_heading">Microbes Details</div>
                  <div className="floratypelist_wrap">
                    {postDisturbanceDetails?.post_Dis_Microbes &&
                    postDisturbanceDetails?.post_Dis_Microbes.length > 0 ? (
                      postDisturbanceDetails.post_Dis_Microbes.map(
                        (item3, i3) =>
                          MicrobesSpecies.filter(
                            (x) => x.subGuId === item3.post_microbesSpecies
                          ).map((microbes) => (
                            <div
                              key={microbes.subGuId}
                              className="floratypelist_item"
                            >
                              <div className="floratypelist_img">
                                <img
                                  src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/SiteDetailsImages/Microbes/${microbes?.imageName}`}
                                  alt="Mangotree"
                                />
                              </div>
                              <div className="floratypelist_content">
                                <div>
                                  <span>
                                    Trees
                                    <abbr>:&nbsp;</abbr>
                                  </span>
                                  <span>{microbes?.description}</span>
                                </div>
                                <div>
                                  <span>
                                    Population <abbr>:&nbsp;</abbr>
                                  </span>
                                  <span>
                                    {item3?.post_microbesNumber_Population
                                      ? item3?.post_microbesNumber_Population
                                      : "-"}
                                  </span>
                                </div>
                                <div>
                                  <span>
                                    Invasive <abbr>:&nbsp;</abbr>
                                  </span>
                                  <span>
                                    {item3?.post_microbesInvasive_Specie
                                      ? item3?.post_microbesInvasive_Specie
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                      )
                    ) : (
                      <div className="norecodfoundprosum_cont">
                        No Record Found
                      </div>
                    )}
                  </div>
                </div>

                <div className="floratype_cont climatewatercontent_wrap">
                  <div className="faflmicTypes_heading">Climate</div>
                  <div className="climatedetails_cont">
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Climate</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl prosumartbltemp sitedet_climatetbl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  postDisturbanceDetails?.climate
                                    ?.climateType !== null &&
                                  postDisturbanceDetails?.climate
                                    ?.climateType !== undefined &&
                                  postDisturbanceDetails?.climate?.climateType
                                    .trim()
                                    .toLowerCase() === "equable"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                Equable
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.climate
                                    ?.climateType !== null &&
                                  postDisturbanceDetails?.climate
                                    ?.climateType !== undefined &&
                                  postDisturbanceDetails?.climate?.climateType
                                    .trim()
                                    .toLowerCase() ===
                                    "neither equable nor extreme"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                Neither Equable Nor Extreme
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.climate
                                    ?.climateType !== null &&
                                  postDisturbanceDetails?.climate
                                    ?.climateType !== undefined &&
                                  postDisturbanceDetails?.climate?.climateType
                                    .trim()
                                    .toLowerCase() === "extreme"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                Extreme
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************average temperature*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Average Temperature (°C)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl prosumartbltemp sitedet_avgtempbl">
                          <tbody>
                            <tr>
                              <td>°C</td>
                              {Array.from({ length: 8 }, (_, i) => {
                                const values = [
                                  -20, -10, 0, 10, 20, 30, 40, 50,
                                ];
                                const value = values[i];
                                let dbValue = 0;
                                if (
                                  postDisturbanceDetails?.climate
                                    ?.avg_Temp_Summer !== null &&
                                  postDisturbanceDetails?.climate
                                    ?.avg_Temp_Summer !== undefined
                                ) {
                                  dbValue = parseInt(
                                    postDisturbanceDetails?.climate
                                      ?.avg_Temp_Summer,
                                    10
                                  );
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      dbValue >= value &&
                                      dbValue <
                                        (i === 7 ? Infinity : values[i + 1])
                                        ? "activetype_td"
                                        : ""
                                    }
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Average Yearly Rainfall (in mm)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Average Yearly Rainfall (in mm)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_avgrainfalltbl">
                          <tbody>
                            <tr>
                              <td>mm</td>
                              {Array.from({ length: 8 }, (_, i) => {
                                const values = [
                                  10, 30, 50, 90, 120, 150, 200, 300,
                                ];
                                const value = values[i];
                                const dbValue =
                                  postDisturbanceDetails?.climate
                                    ?.avg_Yearly_Rainfall;
                                const isActive =
                                  dbValue !== null && dbValue !== undefined
                                    ? dbValue >= value &&
                                      dbValue <
                                        (i === 7 ? Infinity : values[i + 1])
                                    : false;
                                return (
                                  <td
                                    key={value}
                                    className={isActive ? "activetype_td" : ""}
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Humidity (%)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Humidity (%)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table className="prosumarytbl sitedet_humiditytbl">
                          <tbody>
                            <tr>
                              <td>%</td>
                              {Array.from({ length: 10 }, (_, i) => {
                                const value = (i + 1) * 10;
                                let dbValue = 0;
                                if (
                                  postDisturbanceDetails?.climate?.humidity !==
                                    null &&
                                  postDisturbanceDetails?.climate?.humidity !==
                                    undefined
                                ) {
                                  dbValue = parseInt(
                                    postDisturbanceDetails?.climate?.humidity,
                                    10
                                  );
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      dbValue >= value && dbValue < value + 10
                                        ? "activetype_td"
                                        : ""
                                    }
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Wind (km/h)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Wind (km/h)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table className="prosumarytbl sitedet_windkmhpbl">
                          <tbody>
                            <tr>
                              <td>km/h</td>
                              {Array.from({ length: 8 }, (_, i) => {
                                const values = [0, 10, 20, 30, 40, 50, 70, 100];
                                const value = values[i];
                                let highlighted = false;
                                if (
                                  postDisturbanceDetails?.climate
                                    ?.avg_Wind_Speed !== null &&
                                  postDisturbanceDetails?.climate
                                    ?.avg_Wind_Speed !== undefined
                                ) {
                                  const nearestValue = values.reduce(
                                    (prev, curr) => {
                                      return Math.abs(
                                        curr -
                                          postDisturbanceDetails.climate
                                            .avg_Wind_Speed
                                      ) <
                                        Math.abs(
                                          prev -
                                            postDisturbanceDetails.climate
                                              .avg_Wind_Speed
                                        )
                                        ? curr
                                        : prev;
                                    }
                                  );
                                  highlighted = value === nearestValue;
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      highlighted ? "activetype_td" : ""
                                    }
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {/**************Air Quality Index*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Air Quality Index</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_aqibl">
                          <tbody>
                            <tr>
                              <td>AQI</td>
                              <td
                                className={
                                  postDisturbanceDetails?.climate
                                    ?.air_Quality_Index >= 0 &&
                                  postDisturbanceDetails?.climate
                                    ?.air_Quality_Index <= 50
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0-50
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.climate
                                    ?.air_Quality_Index >= 51 &&
                                  postDisturbanceDetails?.climate
                                    ?.air_Quality_Index <= 100
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                51-100
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.climate
                                    ?.air_Quality_Index >= 101 &&
                                  postDisturbanceDetails?.climate
                                    ?.air_Quality_Index <= 200
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                101-200
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.climate
                                    ?.air_Quality_Index >= 201 &&
                                  postDisturbanceDetails?.climate
                                    ?.air_Quality_Index <= 300
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                201-300
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.climate
                                    ?.air_Quality_Index > 300
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                Beyond 300
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Avg. No of Hrs. of Sunlight Availability in Summer*****************/}
                    <div className="climatedetails_item lasttwoheading_maxwidth">
                      <div className="climatedetails_heading">
                        <h3>
                          Avg. No of Hrs. of Sunlight Availability in Summer
                        </h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table className="prosumarytbl sitedet_avghrsslightinsummerbl">
                          <tbody>
                            <tr>
                              <td>Hrs.</td>
                              {Array.from({ length: 12 }, (_, i) => {
                                const value = i + 1;
                                let highlighted = false;
                                if (
                                  postDisturbanceDetails?.climate
                                    ?.avg_No_Hours_Sunlight_Avail_Summer !==
                                    null &&
                                  postDisturbanceDetails?.climate
                                    ?.avg_No_Hours_Sunlight_Avail_Summer !==
                                    undefined
                                ) {
                                  highlighted =
                                    Math.abs(
                                      value -
                                        postDisturbanceDetails.climate
                                          .avg_No_Hours_Sunlight_Avail_Summer
                                    ) <= 0.5;
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      highlighted ? "activetype_td" : ""
                                    }
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {/**************Avg. No of Hrs. of Sunlight Availability in Winter*****************/}
                    <div className="climatedetails_item lasttwoheading_maxwidth">
                      <div className="climatedetails_heading">
                        <h3>
                          Avg. No of Hrs. of Sunlight Availability in Winter
                        </h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table className="prosumarytbl sitedet_avghrsslightinwinterbl">
                          <tbody>
                            <tr>
                              <td>Hrs.</td>
                              {Array.from({ length: 12 }, (_, i) => {
                                const value = i + 1;
                                let highlighted = false;
                                if (
                                  postDisturbanceDetails?.climate
                                    ?.avg_No_Hours_Sunlight_Avail_Winter !==
                                    null &&
                                  postDisturbanceDetails?.climate
                                    ?.avg_No_Hours_Sunlight_Avail_Winter !==
                                    undefined
                                ) {
                                  highlighted =
                                    value ===
                                    postDisturbanceDetails.climate
                                      .avg_No_Hours_Sunlight_Avail_Winter;
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      highlighted ? "activetype_td" : ""
                                    }
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="floratype_cont climatewatercontent_wrap">
                  <div className="faflmicTypes_heading">Water</div>
                  <div className="climatedetails_cont waterdetails_cont">
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Turbidity</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table className="prosumarytbl water_turbiditytbl">
                          <tbody>
                            <tr>
                              {Array.from({ length: 4 }, (_, i) => {
                                const value = i * 25;
                                let highlighted = false;
                                if (
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Turbidity_Value !== null &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Turbidity_Value !== undefined
                                ) {
                                  if (
                                    i === 0 &&
                                    postDisturbanceDetails.water[0]
                                      .post_Turbidity_Value >= 0 &&
                                    postDisturbanceDetails.water[0]
                                      .post_Turbidity_Value <= 24
                                  ) {
                                    highlighted = true;
                                  } else if (
                                    i === 1 &&
                                    postDisturbanceDetails.water[0]
                                      .post_Turbidity_Value > 24 &&
                                    postDisturbanceDetails.water[0]
                                      .post_Turbidity_Value <= 49
                                  ) {
                                    highlighted = true;
                                  } else if (
                                    i === 2 &&
                                    postDisturbanceDetails.water[0]
                                      .post_Turbidity_Value > 49 &&
                                    postDisturbanceDetails.water[0]
                                      .post_Turbidity_Value <= 74
                                  ) {
                                    highlighted = true;
                                  } else if (
                                    i === 3 &&
                                    postDisturbanceDetails.water[0]
                                      .post_Turbidity_Value > 74
                                  ) {
                                    highlighted = true;
                                  }
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      highlighted ? "activetype_td" : ""
                                    }
                                  >
                                    {value}%
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont">
                          <span>High</span>
                          <span>Medium</span>
                          <span>Low</span>
                        </div>
                      </div>
                    </div>
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Taste and Odor</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl water_tastenodertbl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Taste_Odor_Value >= 0 &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Taste_Odor_Value <= 50
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Taste_Odor_Value >= 51 &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Taste_Odor_Value <= 100
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                50-100
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Taste_Odor_Value >= 101 &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Taste_Odor_Value <= 200
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                200
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Taste_Odor_Value >= 201 &&
                                  postDisturbanceDetails?.water
                                    ?.post_Taste_Odor_Value <= 300
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                300
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Taste_Odor_Value >= 301 &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Taste_Odor_Value <= 400
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                400
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Taste_Odor_Value >= 401
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                500+
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont water_tastenodertblfoot">
                          <span>
                            High <br />
                            Purity
                          </span>
                          <span>
                            Relatively <br />
                            High <br />
                            Purity
                          </span>
                          <span>
                            General <br />
                            Purity
                          </span>
                          <span>
                            Lime <br />
                            scale
                          </span>
                          <span>
                            Rough <br />
                            Taste
                          </span>
                          <span>Harmful</span>
                        </div>
                      </div>
                    </div>
                    {/*****************Hardness******************* */}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Hardness</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <div className="waterhardnesstbls_wrap">
                          <div>mg/L & ppm</div>
                          <table class="prosumarytbl water_hardnesstbl">
                            <tbody>
                              <tr>
                                <td
                                  className={
                                    postDisturbanceDetails?.water[0]
                                      ?.post_Hardness_Value <= 75
                                      ? "activetype_td"
                                      : ""
                                  }
                                >
                                  High
                                </td>

                                <td
                                  className={
                                    postDisturbanceDetails?.water[0]
                                      ?.post_Hardness_Value >= 75 &&
                                    postDisturbanceDetails?.water
                                      ?.post_Hardness_Value <= 150
                                      ? "activetype_td"
                                      : ""
                                  }
                                >
                                  75-150
                                </td>
                                <td
                                  className={
                                    postDisturbanceDetails?.water[0]
                                      ?.post_Hardness_Value >= 150 &&
                                    postDisturbanceDetails?.water[0]
                                      ?.post_Hardness_Value <= 300
                                      ? "activetype_td"
                                      : ""
                                  }
                                >
                                  150-300
                                </td>
                                <td
                                  className={
                                    postDisturbanceDetails?.water[0]
                                      ?.post_Hardness_Value >= 300
                                      ? "activetype_td"
                                      : ""
                                  }
                                >
                                  300+
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="waterhardnesstbls_wrap">
                          <div>mg/L & ppm</div>
                          <table class="prosumarytbl water_hardnesstbl">
                            <tbody>
                              <tr>
                                <td>0 - 4</td>
                                <td>4 - 8.5</td>
                                <td>8.5 - 17</td>
                                <td>17+</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="watertblunits_cont water_hardnesstblfoot">
                          <span>Soft</span>
                          <span>Slightly Hard</span>
                          <span>Hard</span>
                          <span>Very Hard</span>
                        </div>
                      </div>
                    </div>
                    {/*****************pH Scale******************* */}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>pH Scale</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl water_phscaletbl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  0
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  1
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                1
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  2
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                2
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  3
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                3
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  4
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                4
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  5
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                5
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  6
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                6
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  7
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                7
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  8
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                8
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  9
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                9
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  10
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                10
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  11
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                11
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  12
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                12
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  13
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                13
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]?.post_PH ===
                                  14
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                14
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont water_tastenodertblfoot">
                          <span>Acidic</span>
                          <span>Neutral</span>
                          <span>Alkaline</span>
                        </div>
                      </div>
                    </div>
                    {/**************average temperature*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Temperature (°C)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table className="prosumarytbl prosumartbltemp sitedet_avgtempbl">
                          <tbody>
                            <tr>
                              <td>°C</td>
                              {Array.from({ length: 9 }, (_, i) => {
                                const value = i * 10 - 20;
                                let highlighted = false;
                                if (
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Temperature !== null &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Temperature !== undefined
                                ) {
                                  const diff = Math.abs(
                                    postDisturbanceDetails.water[0]
                                      .post_Temperature - value
                                  );
                                  highlighted = diff <= 5;
                                }
                                return (
                                  <td
                                    key={value}
                                    className={
                                      highlighted ? "activetype_td" : ""
                                    }
                                  >
                                    {value}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Biochemical Oxygen Demand (BOD) (in mgL)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Biochemical Oxygen Demand (BOD) (in mgL)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl water_hardnesstbl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Biochem_Oxygen_Demand >= 1 &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Biochem_Oxygen_Demand <= 2
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                1 - 2
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Biochem_Oxygen_Demand >= 3 &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Biochem_Oxygen_Demand <= 5
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                3 - 5
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Biochem_Oxygen_Demand >= 6 &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Biochem_Oxygen_Demand <= 9
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                6 - 9
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Biochem_Oxygen_Demand >= 100
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                100 or more
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/**************Dissolved Oxygen (DO) (in mgL)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Dissolved Oxygen (DO) (in mgL)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_aqibl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Dissolved_Oxygen >= 81 &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Dissolved_Oxygen <= 100
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                81-100
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Dissolved_Oxygen >= 61 &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Dissolved_Oxygen <= 80
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                61-80
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Dissolved_Oxygen >= 41 &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Dissolved_Oxygen <= 60
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                41-60
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Dissolved_Oxygen >= 21 &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Dissolved_Oxygen <= 40
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                21-40
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Dissolved_Oxygen >= 1 &&
                                  postDisturbanceDetails?.water[0]
                                    ?.post_Dissolved_Oxygen <= 20
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                1-20
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont water_tastenodertblfoot">
                          <span>Excellent</span>
                          <span>Fair</span>
                          <span>Very Poor</span>
                        </div>
                      </div>
                    </div>
                    {/**************Electrical Conductivity*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Electrical Conductivity</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_aqibl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_WElectrical_Conductivity === "Low"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                Low
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_WElectrical_Conductivity === "Medium"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                Medium
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.water[0]
                                    ?.post_WElectrical_Conductivity === "High"
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                High
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="floratype_cont climatewatercontent_wrap">
                  <div className="faflmicTypes_heading">
                    Water Toxic Substances
                  </div>
                  <div className="watertoxicsubstance_wrap">
                    <div className="watertoxicsubstance_cont">
                      <div className="watertoxicsubstance_left">Organic</div>
                      <div className="watertoxicsubstance_right">
                        <h3>Dioxins and furans</h3>
                        <p>
                          Most common form of water pollution. Originate from
                          domestic sewage, industrial effluents and farm wastes.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="floratype_cont climatewatercontent_wrap soildetailscontent_wrap">
                  <div className="faflmicTypes_heading">Soil</div>
                  <div className="soilswrap">
                    <div>
                      <img src={TextureSoilImg} alt="Texture" />
                      Texture{" "}
                      <b>
                        {postDisturbanceDetails?.soil[0]?.post_Soil_Textures
                          ? postDisturbanceDetails?.soil[0]?.post_Soil_Textures
                          : "-"}
                      </b>
                    </div>
                    <div>
                      <img src={StructureSoilImg} alt="Structure" />
                      Structure{" "}
                      <b>
                        {postDisturbanceDetails?.soil[0]?.post_Soil_Structure
                          ? postDisturbanceDetails?.soil[0]?.post_Soil_Structure
                          : "-"}{" "}
                        (crumbly)
                      </b>
                    </div>
                    <div>
                      <img src={PorocitySoilImg} alt="Porocity" />
                      Porocity{" "}
                      <b>
                        {postDisturbanceDetails?.soil[0]?.post_Soil_Porosity
                          ? postDisturbanceDetails?.soil[0]?.post_Soil_Porosity
                          : "-"}
                      </b>
                    </div>
                    <div>
                      <img src={ColorSoilImg} alt="Color" />
                      Color{" "}
                      <b>
                        {postDisturbanceDetails?.soil[0]?.post_Soil_Color
                          ? postDisturbanceDetails?.soil[0]?.post_Soil_Color
                          : "-"}
                      </b>
                    </div>
                  </div>
                  <div className="climatedetails_cont waterdetails_cont">
                    {/*****************Soil pH******************* */}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Soil pH</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl water_phscaletbl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 0
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 1
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                1
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 2
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                2
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 3
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                3
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 4
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                4
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 5
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                5
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 6
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                6
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 7
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                7
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 8
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                8
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 9
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                9
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 10
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                10
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 11
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                11
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 12
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                12
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 13
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                13
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Soil_pH === 14
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                14
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont water_tastenodertblfoot">
                          <span>Acidic</span>
                          <span>Neutral</span>
                          <span>Alkaline</span>
                        </div>
                      </div>
                    </div>
                    {/**************Nitrate (mg/kg)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Nitrate (mg/kg)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_aqibl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Nitrate >= 0 &&
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Nitrate <= 15
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0-15
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Nitrate >= 16 &&
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Nitrate <= 40
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                16-40
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Nitrate >= 41 &&
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Nitrate <= 50
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                40-50
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Nitrate >= 51
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                60+
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont water_tastenodertblfoot">
                          <span>Low</span>
                          <span>Medium</span>
                          <span>High</span>
                          <span>Very High</span>
                        </div>
                      </div>
                    </div>
                    {/**************Phosphate (PPA)*****************/}
                    <div className="climatedetails_item">
                      <div className="climatedetails_heading">
                        <h3>Phosphate (PPA)</h3>
                      </div>
                      <div className="climatedetails_tblcontent">
                        <table class="prosumarytbl sitedet_aqibl">
                          <tbody>
                            <tr>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Phosphate >= 0 &&
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Phosphate <= 35
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                0-35
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Phosphate >= 36 &&
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Phosphate <= 50
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                35-50
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Phosphate >= 51 &&
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Phosphate <= 80
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                50-80
                              </td>
                              <td
                                className={
                                  postDisturbanceDetails?.soil[0]
                                    ?.post_Phosphate >= 81
                                    ? "activetype_td"
                                    : ""
                                }
                              >
                                80+
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="watertblunits_cont water_tastenodertblfoot">
                          <span>Low</span>
                          <span>Medium</span>
                          <span>High</span>
                          <span>Very High</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="floratype_cont climatewatercontent_wrap">
                  <div className="faflmicTypes_heading">Social</div>
                  <div className="socialwrap">
                    <div className="socialtop_cont">
                      <div className="socialtop_left">
                        <div>
                          <span className="social_counts">
                            {" "}
                            {postDisturbanceDetails?.social?.no_Families
                              ? postDisturbanceDetails?.social?.no_Families
                              : "-"}
                          </span>
                          <span className="social_content">
                            No. of Families
                          </span>
                        </div>
                        <div>
                          <span className="social_counts">
                            {" "}
                            {postDisturbanceDetails?.social?.avg_Family_Size
                              ? postDisturbanceDetails?.social?.avg_Family_Size
                              : "-"}
                          </span>
                          <span className="social_content">
                            Average Family Size
                          </span>
                        </div>
                        <div>
                          <span className="social_counts">
                            {" "}
                            {postDisturbanceDetails?.social
                              ?.avg_Annual_Inc_Family_Farming
                              ? postDisturbanceDetails?.social
                                  ?.avg_Annual_Inc_Family_Farming
                              : "-"}
                          </span>
                          <span className="social_content">
                            Average Annual Income per Family by Farming
                            <abbr>
                              (for the land parcels included in this project)
                            </abbr>
                          </span>
                        </div>
                        <div>
                          <span className="social_counts">
                            {" "}
                            {postDisturbanceDetails?.social
                              ?.avg_Annual_Inc_Family_Other_Sources
                              ? postDisturbanceDetails?.social
                                  ?.avg_Annual_Inc_Family_Other_Sources
                              : "-"}
                          </span>
                          <span className="social_content">
                            Average Annual Income per Family by Other Sources
                          </span>
                        </div>
                      </div>
                      <div className="socialtop_right">
                        <img src={FamilyImg} alt="FamilyImg" />
                      </div>
                    </div>
                    <div className="socialbot_cont">
                      <div className="socialbot_left">
                        <div className="socialbot_heading">Gender Ratio</div>
                        <div className="socialbotiner_left">
                          <img src={FemaleImg} alt="FamilyImg" />
                          <span className="socialbot_contenthead">Female</span>
                          <span className="socialbot_count">
                            {postDisturbanceDetails?.social?.gender
                              ? postDisturbanceDetails?.social?.gender
                              : "-"}
                          </span>
                        </div>
                        <div className="socialbotiner_right">
                          <img src={MaleImg} alt="FamilyImg" />
                          <span className="socialbot_contenthead">Male</span>
                          <span className="socialbot_count">
                            {postDisturbanceDetails?.social?.gender
                              ? postDisturbanceDetails?.social?.gender
                              : "-"}
                          </span>
                        </div>
                      </div>
                      <div className="socialbot_right">
                        <div className="socialbot_heading">Literacy Rate</div>
                        <div className="socialbotiner_left">
                          <span className="socialbot_contenthead">Overall</span>
                          <span className="socialbot_count">
                            {" "}
                            {postDisturbanceDetails?.social
                              ?.literacy_Rate_Overall
                              ? postDisturbanceDetails?.social
                                  ?.literacy_Rate_Overall
                              : "-"}
                          </span>
                        </div>
                        <div className="socialbotiner_right">
                          <img src={FemaleImg} alt="FamilyImg" />
                          <span className="socialbot_contenthead">Female</span>
                          <span className="socialbot_count">
                            {" "}
                            {postDisturbanceDetails?.social
                              ?.literacy_Rate_Female
                              ? postDisturbanceDetails?.social
                                  ?.literacy_Rate_Female
                              : "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === "Disturbance Details" && (
            <div className="tabs-content sitedetdistbnce_tabscontent">
              <div className="sitedetailsaccord_heading sitedetdisturbance_heading">
                Disturbances Occurred
              </div>
              <div className="prosummarylist sitedetdisturbance_list">
                {disturbanceDetails?.disturbances_Occurred &&
                disturbanceDetails?.disturbances_Occurred.length > 0 ? (
                  disturbanceDetails.disturbances_Occurred.map((item, i) => (
                    <div key={i} className="sitedetdisturbance_item">
                      <div className="prosummarylist_item">
                        <div className="produmitem_title">
                          Type of Cause<abbr>:&nbsp;</abbr>
                        </div>
                        <div className="produmitem_content">
                          {typeofCause?.filter(
                            (ss) => ss.mainGuId === item.type_Cause
                          )[0]?.description || "-"}
                        </div>
                      </div>

                      <div className="prosummarylist_item">
                        <div className="produmitem_title">
                          Exact Cause<abbr>:&nbsp;</abbr>
                        </div>
                        <div className="produmitem_content">
                          {exactCause?.filter(
                            (ss) => ss.subGuId === item.exact_Cause
                          )[0]?.description || "-"}
                        </div>
                      </div>
                      <div className="prosummarylist_item">
                        <div className="produmitem_title">
                          Frequency<abbr>:&nbsp;</abbr>
                        </div>
                        <div className="produmitem_content">
                          {frequency?.filter(
                            (ss) => ss.mainGuId === item.frequency
                          )[0]?.description || "-"}
                        </div>
                      </div>
                      <div className="prosummarylist_item">
                        <div className="produmitem_title">
                          Start Month<abbr>:&nbsp;</abbr>
                        </div>
                        <div className="produmitem_content">
                          {item.start_Month
                            ? new Date(item.start_Month)
                                .toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                })
                                .toUpperCase()
                            : "-"}
                        </div>
                      </div>
                      <div className="prosummarylist_item">
                        <div className="produmitem_title">
                          End Month<abbr>:&nbsp;</abbr>
                        </div>
                        <div className="produmitem_content">
                          {item.end_Month
                            ? new Date(item.end_Month)
                                .toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                })
                                .toUpperCase()
                            : "-"}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="norecodfoundprosum_cont">No Record Found</div>
                )}
              </div>

              <div className="sitedetailsaccord_heading sitedetdisturbance_heading">
                Disturbance Impact
              </div>
              <div className="prosummarylist sitedetdisturbance_list sitedetdisturbanceimct_list">
                {disturbanceDetails?.disturbanceImpact?.disturbanceImpactList &&
                disturbanceDetails?.disturbanceImpact?.disturbanceImpactList
                  .length > 0 ? (
                  disturbanceDetails.disturbanceImpact.disturbanceImpactList.map(
                    (item, i) => (
                      <div key={i} className="sitedetdisturbance_item">
                        <div className="prosummarylist_item">
                          <div className="produmitem_title">
                            Impact Severity (As a Collective Result of all
                            Disturbances Occurred)<abbr>:&nbsp;</abbr>
                          </div>
                          <div className="produmitem_content">
                            {impactSeverity?.filter(
                              (ss) => ss.mainGuId === item.impact_Severity
                            )[0]?.description || "-"}
                          </div>
                        </div>

                        <div className="prosummarylist_item">
                          <div className="produmitem_title">
                            Area Impacted (Including whole Project Area) (In
                            Hectare)<abbr>:&nbsp;</abbr>
                          </div>
                          <div className="produmitem_content">
                            {item.area_Impacted}
                          </div>
                        </div>
                        <div className="prosummarylist_item">
                          <div className="produmitem_title">
                            Land Parcels Impacted<abbr>:&nbsp;</abbr>
                          </div>
                          <div className="produmitem_content">
                            {landParcelsList?.filter(
                              (ss) =>
                                ss.landParcelId === item.land_Parcels_Impacted
                            )[0]?.title || "-"}
                          </div>
                        </div>
                        <div className="prosummarylist_item">
                          <div className="produmitem_title">
                            Water Bodies Impacted<abbr>:&nbsp;</abbr>
                          </div>
                          <div className="produmitem_content">
                            {waterBodiesList?.filter(
                              (ss) =>
                                ss.waterBodyId === item.water_Bodies_Impacted
                            )[0]?.title || "-"}
                          </div>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div className="norecodfoundprosum_cont">No Record Found</div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
