import React, { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Polygon } from "@react-google-maps/api";

const libraries = ["drawing"];
const MIN_ZOOM_LEVEL = 3;

export default function ProjectSummaryMap(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: props.GoogleMapApiKey,
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <ProjectSummaryMaps
      noLandParcels={props.noLandParcels}
      landParcelList={props.landParcelList}
      LocationCords={props.LocationCords}
    />
  );
}

function ProjectSummaryMaps({ noLandParcels, landParcelList, LocationCords }) {
  const [zoomLevel, setZoomLevel] = useState(MIN_ZOOM_LEVEL);
  const [center, setCenter] = useState({
    lat: 15.2501627762245,
    lng: 74.0782077379068,
  });
  const [polygon, setPolygon] = useState(null);

  useEffect(() => {
    if (landParcelList && landParcelList.length > 0) {
      const landAlt = landParcelList.map((x) => x.locationAltitude)[0];
      const avgLat = calculateAverage(landAlt.map((x) => x.lat));
      const avgLng = calculateAverage(landAlt.map((x) => x.lng));
      setCenter({ lat: avgLat, lng: avgLng });
    }
  }, [landParcelList]);

  useEffect(() => {
    if (LocationCords && LocationCords.length > 0) {
      getPolyCenter(LocationCords);
    }
  }, [LocationCords]);

  const calculateAverage = (array) =>
    array.reduce((total, item) => total + item, 0) / array.length;

  const getPolyCenter = (boundryCords) => {
    const cords = ConvertToLatLong(boundryCords);
    if (cords.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      cords.forEach((cord) =>
        bounds.extend(new window.google.maps.LatLng(cord.lat, cord.lng))
      );
      const centerCoord = bounds.getCenter();
      setCenter(centerCoord);
      setZoomLevel(15);
    }
  };

  const ConvertToLatLong = (locationCords) => {
    const latLongArray = [];
    for (let i = 0; i < locationCords.length; i += 2) {
      latLongArray.push({ lat: locationCords[i + 1], lng: locationCords[i] });
    }
    return latLongArray;
  };

  const mapOption = {
    mapTypeControl: false,
    mapTypeId: window.google.maps.MapTypeId.HYBRID,
    mapTypeControlOptions: {
      style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: window.google.maps.ControlPosition.LEFT_BOTTOM,
      mapTypeIds: [
        window.google.maps.MapTypeId.ROADMAP,
        window.google.maps.MapTypeId.SATELLITE,
        window.google.maps.MapTypeId.HYBRID,
      ],
    },
  };

  const options = {
    strokeWeight: 2,
    strokeOpacity: 1,
    strokeColor: "#0000ff",
    fillOpacity: 0,
    fillColor: "#333d72",
  };

  return (
    <GoogleMap
      options={mapOption}
      zoom={zoomLevel}
      center={center}
      mapContainerStyle={mapStyle.map_container}
    >
      {LocationCords && LocationCords.length > 0 ? (
        <Polygon options={options} paths={ConvertToLatLong(LocationCords)} />
      ) : (
        <p>No coordinates available</p>
      )}
    </GoogleMap>
  );
}

const mapStyle = {
  map_container: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
};
