import config from "../../../../utils/config";

async function getLayersData(id) {
  try {
    let projectId = id;
    let res = await fetch(
      `${config.API_HOST_URL}/api/Ecosystem/GetLayersByProjectId/${projectId}`,
      // `${"http://localhost:65505"}/api/Ecosystem/GetLayersByProjectId/${projectId}`,
      {
        method: "GET",
        cache: "no-store",
      }
    );

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    let data = await res.json();
    console.log("API response:", data);
    return data;
  } catch (error) {
    console.error("Error fetching layers data:", error);
    throw error;
  }
}
export default getLayersData;
