import config from "../../../../utils/config";

async function S3KmlnData(projectName, data) {
  try {
    let response = await fetch(
      `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/` +
        projectName +
        "/" +
        data
    );

    // let response = await fetch(
    //   `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/Goa%20Pilot%20Project/` +
    //     data
    // );
    return response;
  } catch (error) {
    console.error(error);
  }
}

export default S3KmlnData;
