/**
 * Author: Ranjeet Gupta
 * Description: website config file for application.
 */
// Alpha---Use below for alpha
// export default {
//     AUTH_ID: 'Grow_Microservice',
//     AUTH_SECRET: 'Grow_Microservice_Secret',
//     API_HOST_URL: 'http://localhost:51152',
//     //API_HOST_URL: 'https://growalphams.greenhimalayas.org',
//     Amazon_WebService_Url: "",
//     Amazon_WebService_BucketName: "grow.alpha",
//     Amazon_WebService_CDNUrl: "",

// }

//Beta---Use below for beta
// export default {
//   AUTH_ID: "Grow_Microservice",
//   AUTH_SECRET: "Grow_Microservice_Secret",
//   //API_HOST_URL: "http://localhost:65505",
//   API_HOST_URL: "https://betams.treeties.co.in",
//   Amazon_WebService_Url: "https://s3.ap-south-1.amazonaws.com",
//   Amazon_WebService_BucketName: "grow.beta",
//   Amazon_WebService_CDNUrl: "https://d3kn46kx26n24t.cloudfront.net",
//   BASE_URL: "https://beta.treeties.co.in",
// };

// Live---Use below for live
export default {
  AUTH_ID: "Grow_Microservice",
  AUTH_SECRET: "Grow_Microservice_Secret",
  //API_HOST_URL: 'http://localhost:65505',
  API_HOST_URL: "https://backendms.treeties.co.in",
  Amazon_WebService_Url: "https://s3.ap-south-1.amazonaws.com",
  Amazon_WebService_BucketName: "grow.live",
  Amazon_WebService_CDNUrl: "https://d1ejsf91vocktq.cloudfront.net",
  BASE_URL: "https://treeties.co.in",
 //DateFormat: "MMMM DD, YYYY",
 };

