import React, { useState, useCallback, useEffect } from "react";
import { Modal, Button } from "@patternfly/react-core";
import config from "../../../utils/config";
import { Controller, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import ToastNotification from "../../Common/ToastNotification/ToastNotification";


export default function InstitutionalSponsorsModal(props) {
  const [isModalOpen, setIsModalOpen] = useState(props.IsOpen);
  const [addButton, setAddButton] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [errorMapbound, setErrorMapbound] = useState("");
 const [userList, setUserList] = useState([]);
 const [formPointData, setPointFormData] = useState({});
 const [nGOName, setNGOName] = useState("");
 const [savedData, setSavedData] = useState([]);
 const [formData, setFormData] = useState({
  UserId: '',
  Email: '',
  NameOfNGO: '',
  NGOHeadName:'',
  TypeOfIncorporation: '',
  Mobile : ''
});

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };
  const closeTooltip = () => {
    setShowTooltip(false);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm();

  useEffect(() => {
    GetUserList();
  }, []);
useEffect(() => {
  reset(formData); // Reset form values when formData changes
}, [formData, reset]);

  useEffect(() => {
    if (props.selectedInstitutionalSponsor !== null) {
      setFormData({
        UserId: props.selectedInstitutionalSponsor.UserId,
        Email: props.selectedInstitutionalSponsor.Email,
        Mobile: props.selectedInstitutionalSponsor.Mobile ,
        NameOfNGO: props.selectedInstitutionalSponsor.NameOfNGO,
        NGOHeadName:  props.selectedInstitutionalSponsor.NGOHeadName,
        TypeOfIncorporation: props.selectedInstitutionalSponsor.TypeOfIncorporation,
      });
    }
  }, [props.selectedInstitutionalSponsor]); 
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePointInputChange=(e)=>{
    const name = e.target.name;
    const value = e.target.value;
    let val={
      [name]:value
    }
    setPointFormData({
      ...formPointData,
      [name]:value
    })
  }

  // const handlePointOfEmail=(e)=>{
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setPointOfEmail(value);
  // }
  
  const handleModalToggle = useCallback(() => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  }, [setIsModalOpen]);
  const onSubmit = async (data) => {
    let data1={
                UserId : formData.UserId,
                Email:formData.Email,
                NameOfNGO:formData.NameOfNGO,
                TypeOfIncorporation:formData.TypeOfIncorporation,
                NGOHeadName:formData.NGOHeadName,
                Mobile:formData.Mobile
               }
               const url = `${config.API_HOST_URL}/api/Ecosystem/AddUpdateInstitutionalUser`;
               fetch(url, {
                 method: "POST",
                 headers: {
                   "Content-Type": "application/json",
                 },
                 body: JSON.stringify(data1),
               })
                 .then((response) => response.json())
                 .then((response) => {
                 
                  if (response.success === false) {
                    ToastNotification.ShowError(response.message);
                    throw new Error(`HTTP error! status: ${response.status}`);
                  } else {
                      ToastNotification.ShowSuccess(response.message);
                      handleCancel();
                      props.getInstitutionalSponsorList();
                    return response.json();
                  }
            
                 })
                 .catch((error) => {
                   console.error("Error in request", error);
                 });
   };

  const handleCancel = () => {
    props.setIsPopUpStatus(false);
    handleModalToggle(false);
    props.propsCleardata();
  };

  const GetUserList = () => {
    let url = `${config.API_HOST_URL}/api/Login/GetUserList`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = JSON.parse(data.UserList);
        setUserList(responseData);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };


  // const handleAddData = () => {

  //   console.log("formPointData",formPointData)
  //   debugger
  //   if (
  //     formPointData.ContactName ||
  //     formPointData.Email ||
  //     formPointData.Mobile ||
  //     formPointData.ContactDesignation
  //   ) {
  //     const newData = {
  //       ContactName: formPointData.ContactName,
  //       Mobile: formPointData.Mobile,
  //       Email: formPointData.Email,//pointOfEmail
  //       ContactDesignation: formPointData.ContactDesignation,
  //     };
  //     console.log("newdata", newData);
  //     setSavedData((prevSavedData) => [...prevSavedData, newData]); // Use prevSavedData to maintain the existing data
  //     setAddButton(false);
  //   }
  // };
  
  const handleTypeOfIncorporation = (event) => {
    setFormData({
      UserId: formData.UserId ? formData.UserId:"",
      Email: formData.Email ,
      Mobile: formData.Mobile ,
      NameOfNGO: formData.NameOfNGO ,
      NGOHeadName:  formData.NGOHeadName ,
      TypeOfIncorporation: event.target.value 
    });
  };
  return (
    <>
      <Modal
        title={
          props.selectedInstitutionalSponsor
            ? "Update Partnership Sponsors Details"
            : "Add Partnership Sponsors Details"
          
        }
        isOpen={isModalOpen}
        onClose={() => handleModalToggle(false)}
        className="addproject_modal fieldofficer_modal timeseriesform_modal instsponsor_modal"
        actions={[
          <Button
            key="submit"
            // type="submit"
            className="modal_btn submit_btn"
            onClick={handleSubmit(onSubmit)}
          >
            {props.selectedInstitutionalSponsor ? "Update" : "Submit"}
            {/* Submit */}
          </Button>,
          <Button
            key="cancel"
            className="modal_btn cancel_btn"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
          <ul className="addproject_form">
            <li className="form-group field">
              <Controller
                control={control}
                name="Email"
                rules={{
                  required: "Please Enter Email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address!",
                  },
                  validate: (value) => {
                    const currentUserId = formData?.userId;
                    const userEmails =props.InstitutionalSponsorList
                      .filter(
                        (item) =>
                          item.UserId !== currentUserId && item.Email !== null
                      )
                      .map((item) => item.Email);
                        if(props.selectedInstitutionalSponsor === null){
                        return userEmails.includes(value)
                        ? "Email address is already in use"
                        : undefined;
                      }
                  
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      name="Email"
                      id="Email"
                      autocomplete="off"
                      autofill="off"
                      data-random={Math.random()}
                      value={formData?.Email ? formData?.Email : ""}
                      placeholder="Enter Email"
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="Email">
                      <sup className="mandatory">*</sup>Enter Email
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
            <Controller
                control={control}
                name="NameOfNGO"
                rules={{
                  required: "Please Enter NGO Name",
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Only alphabets are allowed!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      name="NameOfNGO"
                      id="NameOfNGO"
                      value={formData?.NameOfNGO ? formData?.NameOfNGO : ""}
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (!/^[a-zA-Z\s]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Enter NGO Name"
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="NameOfNGO">
                      <sup className="mandatory">*</sup>Enter NGO Name
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>

          <li className="form-group field">
              <Controller
                control={control}
                name="TypeOfIncorporation"
                rules={{
                  required: "Please Select Type Of Incorporation",
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <select
                      id="TypeOfIncorporation"
                      name="TypeOfIncorporation"
                      className="dropdown-130"
                      value={formData?.TypeOfIncorporation ? formData?.TypeOfIncorporation : ""}
                      onChange={(event) => {
                        handleTypeOfIncorporation(event);
                        onChange(event);
                      }}
                    >
                       <option value="" selected="selected">
                       Select
                       </option>
                        {props.uniqueTypeOfIncorporation
                        .map((item, index) => (
                          <option  key={index} value={item}>
                            {item}
                          </option>
                        ))}
                    </select>

                    <label for="TypeOfIncorporation">
                      <sup className="mandatory">*</sup>Select Type Of Incorporation
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>

            <li className="form-group field">
              <Controller
                control={control}
                name="NGOHeadName"
                rules={{
                  required: "Please NGO Head Name",
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Only alphabets are allowed!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      name="NGOHeadName"
                      id="NGOHeadName"
                      value={formData?.NGOHeadName ? formData?.NGOHeadName : ""}
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (!/^[a-zA-Z\s]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Enter NGO Head Name"
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="NGOHeadName">
                      <sup className="mandatory">*</sup>Enter NGO Head Name
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>

            <li className="form-group field">
              <Controller
                control={control}
                name="Mobile"
                rules={{
                  required: "Please Enter Mobile Number",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Only numeric characters are allowed!",
                  },
                  minLength: {
                    value: 10,
                    message: "Contact must be at least 10 digits long!",
                  },
                  maxLength: {
                    value: 10,
                    message: "Contact must not be more than 10 digits long!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="tel"
                      id="Mobile"
                      name="Mobile"
                      value={
                        formData?.Mobile ? formData?.Mobile : ""
                      }
                      placeholder="Enter Mobile Number"
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (
                          event.target.value.length >= 10 &&
                          /\d/.test(event.key)
                        ) {
                          event.preventDefault(); // prevent entering more than 15 digits
                        }
                        if (!/^[0-9]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="mobile">
                      <sup className="mandatory">*</sup>Enter Mobile
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li> 
            {/* <li className="form-group field">
              
              <Controller
                control={control}
                name="SelectNGO"
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <>
                    <select
                      className="dropdown-130"
                      name="execution_PartnerId"
                      value={nGOName}
                      onChange={(event) => {
                        setNGOName(event.target.value);
                        onChange(event);
                      }}
                    >
                      <option value="0">Select</option>
                      {props.userData.map((item) => (
                        <option value={item.UserId}>{item.NameOfNGO}</option>
                      ))}
                    </select>
                    {error && (
                      <div className="mandatory has-error">{error.message}</div>
                    )}
                    <label htmlFor="SelectNGO">
                      <sup className="mandatory">*</sup>Select NGO
                    </label>
                  </>
                )}
              />
            </li> */}
            </ul>         
        </form>

    {/* <form>
    <ul className="addproject_form">
    <li className="bottomhr">
              <div className="forms_subheading">
                Add point of contact for institutional sponsor
              </div>
            </li>
            <li className="form-group field">
              
              <Controller
                control={control}
                name="SelectNGO"
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <>
                    <select
                      className="dropdown-130"
                      name="execution_PartnerId"
                      value={nGOName}
                      onChange={(event) => {
                        setNGOName(event.target.value);
                        onChange(event);
                      }}
                    >
                      <option value="0">Select</option>
                      {props.userData.map((item) => (
                        <option value={item.UserId}>{item.NameOfNGO}</option>
                      ))}
                    </select>
                    {error && (
                      <div className="mandatory has-error">{error.message}</div>
                    )}
                    <label htmlFor="SelectNGO">
                      <sup className="mandatory">*</sup>Select NGO
                    </label>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="ContactName"
                rules={{
                  required: "Please Contact Name",
                  pattern: {
                    value: /^[a-zA-Z]+$/,
                    message: "Only alphabets are allowed!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      name="ContactName"
                      id="ContactName"
                      value= {formPointData?.ContactName ? formPointData?.ContactName:""}//{formData?.ContactName ? formData?.ContactName : ""}
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (!/^[a-zA-Z]$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Enter Contact Name"
                      onChange={(event) => {
                        handlePointInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="ContactName">
                      <sup className="mandatory">*</sup>Enter Contact Name
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="Mobile"
                rules={{
                  required: "Please Enter Contact",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Only numeric characters are allowed!",
                  },
                  minLength: {
                    value: 10,
                    message: "Contact must be at least 10 digits long!",
                  },
                  maxLength: {
                    value: 10,
                    message: "Contact must not be more than 10 digits long!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="tel"
                      id="PointMobile"
                      name="Mobile"
                      value={formPointData?.Mobile ? formPointData?.Mobile:""}
                      placeholder="Enter Mobile Number"
                      key="mobile"
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (
                          event.target.value.length >= 10 &&
                          /\d/.test(event.key)
                        ) {
                          event.preventDefault(); // prevent entering more than 15 digits
                        }
                        if (!/^[0-9]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        handlePointInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="ContactNumber">
                      <sup className="mandatory">*</sup>Enter Mobile
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
             <Controller
                control={control}
                name="Email"
                rules={{
                  required: "Please Enter Email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address!",
                  },
                  // validate: (value) => {
                  //   const currentUserId = formData?.userId;
                  //   const userEmails = userList
                  //     .filter(
                  //       (item) =>
                  //         item.UserId !== currentUserId && item.Email !== null
                  //     )
                  //     .map((item) => item.Email);
                  //   return userEmails.includes(value)
                  //     ? "Email address is already in use"
                  //     : undefined;
                  // },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      name="Email"
                      id="PintEmail"
                      autocomplete="off"
                      autofill="off"
                      key="pointemail"
                      data-random={Math.random()}
                      value={formPointData?.Email ? formPointData?.Email:""}//{pointOfEmail}/
                      placeholder="Enter Email"
                      onChange={(event) => {
                        handlePointInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="PointEmail">
                      <sup className="mandatory">*</sup>Enter Email
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="ContactDesignation"
                rules={{
                  required: "Please Enter Contact Designation",
                  pattern: {
                    value: /^[a-zA-Z]+$/,
                    message: "Only alphabets are allowed!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      id="ContactDesignation"
                      placeholder="Enter Contact Designation"
                      name="ContactDesignation"
                      value={formPointData?.ContactDesignation ? formPointData?.ContactDesignation : ""}
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (!/^[a-zA-Z]$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        handlePointInputChange(event);
                        //handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="ContactDesignation">
                      <sup className="mandatory">*</sup>Enter Contact
                      Designation
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
          </ul>
          <a className="treedetmodal_addbtn" onClick={handleAddData}>Add Data</a>
          <div className="uploadedimgtbl_cont">
            <table>
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Contact Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Contact Designation</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {savedData?.map((data, index) => (
                <tr key={index}>
                  <td>{index+1}</td>
                  <td>{data?.ContactName}</td>
                  <td>{data?.pointOfEmail}</td>
                  <td>{data?.ContactNumber}</td>
                  <td>{data?.ContactDesignation}</td>
                  <td>
                    <a>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24px"
                        height="24px"
                      >
                        <path d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z" />
                      </svg>
                    </a>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        
    </form> */}
      </Modal>
    </>
  );
}
