import React, { useEffect, useState } from "react";
import config from "../../../utils/config";
import Pagination from "react-js-pagination";

export default function PartnershipsView(props) {
  const [partnershipData, setPartnershipData] = useState({});
  const [ngoUserList, setNGOUserList] = useState([]);
  const [associatedUserList, setAssociatedUserList] = useState([]);
  const ASSOCIATED_WITH_EXECUTION_PARTNER =
    "c82a9c95-7152-4d51-83bf-4e0fef59b218";
  const [activeTab, setActiveTab] = useState("Execution Partner");

  useEffect(() => {
    GetPartnershipList();
    GetNGOUserList();
    GetCommonMasters();
  }, [props.projectId]);

  const GetPartnershipList = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetPartnership/${props.projectId}`;
    fetch(url, {
      method: "Get",
      headers: {
        // // WebsiteGuid: this.props.websiteGUID,
        // // LanguageGuid: this.props.languageGUID,
        // // Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data != null && data.length > 0) {
          setPartnershipData(data[0]);
          console.log(partnershipData, data[0]);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetNGOUserList = () => {
    let url = `${config.API_HOST_URL}/api/Login/GetNGOUserList`;
    fetch(url, {
      method: "Get",
      headers: {
        // WebsiteGuid: this.props.websiteGUID,
        // LanguageGuid: this.props.languageGUID,
        // Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = JSON.parse(data.NGOUserList);
        setNGOUserList(responseData);
        // console.log("setNGOUserList", responseData);
        //this.GetCommonMasters();
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetCommonMasters = () => {
    let masterType = "AssociationType";
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMaster/${masterType}`;
    fetch(url, {
      method: "Get",
      headers: {
        // WebsiteGuid: this.props.websiteGUID,
        // LanguageGuid: this.props.languageGUID,
        // Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAssociatedUserList(data);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const BgColorCodes = [
    "#4BC2D2",
    "#E29955",
    "#3E6FB8",
    "#746A6A",
    "#A960CB",
    "#C65555",
    "#7E4D4D",
    "#43D07B",
    "#4E429A",
    "#AF38A3",
    "#F04E4E",
    "#445A93",
    "#7C2FCA",
    "#27A4AC",
  ];
  return (
    <div className="row prosumcordtabscont_wrap">
      <div className="col-5">
        <div className="tab-buttons">
          <button
            className={activeTab === "Execution Partner" ? "active" : ""}
            onClick={() => handleTabChange("Execution Partner")}
          >
            Execution Partner (NGO)
          </button>
          <button
            className={activeTab === "Institutional Sponsor" ? "active" : ""}
            onClick={() => handleTabChange("Institutional Sponsor")}
          >
            Institutional Sponsor
          </button>
          <button
            className={activeTab === "Individual Sponsors" ? "active" : ""}
            onClick={() => handleTabChange("Individual Sponsors")}
          >
            Individual Sponsors
          </button>
          <button
            className={activeTab === "Farmer" ? "active" : ""}
            onClick={() => handleTabChange("Farmer")}
          >
            Farmers
          </button>
          <button
            className={activeTab === "Government Entities" ? "active" : ""}
            onClick={() => handleTabChange("Government Entities")}
          >
            Government Entities
          </button>
          <button
            className={activeTab === "Legal Service Provider" ? "active" : ""}
            onClick={() => handleTabChange("Legal Service Provider")}
          >
            Legal Service Provider
          </button>
          <button
            className={activeTab === "Audit Service Provider" ? "active" : ""}
            onClick={() => handleTabChange("Audit Service Provider")}
          >
            Audit Service Provider
          </button>
        </div>
      </div>

      <div className="col-7">
        {activeTab === "Execution Partner" && (
          <div className="tabs-content">
            <div className="partnerstab_content onlyclspartnerstab_content">
              <div className="executivepartnerstrust_wrap">
                {partnershipData?.execution_Partners != null &&
                partnershipData?.execution_Partners.length > 0 ? (
                  partnershipData?.execution_Partners.map((eachPartner, i) => {
                    return (
                      <div className="executivepatners_trust" key={i}>
                        <>
                          <div
                            className="exectveprtnetrst_shrtnme"
                            style={{
                              backgroundColor:
                                BgColorCodes[i % BgColorCodes.length],
                            }}
                          >
                            {ngoUserList
                              ?.filter(
                                (ngoUser) =>
                                  ngoUser.UserId ===
                                  eachPartner.execution_PartnerId
                              )[0]
                              ?.NameOfNGO.slice(0, 1)}
                          </div>
                          <div className="exectveprtnetrst_right">
                            <div className="exectveprtnetrst_fllnme">
                              {
                                ngoUserList?.filter(
                                  (ngoUser) =>
                                    ngoUser.UserId ===
                                    eachPartner.execution_PartnerId
                                )[0]?.NameOfNGO
                              }
                            </div>
                            <div className="exectveprtnetrst_content">
                              <>
                                <span>
                                  SPOC: {eachPartner.isSpoc ? "Yes" : "No"}
                                </span>
                                <span>Personnel:</span>
                                <div className="exectveprtnetrst_spocswrap">
                                  {eachPartner.pS_EP_Personnel != null &&
                                    eachPartner.pS_EP_Personnel.map(
                                      (eachPartner, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="exectveprtnetrst_spocsitem blue"
                                          >
                                            <div
                                              className="exectveprtnetrst_spocssrtnme"
                                              style={{
                                                backgroundColor:
                                                  BgColorCodes[
                                                    index % BgColorCodes.length
                                                  ],
                                              }}
                                            >
                                              {eachPartner.name.slice(0, 1)}
                                            </div>
                                            <div className="spocshover_content">
                                              <div className="spocs_name">
                                                {eachPartner.name}
                                              </div>
                                              <div className="spocsinfo">
                                                <span>
                                                  {eachPartner.project_Role}
                                                </span>
                                                <span>
                                                  {eachPartner.mobile}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </>
                            </div>
                          </div>
                        </>
                      </div>
                    );
                  })
                ) : (
                  <div className="norecodfoundprosum_cont">No Record Found</div>
                )}
              </div>
            </div>
          </div>
        )}

        {activeTab === "Institutional Sponsor" && (
          <div className="tabs-content">
            <div className="partnerstab_content institlnaltabs_cont">
              <div className="executivepartnerstrust_wrap">
                {partnershipData?.institutional_Sponsors != null &&
                partnershipData?.institutional_Sponsors.length > 0 ? (
                  partnershipData?.institutional_Sponsors.map(
                    (eachPartner, i) => {
                      return (
                        <div className="executivepatners_trust" key={i}>
                          <>
                            <div className="exectveprtnetrst_right">
                              <div className="exectveprtnetrst_fllnme">
                                {
                                  ngoUserList?.filter(
                                    (ngoUser) =>
                                      ngoUser.UserId ===
                                      eachPartner.institutional_SponsorId
                                  )[0]?.NameOfNGO
                                }
                              </div>
                              <div className="institutionaltabs_content">
                                <div className="institutetabs_item">
                                  <span>
                                    Association Type<abbr>:&nbsp;</abbr>
                                  </span>
                                  <span>
                                    {
                                      associatedUserList.filter(
                                        (x) =>
                                          x.mainGuId ===
                                          eachPartner.associationTypeId
                                      )[0]?.description
                                    }
                                  </span>
                                </div>
                                {eachPartner.associationTypeId ===
                                  ASSOCIATED_WITH_EXECUTION_PARTNER &&
                                  ngoUserList.filter(
                                    (x) =>
                                      x.UserId ===
                                      eachPartner.execution_PartnerId
                                  )[0]?.NameOfNGO && (
                                    <div className="institutetabs_item">
                                      <span>
                                        Execution Partner (NGO)
                                        <abbr>:&nbsp;</abbr>
                                      </span>
                                      <span>
                                        {
                                          ngoUserList.filter(
                                            (x) =>
                                              x.UserId ===
                                              eachPartner.execution_PartnerId
                                          )[0]?.NameOfNGO
                                        }
                                      </span>
                                    </div>
                                  )}
                              </div>

                              <div className="exectveprtnetrst_content">
                                <span>Personnel</span>
                                <div className="exectveprtnetrst_spocswrap">
                                  {eachPartner.pS_IS_Personnel != null &&
                                    eachPartner.pS_IS_Personnel.map(
                                      (eachPartner1, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="exectveprtnetrst_spocsitem blue"
                                          >
                                            <div
                                              className="exectveprtnetrst_spocssrtnme"
                                              style={{
                                                backgroundColor:
                                                  BgColorCodes[
                                                    index % BgColorCodes.length
                                                  ],
                                              }}
                                            >
                                              {eachPartner1.name.slice(0, 1)}
                                            </div>
                                            <div className="spocshover_content">
                                              <div className="spocs_name">
                                                {eachPartner1.name}
                                              </div>
                                              <div className="spocsinfo">
                                                <span>
                                                  {eachPartner1.project_Role}
                                                </span>
                                                <span>
                                                  {eachPartner1.mobile}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div className="norecodfoundprosum_cont">No Record Found</div>
                )}
              </div>
            </div>
          </div>
        )}

        {activeTab === "Individual Sponsors" && (
          <div className="tabs-content">
            <div className="partnerstab_content institlnaltabs_cont">
              <div className="executivepartnerstrust_wrap">
                {partnershipData?.individual_Sponsors != null &&
                partnershipData?.individual_Sponsors.length > 0 ? (
                  partnershipData?.individual_Sponsors.map(
                    (eachPartner2, i) => (
                      <div key={i} className="executivepatners_trust">
                        <div className="exectveprtnetrst_right">
                          <div className="exectveprtnetrst_fllnme">
                            {ngoUserList?.filter(
                              (ngoUser) =>
                                ngoUser.UserId ===
                                eachPartner2.individual_SponsorId
                            )[0]?.NameOfNGO || "No record Found"}
                          </div>
                          <div className="institutionaltabs_content">
                            <div className="institutetabs_item">
                              <span>
                                Association Type<abbr>:&nbsp;</abbr>
                              </span>
                              <span>
                                {
                                  associatedUserList.filter(
                                    (ngoUser) =>
                                      ngoUser.mainGuId ===
                                      eachPartner2.associationTypeId
                                  )[0]?.description
                                }
                              </span>
                            </div>
                            {ngoUserList.filter(
                              (ngoUser) =>
                                ngoUser.UserId ===
                                eachPartner2.execution_PartnerId
                            )[0]?.NameOfNGO && (
                              <div className="institutetabs_item">
                                <span>
                                  Execution Partner<abbr>:&nbsp;</abbr>
                                </span>
                                <span>
                                  {
                                    ngoUserList.filter(
                                      (ngoUser) =>
                                        ngoUser.UserId ===
                                        eachPartner2.execution_PartnerId
                                    )[0]?.NameOfNGO
                                  }
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div className="norecodfoundprosum_cont">No Record Found</div>
                )}
              </div>
            </div>
          </div>
        )}

        {activeTab === "Farmer" && (
          <div className="tabs-content">
            <div className="partnerstab_content institlnaltabs_cont">
              <div className="executivepartnerstrust_wrap">
                {partnershipData?.farmers != null &&
                partnershipData?.farmers.length > 0 ? (
                  partnershipData?.farmers.map((eachPartner, i) => (
                    <div key={i} className="executivepatners_trust">
                      <div className="exectveprtnetrst_right">
                        <div className="exectveprtnetrst_fllnme">
                          {ngoUserList?.filter(
                            (ngoUser) =>
                              ngoUser.UserId === eachPartner.farmer_Id
                          )[0]?.NameOfNGO || "No record Found"}
                        </div>
                        <div className="institutionaltabs_content">
                          <div className="institutetabs_item">
                            <span>
                              Association Type<abbr>:&nbsp;</abbr>
                            </span>
                            <span>
                              {
                                associatedUserList.filter(
                                  (ngoUser) =>
                                    ngoUser.mainGuId ===
                                    eachPartner.associationType_farmer_Id
                                )[0]?.description
                              }
                            </span>
                          </div>
                          {ngoUserList.filter(
                            (ngoUser) =>
                              ngoUser.UserId ===
                              eachPartner.farmer_ExecPartnerId
                          )[0]?.NameOfNGO && (
                            <div className="institutetabs_item">
                              <span>
                                Execution Partner<abbr>:&nbsp;</abbr>
                              </span>
                              <span>
                                {
                                  ngoUserList.filter(
                                    (ngoUser) =>
                                      ngoUser.UserId ===
                                      eachPartner.farmer_ExecPartnerId
                                  )[0]?.NameOfNGO
                                }
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="norecodfoundprosum_cont">No Record Found</div>
                )}
              </div>
            </div>
          </div>
        )}

        {activeTab === "Government Entities" && (
          <div className="tabs-content">
            <div className="partnerstab_content institlnaltabs_cont">
              <div className="executivepartnerstrust_wrap">
                {partnershipData?.government_Entities != null &&
                partnershipData?.government_Entities.length > 0 ? (
                  partnershipData?.government_Entities.map((eachPartner, i) => (
                    <div key={i} className="executivepatners_trust">
                      <>
                        <div className="exectveprtnetrst_right">
                          <div className="exectveprtnetrst_fllnme">
                            {ngoUserList?.filter(
                              (ngoUser) =>
                                ngoUser.UserId === eachPartner.govern_EntitiesId
                            )[0]?.NameOfNGO || "No records found"}
                          </div>
                          <div className="institutionaltabs_content">
                            <div className="institutetabs_item">
                              <span>
                                Association Type<abbr>:&nbsp;</abbr>
                              </span>
                              <span>
                                {
                                  associatedUserList.filter(
                                    (x) =>
                                      x.mainGuId ===
                                      eachPartner.associationType_GE_Id
                                  )[0]?.description
                                }
                              </span>
                            </div>
                            {eachPartner.associationType_GE_Id ===
                              ASSOCIATED_WITH_EXECUTION_PARTNER &&
                              ngoUserList.filter(
                                (x) => x.UserId === eachPartner.ge_ExecPartnerId
                              )[0]?.NameOfNGO && (
                                <div className="institutetabs_item">
                                  <span>
                                    Execution Partner (NGO)<abbr>:&nbsp;</abbr>
                                  </span>
                                  <span>
                                    {
                                      ngoUserList.filter(
                                        (x) =>
                                          x.UserId ===
                                          eachPartner.ge_ExecPartnerId
                                      )[0]?.NameOfNGO
                                    }
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="exectveprtnetrst_content">
                            <span>Personnel:</span>
                            <div className="exectveprtnetrst_spocswrap">
                              {eachPartner.pS_GE_Personnel != null &&
                                eachPartner.pS_GE_Personnel.map(
                                  (eachPartner1, index) => (
                                    <div
                                      key={index}
                                      className="exectveprtnetrst_spocsitem blue"
                                    >
                                      <div
                                        className="exectveprtnetrst_spocssrtnme"
                                        style={{
                                          backgroundColor:
                                            BgColorCodes[
                                              index % BgColorCodes.length
                                            ],
                                        }}
                                      >
                                        {eachPartner1.name.slice(0, 1)}
                                      </div>
                                      <div className="spocshover_content">
                                        <div className="spocs_name">
                                          {eachPartner1.name}
                                        </div>
                                        <div className="spocsinfo">
                                          <span>
                                            {eachPartner1.project_Role}
                                          </span>
                                          <span>{eachPartner1.mobile}</span>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  ))
                ) : (
                  <div className="norecodfoundprosum_cont">No Record Found</div>
                )}
              </div>
            </div>
          </div>
        )}

        {activeTab === "Legal Service Provider" && (
          <div className="tabs-content">
            <div className="partnerstab_content institlnaltabs_cont">
              <div className="executivepartnerstrust_wrap">
                {partnershipData?.legal_Service_Providers != null &&
                partnershipData?.legal_Service_Providers.length > 0 ? (
                  partnershipData?.legal_Service_Providers.map(
                    (eachPartner, i) => {
                      return (
                        <div className="executivepatners_trust" key={i}>
                          <>
                            <div className="exectveprtnetrst_right">
                              <div className="exectveprtnetrst_fllnme">
                                {
                                  ngoUserList?.filter(
                                    (ngoUser) =>
                                      ngoUser.UserId === eachPartner.lsp_Id
                                  )[0]?.NameOfNGO
                                }
                              </div>
                              <div className="institutionaltabs_content">
                                <div className="institutetabs_item">
                                  <span>
                                    Association Type<abbr>:&nbsp;</abbr>
                                  </span>
                                  <span>
                                    {
                                      associatedUserList.filter(
                                        (x) =>
                                          x.mainGuId ===
                                          eachPartner.associationType_lsp_Id
                                      )[0]?.description
                                    }
                                  </span>
                                </div>
                                {eachPartner.associationType_lsp_Id ===
                                  ASSOCIATED_WITH_EXECUTION_PARTNER &&
                                  ngoUserList.filter(
                                    (x) =>
                                      x.UserId === eachPartner.lsp_ExecPartnerId
                                  )[0]?.NameOfNGO && (
                                    <div className="institutetabs_item">
                                      <span>
                                        Execution Partner (NGO)
                                        <abbr>:&nbsp;</abbr>
                                      </span>
                                      <span>
                                        {
                                          ngoUserList.filter(
                                            (x) =>
                                              x.UserId ===
                                              eachPartner.lsp_ExecPartnerId
                                          )[0]?.NameOfNGO
                                        }
                                      </span>
                                    </div>
                                  )}
                              </div>

                              <div className="exectveprtnetrst_content">
                                <span>Personnel:</span>
                                <div className="exectveprtnetrst_spocswrap">
                                  {eachPartner.pS_LSP_Personnel != null &&
                                    eachPartner.pS_LSP_Personnel.map(
                                      (eachPartner1, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="exectveprtnetrst_spocsitem blue"
                                          >
                                            <div
                                              className="exectveprtnetrst_spocssrtnme"
                                              style={{
                                                backgroundColor:
                                                  BgColorCodes[
                                                    index % BgColorCodes.length
                                                  ],
                                              }}
                                            >
                                              {eachPartner1.name.slice(0, 1)}
                                            </div>
                                            <div className="spocshover_content">
                                              <div className="spocs_name">
                                                {eachPartner1.name}
                                              </div>
                                              <div className="spocsinfo">
                                                <span>
                                                  {eachPartner1.project_Role}
                                                </span>
                                                <span>
                                                  {eachPartner1.mobile}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          </>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div className="norecodfoundprosum_cont">No Record Found</div>
                )}
              </div>
            </div>
          </div>
        )}

        {activeTab === "Audit Service Provider" && (
          <div className="tabs-content">
            <div className="partnerstab_content institlnaltabs_cont">
              <div className="executivepartnerstrust_wrap">
                {partnershipData?.audit_Service_Providers != null &&
                partnershipData?.audit_Service_Providers.length > 0 ? (
                  partnershipData?.audit_Service_Providers.map(
                    (eachPartner, i) => (
                      <div key={i} className="executivepatners_trust">
                        <>
                          <div className="exectveprtnetrst_right">
                            <div className="exectveprtnetrst_fllnme">
                              {ngoUserList?.filter(
                                (ngoUser) =>
                                  ngoUser.UserId === eachPartner.asp_Id
                              )[0]?.NameOfNGO || "No record found"}
                            </div>
                            <div className="institutionaltabs_content">
                              <div className="institutetabs_item">
                                <span>
                                  Association Type<abbr>:&nbsp;</abbr>
                                </span>
                                <span>
                                  {
                                    associatedUserList.filter(
                                      (x) =>
                                        x.mainGuId ===
                                        eachPartner.associationType_asp_Id
                                    )[0]?.description
                                  }
                                </span>
                              </div>
                              {eachPartner.associationType_asp_Id ===
                                ASSOCIATED_WITH_EXECUTION_PARTNER &&
                                ngoUserList.filter(
                                  (x) =>
                                    x.UserId === eachPartner.asp_ExecPartnerId
                                )[0]?.NameOfNGO && (
                                  <div className="institutetabs_item">
                                    <span>
                                      Execution Partner (NGO)
                                      <abbr>:&nbsp;</abbr>
                                    </span>
                                    <span>
                                      {
                                        ngoUserList.filter(
                                          (x) =>
                                            x.UserId ===
                                            eachPartner.asp_ExecPartnerId
                                        )[0]?.NameOfNGO
                                      }
                                    </span>
                                  </div>
                                )}
                            </div>

                            <div className="exectveprtnetrst_content">
                              <span>Personnel:</span>
                              <div className="exectveprtnetrst_spocswrap">
                                {eachPartner.pS_ASP_Personnel != null &&
                                  eachPartner.pS_ASP_Personnel.map(
                                    (eachPartner1, index) => (
                                      <div
                                        key={index}
                                        className="exectveprtnetrst_spocsitem blue"
                                      >
                                        <div
                                          className="exectveprtnetrst_spocssrtnme"
                                          style={{
                                            backgroundColor:
                                              BgColorCodes[
                                                index % BgColorCodes.length
                                              ],
                                          }}
                                        >
                                          {eachPartner1.name.slice(0, 1)}
                                        </div>
                                        <div className="spocshover_content">
                                          <div className="spocs_name">
                                            {eachPartner1.name}
                                          </div>
                                          <div className="spocsinfo">
                                            <span>
                                              {eachPartner1.project_Role}
                                            </span>
                                            <span>{eachPartner1.mobile}</span>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    )
                  )
                ) : (
                  <div className="norecodfoundprosum_cont">No Record found</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
