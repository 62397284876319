import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container, Row, Col } from "react-bootstrap";
import { Modal, Button, Form } from '@patternfly/react-core';
import Loader from "./../../Common/Loader/Loader";
import config from "../../../utils/config";
import MainViewer from "../../Common/Map/MainViewer";
import ToastNotification from "./../../Common/ToastNotification/ToastNotification";
import { navigate } from "@reach/router";
class AddProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            isModalMapOpen: false,
            fields: {},
            errors: {},
            IsLoader: false,
            ProjectName: "",
            Location: "",
            NumberOfTrees: 0,
            ProjectFundraiserGoal: 0,
            ProjectType: "new",
            AmountRaised: 0,
            TreePlantedTillDate: 0,
            Status: "Unverified",
            userData: [],
            files: [],
            MaxFileSizeToUploadInMB: "0",
            regexp: /^[0-9\b]+$/,
            locationCords: [],
            cameraPosition: [],
            Landareasqm: "",
            hide: false,
            showdocuments: false,
            showlandstatus: false,
            landstatus: "",
            ProjectRequiredDocuments: "",
            UploadTitleDeedDoc: false,
            UploadNOCGovAuthDoc: false,
            TitleDeedDoc: "",
            NOCGovAuthDoc: "",
            LstDonorsDoc: "",
            DonationAgreeDoc: "",
            LatestProjectDoc: "",
            TitleDeedFile: [],
            NOCGovAuthFile: [],
            LstDonorsFile: [],
            DonationAgreFile: [],
            LatestProjectfile: []
        }
        this.mounted = true;
        this.handleModalToggle = () => {
            this.clearFields();
            this.setState(({ isModalOpen }) => ({
                isModalOpen: !isModalOpen, errors: {}
            }));

        };

    }
    getdataFromChild = (data) => {
        this.setState({ locationCords: data.polyLocation, cameraPosition: data.cameraPosition });
        console.log("camerapostion", this.state.cameraPosition);
    }
    onsavechildData = () => {
        if (this.state.locationCords.length > 0) {
            let locationInput = document.querySelector('.cesium-geocoder-input.cesium-geocoder-input-wide');
            if (locationInput) {
                this.setState({ Location: locationInput.value }, () => {
                    //this.validateForm()
                    this.handleModalMapToggle()
                });
            }
        }
    }
    componentDidMount() {
        this.mounted = true;
        this.GetGlobalSettings("projects");
    }
    componentWillUnmount() {
        this.mounted = false;
    }
    setFile = (e) => {
        let filesArr = e.target.files;
        if (e.target.files[0].name.match(/\.(pdf|xls|xlsx)$/)) {
            if ((parseInt(e.target.files[0].size) / 1024) <= (parseInt(this.state.MaxFileSizeToUploadInMB) * 1024)) {
                if (e.target.id === "TitleDeed") {
                    this.setState({ TitleDeedDoc: e.target.files[0].name })
                    var array = [...this.state.TitleDeedFile];
                    array.push(e.target.files)
                    this.setState({ TitleDeedFile: array })
                    var filesize = array.length;

                }
                if (e.target.id === "NOCGovAuth") {
                    this.setState({ NOCGovAuthDoc: e.target.files[0].name })
                    var array = [...this.state.NOCGovAuthFile];
                    array.push(e.target.files)
                    this.setState({ NOCGovAuthFile: array })
                    var filesize = array.length;
                }
                if (e.target.id === "lstDonor") {
                    this.setState({ LstDonorsDoc: e.target.files[0].name })
                    var array = [...this.state.LstDonorsFile];
                    array.push(e.target.files)
                    this.setState({ LstDonorsFile: array })
                    var filesize = array.length;
                }
                if (e.target.id === "Donation") {
                    this.setState({ DonationAgreeDoc: e.target.files[0].name })
                    var array = [...this.state.DonationAgreFile];
                    array.push(e.target.files)
                    this.setState({ DonationAgreFile: array })
                    var filesize = array.length;
                }
                if (e.target.id === "LatestProj") {
                    this.setState({ LatestProjectDoc: e.target.files[0].name })
                    var array = [...this.state.LatestProjectfile];
                    array.push(e.target.files)
                    this.setState({ LatestProjectfile: array })
                    var filesize = array.length;
                }
                if (filesize == 1) {
                    for (var i = 0; i < filesArr.length; ++i) {
                        if (filesArr[i].name.match(/\.(pdf|xls|xlsx)$/)) {
                            if (this.state.MaxFileSizeToUploadInMB) {
                                // if ((parseInt(filesArr[0].size) / 1024) <= (parseInt(this.state.MaxFileSizeToUploadInMB) * 1024))  // validation according to file size
                                // {
                                // let myFilesList = this.state.files;
                                // myFilesList.push(filesArr[i]);
                                // this.setState({ files: myFilesList });
                                let filesinde = e.target.files;
                                let allfiles = this.state.files;
                                if (e.target.id === "TitleDeed") {
                                    let myFilesList = this.state.TitleDeedFile;
                                    myFilesList.push(filesArr[i]);
                                    this.setState({ TitleDeedFile: myFilesList, TitleDeedDoc: e.target.files[0].name });
                                    allfiles.push(filesinde[i]);
                                }
                                if (e.target.id === "NOCGovAuth") {
                                    let myFilesList = this.state.NOCGovAuthFile;
                                    myFilesList.push(filesArr[i]);
                                    this.setState({ NOCGovAuthFile: myFilesList, NOCGovAuthDoc: e.target.files[0].name });
                                    allfiles.push(filesinde[i]);
                                }
                                if (e.target.id === "lstDonor") {
                                    let myFilesList = this.state.LstDonorsFile;
                                    myFilesList.push(filesArr[i]);
                                    this.setState({ LstDonorsFile: myFilesList, LstDonorsDoc: e.target.files[0].name });
                                    allfiles.push(filesinde[i]);
                                }
                                if (e.target.id === "Donation") {
                                    let myFilesList = this.state.DonationAgreFile;
                                    myFilesList.push(filesArr[i]);
                                    this.setState({ DonationAgreFile: myFilesList, DonationAgreeDoc: e.target.files[0].name });
                                    allfiles.push(filesinde[i]);
                                }
                                if (e.target.id === "LatestProj") {
                                    let myFilesList = this.state.LatestProjectfile;
                                    myFilesList.push(filesArr[i]);
                                    this.setState({ LatestProjectfile: myFilesList, LatestProjectDoc: e.target.files[0].name });
                                    allfiles.push(filesinde[i]);
                                }
                                this.setState({ files: allfiles })
                                // } else {
                                //     //alert("Maximum files size allowed is " + this.state.MaxFileSizeToUploadInMB + " MB");
                                //     ToastNotification.ShowWarning('Maximum files size allowed is ' + this.state.MaxFileSizeToUploadInMB + 'MB')
                            }
                        }
                        // } else {
                        //     // alert("Allowed extensions are .pdf, .xls");
                        //     ToastNotification.ShowWarning('Allowed extensions are .pdf, .xls, .xlsx')
                        // }
                    }
                }
                else {
                    if (e.target.id == "TitleDeed") {
                        var index = array.indexOf(e.target.value, 1);
                        array.splice(index, 1);
                        this.setState({ TitleDeedFile: array });
                        ToastNotification.ShowWarning('Select only one file')
                    }
                    if (e.target.id == "NOCGovAuth") {
                        var index = array.indexOf(e.target.value, 1);
                        array.splice(index, 1);
                        this.setState({ NOCGovAuthFile: array });
                        ToastNotification.ShowWarning('Select only one file')
                    }
                    if (e.target.id == "lstDonor") {
                        var index = array.indexOf(e.target.value, 1);
                        array.splice(index, 1);
                        this.setState({ LstDonorsFile: array });
                        ToastNotification.ShowWarning('Select only one file')
                    }
                    if (e.target.id == "Donation") {
                        var index = array.indexOf(e.target.value, 1);
                        array.splice(index, 1);
                        this.setState({ DonationAgreFile: array });
                        ToastNotification.ShowWarning('Select only one file')
                    }
                    if (e.target.id == "LatestProj") {
                        var index = array.indexOf(e.target.value, 1);
                        array.splice(index, 1);
                        this.setState({ LatestProjectfile: array });
                        ToastNotification.ShowWarning('Select only one file')
                    }
                }
            }
            else ToastNotification.ShowWarning('Maximum files size allowed is ' + this.state.MaxFileSizeToUploadInMB + 'MB')

        }
        else ToastNotification.ShowWarning('Allowed extensions are .pdf, .xls, .xlsx')

    }

    clearFields = (e) => {
        if (this.mounted) {
            this.setState({
                ProjectName: "",
                Location: "",
                NumberOfTrees: 0,
                ProjectFundraiserGoal: 0,
                ProjectType: "new",
                AmountRaised: 0,
                TreePlantedTillDate: 0,
                Status: "Unverified",
                files: [],
                locationCords: [],
                Landareasqm: "",
                landstatus: "Select Status",
                ownername: "",
                ProjectRequiredDocuments: "Select Documnets",
                showdocuments: false,
                TitleDeedDoc: "",
                NOCGovAuthDoc: "",
                LstDonorsDoc: "",
                DonationAgreeDoc: "",
                LatestProjectDoc: "",
                TitleDeedFile: [],
                NOCGovAuthFile: [],
                LstDonorsFile: [],
                DonationAgreFile: [],
                LatestProjectfile: []
            });
        }
    }

    // this is for radio buttons
    onValueChange = (event) => {
        if (this.mounted) {
            this.setState({
                ProjectType: event.target.value
            });
            if (event.target.value === "existing") this.setState({
                hide: true, ProjectRequiredDocuments: "Select Documnets", landstatus: "Select Status", showlandstatus: false, showdocuments: false,
                UploadNOCGovAuthDoc: false, UploadTitleDeedDoc: false, TitleDeedDoc: "", TitleDeedFile: [], NOCGovAuthDoc: "", NOCGovAuthFile: [], ownername: ""
            })
            if (event.target.value === "new") this.setState({
                hide: false, ProjectRequiredDocuments: "Select Documnets", landstatus: "Select Status", showlandstatus: false, showdocuments: false, UploadNOCGovAuthDoc: false, UploadTitleDeedDoc: false,
                TitleDeedDoc: "", TitleDeedFile: [], NOCGovAuthDoc: "", NOCGovAuthFile: [], DonationAgreFile: [], DonationAgreeDoc: "", LstDonorsDoc: "", LstDonorsFile: [],
                LatestProjectDoc: "", LatestProjectfile: [], ownername: ""
            })

        }
    }

    mobileSidebarToggle = (e) => {
        document.documentElement.classList.toggle("nav-open");
        e.stopPropagation();
    };

    goBack = (e) => {
        window.history.back();
    }

    MapKeyInArray = (responseData, keyname) => {
        let myArray = responseData.find(obj => obj.key === keyname)
        if (myArray) {
            return myArray.value;
        }
    }


    // This function do validate the Registration Form
    validateForm = (e) => {
        let fields;
        fields = {
            ProjectName: this.state.ProjectName,
            Location: this.state.Location,
            NumberOfTrees: this.state.NumberOfTrees,
            Landarea: this.state.Landarea,
            ProjectType: this.state.ProjectType,
            Status: "Unverified",
            AmountRaised: "",
            TreePlantedTillDate: "",
            Landareasqm: this.state.Landareasqm,
            landstatus: this.state.landstatus,
            ProjectRequiredDocuments: this.state.ProjectRequiredDocuments,
            ownername: this.state.ownername,
            titledeedDocument: this.state.TitleDeedDoc,
            nocgovAuthDocument: this.state.NOCGovAuthDoc,
            LstDonorsDocument: this.state.LstDonorsDoc,
            LatestProjdocument: this.state.LatestProjectDoc,
            DonationAgreeDocument: this.state.DonationAgreeDoc
        }
        let errors = {};
        let formIsValid = true;
        if (!fields["ProjectName"]) {
            formIsValid = false;
            errors["ProjectName"] = "Enter project name.";
        }
        if (!fields["Location"]) {
            formIsValid = false;
            errors["Location"] = "Enter location.";
        }
        // if (this.state.files.length == 0) {
        //     formIsValid = false;
        //     errors["inventory"] = "Please select at least one inventory file.";
        // }
        if (this.state.locationCords.length == 0) {
            formIsValid = false;
            errors["Location"] = "Please draw area on map.";
        }
        // if (!fields["NumberOfTrees"]) {
        //     formIsValid = false;
        //     errors["NumberOfTrees"] = "Enter number of trees.";
        // }
        // if (!fields["FundraiserGoal"]) {
        //     formIsValid = false;
        //     errors["FundraiserGoal"] = "Enter project fund raiser goal.";
        // }
        if (fields.FundraiserGoal < fields.AmountRaised) {
            errors["AmountRaised"] = "Amount raised not grater then Project Goal.";
        }
        if (!fields["Landareasqm"]) {
            formIsValid = false;
            errors["Landareasqm"] = "Please enter land area (Sqm)";
        }

        if (fields["landstatus"] === "Select Status") {
            formIsValid = false;
            errors["landstatus"] = "Please select land status";
        }
        // if (this.state.hide && fields["ProjectRequiredDocuments"] === "Select Documnets") {
        //     formIsValid = false;
        //     errors["ProjectRequiredDocuments"] = "Please select required documents";
        // }
        if (this.state.showlandstatus && !fields["ownername"]) {
            formIsValid = false;
            errors["ownername"] = "Please enter owner name";
        }
        if (this.state.UploadTitleDeedDoc && !fields["titledeedDocument"]) {
            formIsValid = false;
            errors["titledeedDoc"] = "Please upload title deed document";
        }
        if (this.state.UploadNOCGovAuthDoc && !fields["nocgovAuthDocument"]) {
            formIsValid = false;
            errors["NOCGovDoc"] = "Please upload NOC goverment document";
        }
        if (this.state.hide && !fields["LstDonorsDocument"]) {
            formIsValid = false;
            errors["LstDonorDoc"] = "Please upload list of donors document";
        }
        if (this.state.hide && !fields["LatestProjdocument"]) {
            formIsValid = false;
            errors["LatestProjDoc"] = "Please upload latest project document";
        }
        if (this.state.hide && !fields["DonationAgreeDocument"]) {
            formIsValid = false;
            errors["DonationAgreeDoc"] = "Please upload donation agreement document";
        }
        if (this.mounted) {
            this.setState({ errors: errors });
        }
        return formIsValid;
    }

    removeFileFromList = (e, id) => {
        if (id === "TitleDeed") {
            var array = [...this.state.files]; // make a separate copy of the array
            array.splice(e.target.id, 1);
            this.setState({ TitleDeedDoc: "", TitleDeedFile: [], files: array });
        }
        if (id === "NOCGovAuth") {
            var array = [...this.state.files]; // make a separate copy of the array
            array.splice(e.target.id, 1);
            this.setState({ NOCGovAuthDoc: "", NOCGovAuthFile: [], files: array });
        }
        if (id === "lstDonor") {
            var array = [...this.state.files]; // make a separate copy of the array
            array.splice(e.target.id, 1);
            this.setState({ LstDonorsDoc: "", LstDonorsFile: [], files: array });
        }
        if (id === "Donation") {
            var array = [...this.state.files]; // make a separate copy of the array
            array.splice(e.target.id, 1);
            this.setState({ DonationAgreeDoc: "", DonationAgreFile: [], files: array });
        }
        if (id === "LatestProj") {
            var array = [...this.state.files]; // make a separate copy of the array
            array.splice(e.target.id, 1);
            this.setState({ LatestProjectDoc: "", LatestProjectfile: [], files: array });
        }
        // var array = [...this.state.files]; // make a separate copy of the array
        // array.splice(e.target.id, 1);
        // this.setState({ files: array });
    }

    click = () => {
        this.props.parentMethod("All");
    }

    GetGlobalSettings = (GroupName) => {
        let UserDetail = {
            GroupName: GroupName
        }
        let uri = `${config.API_HOST_URL}/api/projects/GetGlobalGeoupValue`;
        let fetchOptions = {
            method: "POST",
            body: JSON.stringify(UserDetail),
            headers: {
                WebsiteGuid: this.props.websiteGUID,
                LanguageGuid: this.props.languageGUID,
                Authorization: `Bearer ${this.props.token}`,
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        };

        fetch(uri, fetchOptions)
            .then(response => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then(data => {
                        if (data.success) {
                            let responseData = data.data;
                            if (this.mounted) {
                                this.setState({ TreePrice: this.MapKeyInArray(responseData, "TreePrice") }, function () { console.log(this.state.TreePrice) });
                                this.setState({ Landarea: this.MapKeyInArray(responseData, "Landarea") });
                                this.setState({ MaxFileSizeToUploadInMB: this.MapKeyInArray(responseData, "MaxFileSizeToUploadInMB") });
                            }
                        } else {
                            let errors = {};
                            errors["message"] = data.message;
                            if (this.mounted) {
                                this.setState({ errors: errors });
                            }
                        }
                    });
                } else {
                    return response.json().then(data => {
                        let errors = {};
                        errors["message"] = data.message;
                        if (this.mounted) {
                            this.setState({
                                errors: errors
                            });
                        }
                    });
                }

            })
            .catch(error => {
                if (this.mounted) {
                    console.log(error);
                    this.setState({
                        errorMsg: error,
                        IsLoader: false
                    })
                }
            });
    }
    AddNewProject = (e) => {
        e.preventDefault();

        if (this.validateForm()) {
            if (this.mounted) {

                let file = this.state.files;
                const formData = new FormData();
                for (var index = 0; index < file.length; index++) {
                    var element = file[index];
                    formData.append('FormFiles', element);
                }
                formData.append('UserId', this.props.userData.userId);
                formData.append('ProjectName', this.state.ProjectName);
                formData.append('Location', this.state.Location);
                formData.append('NumberOfTrees', this.state.NumberOfTrees);
                formData.append('Landarea', this.state.Landarea);
                //formData.append('TreePrice', this.state.TreePrice);
                formData.append('ProjectType', this.state.ProjectType);
                formData.append('AmountRaised', this.state.AmountRaised);
                formData.append('TreePlantedTillDate', this.state.TreePlantedTillDate);
                formData.append('Status', "Unverified");
                formData.append('IsActive', true);
                formData.append('LaunchDate', new Date());
                formData.append('LocationCords[0]', this.state.locationCords[0]);
                formData.append('LocationCords[1]', this.state.locationCords[1]);
                formData.append('LocationCords[2]', this.state.locationCords[2]);
                formData.append('LocationCords[3]', this.state.locationCords[3]);
                formData.append('LocationCords[4]', this.state.locationCords[4]);
                formData.append('LocationCords[5]', this.state.locationCords[5]);
                formData.append('LocationCords[6]', this.state.locationCords[6]);
                formData.append('LocationCords[7]', this.state.locationCords[7]);
                formData.append('CameraPosition[0]', this.state.cameraPosition[0]);
                formData.append('CameraPosition[1]', this.state.cameraPosition[1]);
                formData.append('CameraPosition[2]', this.state.cameraPosition[2]);
                formData.append('Landstatus', this.state.landstatus);
                formData.append('ProjectRequiredDocuments', this.state.TitleDeedDoc != "" && this.state.TitleDeedFile.length != 0 ? "Title deed" : "NOC from government authority");
                formData.append('Ownername', this.state.ownername);
                formData.append('Landareasqm', this.state.Landareasqm);
                formData.append('TitleDeedDoc', this.state.TitleDeedDoc);
                formData.append('NOCGovAuthDoc', this.state.NOCGovAuthDoc);
                formData.append('LstDonorDoc', this.state.LstDonorsDoc);
                formData.append('DonationAgreeDoc', this.state.DonationAgreeDoc);
                formData.append('LatestProjDoc', this.state.LatestProjectDoc);

                let uri = `${config.API_HOST_URL}/api/projects/AddNewProject`;
                let fetchOptions = {
                    method: "POST",
                    //body: JSON.stringify(UserDetail),
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${this.props.token}`,
                        Accept: "application/json",
                        ContentType: 'multipart/form-data',
                    }
                };
                this.setState({ IsLoader: true });
                fetch(uri, fetchOptions)
                    .then(response => {
                        return response.json().then(data => {
                            if (data.success) {
                                let errors = {};
                                errors["message"] = data.message;
                                if (this.mounted) {
                                    this.setState({
                                        errors: errors,
                                        IsLoader: false
                                    });
                                }
                                this.click();
                                this.clearFields();
                                this.handleModalToggle();
                                //alert(data.message);
                                ToastNotification.ShowSuccess(data.message)
                            } else {
                                let errors = {};
                                errors["message"] = data.message;
                                if (this.mounted) {
                                    this.setState({
                                        errors: errors,
                                        IsLoader: false
                                    });
                                }
                            }
                        });

                    })
                    .catch(error => {
                        if (this.mounted) {
                            console.log(error);
                            this.setState({
                                errorMsg: error,
                                IsLoader: false
                            })
                        }
                    });
            }
        }
    }

    // This function will set the state of input fields and validate them when user enters the value in input boxes

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (this.mounted) {
            this.setState({ [name]: value });

        }
    }

    handleModalMapToggle = () => {
        this.setState({ isModalMapOpen: !this.state.isModalMapOpen }, function () {
            if (this.state.isModalMapOpen)
                document.body.classList.add("mapmodalopen");
            else
                document.body.classList.remove("mapmodalopen");
        });
    }
    onddlselectlandstatus = (e) => {
        if (this.mounted) {
            this.setState({
                landstatus: e.target.value, showdocuments: false//true
            });
            if (e.target.value === "Select Status") this.setState({ ProjectRequiredDocuments: "Select Documnets", showlandstatus: false, ownername: "", UploadTitleDeedDoc: false, UploadNOCGovAuthDoc: false })
            if (e.target.value === "Private owned land") {
                this.setState({
                    showlandstatus: true, ProjectRequiredDocuments: "Select Documnets",
                    UploadTitleDeedDoc: true, UploadNOCGovAuthDoc: false, NOCGovAuthDoc: "", NOCGovAuthFile: []
                })
            }
            if (e.target.value === "Goverment and community land") this.setState({
                showlandstatus: false, ProjectRequiredDocuments: "Select Documnets", ownername: "",
                UploadTitleDeedDoc: false, UploadNOCGovAuthDoc: true, TitleDeedDoc: "", TitleDeedFile: []
            })
        }
    }
    onddlselectprorequidoc = (e) => {
        if (this.mounted) {
            this.setState({
                ProjectRequiredDocuments: e.target.value
            });
        }
    }
    render() {
        const { isModalOpen, isModalMapOpen } = this.state;
        return (

            <div>

                {this.state.IsLoader ? <Loader /> :
                    <div className="addpro_btn">
                        <Button onClick={this.handleModalToggle}>Add Project <i className="fa fa-plus"></i>
                        </Button>
                        <Modal title="Add Project"
                            isOpen={isModalOpen}
                            onClose={this.handleModalToggle}
                            className="addproject_modal"
                            actions={[
                                <Button key="submit" className="modal_btn submit_btn" onClick={this.AddNewProject}>Submit</Button>,
                                <Button key="cancel" className="modal_btn cancel_btn" onClick={this.handleModalToggle}>Cancel</Button>
                            ]}
                        >

                            <div>
                                <ul className="addproject_form">
                                    <li className="form-group field">
                                        <input type="text" id="proname" placeholder="Enter your project name" name="ProjectName" value={this.state.ProjectName} onChange={(event) => this.handleUserInput(event)} />
                                        {/* <input type="text" id="proname" placeholder="Enter your project name" name="ProjectName" ref="ProjectName" onChange={(event) => this.handleUserInput.bind(this, "ProjectName", event)} value={this.state.fields["ProjectName"]} /> */}
                                        <label for="proname"><sup className="mandatory">*</sup>Project Name</label>
                                        <div className="mandatory has-error">{this.state.errors.ProjectName}</div>
                                    </li>
                                    <div className={this.state.locationCords.length > 0 ? "mapmodal_cont laglongaddfieldcont" : "mapmodal_cont"}>
                                        <li className="form-group field location_cont" >
                                            <input type="text" id="prolocation" placeholder="Enter Location" required name="Location" value={this.state.Location} onChange={(event) => this.handleUserInput(event)} />
                                            <span className="showmapsymbol" onClick={this.handleModalMapToggle}><i className="icon icon-location-pin"></i></span>
                                            <label for="prolocation"><sup className="mandatory">*</sup>Enter Location</label>

                                            <div className="mandatory has-error">{this.state.errors.Location}</div>
                                        </li>
                                        {this.state.locationCords.length > 0 &&
                                            <div className="showcords">
                                                <span><b>A)</b>{this.state.locationCords[0]},<span>{this.state.locationCords[1]}</span></span>
                                                <span><b>B)</b>{this.state.locationCords[2]},<span>{this.state.locationCords[3]} </span></span>
                                                <span><b>C)</b>{this.state.locationCords[4]},<span>{this.state.locationCords[5]}</span></span>
                                                <span><b>D)</b>{this.state.locationCords[6]},<span>{this.state.locationCords[7]}</span></span>
                                            </div>}
                                        <Modal
                                            title=""
                                            position="top"
                                            isOpen={isModalMapOpen}
                                            onClose={this.handleModalMapToggle}
                                            className="addprojectmap_modal"
                                        >
                                            <div className="btn_mainviewer">
                                                <Button key="save" className="modal_btn submit_btn" variant="primary" onClick={this.onsavechildData}>
                                                    Save
                                                    <div className="tooltipdata">Save</div>
                                                </Button>
                                                <MainViewer onparentCallback={this.getdataFromChild} />
                                            </div>

                                        </Modal>
                                    </div>
                                    <li className="form-group field areamapcont">
                                        <input type="number" pattern="[0-9]*" inputmode="numeric" id="nooftrees" placeholder="Enter estimated number of trees" name="NumberOfTrees" value={this.state.NumberOfTrees} onChange={(event) => this.handleUserInput(event)} />
                                        <label for="nooftrees">Estimated Number of Trees</label>
                                    </li>
                                    <li className="form-group field">
                                        <input type="text" id="Landareasqm" placeholder="Enter Land area (in square meter)" name="Landareasqm" value={this.state.Landareasqm} onChange={(event) => this.handleUserInput(event)} />
                                        <label for="Landareasqm">Land area (in square meter)</label>
                                        <div className="mandatory has-error">{this.state.errors.Landareasqm}</div>
                                    </li>

                                    <li className="form-group typeproject_cont">
                                        <label htmlFor="projecttype">
                                            Type of Project
                                        </label>
                                        <div className="input-group radiobtns_wrap">
                                            <div className="radio__input">
                                                <div className="custom-radio">
                                                    <input value="existing" type="radio" id="existing" name="ProjectType" checked={this.state.ProjectType === "existing"} onChange={this.onValueChange} />
                                                </div>
                                                <label htmlFor="existing" className="radio__label">Existing</label>
                                            </div>
                                            <div className="radio__input">
                                                <div className="custom-radio">
                                                    <input value="new" type="radio" id="new" name="ProjectType" checked={this.state.ProjectType === "new"} onChange={this.onValueChange} />
                                                </div>
                                                <label htmlFor="new" className="radio__label">New</label>
                                            </div>
                                            <div className="mandatory has-error">{this.state.errors.ProjectType}</div>
                                        </div>
                                    </li>
                                    {this.state.hide &&
                                        <div>
                                            <li className="form-group field">

                                                <input type="number" pattern="[0-9]*" inputmode="numeric" id="amtraised" placeholder="Enter numner of amount raised" name="AmountRaised" value={this.state.AmountRaised} onChange={(event) => this.handleUserInput(event)} />
                                                <label for="amtraised">
                                                    Amount Raised
                                                </label>
                                                <div className="mandatory has-error">{this.state.errors.AmountRaised}</div>
                                            </li>
                                            <li className="form-group field">
                                                <input type="number" pattern="[0-9]*" inputmode="numeric" id="treeplanttilldate" placeholder="Enter number of trees planted till date" name="TreePlantedTillDate" value={this.state.TreePlantedTillDate} onChange={(event) => this.handleUserInput(event)} />
                                                <label for="treeplanttilldate">
                                                    Tree planted till Date
                                                </label>
                                                <div className="mandatory has-error">{this.state.errors.TreePlantedTillDate}</div>
                                            </li>
                                        </div>}
                                    <li className="form-group field">
                                        <select onChange={this.onddlselectlandstatus}>
                                            <option value="Select Status" selected={this.state.landstatus == "Select Status"}>Select Status of Land</option>
                                            <option value="Private owned land">Private owned land</option>
                                            <option value="Goverment and community land">Goverment and community land</option>
                                        </select>
                                        <label for="statusofland">
                                            Status of land
                                        </label>
                                        <div className="mandatory has-error">{this.state.errors.landstatus}</div>
                                    </li>
                                    {this.state.showlandstatus &&
                                        <li className="form-group field">
                                            <input type="text" id="ownername" placeholder="Enter owner name" name="ownername" value={this.state.ownername} onChange={(event) => this.handleUserInput(event)} />
                                            <label for="ownername">Owner’s name</label>
                                            <div className="mandatory has-error">{this.state.errors.ownername}</div>
                                        </li>}
                                    {this.state.showdocuments &&
                                        <li className="form-group field">

                                            {this.state.hide ?
                                                <div>
                                                    <select onChange={this.onddlselectprorequidoc}>
                                                        <option value="Select Documnets" selected={this.state.ProjectRequiredDocuments == "Select Documnets"}>Select Documnets</option>
                                                        <option value="List of donors">List of donors</option>
                                                        <option value="Donation agreement">Donation agreement</option>
                                                        <option value="Latest project report">Latest project report</option>
                                                        {this.state.showlandstatus && <option value="Title deed">Title deed</option>}
                                                        {!this.state.showlandstatus && <option value="NOC from government authority">NOC from government authority</option>}
                                                    </select>
                                                    <div className="mandatory has-error">{this.state.errors.ProjectRequiredDocuments}</div>
                                                </div> :
                                                <div>
                                                    <select onChange={this.onddlselectprorequidoc}>
                                                        <option value="Select Documnets" selected={this.state.ProjectRequiredDocuments == "Select Documnets"}>Select Documnets</option>
                                                        {this.state.showlandstatus && <option value="Title deed">Title deed</option>}
                                                        {!this.state.showlandstatus && <option value="NOC from government authority">NOC from government authority</option>}
                                                    </select>
                                                    <div className="mandatory has-error">{this.state.errors.ProjectRequiredDocuments}</div>
                                                </div>
                                            }
                                            <label for="selectdoctype">
                                                Select document type
                                            </label>
                                        </li>}

                                    {this.state.UploadNOCGovAuthDoc === true && <li className="form-group inventory_cont">
                                        <label for="forinventory">
                                            <sup className="mandatory">*</sup>
                                            Upload Government NOC Document
                                        </label>

                                        <div className="input-group divUploadFile">
                                            <div className="qq-upload-drop-area">
                                                <span>Drop files here to upload</span>
                                            </div>
                                            <div className="qq-upload-button">
                                                Drag and Drop Files
                                                <span className="redMandat">
                                                    Upload file type .pdf, .xls, .xlsx File size limit 4MB
                                                </span>
                                            </div>
                                            <input id="NOCGovAuth" type="file" name="files" onChange={e => this.setFile(e)} onClick={(event) => { event.target.value = null }} />
                                        </div>
                                        <div>
                                            <ul id="fileList" className="ul-project-files">
                                                {this.state.NOCGovAuthFile !== null && this.state.NOCGovAuthFile.map((item, index) => (
                                                    <li class="file-list-li">
                                                        <i class="fa fa-check"></i>{item.name}
                                                        <a class="remove-files-list" id={index} onClick={(e) => this.removeFileFromList(e, "NOCGovAuth")}>Remove</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                        {this.state.NOCGovAuthDoc.length == 0 &&
                                            <div className="mandatory has-error">{this.state.errors.NOCGovDoc}</div>}
                                    </li>}
                                    {this.state.UploadTitleDeedDoc === true && <li className="form-group inventory_cont">
                                        <label for="forinventory">
                                            <sup className="mandatory">*</sup>
                                            Upload Title Deed Document
                                        </label>

                                        <div className="input-group divUploadFile">
                                            <div className="qq-upload-drop-area">
                                                <span>Drop files here to upload</span>
                                            </div>
                                            <div className="qq-upload-button">
                                                Drag and Drop Files
                                                <span className="redMandat">
                                                    Upload file type .pdf, .xls, .xlsx File size limit 4MB
                                                </span>
                                            </div>
                                            <input id="TitleDeed" type="file" name="files" onChange={e => this.setFile(e)} onClick={(event) => { event.target.value = null }} />
                                        </div>
                                        <div>
                                            <ul id="fileList" className="ul-project-files">
                                                {this.state.TitleDeedFile !== null && this.state.TitleDeedFile.map((item, index) => (
                                                    <li class="file-list-li">
                                                        <i class="fa fa-check"></i>{item.name}
                                                        <a class="remove-files-list" id={index} onClick={(e) => this.removeFileFromList(e, "TitleDeed")}>Remove</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                        {this.state.TitleDeedDoc.length == 0 &&
                                            <div className="mandatory has-error">{this.state.errors.titledeedDoc}</div>}
                                    </li>}
                                    {this.state.hide && <React.Fragment>
                                        <li className="form-group inventory_cont">
                                            <label for="forinventory">
                                                <sup className="mandatory">*</sup>
                                                Upload List of donors document
                                            </label>

                                            <div className="input-group divUploadFile">
                                                <div className="qq-upload-drop-area">
                                                    <span>Drop files here to upload</span>
                                                </div>
                                                <div className="qq-upload-button">
                                                    Drag and Drop Files
                                                    <span className="redMandat">
                                                        Upload file type .pdf, .xls, .xlsx File size limit 4MB
                                                    </span>
                                                </div>
                                                <input id="lstDonor" type="file" name="files" onChange={e => this.setFile(e)} onClick={(event) => { event.target.value = null }} />
                                            </div>
                                            <div>
                                                <ul id="fileList" className="ul-project-files">
                                                    {this.state.LstDonorsFile !== null && this.state.LstDonorsFile.map((item, index) => (
                                                        <li class="file-list-li">
                                                            <i class="fa fa-check"></i>{item.name}
                                                            <a class="remove-files-list" id={index} onClick={(e) => this.removeFileFromList(e, "lstDonor")}>Remove</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {this.state.LstDonorsDoc.length == 0 &&
                                                <div className="mandatory has-error">{this.state.errors.LstDonorDoc}</div>}
                                        </li>
                                        <li className="form-group inventory_cont">
                                            <label for="forinventory">
                                                <sup className="mandatory">*</sup>
                                                Upload Donation agreement document
                                            </label>

                                            <div className="input-group divUploadFile">
                                                <div className="qq-upload-drop-area">
                                                    <span>Drop files here to upload</span>
                                                </div>
                                                <div className="qq-upload-button">
                                                    Drag and Drop Files
                                                    <span className="redMandat">
                                                        Upload file type .pdf, .xls, .xlsx File size limit 4MB
                                                    </span>
                                                </div>
                                                <input id="Donation" type="file" name="files" onChange={e => this.setFile(e)} onClick={(event) => { event.target.value = null }} />
                                            </div>
                                            <div>
                                                <ul id="fileList" className="ul-project-files">
                                                    {this.state.DonationAgreFile !== null && this.state.DonationAgreFile.map((item, index) => (
                                                        <li class="file-list-li">
                                                            <i class="fa fa-check"></i>{item.name}
                                                            <a class="remove-files-list" id={index} onClick={(e) => this.removeFileFromList(e, "Donation")}>Remove</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {this.state.DonationAgreeDoc.length == 0 &&
                                                <div className="mandatory has-error">{this.state.errors.DonationAgreeDoc}</div>}
                                        </li>
                                        <li className="form-group inventory_cont">
                                            <label for="forinventory">
                                                <sup className="mandatory">*</sup>
                                                Upload Latest project report document
                                            </label>

                                            <div className="input-group divUploadFile">
                                                <div className="qq-upload-drop-area">
                                                    <span>Drop files here to upload</span>
                                                </div>
                                                <div className="qq-upload-button">
                                                    Drag and Drop Files
                                                    <span className="redMandat">
                                                        Upload file type .pdf, .xls, .xlsx File size limit 4MB
                                                    </span>
                                                </div>
                                                <input id="LatestProj" type="file" name="files" onChange={e => this.setFile(e)} onClick={(event) => { event.target.value = null }} />
                                            </div>
                                            <div>
                                                <ul id="fileList" className="ul-project-files">
                                                    {this.state.LatestProjectfile !== null && this.state.LatestProjectfile.map((item, index) => (
                                                        <li class="file-list-li">
                                                            <i class="fa fa-check"></i>{item.name}
                                                            <a class="remove-files-list" id={index} onClick={(e) => this.removeFileFromList(e, "LatestProj")}>Remove</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {this.state.LatestProjectDoc.length == 0 &&
                                                <div className="mandatory has-error">{this.state.errors.LatestProjDoc}</div>}
                                        </li>
                                    </React.Fragment>}
                                </ul>

                            </div>

                        </Modal>
                    </div>
                }
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
    };
}

export default connect(
    mapStateToProps
)(AddProject);