import React, { Component } from "react";
import config from "../../utils/config";
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Button } from "react-bootstrap";
import ExpStory_img from "../../assets/storycont_img.png";
import LeafButton from "../../assets/leafbtn_img.png";
import verydense from "../../assets/verydense.png";
import moderatelydense from "../../assets/moderatelydense.png";
import openforest from "../../assets/openforest.png";
import Restoration from "../../assets/Restoration.png";
import Afforestation from "../../assets/Afforestation.png";
import AgriculturalLandRestoration from "../../assets/AgriculturalLandRestoration.png";
import Agroforestry from "../../assets/Agroforestry.png";
import Reforestation from "../../assets/Reforestation.png";
import GrasslandRestoration from "../../assets/GrasslandRestoration.png";
import WaterSystems from "../../assets/WaterSystems.png";
import Mangroves from "../../assets/Mangroves.png";
import CostalReforestation from "../../assets/CostalReforestation.png";
import Seashore from "../../assets/Seashore.png";
import greenpoitns_img1 from "../../assets/greenpoitns_img1.png";
import greenpoitns_img2 from "../../assets/greenpoitns_img2.png";
import greenpoitns_img3 from "../../assets/greenpoitns_img3.png";
import greenpoitns_img4 from "../../assets/greenpoitns_img4.png";
import backtbn from "../../assets/backbtn.png";
import { Link, globalHistory } from "@reach/router";

class OurExperience extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.mounted = true;
        this.winScroll = this.winScroll.bind(this);
    }

    componentWillUnmount() {
        this.mounted = false;
        document.body.classList.remove('ourexperience_bg');
    }
    componentDidMount() {
        document.body.classList.add('ourexperience_bg');
        this.mounted = true;
        window.addEventListener("scroll", this.winScroll);
    }
    winScroll = () => {
        this.setState({ scrollCount: window.scrollY })
    }
    goBack() {
        window.history.go(-2);
    }
    render() {
        
        return (
            <>
                <Button className="backbtn" onClick={this.goBack}>
                    <img src={backtbn} alt={backtbn} />
                    <span>Back</span>
                </Button>

                <div className="morelrpadd_cont expstory_cont">
                    <div className="treeties_container">
                        <h1><span className="greenunderline">Ladakh Story:</span> A Decade of</h1>
                        <h1>Green Transformation in the</h1>
                        <h1>Western Himalayas</h1>
                        <p>In the face of climate change and environmental degradation, Treeties has<br/> made a difference in the Ladakh region of the Western Himalayas since 2013.<br/> With 11 sustainability projects implemented over the past 10 years in<br/> collaboration with local NGOs, DRDO Leh, and other partners, Treeties has<br/> been at the forefront of environmental conservation and restoration in one<br/> of the most challenging terrains in the world.</p>
                        <img src={ExpStory_img} alt={ExpStory_img} />
                    </div>
                </div>

                <div id="keyachiev_cont" className="morelrpadd_cont keyachiev_cont paddingbotm">
                    <div className="treeties_container">
                        <h1>Our <span className="greenunderline">Key Achievements</span></h1>
                        <ul className="keyachiev_list">
                            <li>Launched on World Environment Day, June 5, 2022, with 100,000 saplings planted and 3,000 people mobilised under Project Green Himalayas.</li>
                            <li>Digital E-learning has been introduced in 32 monastic and village schools since 2013, impacting over 5,000 children.</li>
                            <li>Addressed climate impact, glacial melting, and landslides since 2014 by building tree covers on Himalayan foothills to mitigate climate change and environmental destruction in Ladakh.</li>
                            <li>Collaborated with ATREE and various research institutions to implement projects grounded in scientific knowledge.</li>
                            <li>Established an all-inclusive platform to facilitate cooperation between financial contributors and community-based non-profit organisations.</li>
                        </ul>
                        <Button className="leafbtn_cont">
                            <Link to="/contactus">
                                <img src={LeafButton} alt="leafbtn" />
                                <span>Join our efforts</span>
                            </Link>
                        </Button>
                    </div>
                </div>

                <div className="morelrpadd_cont keylearn_cont paddingbotm">
                    <div className="treeties_container">
                        <h1><span className="greenunderline">Key Learnings </span> from Ladakh</h1>
                        <div className="experience_list keylearn_list">
                            <div>
                                <span>1.</span>
                                <span>Insights reflecting<br/> the intricate dance<br/> of nature and<br/> human involvement.</span>
                            </div>
                            <div>
                                <span>2.</span>
                                <span>Interdependence and<br/> connection and that<br/> the human element is<br/> at the very centre.</span>
                            </div>
                            <div>
                                <span>3.</span>
                                <span>Richness and diversity of<br/> ecosystem archetypes,<br/> each a unique  piece of the<br/> ecological puzzle.</span>
                            </div>
                            <div>
                                <span>4.</span>
                                <span>Stakeholder management<br/> is instrumental in<br/> navigating this complex<br/> terrain.</span>
                            </div>
                            <div>
                                <span>5.</span>
                                <span>Each experience<br/> adds depth and<br/> colour to our<br/> understanding.</span>
                            </div>   
                        </div>
                    </div>
                </div>

                <div className="morelrpadd_cont forestchange_cont paddingbotm">
                    <div className="treeties_container">
                        <h1><span className="greenunderline">Fostering Change </span> Through</h1>
                        <h1>Local Action in Ladakh</h1>
                        <p>Spanning 168,055 square kilometers of breathtaking landscape, Ladakh<br/> presents a diverse and complex environment. Treeties has dedicated itself to<br/> positively impacting this region, working diligently to protect and restore its<br/> precious ecosystems.</p>
                        <p>Through density mapping, identified distribution of forest<br/> cover in Ladakh includes:</p>
                        <div className="experience_list forestchange_list">
                            <div>
                               <img src={verydense} alt={verydense} />
                               <span className="forechngnum">2 <span>sq.km</span></span>
                               <span>Very Dense</span>
                            </div>
                            <div>
                                <img src={moderatelydense} alt={moderatelydense}  />
                                <span className="forechngnum">512 <span>sq.km</span></span>
                                <span>Moderately Dense</span>
                            </div>
                            <div>
                                <img src={openforest} alt={openforest} />
                                <span className="forechngnum">1,758 <span>sq.km</span></span>
                                <span>Open Forest</span>
                            </div>
                        </div>
                        <Button className="leafbtn_cont">
                            <Link to="/contactus">
                                <img src={LeafButton} alt="leafbtn" />
                                <span>Be the Change</span>
                            </Link>
                        </Button>
                    </div>
                </div>
                <div className="morelrpadd_cont networkmodl_cont paddingbotm">
                    <div className="treeties_container">
                        <h1>TreeTies Network Model</h1>
                        <div className="netwrkmod_topheading">
                            Anchor Partners, Investors, Sponsors,<br/> Customers, WEF, 1t.org, Intl Partners, Coalition
                        </div>
                        <div className="networkmod_middlecontainer">
                            <div className="netwrkmod_left">
                                <span>Treeties Partner Ecosystem</span>
                                <span>NGO/ Village Communities Private Landowners Corporates State Government - Forests Community Land</span>
                                <span>Central/ State Government ATREE, TERI, DRDO</span>
                                <span>Satellite Imaging Drone Partners Data Science, GEO Spatial</span>
                            </div>
                            <div className="netwrkmod_middle">
                                <div>
                                    <img src={Restoration} alt={Restoration} />
                                    <span>Invasive Plant<br/> Reforestation</span>
                                </div>
                                <div>
                                    <img src={Afforestation} alt={Afforestation} />
                                    <span>Afforestation</span>
                                </div>
                                <div>
                                    <img src={Agroforestry} alt={Agroforestry} />
                                    <span>Agroforestry</span>
                                </div>
                                <div>
                                    <img src={Reforestation} alt={Reforestation} />
                                    <span>Reforestation</span>
                                </div>
                                <div>
                                    <img src={GrasslandRestoration} alt={GrasslandRestoration} />
                                    <span>Grassland<br/> Restoration</span>
                                </div>
                                <div>
                                    <img src={AgriculturalLandRestoration} alt={AgriculturalLandRestoration} />
                                    <span>Agricultural Land<br/> Restoration</span>
                                </div>
                                <div>
                                    <img src={Seashore} alt={Seashore} />
                                    <span>Seashore</span>
                                </div>
                                <div>
                                    <img src={CostalReforestation} alt={CostalReforestation} />
                                    <span>Costal <br/> Reforestation</span>
                                </div>
                                <div>
                                    <img src={Mangroves} alt={Mangroves} />
                                    <span>Costal <br/>Reforestation</span>
                                </div>
                                <div>
                                    <img src={WaterSystems} alt={WaterSystems} />
                                    <span>Water <br/>Systems</span>
                                </div>
                            </div>
                            <div className="netwrkmod_right">
                                <span>Green India Mission</span>
                                <span>Bio Diversity</span>
                                <span>Sustainable Livelihoods & Social Impact</span>
                                <span>Carbon Offsets</span>
                            </div>
                        </div>
                        <div className="netwrkmod_botheading">
                        Treeties Systems & Platforms: Methods, Protocols, Process<br/>Climate Science, Ecology, Technology, Track & Trace
                        </div>
                    </div>
                </div>
                <div className="morelrpadd_cont greenpoints_cont paddingbotm">
                    <div className="treeties_container">
                        <h1><span className="greenunderline">Future Greenprints:</span> Our Next Steps</h1> 
                        <h1>in Sustainability</h1>
                        <p>As we continue our journey of environmental stewardship, Treeties is excited<br/> to share our ongoing and upcoming projects. These initiatives build upon the<br/> successes and lessons learned from our work in Ladakh and aim to create an<br/> even greater impact on both the local and global scale. Discover the inspiring<br/> projects ahead and join us in making a tangible difference in the world.</p>
                        <ul className="greenpoints_list">
                            <li>
                                <img src={greenpoitns_img1}  alt={greenpoitns_img1} />
                                <span>Restoration of abandoned tea<br/>/coffee/cardamom estates into rainforest<br/> fragments, Valparai</span>
                            </li>
                            <li>
                            <img src={greenpoitns_img2}  alt={greenpoitns_img2} />
                                <span>Restoring hill wetlands in the<br/> Nilgiri Biosphere Reserve,<br/> Western Ghats</span>
                            </li>
                            <li>
                            <img src={greenpoitns_img3}  alt={greenpoitns_img3} />
                                <span>Grassland restoration through<br/> removal of invasive species -<br/> Banni, Gujarat</span>
                            </li>
                            <li>
                            <img src={greenpoitns_img4}  alt={greenpoitns_img4} />
                                <span>Invasive species removal :<br/> Lantana camara, Govindpura</span>
                            </li>
                        </ul>
                        <Button className="leafbtn_cont">
                            <Link to="/contactus">
                                <img src={LeafButton} alt="leafbtn" />
                                <span>Learn more</span>
                            </Link>
                        </Button>
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
        registrationData: state.registration.registrationData
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OurExperience);

