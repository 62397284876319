import React, { useState, useCallback, useEffect } from "react";
import { Modal, Button } from "@patternfly/react-core";
import config from "../../../utils/config";
import { Controller, useForm, getValues } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import ToastNotification from "../../Common/ToastNotification/ToastNotification";

export default function UploadTimeSeriesImagesModal(props) {
  const ProjectStatus = "Verified";
  const [selectedProjects, setSelectedProjects] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(props.IsOpen);
  const [fileNameControl, setFileNameControl] = useState("");
  const [prevData, setFormData] = useState({});
  const [selectedYear, setSelectedYear] = useState("");
  const [imageAdded, setImageAdded] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [uploadingPhotoForValue, setUploadingPhotoForValue] = useState("");
  const [fileControl, setFileControl] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [addButton, setAddButton] = useState(false);

  const [projectList, setProjectList] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [errorMapbound, setErrorMapbound] = useState("");
  const [selectedTimeSeries, setSelectedTimeSeries] = useState({});
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedYears, setUploadedYears] = useState([]);
  const [uploadedImageName, setUploadedImageName] = useState("");

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };
  const closeTooltip = () => {
    setShowTooltip(false);
  };
  const [Uploaddata, setUploaddata] = useState({ imageTreeUpload: [] });
  const [UploadImagedata, setUploadImagedata] = useState({
    imageTreeUpload: [],
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm();

  useEffect(() => {
    setSelectedTimeSeries(props?.selectedTimeSeries);

    fetchProjects();
    if (props?.selectedTimeSeries) {
      setSelectedProjects(props?.selectedTimeSeries.projectId);
      const { uploadedTimeSeriesImg } = props?.selectedTimeSeries;
      const uploadedImages = uploadedTimeSeriesImg.map((image, index) => {
        return {
          name: `img${index + 1}`,
          imageName: image.imageName,
          year: image.year,
          bounds: image.bounds.join(", "),
        };
      });
      setUploaddata((prevUploaddata) => ({
        ...prevUploaddata,
        imageTreeUpload: uploadedImages,
      }));
      setImageAdded(true);
      setValue("ProjectId", props?.selectedTimeSeries.ProjectId);
    }
  }, [props?.selectedTimeSeries]);

  const fetchProjects = async () => {
    const UserDetail = {
      Status: ProjectStatus,
    };
    try {
      const response = await fetch(
        `${config.API_HOST_URL}/api/projects/GetProjectDataByFilter`,
        {
          method: "POST",
          headers: {
            WebsiteGuid: props.websiteGUID,
            LanguageGuid: props.languageGUID,
            Authorization: `Bearer ${props.token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(UserDetail),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.success) {
        setProjectList(data.data);
      } else {
        console.error("Error fetching projects", data.message);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let newData = { ...prevData };
    newData[name] = value;
    setFormData(newData);

    if (name === "mapBounds") {
      const validChars = /^[-\d.,]*$/;
      if (!validChars.test(value)) {
        setErrorMapbound(
          "Only numbers, commas, periods, and minus signs are allowed."
        );
        return;
      }
      const regex = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?,-?\d+(\.\d+)?,-?\d+(\.\d+)?$/;
      if (!regex.test(value)) {
        setErrorMapbound(
          "Invalid format. Please use the format: 15.250110379,74.077749917,15.257275915,74.082876954"
        );
      } else {
        setErrorMapbound("");
      }
    }
    setFormData({
      ...prevData,
      [name]: value,
    });
  };

  const handleImageChange = async (file, selectedYear, mapBounds) => {
    let projectId = selectedProjects;
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${config.API_HOST_URL}/api/Ecosystem/UploadMultipleTimeSerisImage/${projectId}`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Failed to upload image");
      }
      const result = await response.json();
      if (result.success === true) {
        const newImage = {
          year: selectedYear,
          imageName: result.data.uploadedImages[0],
          bounds: mapBounds,
        };
        setUploadImagedata((prevUploaddata) => ({
          ...prevUploaddata,
          imageTreeUpload: [...prevUploaddata.imageTreeUpload, newImage],
        }));
        setUploadingPhotoForValue(`${selectedYear}`);
        setImageName(result.data.uploadedImages);
        setUploadedYears((prevYears) => [...prevYears, selectedYear]);
        setAddButton(true);
        ToastNotification.ShowSuccess(result.message);
        document.getElementById("upload-message").innerHTML =
          "Image uploaded successfully!";
      } else {
        ToastNotification.ShowError(result.message);
        document.getElementById("upload-message").innerHTML =
          "Error uploading image!";
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      ToastNotification.ShowError("Error uploading image");
      document.getElementById("upload-message").innerHTML =
        "Error uploading image!";
    }
  };

  const handleModalToggle = useCallback(() => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  }, [setIsModalOpen]);

  const onSubmit = async (data) => {
    const uploadedTimeSeriesImgs = Uploaddata.imageTreeUpload.map((item) => ({
      ImageName: item.imageName,
      ImgId: item.name,
      Year: item.year,
      Bounds: item.bounds
        ? item.bounds.split(",").map((bound) => parseFloat(bound.trim()))
        : [],
    }));

    uploadedTimeSeriesImgs.forEach((img) => {
      img.Bounds = img.Bounds.map((bound) => {
        return parseFloat(bound.toFixed(2));
      });
    });

    const formData = {
      ProjectId: data.ProjectId,
      ProjectName: projectList.find(
        (option) => option.projectId === data.ProjectId
      ).projectName,
      UploadedTimeSeriesImg: uploadedTimeSeriesImgs,
    };
    try {
      let url;
      if (props?.selectedTimeSeries) {
        url = `${config.API_HOST_URL}/api/Ecosystem/UpdateTimeSeriesDetails`;
      } else {
        url = `${config.API_HOST_URL}/api/Ecosystem/AddTimeSeriesDetails`;
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`Error adding time series data: ${response.status}`);
      }

      const responseData = await response.json();

      if (responseData.success) {
        ToastNotification.ShowSuccess(responseData.message);
        handleCancel();
      } else {
        ToastNotification.ShowError(responseData.message);
      }
    } catch (error) {
      console.error("Error adding time series data:", error);
      ToastNotification.ShowError("Error updating time series details");
    }
  };

  const clearAll = () => {
    setFormData({});
    setImageAdded(false);
    setUploaddata({});
    setSelectedYear("");
    setSelectedProjects("");
    setErrorMsg("");
    setUploadingPhotoForValue("");
    setUploadedImage(null);
    setImageName("");
  };

  const handleCancel = () => {
    props.setIsPopUpStatus(false);
    clearAll();
    props.propsCleardata();
    handleModalToggle(false);
  };
  return (
    <>
      <Modal
        title={
          props?.selectedTimeSeries
            ? "Update TimeSeries Data"
            : "Add TimeSeries Data"
        }
        isOpen={isModalOpen}
        onClose={() => handleModalToggle(false)}
        className="addproject_modal fieldofficer_modal timeseriesform_modal"
        actions={[
          <Button
            key="submit"
            type="submit"
            className="modal_btn submit_btn"
            onClick={handleSubmit(onSubmit)}
          >
            {props?.selectedTimeSeries ? "Update" : "Submit"}
          </Button>,
          <Button
            key="cancel"
            className="modal_btn cancel_btn"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul className="addproject_form">
            <li className="form-group field projectidfield">
              <Controller
                control={control}
                name="ProjectId"
                rules={{
                  required: "Please Select Project Id",
                }}
                defaultValue={selectedProjects || ""}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <>
                      <select
                        id="ProjectId"
                        name="ProjectId"
                        className="dropdown-130"
                        value={value}
                        onChange={(event) => {
                          const selectedValue = event.target.value;
                          setSelectedProjects(selectedValue);
                          onChange(selectedValue);
                        }}
                      >
                        <option value="">Select</option>
                        {projectList.map((option) => (
                          <option
                            key={option.projectId}
                            value={option.projectId}
                          >
                            {option.projectName}
                          </option>
                        ))}
                      </select>

                      {error && (
                        <div className="mandatory has-error">
                          {error.message}
                        </div>
                      )}
                      <label htmlFor="ProjectId">
                        <sup className="mandatory">*</sup>Select Project Id
                      </label>
                    </>
                  );
                }}
              />
            </li>

            <li className="form-group field">
              <Controller
                control={control}
                name="Year"
                rules={{
                  required: "Please Select Year",
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <>
                    <select
                      id="Year"
                      name="Year"
                      className="dropdown-130"
                      value={value}
                      onChange={(event) => {
                        setSelectedYear(parseInt(event.target.value));
                        onChange(parseInt(event.target.value));
                      }}
                    >
                      {/* Year options */}
                      <option value="">Select</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                    </select>
                    {error && (
                      <div className="mandatory has-error">{error.message}</div>
                    )}
                    <label htmlFor="Year">
                      <sup className="mandatory">*</sup>Select Year
                    </label>
                  </>
                )}
              />
            </li>

            <li class="form-group">
              <div className="content-title-text112 mapboundsfield_cont">
                <div className="hero-title13">
                  <div className="project-summary16">
                    Map Bounds
                    <div className="mapboundinfo_cont" onClick={toggleTooltip}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M7.2 12H8.8V7.2H7.2V12ZM8 5.6C8.22667 5.6 8.41667 5.52333 8.57 5.37C8.72333 5.21667 8.8 5.02667 8.8 4.8C8.8 4.57333 8.72333 4.38333 8.57 4.23C8.41667 4.07667 8.22667 4 8 4C7.77333 4 7.58333 4.07667 7.43 4.23C7.27667 4.38333 7.2 4.57333 7.2 4.8C7.2 5.02667 7.27667 5.21667 7.43 5.37C7.58333 5.52333 7.77333 5.6 8 5.6ZM8 16C6.89333 16 5.85333 15.79 4.88 15.37C3.90667 14.95 3.06 14.38 2.34 13.66C1.62 12.94 1.05 12.0933 0.63 11.12C0.21 10.1467 0 9.10667 0 8C0 6.89333 0.21 5.85333 0.63 4.88C1.05 3.90667 1.62 3.06 2.34 2.34C3.06 1.62 3.90667 1.05 4.88 0.63C5.85333 0.21 6.89333 0 8 0C9.10667 0 10.1467 0.21 11.12 0.63C12.0933 1.05 12.94 1.62 13.66 2.34C14.38 3.06 14.95 3.90667 15.37 4.88C15.79 5.85333 16 6.89333 16 8C16 9.10667 15.79 10.1467 15.37 11.12C14.95 12.0933 14.38 12.94 13.66 13.66C12.94 14.38 12.0933 14.95 11.12 15.37C10.1467 15.79 9.10667 16 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z"
                          fill="#868686"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <Controller
                  control={control}
                  name="Bounds"
                  rules={{
                    validate: (value) => {
                      if (
                        value &&
                        (!Array.isArray(value) || value.length !== 4)
                      ) {
                        return "Map Bounds must include exactly 4 coordinates";
                      }
                      return true;
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      <input
                        className="enter-input-text-1"
                        type="text"
                        id="Bounds"
                        placeholder="Enter Map Bounds"
                        name="Bounds"
                        value={
                          Array.isArray(value) ? value.join(",") : value || ""
                        }
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          setFormData((prevData) => ({
                            ...prevData,
                            Bounds: inputValue,
                          }));
                          onChange(inputValue.split(",").map(Number));
                        }}
                      />
                      {error && (
                        <div className="mandatory has-error">
                          {error.message}
                        </div>
                      )}
                      {showTooltip && (
                        <div className="mapbound_infowindow">
                          <button
                            onClick={closeTooltip}
                            className="mapinfowidw_clsbtn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="18"
                              viewBox="0 0 20 21"
                              fill="none"
                            >
                              <path
                                d="M9.99967 10.256L5.83301 6.08936M9.99967 10.256L14.1663 14.4227M9.99967 10.256L14.1663 6.08936M9.99967 10.256L5.83301 14.4227"
                                stroke="#7E7E7E"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                          <div>
                            The term "extent" refers to the spatial boundaries
                            or limits of a geographic feature or dataset. It is
                            represented by the minimum and maximum x and y
                            coordinates that define the spatial extent of the
                            feature or dataset in a map. Eg.
                            15.250110379,74.077749917,15.257275915, 74.082876954
                          </div>
                        </div>
                      )}
                    </>
                  )}
                />
              </div>
            </li>

            <li className="form-group inventory_cont">
              <Controller
                control={control}
                name="idProofPhoto"
                render={({
                  field: { onChange, onBlur, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <label htmlFor="forinventory">
                      <sup className="mandatory">*</sup>
                      Upload File
                    </label>
                    <div className="input-group divUploadFile">
                      <div className="qq-upload-drop-area">
                        <span>Drop files here to upload</span>
                      </div>
                      <div className="qq-upload-button">
                        Drag and Drop Files
                        <span className="redMandat">
                          Upload file type jpg, jpeg and png.
                        </span>
                      </div>
                      <input
                        id="choose"
                        type="file"
                        name="files"
                        accept=".jpg, .jpeg, .png, .gif"
                        onChange={(event) => {
                          const file = event.target.files[0];
                          const fileType = file.type;
                          if (!fileType.match("image")) {
                            setErrorMsg("Only image files are allowed");
                            return;
                          }
                          if (!selectedYear) {
                            setErrorMsg("Please select a year first");
                            return;
                          }

                          handleImageChange(
                            file,
                            selectedYear,
                            prevData.Bounds
                              ? prevData.Bounds
                              : "0.0, 0.0, 0.0, 0.0"
                          );
                          setImageAdded(false);
                        }}
                      />
                      <p id="upload-message" className="treedetmodal_uploadmsg">
                        {imageAdded ? null : "Image uploaded successfully!"}
                      </p>
                      <a
                        className="treedetmodal_addbtn"
                        onClick={() => {
                          const newImage =
                            UploadImagedata.imageTreeUpload[
                              UploadImagedata.imageTreeUpload.length - 1
                            ];
                          const updatedImageTreeUpload = [
                            ...Uploaddata.imageTreeUpload,
                            newImage,
                          ];
                          setUploaddata((prevUploaddata) => ({
                            ...prevUploaddata,
                            imageTreeUpload: updatedImageTreeUpload,
                          }));
                          setImageAdded(true);
                        }}
                      >
                        {imageAdded ? null : "Add Image"}
                      </a>
                    </div>
                    {/* Error Message */}
                    {errorMsg && (
                      <div className="mandatory has-error"> {errorMsg}</div>
                    )}
                    {/* Uploaded Images Table */}
                    {Uploaddata?.imageTreeUpload &&
                      Uploaddata.imageTreeUpload.length > 0 && (
                        <div className="uploadedimgtbl_cont">
                          <table>
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>Year</th>
                                <th>Uploaded Image</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Uploaddata.imageTreeUpload.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.year}</td>
                                  <td>
                                    <img
                                      src={`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/${selectedProjects}/TimeSeries/${item.imageName}`}
                                      alt="Uploaded"
                                    />
                                  </td>
                                  <td>
                                    <a
                                      onClick={() => {
                                        const updatedImageTreeUpload = [
                                          ...Uploaddata.imageTreeUpload,
                                        ];
                                        updatedImageTreeUpload.splice(index, 1);
                                        setUploaddata((prevUploaddata) => ({
                                          ...prevUploaddata,
                                          imageTreeUpload:
                                            updatedImageTreeUpload,
                                        }));
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        height="24px"
                                      >
                                        <path d="M 10 2 L 9 3 L 3 3 L 3 5 L 21 5 L 21 3 L 15 3 L 14 2 L 10 2 z M 4.3652344 7 L 5.8925781 20.263672 C 6.0245781 21.253672 6.877 22 7.875 22 L 16.123047 22 C 17.121047 22 17.974422 21.254859 18.107422 20.255859 L 19.634766 7 L 4.3652344 7 z" />
                                      </svg>
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}

                    <span>{fileControl ? fileNameControl : ""}</span>
                  </>
                )}
              />
            </li>
          </ul>
        </form>
      </Modal>
    </>
  );
}
