import React from "react";
import { Polyline } from "@react-google-maps/api";

export default function StreamLayer({ streamData }) {
  const options = {
    strokeColor: "#4297e7",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "#4297e7",
    fillOpacity: 0.8,
  };
  //   blue shades #5fd5e7 , #4297e7
  //   red #FF0000

  return (
    <>
      {streamData?.length > 0 &&
        streamData?.map((eachFeature, index) => {
          let cords = eachFeature.geometry?.coordinates;
          let newCords = [];
          cords?.map((item) => {
            let obj = {};
            obj["lat"] = parseFloat(item?.[1]);
            obj["lng"] = parseFloat(item?.[0]);
            newCords.push(obj);
          });
          return <Polyline path={newCords} options={options} />;
        })}
    </>
  );
}
