import React, { Component } from 'react';
import config from '../utils/config';
import { connect } from 'react-redux';
import Layout from './Common/Layout/Layout';

class AppContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.checkToken();
    }
    checkToken = () => {
        let tokenTime = new Date(this.props.tokenTime);
        tokenTime.setHours(tokenTime.getHours() + 22);
        let tokenTimeDiff = tokenTime - new Date();
        if (tokenTimeDiff < 0 || this.props.token.length === 0) {
            this.fetchToken();
        }
    }
    fetchToken = () => {
        let formdata = new FormData();
        formdata.append('username', config.AUTH_ID);
        formdata.append('password', config.AUTH_SECRET);
        fetch(`${config.API_HOST_URL}/api/token`, { method: 'POST', body: formdata })
            .then(res => res.json())
            .then(res => {
                this.props.setAuthToken(res.access_token);
                //Uncomment below method When get API is ready
                //this.fetchConfiguration(res.access_token);
            })
            .catch(err => {
                console.log(err)
            });
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        return (
            <Layout />
        );
    }
}

function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        tokenTime: state.configuration.tokenTime,
        languageGUID: state.configuration.languageGUID,
        currencyGUID: state.configuration.currencyGUID,
        currencySymbol: state.configuration.currencySymbol
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setAuthToken: (token) => dispatch({ type: 'SET_TOKEN', token: token }),
        setLanguageGuid: (guid) => dispatch({ type: 'SET_LANGUAGE_GUID', guid: guid }),
        setCurrencyGuid: (guid) => dispatch({ type: 'SET_CURRENCY_GUID', guid: guid }),
        setCurrencySymbol: (currencySymbol) => dispatch({ type: 'SET_CURRENCY_SYMBOL', currencySymbol: currencySymbol })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);