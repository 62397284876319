import React, { Component, ReactDOM } from "react";
import { connect } from 'react-redux';
import config from "../../../utils/config";
import "@patternfly/react-core/dist/styles/base.css";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

class GrowManagerHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LookerStudioLink:""
        }
        this.mounted = true;
        
    }

    componentDidMount() {
        this.mounted = true;
        this.GetGlobalSettings("Admin Dashboard");
    }

    MapKeyInArray = (responseData, keyname) => {
        let myArray = responseData.find(obj => obj.key === keyname)
        if (myArray) {
            return myArray.value;
        }
    }

    GetGlobalSettings = (GroupName) => {debugger
        let UserDetail = {
            GroupName: GroupName
        }
        let uri = `${config.API_HOST_URL}/api/projects/GetGlobalGeoupValue`;
        let fetchOptions = {
            method: "POST",
            body: JSON.stringify(UserDetail),
            headers: {
                Authorization: `Bearer ${this.props.token}`,
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        };

        fetch(uri, fetchOptions)
            .then(response => {
                return response.json().then(data => {
                    if (data.success) {
                        let responseData = data.data;
                        if (this.mounted) {
                            this.setState({ LookerStudioLink: this.MapKeyInArray(responseData, "LookerStudioLink") });
                        }
                    } else {
                        let errors = {};
                        errors["message"] = data.message;
                        if (this.mounted) {
                            this.setState({ errors: errors });
                        }
                    }
                });

            })
            .catch(error => {
                if (this.mounted) {
                    console.log(error);
                    this.setState({
                        errorMsg: error,
                        IsLoader: false
                    })
                }
            });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    
    render() {
        return (
            <>
                <div className="headbottom_cont">
                    <ul className="headbottom_content">
                        <li className="heading_cont">Dashboard</li>
                    </ul>
                </div>

                <div className="growmanhome_cont" style={{height:"1000px"}}>
                  <iframe 
                     src= {this.state.LookerStudioLink}
                     frameborder="0"
                     style={{height:"900px", width:"100%"}}
                     allowfullscreen>
                   </iframe>
                </div >
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUserData: data => dispatch({ type: "SET_USER_DETAILS", data: data }),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GrowManagerHome);
