import React, { useState, useCallback, useEffect } from "react";
import { Modal, Button } from "@patternfly/react-core";
import config from "../../../utils/config";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import moment from "moment";
import ToastNotification from "../../Common/ToastNotification/ToastNotification";

export default function FieldOfficerModal(props) {
  const [isModalOpen, setIsModalOpen] = useState(props.IsOpen);
  const [dob, setDob] = useState(null);
  const [formData, setFormData] = useState({});
  const [profilePhoto, setProfilePhoto] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  const [projectList, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [uploadfile, setUploadfile] = useState([]);
  const [uploadProfile, setUploadProfile] = useState([]);
  const [isProfileImageChange, setIsProfileImageChange] = useState(false);
  const [isProfileIdChange, setIsProfileIdChange] = useState(false);
  const [userList, setUserList] = useState([]);
  const { userData } = props;
  const ProjectStatus = "Verified";
  const clearError = (name) => {
    setError(name, null);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm();

  const GetProjectByFilter = async () => {
    if (isMounted) {
      const UserDetail = {
        Status: ProjectStatus,
      };
      const uri = `${config.API_HOST_URL}/api/projects/GetProjectDataByFilter`;
      const fetchOptions = {
        method: "POST",
        body: JSON.stringify(UserDetail),
        headers: {
          WebsiteGuid: props.websiteGUID,
          LanguageGuid: props.languageGUID,
          Authorization: `Bearer ${props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      try {
        const response = await fetch(uri, fetchOptions);
        const data = await response.json();
        if (data.success) {
          const projectListData = data.data;
          setProjects(projectListData);
        } else {
          console.error("Error fetching projects", data.message);
        }
      } catch (error) {
        console.error("Error fetching projects", error);
      }
    }
  };

  useEffect(() => {
    if (props?.selectedOfficer !== null) {
      const date = moment(props.selectedOfficer.dob).format("YYYY-MM-DD");
      setDob(date);
      setSelectedProjects(props?.selectedOfficer?.projectIds);
      setFormData({
        parentUserGuid: props.userId,
      });
      updateFormValues(props?.selectedOfficer);
    } else {
      setFormData({
        parentUserGuid: props.userId,
        dob: null,
        userRole: "Field Officer",
        email: "",
        password: "",
      });
    }
  }, [props?.selectedOfficer]);

  useEffect(() => {
    GetProjectByFilter();
    GetUserList();
  }, []);

  const handleProjectChange = (event) => {
    const selectedOptions = Array.prototype.slice.call(
      event.target.selectedOptions
    );
    const selectedProjectIds = selectedOptions.map((option) => option.value);
    setSelectedProjects(selectedProjectIds);
  };

  const updateFormValues = (data) => {
    Object.keys(data).forEach((key) => {
      if (key === "dob" && data[key]) {
        const date = moment(data[key]).format("YYYY-MM-DD");
        setDob(date);
        setValue("dob", date);
        //     // console.log(`dob value: ${data[key]}`);
        //     // const date = new Date(data[key]);
        //     // if (isNaN(date.getTime())) {
        //     //   console.error(`Invalid date format: ${data[key]}`);
        //     // } else {
        //     //   setDob(date);
        //     //   setValue(key, date.toUTCString());
        //     // }
      } else {
        setValue(key, data[key]);
      }
    });
    setFormData(data);
    setSelectedProjects(data.projectIds);
    setProfileImage(props.selectedOfficer.profileImage);
    setProfilePhoto(props.selectedOfficer.idProofPhoto);
  };

  const handleFileChange = async (e, profilenametype) => {
    let filesArr = e.target.files;
    const array = [];
    array.length = 0;
    if (filesArr.length > 0) {
      array.push(filesArr[0]);
    }
    if (profilenametype === "idProofPhoto") {
      setUploadfile(array);
      setIsProfileIdChange(true);
    } else {
      setUploadProfile(array);
      setIsProfileImageChange(true);
    }

    const file = e.target.files[0];
    // Upload file to S3
    const fileData = new FormData();
    fileData.append("file", file);

    const response = await fetch(
      `${config.API_HOST_URL}/api/FeildOfficer/UploadDocument`,
      {
        method: "POST",
        body: fileData,
      }
    );

    const data = await response.json();

    if (data.success) {
      let imageName = data.data;
      if (profilenametype === "idProofPhoto") {
        setProfilePhoto(imageName);
      } else {
        setProfileImage(imageName);
      }

      // Return the S3 path
      return imageName;
    } else {
      console.error("Error uploading file", data.message);
      return null;
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleModalToggle = useCallback(() => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  }, []);

  const GetUserList = () => {
    let url = `${config.API_HOST_URL}/api/Login/GetUserList`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = JSON.parse(data.UserList);
        setUserList(responseData);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const getSaveFieldOfficerDetails = (data) => {
    const url = `${config.API_HOST_URL}/api/FeildOfficer/AddFieldOfficerDetails`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          ToastNotification.ShowError("Field Officer Not Added Successfully");
          throw new Error(`HTTP error! status: ${response.status}`);
        } else {
          if (props?.selectedOfficer != null) {
            ToastNotification.ShowSuccess("Field Officer Updated Successfully");
          } else {
            ToastNotification.ShowSuccess("Field Officer Added Successfully");
          }
          return response.json();
        }
      })
      .then((data) => {
        typeof props?.getFieldOfficerDetails(props?.userId) == "function" &&
          props?.getFieldOfficerDetails(props?.userId);
        handleModalToggle();
        clearAll();
      })
      .catch((error) => {
        console.error("Error in request", error);
      });
  };
  const onSubmit = (data) => {
    data.projectIds = selectedProjects;
    data.userId = formData?.userId;
    data.parentUserGuid = props.userId;
    data.userRole = "Field Officer";
    data.profileImage = profileImage;
    data.idProofPhoto = profilePhoto;
    getSaveFieldOfficerDetails(data);
  };

  const removeFileFromList = (e, profilenametype) => {
    if (profilenametype === "idProofPhoto") {
      var array = [...uploadfile]; // make a separate copy of the array
      array.splice(e.target.id, 1);
      setUploadfile(array);
    } else {
      var arrays = [...uploadProfile];
      arrays.splice(e.target.id, 1);
      setUploadProfile(array);
    }
  };

  const handleCancel = () => {
    props.setIsPopUpStatus(false);
    clearAll();
    handleModalToggle(false);
  };

  const clearAll = () => {
    setFormData({});
    props?.setSelectedOfficer(null);
  };

  const handleDobChange = (value) => {
    setDob(value);
    const age = calculateAge(value);
    if (age < 18) {
      setError("dob", {
        message: "Age must be greater than 18",
      });
      setValue("age", "");
    } else {
      setFormData((prev) => ({ ...prev, age }));
      setValue("age", age);
      setError("dob", { message: "" });
    }
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  return (
    <>
      <Modal
        title={
          props.selectedOfficer ? "Update Field Officer" : "Add Field Officer"
        }
        isOpen={isModalOpen}
        onClose={() => handleModalToggle(false)}
        className="addproject_modal fieldofficer_modal"
        actions={[
          <Button
            key="submit"
            className="modal_btn submit_btn"
            onClick={handleSubmit(onSubmit)}
          >
            {props.selectedOfficer ? "Update" : "Submit"}
          </Button>,
          <Button
            key="cancel"
            className="modal_btn cancel_btn"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>,
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
          <ul className="addproject_form fieldofficer_form">
            <li className="form-group field">
              <Controller
                control={control}
                name="firstName"
                rules={{
                  required: "Please Enter First Name",
                  pattern: {
                    value: /^[a-zA-Z]+$/,
                    message: "Only alphabets are allowed!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      id="firstName"
                      placeholder="Enter first name"
                      name="firstName"
                      value={formData?.firstName ? formData?.firstName : ""}
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (!/^[a-zA-Z]$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="firstName">
                      <sup className="mandatory">*</sup>Enter First Name
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="lastName"
                rules={{
                  required: "Please Enter Last Name",
                  pattern: {
                    value: /^[a-zA-Z]+$/,
                    message: "Only alphabets are allowed!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={formData?.lastName ? formData?.lastName : ""}
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (!/^[a-zA-Z]$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Enter Last Name"
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="lastName">
                      <sup className="mandatory">*</sup>Enter Last Name
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            {/* <li className="form-group field">
              <Controller
                control={control}
                name="guardianName"
                rules={{
                  required: "Please Enter Guardian Name",
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Only alphabets are allowed!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      id="guardianName"
                      name="guardianName"
                      value={
                        formData?.guardianName ? formData?.guardianName : ""
                      }
                      placeholder="Enter Guardian Name"
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        const currentValue = event.target.value;
                        const cursorPosition = event.target.selectionStart;
                        const charBeforeCursor =
                          currentValue[cursorPosition - 1];
                        if (event.key === " ") {
                          if (charBeforeCursor === " ") {
                            event.preventDefault(); // prevent multiple spaces
                          }
                        } else if (!/^[a-zA-Z]$/.test(event.key)) {
                          event.preventDefault(); // prevent numbers and special characters
                        }
                      }}
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="guardianName">
                      <sup className="mandatory">*</sup>Enter Guardian Name
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li> */}
            <li className="form-group field">
              <Controller
                control={control}
                name="email"
                rules={{
                  required: "Please Enter Email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address!",
                  },
                  validate: (value) => {
                    const currentUserId = formData?.userId;
                    const userEmails = userList
                      .filter(
                        (item) =>
                          item.UserId !== currentUserId && item.Email !== null
                      )
                      .map((item) => item.Email);
                    return userEmails.includes(value)
                      ? "Email address is already in use"
                      : undefined;
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      autocomplete="off"
                      autofill="off"
                      data-random={Math.random()}
                      value={formData?.email ? formData?.email : ""}
                      placeholder="Enter Email"
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="email">
                      <sup className="mandatory">*</sup>Enter Email
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="contactNumber"
                rules={{
                  required: "Please Enter Contact",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Only numeric characters are allowed!",
                  },
                  minLength: {
                    value: 10,
                    message: "Contact must be at least 10 digits long!",
                  },
                  maxLength: {
                    value: 10,
                    message: "Contact must not be more than 10 digits long!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="tel"
                      id="contactNumber"
                      name="contactNumber"
                      value={
                        formData?.contactNumber ? formData?.contactNumber : ""
                      }
                      placeholder="Enter Contact Number"
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (
                          event.target.value.length >= 10 &&
                          /\d/.test(event.key)
                        ) {
                          event.preventDefault(); // prevent entering more than 15 digits
                        }
                        if (!/^[0-9]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="contactNumber">
                      <sup className="mandatory">*</sup>Enter Contact Number
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="gender"
                rules={{
                  required: "Please Select Gender",
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <select
                      id="gender"
                      name="gender"
                      className="dropdown-130"
                      value={formData?.gender ? formData?.gender : ""}
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Transgender">Transgender</option>
                    </select>

                    <label for="gender">
                      <sup className="mandatory">*</sup>Select Gender
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="dob"
                // rules={{
                //   required: "Please Select Date Of Birth",
                // }}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="date"
                      placeholderText="Select date of birth"
                      className="form-control"
                      selected={dob}
                      value={dob}
                      name="dob"
                      onChange={(e) => {
                        handleDobChange(e.target.value);
                        onChange(e.target.value);
                      }}
                      maxDate={new Date()}
                    />
                    <label for="dob">
                      {/* <sup className="mandatory">*</sup> */}
                      Enter DOB
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="age"
                // rules={{
                //   required: "Please Enter Age",
                //   pattern: {
                //     value: /^[0-9]+$/,
                //     message: "Only numeric characters are allowed!",
                //   },
                // }}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="number"
                      name="age"
                      id="age"
                      disabled
                      value={formData?.age ? formData?.age : ""}
                      placeholder="Enter Age"
                      onKeyDown={(e) => {
                        const allowedKeys = ["ArrowUp", "Backspace", "Delete"];
                        const numberKeys = [...Array(10).keys()].map((key) =>
                          key.toString()
                        );
                        if (
                          ["e", "E", "+", "-"].includes(e.key) ||
                          (!allowedKeys.includes(e.key) &&
                            !numberKeys.includes(e.key))
                        ) {
                          e.preventDefault();
                        }
                        if (e.key === "Backspace" || e.key === "Delete") {
                          return;
                        }
                        const newValue = Number(e.target.value + e.key);
                        if (newValue < 1 || e.target.value.length >= 3) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        handleInputChange(e);
                        onChange(e);
                      }}
                    />
                    <label for="age">
                      {/* <sup className="mandatory">*</sup> */}
                      Enter Age
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="education"
                rules={{
                  required: "Please Select Education",
                  // pattern: {
                  //   value: /^[a-zA-Z\s]+$/,
                  //   message: "Only alphabets are allowed!",
                  // },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <select
                      id="education"
                      name="education"
                      className="dropdown-130"
                      value={formData?.education ? formData?.education : ""}
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    >
                      <option value="">Select Education</option>
                      <option value="Graduation">Graduation</option>
                      <option value="Post Graduation">Post Graduation</option>
                      <option value="Diploma">Diploma</option>
                      <option value="HSC">HSC</option>
                      <option value="SSC">SSC</option>
                      <option value="Others">Others</option>
                    </select>
                    <label for="education">
                      <sup className="mandatory">*</sup>Enter education
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="maritalStatus"
                // rules={{
                //   required: "Please Select Marital Status",
                // }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <select
                      id="maritalStatus"
                      name="maritalStatus"
                      className="dropdown-130"
                      value={
                        formData?.maritalStatus ? formData?.maritalStatus : ""
                      }
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    >
                      <option value="">Select Marital Status</option>
                      <option value="Married">Married</option>
                      <option value="Unmarried">Unmarried</option>
                    </select>
                    <label for="maritalStatus">
                      {/* <sup className="mandatory">*</sup> */}
                      Select Marital Status
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>

            {/* <li className="form-group field">
              <Controller
                control={control}
                name="userId"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <>
                    <input
                      type="text"
                      name="userId"
                      id="userId"
                      value={formData?.userId ? formData?.userId : ""}
                      placeholder="Enter User ID"
                      readOnly
                    />
                    <label for="userId">
                      <sup className="mandatory">*</sup>User ID
                    </label>
                  </>
                )}
              />
            </li> */}
            <li className="form-group field">
              <Controller
                control={control}
                name="password"
                rules={{
                  required: "Please Enter Password",
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
                    message: "Please Give Strong Password",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="password"
                      id="password"
                      placeholder="Enter Password"
                      name="password"
                      value={formData?.password ? formData?.password : ""}
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="password">
                      <sup className="mandatory">*</sup>Enter Password
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            {/* <li className="form-group field">
              <Controller
                control={control}
                name="userRole"
                rules={{
                  required: "Please Enter User Role",
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Only alphabets are allowed!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      id="userRole"
                      name="userRole"
                      value={formData?.userRole ? formData?.userRole : ""}
                      placeholder="Enter User Role"
                      onKeyDown={(event) => {
                        if (
                          event.key === "Backspace" ||
                          event.key === "Delete"
                        ) {
                          return;
                        }
                        if (!/^[a-zA-Z\s]$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="userRole">
                      <sup className="mandatory">*</sup>Enter User Role
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li> */}
            <li className="form-group field projectidfield">
              <Controller
                control={control}
                name="projectIds"
                rules={{
                  required: "Please Select Project Ids",
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <Select
                      isMulti
                      name="projectIds"
                      value={projectList?.filter((option) =>
                        selectedProjects?.includes(option.projectId)
                      )}
                      options={projectList}
                      getOptionLabel={(option) => option.projectName} // Add this line
                      getOptionValue={(option) => option.projectId} // Add this line
                      onChange={(value) => {
                        const selectedProjectIds = value.map(
                          (option) => option.projectId
                        );
                        setSelectedProjects(selectedProjectIds);
                        onChange(selectedProjectIds);
                      }}
                    />

                    <label htmlFor="projectIds">
                      <sup className="mandatory">*</sup>Project Names
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group field">
              <Controller
                control={control}
                name="nationalIdProof"
                rules={{
                  required: "Please Select National Id Proof",
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <select
                      id="nationalIdProof"
                      name="nationalIdProof"
                      className="dropdown-130"
                      value={
                        formData?.nationalIdProof
                          ? formData?.nationalIdProof
                          : ""
                      }
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    >
                      <option value="">Select National Id Proof</option>
                      <option value="Aadhaar Card">Aadhaar Card</option>
                      <option value="Pan Card">Pan Card</option>
                      <option value="Voter Id Card">Voter Id Card</option>
                    </select>
                    <label for="nationalIdProof">
                      <sup className="mandatory">*</sup>Select National Id Proof
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            {/* <li className="form-group field">
              <Controller
                control={control}
                name="proofNo"
                rules={{
                  required: "Please enter proof no",
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Only alphabets are allowed!",
                  },
                }}
                render={({
                  field: { onChange, onBlur, value, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <input
                      type="text"
                      id="proofNo"
                      placeholder="Enter Proof No"
                      name="proofNo"
                      value={formData?.proofNo ? formData?.proofNo : ""}
                      onChange={(event) => {
                        handleInputChange(event);
                        onChange(event);
                      }}
                    />
                    <label for="proofNo">
                      <sup className="mandatory">*</sup>Enter Proof No
                    </label>
                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li> */}

            <li className="form-group inventory_cont idproofphoto_field">
              <Controller
                control={control}
                name="idProofPhoto"
                rules={{
                  required: "Please Upload Id Proof Photo",
                }}
                render={({
                  field: { onChange, onBlur, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <label for="forinventory">
                      <sup className="mandatory">*</sup>
                      Proof Photo
                    </label>
                    <div className="input-group divUploadFile">
                      <div className="qq-upload-drop-area">
                        <span>Drop files here to upload</span>
                      </div>
                      <div className="qq-upload-button">
                        Drag and Drop Files
                        <span className="redMandat">
                          Upload file type jpg, jpeg, png File size limit 1MB
                        </span>
                      </div>
                      <input
                        id="choose"
                        type="file"
                        name="files"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            if (file.size > 1048576) {
                              setError("idProofPhoto", {
                                type: "anual",
                                message: "File size should not exceed 1MB",
                              });
                            } else {
                              clearError("idProofPhoto");
                              handleFileChange(e, "idProofPhoto");
                              onChange(file);
                            }
                          }
                        }}
                      />
                    </div>
                    <div>
                      <ul id="fileList" className="ul-project-files">
                        {uploadfile &&
                          uploadfile?.map((item, index) => (
                            <li class="file-list-li" key={index}>
                              <i class="fa fa-check"></i>
                              {item.name}
                              <a
                                class="remove-files-list"
                                id={index}
                                onClick={(e) =>
                                  removeFileFromList(e, "idProofPhoto")
                                }
                              >
                                Remove
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                    {!isProfileIdChange && profilePhoto && (
                      <div>
                        {" "}
                        <ul id="fileList" className="ul-project-files">
                          {" "}
                          <li class="file-list-li">
                            <i class="fa fa-check"></i>
                            {profilePhoto}
                          </li>
                        </ul>
                      </div>
                    )}

                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
            <li className="form-group inventory_cont">
              <Controller
                control={control}
                name="profileImage"
                rules={{
                  required: "Please Upload Profile Image",
                }}
                render={({
                  field: { onChange, onBlur, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <label for="forinventory">
                      <sup className="mandatory">*</sup>
                      Profile Image
                    </label>
                    <div className="input-group divUploadFile">
                      <div className="qq-upload-drop-area">
                        <span>Drop files here to upload</span>
                      </div>
                      <div className="qq-upload-button">
                        Drag and Drop Files
                        <span className="redMandat">
                          Upload file type jpg, jpeg, png File size limit 1MB
                        </span>
                      </div>
                      <input
                        id="choose"
                        type="file"
                        name="files"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            if (file.size > 1048576) {
                              setError("profileImage", {
                                type: "anual",
                                message: "File size should not exceed 1MB",
                              });
                            } else {
                              handleFileChange(e, "profileImage");
                              onChange(file);
                            }
                          }
                        }}
                      />
                    </div>
                    <div>
                      <ul id="fileList" className="ul-project-files">
                        {uploadProfile &&
                          uploadProfile?.map((item, index) => (
                            <li class="file-list-li" key={index}>
                              <i class="fa fa-check"></i>
                              {item.name}
                              <a
                                class="remove-files-list"
                                id={index}
                                onClick={(e) =>
                                  removeFileFromList(e, "profileImage")
                                }
                              >
                                Remove
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                    {!isProfileImageChange && profileImage && (
                      <div>
                        {" "}
                        <ul id="fileList" className="ul-project-files">
                          {" "}
                          <li class="file-list-li">
                            <i class="fa fa-check"></i>
                            {profileImage}
                          </li>
                        </ul>
                      </div>
                    )}

                    <div className="mandatory has-error">{error?.message}</div>
                  </>
                )}
              />
            </li>
          </ul>
        </form>
      </Modal>
    </>
  );
}
