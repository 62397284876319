import React, { Component } from "react";
import { navigate } from "@reach/router";
import queryString from "query-string";

class SuccessMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            WebSiteUrl: "",
            PrimaryColor: ""
        }
    }

    componentDidMount() {
        let Url = new URLSearchParams(window.location.search).get('Url');
        let Color = new URLSearchParams(window.location.href).get('Color');
        this.setState({ WebSiteUrl: Url, PrimaryColor: Color });

    }
    SuccessClick = websitevalue => () => {
        let WebSiteUrl = websitevalue;
        window.location = WebSiteUrl;
    }
    render() {
        const websitebackgroundcolorcodestyle = {
            backgroundColor: this.state.PrimaryColor,
            borderColor: this.state.PrimaryColor
        };
        return (

            <div className="paysuccesscont">
                <div>Thank you for your contribution.<br></br>Your carbon emissions certificate has been sent to your email address. </div>
                <div>
                    <button style={websitebackgroundcolorcodestyle} type="button" onClick={this.SuccessClick(this.state.WebSiteUrl)} >Back to calculator</button>
                </div>

            </div>
        )
    }
}
export default SuccessMessage;