import React from "react";
import { Polygon } from "@react-google-maps/api";

export default function Multipolygon({ multiPolygonData, layerName }) {
  const options = {
    strokeWeight: 1,
    strokeOpacity: 1,
    strokeColor: "black",
    fillOpacity: layerName === "Basin" ? 0.8 : 0.5,
    fillColor: layerName === "Basin" ? "#c43c39" : "#85b66f",
  };

  return (
    <>
      {multiPolygonData?.length > 0 &&
        multiPolygonData?.map((eachFeature, index) => {
          let multiPoly =
            layerName === "Basin"
              ? eachFeature.geometry?.coordinates?.[0] || []
              : eachFeature.geometry?.coordinates?.[0]?.[0] || [];
          let newMultiCord = [];
          {
            layerName === "Basin" &&
              multiPoly?.map((item) => {
                let polygonCords = item;
                let newCords = [];
                polygonCords?.map((cord) => {
                  let obj = {};
                  obj["lat"] = parseFloat(cord?.[1]);
                  obj["lng"] = parseFloat(cord?.[0]);
                  newCords.push(obj);
                });
                newMultiCord.push(newCords);
              });
          }
          {
            layerName === "Tree Canopy Cover - LIDAR" &&
              multiPoly?.map((cord) => {
                let obj = {};
                obj["lat"] = parseFloat(cord?.[1]);
                obj["lng"] = parseFloat(cord?.[0]);
                newMultiCord.push(obj);
              });
          }
          return <Polygon key={index} paths={newMultiCord} options={options} />;
        })}
    </>
  );
}
