import React, { useEffect, useState } from "react";
import { Modal } from "@patternfly/react-core";
import ProjectLevelMedia from "./ProjectLevelMedia";
import config from "../../../utils/config";
import { globalHistory } from "@reach/router";

export default function ProjectLevelImages(props) {
  const [projectId, setProjectId] = useState("");
  const [isModalProjectImage, setisModalProjectImage] = useState(false);
  const [ImgNames, setImagesNames] = useState([]);

  const handleModalProjectImage = () => {
    setisModalProjectImage(!isModalProjectImage);
    if (!isModalProjectImage) {
      document.body.classList.remove("prolistviewbackdrop_cont");
      document.body.classList.add("prolistviewkmediabg_cont");
    } else {
      document.body.classList.add("prolistviewbackdrop_cont");
      document.body.classList.remove("prolistviewkmediabg_cont");
    }
  };

  useEffect(() => {
    const extractProjectId = () => {
      let currentPath = globalHistory.location.pathname;
      console.log("Current path:", currentPath);
      let parts = currentPath.split(":");
      if (parts.length > 1) {
        let Id = parts[1].replace("/", "");
        setProjectId(Id);
        console.log("Extracted Id:", Id);
      } else {
        console.error("Unexpected path format, cannot extract project Id");
      }
    };

    extractProjectId();

    const unlisten = globalHistory.listen((location) => {
      extractProjectId();
    });

    return () => {
      if (unlisten) unlisten();
    };
  }, []);

  useEffect(() => {
    if (projectId) {
      GetProjectImages();
    }
  }, [projectId]);

  const GetProjectImages = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectsImages/${projectId}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 5000,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((text) => {
        if (!text) {
          throw new Error("Response body is empty");
        }
        return JSON.parse(text);
      })
      .then((data) => {
        let responseData = data?.data[0];
        let ImagesNames = responseData?.uploadedImages?.map(
          (image) => image?.imageName ?? ""
        );
        if (ImagesNames && ImagesNames.length > 0) {
          let filteredImagesNames = ImagesNames.filter(
            (imageName) => imageName !== null && imageName !== ""
          );
          if (filteredImagesNames.length > 0) {
            setImagesNames(filteredImagesNames);
          } else {
            setImagesNames(["No images uploaded"]);
          }
        } else {
          setImagesNames(["No images uploaded"]);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  return (
    <div className="prolevelimages_cont">
      <ul className="prolevelimages_list">
        {ImgNames.length > 0 ? (
          ImgNames[0] === "No images uploaded" ? (
            <p>No images uploaded</p>
          ) : (
            ImgNames.map((imageName, index) => (
              <li>
                <a key={index} onClick={handleModalProjectImage}>
                  <img
                    src={`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/ProjectLevelImages/${projectId}/${imageName}`}
                  />
                </a>
              </li>
            ))
          )
        ) : (
          <p>No images uploaded</p>
        )}
      </ul>
      <Modal
        title="listViewTitle"
        ariaLabel="listView"
        ariaLabelledby="list-view-title"
        isOpen={isModalProjectImage}
        onClose={handleModalProjectImage}
        className="projdetailpage_modal projdetimgcarusel_modal"
      >
        <ProjectLevelMedia images={ImgNames} />
      </Modal>
    </div>
  );
}
