import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../utils/config";
import { navigate } from "@reach/router";
import "../EcosystemRestorationProject/global.css";
import "../EcosystemRestorationProject/ecosystabsall.css";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import Header from "../EcosystemCommon/Header";
import LeftNavbar from "../EcosystemCommon/LeftNavbar";
import { globalHistory } from "@reach/router";
import { v4 as uuidv4 } from "uuid";
import Multiselect from "multiselect-react-dropdown";
import ToastNotification from "../Common/ToastNotification/ToastNotification";
import { DataList } from "@patternfly/react-core";
//import MultiSelect from "@kenshooui/react-multi-select";

class TreeTiesAddProjectForms1Own extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectowner: [],
      selectfarmer: "",
      noowner: 0,
      isDisputed: "",
      isOnboardedFarmer: "",
      OwnershipId: "",
      commonMastersList: [],
      ownertypeDropdownList: [],
      //UploadedFileName: [],
      ownerproofdDropdownList: [],
      selectedownertype: "",
      isOnboardedFarmer: "",
      // proofofownership: "",
      projectId: "",
      farmer: "",
      ownertype: "",
      owneronboard: "",
      farmer: "",
      ownerName: "",
      owneraddress: "",
      owneraadhar: "",
      contactno: "",
      ownerproof: "",
      ownershipListId: "",
      ListObjOwner: [],
      files: [],
      S3array: [],
      patnershipList: [],
      UserData: [],
      added_Farmer: [],
      farmerUserList: [],
      farmer_Id: "",
      projectLocation: [],
      landParcelsList: [],
      LandParcelListdropdown: [],
      activeOwnerShipRecord: null,
      OwnerList: [],
      activeownerdetail: null,
      ownerDropdown: [],
      OwnerListId: "",
      // ErrorMsg: {},
      MaxFileSizeToUploadInMB: 5,
    };

    this.mounted = true;
    // this.handleselectowner = this.handleselectowner.bind(this);
    // this.multiselectRef_SelectOwner = React.createRef();
  }
  componentDidMount() {
    let currentPath = globalHistory.location.pathname;
    let Id = currentPath.split(":")[1].replace("/", "");
    this.setState({ projectId: Id });
    this.GetOwnership(Id);
    //this.Uploadfile(Id);
    this.GetPatnership(Id);
    this.GetNGOUserList();
    this.GetCommonMasters();
    this.GetProjectLocation(Id);
    //this.AddOwner();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  GetCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMaster/Ownership`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          commonMastersList: data,
        });
        if (this.state.commonMastersList.length > 0) {
          var OwnershipType = this.state.commonMastersList.filter(
            (x) => x.type == "Ownership Type"
          );
          this.setState({ ownertypeDropdownList: OwnershipType });

          // var Owneronboard = this.state.commonMastersList.filter(
          //   (x) => x.type == "Is this Owner an Onboarded Farmer"
          // );
          // this.setState({ owneronboardDropdownList: Owneronboard });

          var OwnerOwnerproof = this.state.commonMastersList.filter(
            (x) => x.type == "Proof of Ownership"
          );
          this.setState({ ownerproofdDropdownList: OwnerOwnerproof });
        }
      })

      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  GetNGOUserList = () => {
    let url = `${config.API_HOST_URL}/api/Login/GetNGOUserList`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = JSON.parse(data.NGOUserList);
        //  console.log("ngouser", data.NGOUserList);
        this.setState({
          UserData: responseData,
          farmerUserList: responseData.filter(
            (x) => x.TypeOfIncorporation === "Farmer"
          ),
        });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  GetProjectLocation = (ProjectId) => {
    let projId = { ProjectId: ProjectId };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectLocation`;
    fetch(url, {
      method: "Post",
      body: JSON.stringify(projId),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        this.setState({
          projectLocation: responseData,
          landParcelsList: responseData.landParcels,
        });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  GetPatnership = (ProjectId) => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetPartnership/${ProjectId}`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("data1", data);
        if (data != null && data.length > 0) {
          this.setState({
            patnershipList: data,
            //added_Farmer: data[0].farmer,
          });
          //console.log("abcd", this.state.patnershipList);

          // console.log("data", data);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  handleownershiptype = (event) => {
    var ownertype = event.target.value;
    this.setState({ selectedownertype: ownertype });
  };

  handleisowneranonboarded = (event) => {
    var owneronboard = event.target.value;
    this.setState({ selectedowneronboard: owneronboard });
  };

  // handleselectowner = (event) => {
  //   // var ownername = event.target.value;
  //   // this.setState({ selectowner: ownername });

  //   //this.setState({ selectowner: event.map((a) => a.ownerName) });
  //   // console.log("event", event);
  //   let multiSelect = [];
  //   event.map((x) => {
  //     multiSelect.push({
  //       owner: x.ownerName,
  //       isOnboardedFarmer: x.isOnboardedFarmer,
  //     });
  //   });
  //   this.setState({ selectowner: multiSelect });
  // };
  handleselectfarmer = (event) => {
    var farmer = event.target.value;
    this.setState({ selectfarmer: farmer });
  };

  handleUserInput(e, i) {
    const name = e.target.name;
    const value = e.target.value;
    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    if (value === "" || alphanumericRegex.test(value)) {
      this.setState({ [name]: value });
    }
    //   else {
    //     this.setState.error('Please enter only alphanumeric characters and spaces.');
    // }
    // this.setState({ [name]: value });
  }
  handleChange_radio = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {});
    if (value === "No") {
      this.clearOwnerList();
    }
  };
  setfile = (e, mainGuId, i) => {
    //debugger
    // let filesArr = e.target.files[0];
    let filesArr = e.target.files;
    for (var i = 0; i < filesArr.length; ++i) {
      if (filesArr[0].name.match(/\.(pdf|xls|xlsx|jpg|jpeg|png|gif)$/)) {
        if (
          parseInt(e.target.files[0].size) / 1024 <=
          parseInt(this.state.MaxFileSizeToUploadInMB) * 1024
        )
          // this.setState({ files: e.target.files[0], DocumentId: mainGuId },this.Uploadfile(i)
          // this.setState({ files: e.target.files[0], DocumentId: mainGuId }, () => this.Uploadfile(i));
          // this.setState(
          //   { files: e.target.files[0], DocumentId: mainGuId },
          //   function () {
          //     this.Uploadfile(i);
          //   }
          // );
          this.setState(
            { files: filesArr[0], DocumentId: mainGuId },
            function () {
              this.Uploadfile(i);
            }
          );
      } else {
        let docArray = document.getElementsByName("files");
        for (let index = 0; index < docArray.length; index++) {
          docArray[index].value = "";
        }
        ToastNotification.ShowError(
          "File Extension pdf, xls, xlsx, jpg, jpeg, png, gif allowed"
        );
      }
    }
  };

  Uploadfile = (i) => {
    //debugger
    let file = this.state.files;

    const formData = new FormData();
    formData.append("OwnerFiles", file);
    formData.append("ProjectId", this.state.projectId);

    let url = `${config.API_HOST_URL}/api/Ecosystem/UploadFile`;
    fetch(url, {
      method: "POST",
      body: formData,

      headers: {
        Authorization: `Bearer ${this.props.token}`,
        Accept: "application/json",
        ContentType: "multipart/form-data",
      },
      // body: formData,
    })
      .then((response) => response.json())
      .then((resp) => {
        // debugger;

        if (resp.success === true) {
          let { S3array } = this.state;

          let filteredDoc = S3array.filter(
            (x) => x.documentId === this.state.DocumentId
          );

          if (filteredDoc.length > 0) {
            filteredDoc[0].documentName = resp.data.documentName;
          } else {
            S3array.push({
              documentName: resp.data.documentName,
              documentId: this.state.DocumentId,
            });
          }

          this.setState({ S3array: S3array });
          //ToastNotification.ShowSuccess(resp.message);
        } else {
          //ToastNotification.ShowError(resp.message);
        }
        // console.log("s3array", this.state.ownerproofdDropdownList);
        // }
        // alert(resp.message);
      })

      .catch((error) => {
        console.log("Request failed", error);
      });
  };

  AddMore = () => {
    //debugger;
    if (this.ValidationAddmore()) {
      let { ListObjOwner } = this.state;
      if (this.state.selectedownertype !== null) {
        let filteredOwner = [];
        if (this.state.activeOwnerShipRecord != null) {
          filteredOwner = ListObjOwner.filter(
            (x) =>
              x.ownershipListId ===
              this.state.activeOwnerShipRecord.ownershipListId
          );
        }

        if (filteredOwner.length > 0) {
          filteredOwner[0].ownershipType = this.state.selectedownertype;
          filteredOwner[0].isOnboardedFarmer = this.state.owneronboard;
          // filteredOwner[0].Farmer = this.state.selectfarmer;
          filteredOwner[0].farmer = this.state.selectfarmer;
          filteredOwner[0].ownerName = this.state.ownerName;
          filteredOwner[0].OwnerAddress = this.state.owneraddress;
          filteredOwner[0].AadhaarNo = this.state.owneraadhar;
          filteredOwner[0].contactNo = this.state.contactno;
          //filteredOwner[0].OwnershipFiles = this.state.S3array;
          filteredOwner[0].ownershipFiles = this.state.S3array;
        } else {
          let newownershipdata = {
            OwnershipListId: this.state.ownershipListId,

            // ownertype: this.state.selectedownertype,
            ownershipType: this.state.selectedownertype,
            isOnboardedFarmer: this.state.owneronboard,
            // Farmer: this.state.selectfarmer,
            farmer: this.state.selectfarmer,
            ownerName: this.state.ownerName,
            OwnerAddress: this.state.owneraddress,
            AadhaarNo: this.state.owneraadhar,
            contactNo: this.state.contactno,
            ownershipFiles: this.state.S3array,
            //Files: this.state.files,
          };

          ListObjOwner.push(newownershipdata);

          // if (
          //   this.state.selectedownertype == "Private - Individuals" &&
          //   this.state.owneronboard == "No"
          // ) {
          //   let { noowner } = this.state;
          //   noowner = noowner + 1;
          //   this.setState({ noowner: noowner });
          // }
        }

        this.setState({
          ListObjOwner: ListObjOwner,
        });
        let noowner = 0;
        this.setState({ noowner: ListObjOwner.length });

        //console.log("ListObjOwner", this.state.ListObjOwner);

        this.clearFields();
      }
    }
  };
  ValidationAddmore = () => {
    let isValid = true;
    // debugger;
    //console.log("this.state.owneronboard", this.state.owneronboard);
    // console.log("this.state.selectedownertype", this.state.selectedownertype);
    // if (
    //   this.state.selectedownertype == "Private - Individuals" &&
    //   this.state.owneronboard === "No"
    // ) {
    //   let fields;
    //   fields = {
    //     ownerName: this.state.ownerName,
    //   };
    //   let errors = {};

    //   if (!fields["ownerName"]) {
    //     isValid = false;
    //     errors["ownerName"] = "Enter Owner Name";
    //     errors["ownerName"] = ToastNotification.ShowError("Enter OwnerName");
    //   }
    // }

    let fields;
    fields = {
      selectedownertype: this.state.selectedownertype,
    };
    let errors = {};

    if (!fields["selectedownertype"]) {
      isValid = false;
      errors["selectedownertype"] = "Select Ownership Type";
      errors["selectedownertype"] = ToastNotification.ShowError(
        "Select Ownership Type"
      );
    }

    return isValid;
  };

  GetOwnership = (ProjectId) => {
    // debugger;
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetOwnership`;
    let proj = {
      ProjectId: ProjectId,
    };
    fetch(url, {
      method: "Post",
      body: JSON.stringify(proj),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // debugger;
        let responseData = data.data;
        //console.log("ownerdetail", responseData);
        //debugger;
        this.setState({
          projectId: responseData.projectId,
          //ListObjOwner: responseData.ownershipList,
          noowner: responseData.numOfOwners,
          //isDisputed: responseData.isOwnershipDisputed,
          OwnershipId: responseData.ownershipId,
          // files: responseData.OwnershipFiles,
          //ownertype: responseData.Ownertype,
          //contactNo: responseData.Contactno,
          //selectowner: responseData.owners,
          ListObjOwner: responseData.ownershipList,
          //OwnerDetail:responseData.owners,
          OwnerList: responseData.ownerList,
        });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  Addownership = (e) => {
    e.preventDefault();
    //debugger;
    // if (this.handleValidation() === false) {
    //   return;
    // }

    //console.log("this.state.ListObjOwner", this.state.ListObjOwner);

    let data = {
      ProjectId: this.state.projectId,
      OwnershipList: this.state.ListObjOwner,
      NumOfOwners: this.state.noowner,
      IsOwnershipDisputed: this.state.isDisputed,
      OwnershipId: this.state.OwnershipId,
      //OwnershipFiles: this.state.ProofFiles,
      // Owners: this.state.selectowner,
      // Owners: this.state.OwnerDetail[0].Owners,
      // IsOwnershipDisputed: this.state.OwnerDetail[0].IsOwnershipDisputed,
      OwnerList: this.state.OwnerList,
    };

    let url = `${config.API_HOST_URL}/api/Ecosystem/AddOwnership`;
    fetch(url, {
      method: "POST",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
      //body: data,
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.success === true) {
          this.clearFields();
          // this.multiselectRef_SelectOwner.current.resetSelectedValues();
          ToastNotification.ShowSuccess(resp.message);
          this.Nextpage();
        } else {
          ToastNotification.ShowError(resp.message);
        }
      })
      .catch((error) => {
        console.log("Request failed", error);
      });
    //}
  };

  Nextpage = () => {
    navigate("/TreeTiesAddProjectForms1Sit/:" + this.state.projectId, {
      state: { activeMenu: 6, activesubSite: true },
    });
  };

  Back = () => {
    navigate("/TreeTiesAddProjectForms1Loc/:" + this.state.projectId, {
      state: { activeMenu: 4 },
    });
  };

  clearFields = (e) => {
    this.setState({
      selectedownertype: "",
      owneronboard: "",
      selectfarmer: "",
      ownerName: "",
      owneraddress: "",
      owneraadhar: "",
      contactno: "",
      //ProofFiles: [],
      // files: "",
      S3array: [],
    });
    let docArray = document.getElementsByName("files");
    for (let index = 0; index < docArray.length; index++) {
      docArray[index].value = "";
    }
  };
  clearOwnerList = (e) => {
    this.setState({
      OwnerList: [],
    });
  };
  remove = (i) => {
    let { noowner } = this.state;
    noowner = noowner - 1;

    this.state.ListObjOwner.splice(i, 1);
    this.setState({ noowner: this.state.ListObjOwner.length });
  };
  // remove = (i) => {
  //   let ListObjOwner = [...this.state.ListObjOwner];
  //   if (
  //     ListObjOwner[i].ownershipType == "Private - Individuals" &&
  //     ListObjOwner[i].isOnboardedFarmer == "No"
  //   ) {
  //     let { noowner } = this.state;
  //     noowner = noowner - 1;
  //     this.setState({ noowner: noowner });
  //   }
  //   ListObjOwner.splice(i, 1);
  //   this.setState({ ListObjOwner: ListObjOwner });
  // };

  editownership = (i) => {
    //console.log("edit", this.state.ListObjOwner[i]);
    this.setState({
      selectedownertype: this.state.ListObjOwner[i].ownershipType,
      owneronboard: this.state.ListObjOwner[i].isOnboardedFarmer,
      selectfarmer: this.state.ListObjOwner[i].farmer,
      ownerName: this.state.ListObjOwner[i].ownerName,
      owneraddress: this.state.ListObjOwner[i].ownerAddress,
      owneraadhar: this.state.ListObjOwner[i].aadhaarNo,
      contactno: this.state.ListObjOwner[i].contactNo,
      S3array: this.state.ListObjOwner[i].ownershipFiles,
      activeOwnerShipRecord: this.state.ListObjOwner[i],
    });
  };

  handleDownloadDocument = (FileName) => {
    //debugger

    let url = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/ProjectFiles/Project/${this.state.projectId}/${FileName}`;
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = FileName;
        a.click();
      });
    });
  };

  removeFileFromList = (e, index) => {
    let { S3array } = this.state;
    let filteredDoc = S3array.filter((x) => x.documentId !== e.target.id);
    //console.log("filteredDoc", filteredDoc);
    this.setState({ S3array: filteredDoc });
    let docArray = document.getElementsByName("files");
    if (docArray.length > 0) {
      docArray[index].value = "";
    }
  };

  handleValidation = (e) => {
    // if (this.state.selectowner.length === 0) {
    //   ToastNotification.ShowInfo("Please select Owner.");
    //   return false;
    // }
    if (this.state.OwnerList.length === 0) {
      ToastNotification.ShowInfo("Ownership Not Add successful.");
      return false;
    }
  };

  AddOwner = (e) => {
    e.preventDefault();
    //debugger;
    let { OwnerList } = this.state;
    // if (this.state.selectowner !== null) {
    //   let filter = [];
    //   if (this.state.activeownerdetail !== null) {
    //     filter = OwnerDetail.filter(
    //       (x) => x.selectowner === this.state.activeownerdetail.Owners
    //     );
    //   }
    //   if (filter.length > 0) {
    //     filter[0].Owner = this.state.selectowner;
    //     filter[0].IsOwnershipDisputed = this.state.isDisputed;
    //   } else {

    //console.log("this.state.OwnerListId", this.state.OwnerListId);
    //if (this.state.selectowner !== null) {
    if (
      this.state.ownerName === "" ||
      this.state.ownerName === null ||
      this.state.ownerName === undefined
    ) {
      ToastNotification.ShowError("Enter Owner Name");
    } else {
      let filter = [];
      if (this.state.activeownerdetail !== null) {
        filter = OwnerList.filter(
          (x) => x.ownerListId === this.state.activeownerdetail.ownerListId
        );
      }
      if (filter.length > 0) {
        // filter[0].isOwnershipDisputed = this.state.isDisputed;
        filter[0].owner = this.state.ownerName;
        //filter[0].isOnboardedFarmer = this.state.isOnboardedFarmer;
        //filter[0].isOnboardedFarmer = filter[0].isOnboardedFarmer;
        //if (this.state.selectownerId !== null) {
        // if (this.state.OwnerListId!="" && this.state.OwnerListId !== null) {
        // let filteredOwner = OwnerList.filter(
        //   (x) => x.ownerListId === this.state.selectownerId
        // );
        // filteredOwner[0].isOwnershipDisputed = this.state.isDisputed;
      } else {
        // console.log("this.state.selectowner", this.state.selectowner);
        // this.state.selectowner.map((x) => {
        let newownersdata = {
          owner: this.state.ownerName,
          // isOwnershipDisputed: this.state.isDisputed,
          ownerListId: this.state.OwnerListId,
          // isOnboardedFarmer: x.isOnboardedFarmer,
        };
        //console.log("owner", this.state.selectowner);
        //let { OwnerDetail } = this.state;
        //console.log("OwnerList", OwnerList);
        OwnerList.push(newownersdata);
        // });
      }
      this.setState({
        OwnerList: OwnerList,
        selectownerId: null,
      });
    }
    // }
    // this.clearOwner();
  };
  // }
  // };
  // handleownerdetail = (e) => {
  //   debugger
  //    e.preventDefault();
  //    if (this.state.OwnershipId == "") {
  //      let newownersdata = {
  //        Owners: this.state.selectowner,
  //        IsOwnershipDisputed: this.state.isDisputed,
  //      };
  //      let { OwnerDetail } = this.state;
  //      //     console.log("OwnerDetail", OwnerDetail);
  //      OwnerDetail.push(newownersdata);

  //      this.setState({
  //        OwnerDetail: OwnerDetail,
  //      });
  //    } else {
  //      const newState = this.state.OwnerDetail.map((obj) => {
  //        if (obj.OwnershipId === this.state.OwnershipId) {
  //          return {
  //            ...obj,
  //            Owners: this.state.selectowner,
  //            IsOwnershipDisputed: this.state.isDisputed,
  //          };
  //        }
  //        return obj;
  //      });
  //      this.setState({ OwnerDetail: newState });
  //      //console.log("owner", this.state.post_ListObjSoil);
  //    }
  // };
  editOwner = (i) => {
    // debugger;
    this.setState({
      // selectowner: this.state.OwnerList,
      selectownerId: this.state.OwnerList[i].ownerListId,
      // isDisputed: this.state.OwnerList[i].isOwnershipDisputed,
      // isOnboardedFarmer:this.state.isOnboardedFarmer,
      activeownerdetail: this.state.OwnerList[i],
      ownerName: this.state.OwnerList[i].owner,
    });
  };

  deleteOwner = (i) => {
    this.state.OwnerList.splice(i, 1);
    this.setState({ OwnerList: this.state.OwnerList });
  };

  clearOwner = (e) => {
    this.setState({
      //OwnerDetail: [],
      selectowner: [],
      isDisputed: "",
    });
    // this.multiselectRef_SelectOwner.current.resetSelectedValues();
  };
  render() {
    // console.log("listofobj", this.state.ListObjOwner);
    ///////////////////////////
    let ownerDropdownNew = [];
    if (this.state.ListObjOwner != null && this.state.ListObjOwner.length > 0) {
      this.state.ListObjOwner.map((x) => {
        let ownerId = "";
        let ownerName = "";
        let isOnboardedFarmer = "";
        if (
          x.ownershipType === "Private - Individuals" &&
          x.isOnboardedFarmer === "Yes"
        ) {
          ownerId = x.farmer;
          ownerName = this.state.farmerUserList.filter(
            // (eachFarmer) => eachFarmer.UserId === x.Farmer
            (eachFarmer) => eachFarmer.UserId === x.farmer
          )[0]?.NameOfNGO;
          isOnboardedFarmer = x.isOnboardedFarmer;
          ownerDropdownNew.push({
            ownerId: ownerId,
            ownerName: ownerName,
            isOnboardedFarmer: isOnboardedFarmer,
          });
        } else if (
          x.ownershipType === "Private - Individuals" &&
          x.isOnboardedFarmer === "No"
        ) {
          ownerId = x.ownerName;
          ownerName = x.ownerName;
          isOnboardedFarmer = x.isOnboardedFarmer;
          ownerDropdownNew.push({
            ownerId: ownerId,
            ownerName: ownerName,
            isOnboardedFarmer: isOnboardedFarmer,
          });
        }
      });
      //console.log("ownerDropdownNew", ownerDropdownNew);
    }

    //console.log("this.state.OwnerList", this.state.OwnerList);
    /////////////////////
    return (
      <div className="treeties-addproject-forms1-own ecosyscom_cont">
        {/* <Header /> */}
        <div className="main-content-area5">
          {/* <LeftNavbar/> */}
          <div className="middle-content-area5">
            <div className="container-18 ecoprosumry_cont">
              <ProjectSummary />
            </div>
            <div className="ecosys_middlecont ecoown_cont">
              <LeftTabMenuContainer
                activeMenu={this.props.location.state?.activeMenu}
              />
              <div className="ecosys_rightcont ">
                <div className="hero-title12 pb-4">
                  <b className="project-summary12">Ownership</b>
                </div>
                <div className="content-area">
                  <div className="container9">
                    <div className="input-field-text15">
                      <div className="content-title-text150">
                        <div className="table-row">
                          <div className="select-spoc-for">Ownership Type</div>
                        </div>

                        <select
                          className="dropdown-119"
                          name="ownertype"
                          value={this.state.selectedownertype}
                          onChange={this.handleownershiptype}
                        >
                          <option value="text">Select</option>
                          {this.state.ownertypeDropdownList.map(function (
                            item
                          ) {
                            return (
                              <option value={item.description}>
                                {item.description}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {this.state.selectedownertype ===
                        "Private - Individuals" &&
                      this.state.patnershipList.length > 0 &&
                      this.state.patnershipList[0].farmers !== null &&
                      this.state.patnershipList[0].farmers?.length > 0 ? (
                        <div className="content-title-text150">
                          <div className="table-row">
                            <div className="select-spoc-for">
                              Is this Owner an Onboarded Farmer?
                            </div>
                          </div>

                          <select
                            className="dropdown-119"
                            name="owneronboard"
                            variant="flushed"
                            backgroundColor="#E7F0FF"
                            borderColor="#D9D9D9"
                            focusBorderColor="#D9D9D9"
                            value={this.state.owneronboard}
                            onChange={this.handleChange_radio}
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="input-field-text16">
                      {this.state.selectedownertype ===
                        "Private - Individuals" &&
                      this.state.owneronboard != "No" &&
                      this.state.patnershipList.length > 0 &&
                      this.state.patnershipList[0].farmers !== null &&
                      this.state.patnershipList[0].farmers?.length > 0 ? (
                        <div className="content-title-text150">
                          <div className="table-row">
                            <div className="select-spoc-for">Select Farmer</div>
                          </div>

                          <select
                            className="dropdown-119"
                            name="selecfarmer"
                            value={this.state.selectfarmer}
                            onChange={this.handleselectfarmer}
                          >
                            <option value="">Select</option>
                            {this.state.patnershipList.length &&
                              this.state.patnershipList[0].farmers.map(
                                (item, k) => {
                                  return (
                                    <option value={item.farmer_Id}>
                                      {
                                        this.state.farmerUserList.filter(
                                          (x) => x.UserId === item.farmer_Id
                                        )[0]?.NameOfNGO
                                      }
                                    </option>
                                  );
                                }
                              )}
                          </select>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.selectedownertype ===
                        "Private - Individuals" &&
                      this.state.owneronboard != "Yes" &&
                      this.state.patnershipList.length > 0 &&
                      this.state.patnershipList[0].farmers !== null &&
                      this.state.patnershipList[0].farmers?.length > 0 ? (
                        <div className="content-title-text150">
                          <div className="table-row">
                            <div className="select-spoc-for">Owner Name</div>
                          </div>
                          <input
                            className="text-input-field-315"
                            required
                            pattern="[a-zA-Z0-9]+"
                            type="text"
                            name="ownerName"
                            onChange={(event) => this.handleUserInput(event)}
                            value={this.state.ownerName}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.state.selectedownertype === "Private - Individuals" &&
                    this.state.owneronboard != "Yes" &&
                    this.state.patnershipList.length > 0 &&
                    this.state.patnershipList[0].farmers !== null &&
                    this.state.patnershipList[0].farmers?.length > 0 ? (
                      <div className="input-field-text17 fullwidthspon">
                        <div className="content-title-text92">
                          <div className="table-row">
                            <div className="select-spoc-for">Owner Address</div>
                          </div>
                          <input
                            className="text-input-field-315"
                            required
                            pattern="[a-zA-Z0-9]+"
                            type="text"
                            name="owneraddress"
                            onChange={(event) => this.handleUserInput(event)}
                            value={this.state.owneraddress}
                          />
                        </div>

                        <div className="content-title-text92">
                          <div className="table-row">
                            <div className="select-spoc-for">
                              Owner Aadhaar No.
                            </div>
                          </div>
                          <input
                            className="text-input-field-315"
                            required
                            pattern="[a-zA-Z0-9]+"
                            type="text"
                            name="owneraadhar"
                            onChange={(event) => this.handleUserInput(event)}
                            value={this.state.owneraadhar}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.selectedownertype === "Private - Individuals" &&
                    this.state.owneronboard != "Yes" &&
                    this.state.patnershipList.length > 0 &&
                    this.state.patnershipList[0].farmers !== null &&
                    this.state.patnershipList[0].farmers?.length > 0 ? (
                      <div className="input-field-text18 fullwidthspon">
                        <div className="content-title-text92">
                          <div className="table-row">
                            <div className="select-spoc-for">Contact No.</div>
                          </div>
                          <input
                            className="text-input-field-315"
                            required
                            pattern="[a-zA-Z0-9]+"
                            type="text"
                            name="contactno"
                            maxlength="10"
                            onChange={(event) => this.handleUserInput(event)}
                            value={this.state.contactno}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="table3">
                      <div className="table-header-row">
                        <div className="table-left-title3">
                          <div className="select-spoc-for">
                            Proof of Ownership
                          </div>
                        </div>
                        <div className="table-right-title3">
                          <div className="select-spoc-for">Attachment</div>
                        </div>
                        <div className="table-right-title3">
                          <div className="select-spoc-for">Action</div>
                        </div>
                        <div className="table-right-title3">
                          <div className="select-spoc-for">Download</div>
                        </div>
                      </div>

                      {this.state.ownerproofdDropdownList.map(
                        (eachProof, i) => {
                          return (
                            <div className="table-row" key={i}>
                              <div className="table-row-content-a26">
                                <div className="select">
                                  {eachProof.description}
                                </div>
                              </div>
                              <div className="table-row-content-link ">
                                <input
                                  required
                                  type="file"
                                  name="files"
                                  id={eachProof.mainGuId}
                                  allowedExtensions=".pdf, .jpg, .xls, .xlsx,.jpeg,.gif"
                                  onChange={(e) =>
                                    this.setfile(e, eachProof.mainGuId, i)
                                  }
                                  onClick={(event) => {
                                    event.target.value = null;
                                  }}
                                />
                              </div>
                              {this.state.S3array.filter(
                                (x) =>
                                  x.documentId === eachProof.mainGuId &&
                                  x.documentName !== null
                              ).length > 0 ? (
                                <div className="table-row-content-a26">
                                  <div className="select">
                                    <i class="fa fa-check"></i>
                                    <a
                                      class="remove-files-list"
                                      id={eachProof.mainGuId}
                                      onClick={(e) =>
                                        this.removeFileFromList(e, i)
                                      }
                                    >
                                      Remove (
                                      {/* {
                                        this.state.S3array.filter(
                                          (x) =>
                                            x.documentId === eachProof.mainGuId
                                        )[0].documentName
                                      } */}
                                      )
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <i
                                      class="fa fa-download"
                                      onClick={() =>
                                        this.handleDownloadDocument(
                                          this.state.S3array.filter(
                                            (x) =>
                                              x.documentId ===
                                              eachProof.mainGuId
                                          )[0].documentName
                                        )
                                      }
                                    ></i>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="close6">close</div>
                  </div>
                  <div className="cta-add-more3 pt-3 pb-3">
                    <div className="logo-a5">plus</div>
                    <div
                      className="add-more3 addbtnstyle"
                      onClick={this.AddMore}
                    >
                      Add
                    </div>
                  </div>
                </div>
                <div className="ownerTable pb-4">
                  <div className="table4">
                    <div className="hero-title19">
                      <div className="project-summary19">Ownership Type</div>
                      <div className="project-summary19">Owner Name</div>
                      <div className="project-summary19">Contact No</div>
                      <div className="project-summary19">Action</div>
                    </div>

                    {this.state.ListObjOwner &&
                      this.state.ListObjOwner.map((x, i) => {
                        return (
                          <div className="innerOwnerTable">
                            <div className="inner-title" key={i}>
                              <div className="personnel-lorem-ipsum34">
                                {x.ownershipType}
                              </div>
                              <div className="personnel-lorem-ipsum34">
                                {x.ownerName?.length > 0 ? x.ownerName : "-"}
                              </div>
                              <div className="personnel-lorem-ipsum34">
                                {x.contactNo?.length > 0 ? x.contactNo : "-"}
                              </div>
                              <div className="personnel-lorem-ipsum34">
                                <a>
                                  <i
                                    className="fas fa-trash"
                                    onClick={() => this.remove(i)}
                                  ></i>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                </a>
                                <a>
                                  <i
                                    class="fa fa-edit"
                                    onClick={() => this.editownership(i)}
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="content-title-text98 pt-4 pb-4">
                  <div className="table-row">
                    <div className="select-spoc-for">
                      Is Ownership Disputed?
                    </div>
                  </div>
                  <div className="radio-button-input-text">
                    <select
                      className="dropdown-113"
                      name="isDisputed"
                      variant="flushed"
                      backgroundColor="#E7F0FF"
                      borderColor="#D9D9D9"
                      focusBorderColor="#D9D9D9"
                      value={this.state.isDisputed}
                      onChange={this.handleChange_radio}
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
                {this.state.isDisputed === "Yes" && (
                  <div className="input-field-text19 fullwidthspon pb-4">
                    <div className="content-title-text92">
                      <div className="table-row">
                        <div className="select-spoc-for">No. of Owners</div>
                      </div>
                      <input
                        required
                        className="text-input-field-315"
                        type="text"
                        name="noowner"
                        value={this.state.noowner}
                      />
                    </div>
                    {/* <div className="content-title-text89"> */}
                    <div className="content-title-text92">
                      <div className="table-row">
                        <div className="select-spoc-for"> Owner Name</div>
                      </div>
                      <input
                        className="text-input-field-315"
                        required
                        pattern="[a-zA-Z0-9]+"
                        type="text"
                        name="ownerName"
                        onChange={(event) => this.handleUserInput(event)}
                        value={this.state.ownerName}
                      />
                      {/* {this.state.ListObjOwner[i].ownershipType == "Private -
                    Individuals" && ListObjOwner[i].isOnboardedFarmer == "No" ) */}
                      {/* <Multiselect
                      name="selectowner"
                      options={ownerDropdownNew.map((e) => ({
                        ownerId: e.ownerId,
                        ownerName: e.ownerName,
                        isOnboardedFarmer: e.isOnboardedFarmer,
                      }))}
                      placeholder="Select"
                      displayValue="ownerName"
                      onSelect={(e) => this.handleselectowner(e)}
                      ref={this.multiselectRef_SelectOwner}
                      className="multiselector_cont"
                      // selectedValues={[
                      //   {
                      //     ownerId: this.state.selectowner,
                      //     ownerName: this.state.selectownerId,
                      //   },
                      // ]}
                    /> */}
                    </div>
                  </div>
                )}
                {this.state.isDisputed === "Yes" && (
                  <div className="pb-4">
                    <button
                      className="addbtnstyle"
                      onClick={(e) => this.AddOwner(e)}
                    >
                      {" "}
                      Add
                    </button>
                  </div>
                )}
                {this.state.isDisputed === "Yes" && (
                  <table className="newTable nofieldstbl">
                    <thead>
                      <tr>
                        <th>Owner Name</th>
                        {/* <th>Is Ownership Disputed</th> */}
                        {/* <th>Is this Owner an Onboarded Farmer</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.OwnerList &&
                        this.state.OwnerList.map((eachItem, i) => (
                          <tr key={i}>
                            <td>
                              {" "}
                              <div> {eachItem.owner} </div>{" "}
                            </td>
                            {/* <td>
                            {" "}
                            <div>{eachItem.isOwnershipDisputed} </div>{" "}
                          </td> */}
                            {/* <td>
                            {" "}
                            <div>{eachItem.isOnboardedFarmer} </div>{" "}
                          </td> */}
                            <td>
                              {" "}
                              <div>
                                <a>
                                  <i
                                    className="fas fa-edit"
                                    onClick={() => this.editOwner(i)}
                                  ></i>
                                </a>
                                <a>
                                  <i
                                    className="fas fa-trash"
                                    onClick={() => this.deleteOwner(i)}
                                  ></i>
                                </a>
                              </div>{" "}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                <div className="action-buttons4 pt-4">
                  <button className="cta9">
                    <div className="back4" on onClick={this.Back}>
                      Back
                    </div>
                  </button>
                  <button className="cta10">
                    <div
                      className="save-continue5"
                      on
                      onClick={(e) => this.Addownership(e)}
                    >{`Save & Continue`}</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="right-navbar5">
            <button className="plus-circle5">plus-circle</button>
            <button className="notifications-bell5">notifications-bell</button>
            <button className="notifications-bell5">email</button>
          </div> */}
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(TreeTiesAddProjectForms1Own);
