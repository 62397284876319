import { useEffect, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Polygon,
  KmlLayer,
  GroundOverlay,
} from "@react-google-maps/api";
import "./CSS/newDesign.scss";
import ProjectDetailHeader from "./ProjectDetailHeader";
import userEvent from "@testing-library/user-event";
import StreamLayer from "../Map/StreamLayer";
import Multipolygon from "./Multiploygon";
import StreamLayerPoly from "./StreamLayerPoly";
import GetMapLayerData from "./API/GetMapLayerData";
import S3KmlnData from "./API/S3KmlnData";
import S3GeoJsonData from "./API/S3GeoJsonData";
import LayerPolygon from "./LayerPolygon";
import LayerMarker from "./LayerMarker";
import EyeIconUncheck from "./icons/eyeiconuncheck.svg";
import EyeIconcheck from "./icons/eyeiconcheck.svg";
import LandParcelAndWaterBodies from "./LandParcelAndWaterBodies";
import { useDispatch, useSelector } from "react-redux";
import project from "../../../Reducers/Project";
import TreeDetailMarker from "./TreeDetailMarker";
import TimeSeries from "./TimeSeries";
import config from "../../../utils/config";
import GetTreeDetails from "./API/GetTreeDetails";
import { Modal, ModalVariant } from "@patternfly/react-core";
import CloseIcon from "./icons/close-gray.png";
import GridPolygons from "./GridPolygons";
import GetProjectRelatedGridData from "./API/GetProjectGridData";

export default function GoogleMapNew(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: props.GoogleMapApiKey,
  });

  if (isLoaded == false) return <div>Loading...</div>;
  return (
    <Map
      projectDetail={props.projectDetail}
      locationCords={props.locationCords}
      projectTreeType={props.projectTreeType}
    ></Map>
  );
}

function Map(props) {
  const [center, setCenter] = useState({ lat: "", lng: "" });
  const [zoomLevel, setZoomLevel] = useState(17);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSubLaerysOpen, setIsSubLaerysOpen] = useState(false);
  const [openedSubLayerGuid, setOpenedSubLayerGuid] = useState(null);
  const id = window.location.href.split("/")[4];
  const projectId = id.split(":")[1];

  const [projectLayers, setProjectLayers] = useState([]);
  const reduxState = useSelector((state) => state.project);
  const [selectedSpeices, setSelectedSpeices] = useState([]);
  const [speicesCount, setSpeicesCount] = useState({});
  const [groundSamplingCount, setGroundSamplingCount] = useState({});
  const [droneLidarCount, setDroneLidarCount] = useState({});
  const [droneRGBCount, setDroneRGBCount] = useState({});
  const dispatch = useDispatch();
  //const [showTooltipLayerGuid, setShowTooltipLayerGuid] = useState(null);
  const [showTooltipLayerGuid, setShowTooltipLayerGuid] = useState(null);
  const [hoveredLayerGuid, setHoveredLayerGuid] = useState(null);
  const [showClearAll, setShowClearAll] = useState(false);
  const [openedToolTip, setOpenedToolTip] = useState(null);
  const [selectedLayerGuid, setSelectedLayerGuid] = useState([]);
  const [layerDetails, setLayerDetails] = useState([]);
  const [showTooltip, setShowTooltip] = useState(null);
  const [isModalinfopopup, setisModalinfopopup] = useState(false);
  const [isModalInfoTooltipOpen, setisModalInfoTooltipOpen] = useState(false);

  const handleModalTootip = (open, clickedLayer) => {
    setisModalInfoTooltipOpen(open);
    setLayerDetails(clickedLayer);
  };

  const handleMouseEnter = (layerGuid) => {
    setHoveredLayerGuid(layerGuid);
  };

  const handleMouseLeave = () => {
    setHoveredLayerGuid(null);
  };

  const handleToggleMouseEnter = (option) => {
    //setHoveredLayerGuid(layerGuid);
    setShowTooltipLayerGuid(option.layerGuid);
  };

  const handleToggleMouseLeave = () => {
    //setHoveredLayerGuid(null);
    setShowTooltipLayerGuid(null);
  };

  const toggleSidebar = (open) => {
    setIsSidebarOpen(open);
  };

  const toggleSubLayers = (guid) => {
    if (openedSubLayerGuid === guid) {
      setOpenedSubLayerGuid(null);
    } else {
      setOpenedSubLayerGuid(guid);
    }
  };

  useEffect(() => {
    if (reduxState.filterSpeciesData) {
      setSelectedSpeices(reduxState?.filterSpeciesData);
    }
  }, [reduxState?.filterSpeciesData]);

  useEffect(() => {
    if (reduxState.treeData) {
      let centerCord = {
        lat: reduxState?.treeData?.coordinates?.geometry?.coordinates?.[1],
        lng: reduxState?.treeData?.coordinates?.geometry?.coordinates?.[0],
      };

      if (reduxState?.treeData?.coordinates) {
        setZoomLevel(20);
        setCenter(centerCord);
      }
    }
  }, [reduxState?.treeData]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetMapLayerData(projectId);
      let list = [...response.data];
      const formattedList = await Promise.all(
        list.map(async (item) => {
          if (item.layerType === "gridLayer" && item.fileType === "db") {
            let gridDetails = await GetProjectRelatedGridData(projectId);
            item.featuresData = gridDetails.data;
          } else if (item.fileType === "png" || item.fileType === "kml") {
            let data = await GetLayersUrl(item.fileName, item.projectName);
            item.url = data === undefined ? "" : data;
          } else if (item.fileType === "geojson") {
            let geoData = await GetLayersData(item.fileName, item.projectName);
            item.featuresData = geoData;
          } else if (item.fileType === "db") {
            let treeDetails = await GetTreeDeta(projectId, item.layerName);
            item.featuresData = treeDetails.data;
            let speciesCountDetails = {};
            Object.keys(treeDetails.commonNameCount).forEach((key) => {
              speciesCountDetails[key.toLowerCase()] =
                treeDetails.commonNameCount[key];
            });
            setSpeicesCount(speciesCountDetails);
          }
          return item;
        })
      );
      setProjectLayers(formattedList);
    };

    if (projectId) {
      fetchData();
    }
  }, [projectId]);

  const GetLayersUrl = async (fileName, projectName) => {
    let data = await S3KmlnData(projectName, fileName);
    return data?.url;
  };

  const GetLayersData = async (fileName, projectName) => {
    let data = await S3GeoJsonData(projectName, fileName); //s3TreeDetailsData(filename);
    return data?.features;
  };

  const GetTreeDeta = async (ProjectId, Source) => {
    let data;
    if (Source === "Ground Sampling") {
      data = await GetTreeDetails(ProjectId, Source);
      let groundCountDetails = {};
      Object.keys(data.commonNameCount).forEach((key) => {
        groundCountDetails[key.toLowerCase()] = data.commonNameCount[key];
      });
      setGroundSamplingCount(groundCountDetails);
    } else if (Source === "Drone LiDAR") {
      data = await GetTreeDetails(ProjectId, Source);
      let droneLidarCountDetails = {};
      Object.keys(data.commonNameCount).forEach((key) => {
        droneLidarCountDetails[key.toLowerCase()] = data.commonNameCount[key];
      });
      setDroneLidarCount(droneLidarCountDetails);
    } else if (Source === "Drone RGB") {
      data = await GetTreeDetails(ProjectId, Source);
      let droneRGBCountDetails = {};
      Object.keys(data.commonNameCount).forEach((key) => {
        droneRGBCountDetails[key.toLowerCase()] = data.commonNameCount[key];
      });
      setDroneRGBCount(droneRGBCountDetails);
    }
    return data;
  };

  const mapOption = {
    mapTypeControl: false,
    mapTypeId: window.google.maps.MapTypeId.SATELLITE,
    mapTypeControlOptions: {
      style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: window.google.maps.ControlPosition.LEFT_BOTTOM,
      mapTypeIds: [
        window.google.maps.MapTypeId.ROADMAP,
        window.google.maps.MapTypeId.SATELLITE,
        window.google.maps.MapTypeId.HYBRID,
      ],
    },
    scaleControl: true,
    keyboardShortcuts: false,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: window.google.maps.ControlPosition.BOTTOM_LEFT,
    },
  };

  const ConvertToLatLong = (locationCords) => {
    let latLongArray = [];
    let eachlatLong = {};
    let latArray = [];
    let lngArray = [];

    for (var i = 0; i < locationCords.length; i++) {
      if (i % 2 == 0) {
        eachlatLong.lng = locationCords[i];
        lngArray.push(locationCords[i]);
      } else {
        eachlatLong.lat = locationCords[i];
        latArray.push(locationCords[i]);
        latLongArray.push(eachlatLong);
        eachlatLong = {};
      }
    }

    if (latArray.length !== lngArray.length) {
      console.log("Invalid Polygon Location Cords in database.");
    }

    return latLongArray;
  };

  const getPolyCenter = async (boundryCords) => {
    let cords = await ConvertToLatLong(boundryCords);
    if (cords && cords.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      cords.forEach((cord) =>
        bounds.extend(new window.google.maps.LatLng(cord.lat, cord.lng))
      );
      const centerCoord = bounds.getCenter();
      console.log("MiddleCenter", centerCoord.lat(), centerCoord.lng());
      setCenter(centerCoord);
      setZoomLevel(16);
    }
  };

  useEffect(() => {
    if (props.projectTreeType != null) {
      getPolyCenter(props.projectTreeType?.locationCords);
    } else {
      if (props?.locationCords?.length > 1) {
        getPolyCenter(props?.locationCords);
      }
    }
  }, [props.projectTreeType]);

  const renderMapLayer = (option) => {
    switch (option.layerType) {
      case "ground-overlay":
        return (
          <div>
            <GroundOverlay
              key={option.url}
              url={option.url}
              bounds={
                new window.google.maps.LatLngBounds(
                  new window.google.maps.LatLng(
                    option.mapBounds?.[0],
                    option.mapBounds?.[1]
                  ),
                  new window.google.maps.LatLng(
                    option.mapBounds?.[2],
                    option.mapBounds?.[3]
                  )
                )
              }
              defaultOpacity={0.5}
            />
          </div>
        );
      case "polygon":
        return <LayerPolygon locationCords={props.locationCords} />;
      case "KmlLayer":
        return (
          <KmlLayer url={option.url} options={{ preserveViewport: true }} />
        );
      case "multipolygon":
        return (
          <Multipolygon
            multiPolygonData={option.featuresData}
            layerName={option.layerName}
          />
        );
      case "StreamLayer":
        return <StreamLayerPoly streamData={option.featuresData} />;
      case "LayerMarker":
        return (
          <LayerMarker
            markerData={option.featuresData}
            center={center}
            selectedTree={props.projectTreeType}
            imageName={option.iconImageName}
            speciesData={option.speciesName}
          />
        );
      case "gridLayer":
        return (
          <GridPolygons
            multiPolygonData={option.featuresData}
            center={center}
          />
        );
      default:
        return <div>{}</div>;
    }
  };

  const handleLayersSelection = async (isSelected, option) => {
    let layerGuid = [...selectedLayerGuid];
    layerGuid.push(option.layerGuid);
    setSelectedLayerGuid(layerGuid);
    let list = [...projectLayers];
    list = list?.map((item) => {
      if (item.layerGuid === option.layerGuid) {
        item.isSelected = isSelected;

        item?.speciesName?.forEach((species) => {
          if (item.layerGuid === option.layerGuid) {
            species.isSelected = isSelected;
          }
        });
      }
      return item;
    });

    setProjectLayers(list);
    setShowClearAll(true);
    project(
      reduxState?.filterSpeciesData,
      dispatch({ type: "SET_FILTER_SPECIES", data: list })
    );
  };

  const getSVG = (color) => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="6" fill={color} stroke="black" />
      </svg>
    );
  };

  const handleSpeciesSelection = async (isSelected, itm, option) => {
    let list = [...projectLayers];
    list = list.map((item) => {
      if (item.layerName === option.layerName) {
        item.speciesName.forEach((species) => {
          if (species.speciesGuid === itm.speciesGuid) {
            species.isSelected = isSelected;
          }
        });
      }
      return item;
    });

    setProjectLayers(list);
    project(
      reduxState?.filterSpeciesData,
      dispatch({ type: "SET_FILTER_SPECIES", data: list })
    );
  };

  const handleClearAll = async (option) => {
    let layerGuid = [...selectedLayerGuid];
    if (layerGuid?.includes(option.layerGuid)) {
      layerGuid = layerGuid?.filter((guid) => guid !== option?.layerGuid);
    }
    setSelectedLayerGuid(layerGuid);
    let list = [...projectLayers];
    list = list.map((item) => {
      if (item.layerName === option.layerName) {
        //item.isSelected = false;
        item.speciesName.map((species) => {
          species.isSelected = false;
        });
      }
      return item;
    });

    setProjectLayers(list);
    setShowClearAll(false);

    project(
      reduxState?.filterSpeciesData,
      dispatch({ type: "SET_FILTER_SPECIES", data: list })
    );
  };

  const renderLegendData = (option) => {
    if (option?.isSelected && option?.legend?.length > 0)
      return (
        <div className="legendscont">
          <div className="legends_heading">
            {option.legendName ? option.legendName : option.layerName}
          </div>

          <div className="legends_list">
            {option?.legend?.map((item) => (
              <div
                style={{
                  backgroundColor: item.colorCode,
                  display: "flex",
                  alignItems: "center",
                }}
                key={item.name}
                className={`legend_item ${item.colorCode}`}
              >
                <span
                  className="legend_clr"
                  style={{
                    backgroundColor: item.colorCode,
                  }}
                ></span>
                <span
                  className="legend_clrname"
                  style={{
                    backgroundColor: item.colorCode,
                  }}
                >
                  <span
                    className="legendclrname_arrow"
                    style={{
                      borderTopColor: item.colorCode,
                    }}
                  ></span>
                  {item.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      );
  };

  const getLayerCount = (source, item) => {
    if (source === "Ground Sampling") {
      if (groundSamplingCount[item.speciesName.toLowerCase()]) {
        return groundSamplingCount[item.speciesName.toLowerCase()];
      } else {
        return 0;
      }
    } else if (source === "Drone RGB") {
      if (droneRGBCount[item.speciesName.toLowerCase()]) {
        return droneRGBCount[item.speciesName.toLowerCase()];
      } else {
        return 0;
      }
    } else if (source === "Drone LiDAR") {
      if (droneLidarCount[item.speciesName.toLowerCase()]) {
        return droneLidarCount[item.speciesName.toLowerCase()];
      } else {
        return 0;
      }
    }
  };

  return (
    <div className="mapClass">
      <GoogleMap
        options={mapOption}
        defaultZoom={zoomLevel}
        zoom={zoomLevel}
        defaultCenter={center}
        center={center}
        mapContainerStyle={mapStyle.map_container}
      >
        <ProjectDetailHeader
          projectDetail={props.projectDetail}
          projectIndex={props.projectIndex}
        />
        {projectLayers != null &&
          projectLayers?.map((option) => {
            // if (option?.isSelected && typeof option?.url === "string")
            if (option?.isSelected) return renderMapLayer(option);
          })}
        {selectedSpeices != null &&
          selectedSpeices.speciesName?.map((species) => {
            {
              if (species?.isSelected) {
                return renderMapLayer(selectedSpeices);
              }
            }
          })}

        <LandParcelAndWaterBodies />
        <TreeDetailMarker />

        {/* {config.BASE_URL == "https://beta.treeties.co.in" && [
          props?.projectDetail?.isShowTimeSeries && (
            <TimeSeries projectDetail={props.projectDetail} />
          ),
        ]} */}

        {props?.projectDetail?.isShowTimeSeries && (
          <TimeSeries projectDetail={props.projectDetail} />
        )}

        <div className="root-hamburger-menu">
          <button
            className={`hamburger-menu ${
              isSidebarOpen ? "iconmapsidebar" : ""
            }`}
          >
            <a
              className="opensidebarbtn"
              onClick={() => toggleSidebar(!isSidebarOpen)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 2L2 7L12 12L22 7L12 2Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 17L12 22L22 17"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 12L12 17L22 12"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>

            <span>View Layers</span>
            <a
              className="close-sidebar-btn"
              onClick={() => toggleSidebar(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <path
                  d="M1.1 11.7899L0 10.632L4.4 6.00041L0 1.36883L1.1 0.210938L5.5 4.84252L9.9 0.210938L11 1.36883L6.6 6.00041L11 10.632L9.9 11.7899L5.5 7.15831L1.1 11.7899Z"
                  fill="white"
                />
              </svg>
            </a>
          </button>

          <aside
            className={`hamburger-menu-sidebar ${
              isSidebarOpen ? "togglemapsidebar" : ""
            }`}
          >
            <nav>
              {projectLayers != null &&
                projectLayers?.map((option) => (
                  <>
                    {option.isOnlyTitle ? (
                      <div className="parentsublayers_wrap">
                        <div className="parentsublayers_heading">
                          {option.layerName}
                          <span
                            className={`layersinfo_tooltipcont ${
                              layerDetails?.layerGuid == option?.layerGuid
                                ? "active_infoicon"
                                : "inactive_infoicon"
                            }`}
                            key={option.layerGuid}
                            onClick={() => handleModalTootip(true, option)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M7.2 12H8.8V7.2H7.2V12ZM8 5.6C8.22667 5.6 8.41667 5.52333 8.57 5.37C8.72333 5.21667 8.8 5.02667 8.8 4.8C8.8 4.57333 8.72333 4.38333 8.57 4.23C8.41667 4.07667 8.22667 4 8 4C7.77333 4 7.58333 4.07667 7.43 4.23C7.27667 4.38333 7.2 4.57333 7.2 4.8C7.2 5.02667 7.27667 5.21667 7.43 5.37C7.58333 5.52333 7.77333 5.6 8 5.6ZM8 16C6.89333 16 5.85333 15.79 4.88 15.37C3.90667 14.95 3.06 14.38 2.34 13.66C1.62 12.94 1.05 12.0933 0.63 11.12C0.21 10.1467 0 9.10667 0 8C0 6.89333 0.21 5.85333 0.63 4.88C1.05 3.90667 1.62 3.06 2.34 2.34C3.06 1.62 3.90667 1.05 4.88 0.63C5.85333 0.21 6.89333 0 8 0C9.10667 0 10.1467 0.21 11.12 0.63C12.0933 1.05 12.94 1.62 13.66 2.34C14.38 3.06 14.95 3.90667 15.37 4.88C15.79 5.85333 16 6.89333 16 8C16 9.10667 15.79 10.1467 15.37 11.12C14.95 12.0933 14.38 12.94 13.66 13.66C12.94 14.38 12.0933 14.95 11.12 15.37C10.1467 15.79 9.10667 16 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z"
                                fill="#868686"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="hamburger-menu-item">
                          <input
                            type="checkbox"
                            className="largerCheckbox"
                            checked={option.isSelected}
                            onChange={(e) =>
                              handleLayersSelection(e.target.checked, option)
                            }
                          />
                          <img
                            src={
                              option.isSelected ? EyeIconcheck : EyeIconUncheck
                            }
                            alt={option.isSelected ? "Selected" : "Unselected"}
                            className={`eye-icon ${
                              option.isSelected ? "selected" : "unselected"
                            }`}
                          />
                          <span className="checkboxLabel">
                            {option?.layerName}{" "}
                            {option.isShowCount &&
                              `(${option?.featuresData?.length})`}
                          </span>
                          <span
                            className={`layersinfo_tooltipcont ${
                              layerDetails?.layerGuid == option?.layerGuid
                                ? "active_infoicon"
                                : "inactive_infoicon"
                            }`}
                            key={option.layerGuid}
                            onClick={() => handleModalTootip(true, option)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M7.2 12H8.8V7.2H7.2V12ZM8 5.6C8.22667 5.6 8.41667 5.52333 8.57 5.37C8.72333 5.21667 8.8 5.02667 8.8 4.8C8.8 4.57333 8.72333 4.38333 8.57 4.23C8.41667 4.07667 8.22667 4 8 4C7.77333 4 7.58333 4.07667 7.43 4.23C7.27667 4.38333 7.2 4.57333 7.2 4.8C7.2 5.02667 7.27667 5.21667 7.43 5.37C7.58333 5.52333 7.77333 5.6 8 5.6ZM8 16C6.89333 16 5.85333 15.79 4.88 15.37C3.90667 14.95 3.06 14.38 2.34 13.66C1.62 12.94 1.05 12.0933 0.63 11.12C0.21 10.1467 0 9.10667 0 8C0 6.89333 0.21 5.85333 0.63 4.88C1.05 3.90667 1.62 3.06 2.34 2.34C3.06 1.62 3.90667 1.05 4.88 0.63C5.85333 0.21 6.89333 0 8 0C9.10667 0 10.1467 0.21 11.12 0.63C12.0933 1.05 12.94 1.62 13.66 2.34C14.38 3.06 14.95 3.90667 15.37 4.88C15.79 5.85333 16 6.89333 16 8C16 9.10667 15.79 10.1467 15.37 11.12C14.95 12.0933 14.38 12.94 13.66 13.66C12.94 14.38 12.0933 14.95 11.12 15.37C10.1467 15.79 9.10667 16 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z"
                                fill="#868686"
                              />
                            </svg>
                          </span>
                        </div>

                        {option?.isFilterApply && (
                          <div className="sublaerysparent_cont">
                            <div className="sublayers_heading">
                              Species{" "}
                              <span className="layers_speciescount">
                                {" "}
                                ({option?.speciesName?.length})
                              </span>
                              <a
                                className={`sublayerstoggle ${
                                  openedSubLayerGuid === option?.layerGuid
                                    ? "changesublayericon"
                                    : ""
                                }`}
                                onClick={() =>
                                  toggleSubLayers(option?.layerGuid)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="9"
                                  viewBox="0 0 12 9"
                                  fill="none"
                                >
                                  <path
                                    d="M12 6.9668L6 0.966797L0 6.9668L1.065 8.0318L6 3.0968L10.935 8.0318L12 6.9668Z"
                                    fill="#118D45"
                                  />
                                </svg>
                              </a>
                            </div>
                            <div
                              className={`sublayers_innercont ${
                                openedSubLayerGuid === option?.layerGuid
                                  ? "togglesublayerscont"
                                  : "hidden"
                              }`}
                            >
                              {option?.speciesName?.map((item) => (
                                <div className="hamburger-menu-item ">
                                  <input
                                    type="checkbox"
                                    className="largerCheckbox"
                                    checked={item.isSelected}
                                    onChange={(e) =>
                                      handleSpeciesSelection(
                                        e.target.checked,
                                        item,
                                        option
                                      )
                                    }
                                  />
                                  <img
                                    src={
                                      item.isSelected
                                        ? EyeIconcheck
                                        : EyeIconUncheck
                                    }
                                    alt={
                                      item.isSelected
                                        ? "Selected"
                                        : "Unselected"
                                    }
                                    className={`eye-icon ${
                                      item.isSelected
                                        ? "selected"
                                        : "unselected"
                                    }`}
                                  />
                                  {/* <img
                                    src={EyeIconUncheck}
                                    className="eye-icon"
                                  /> */}
                                  <span className="checkboxLabel">
                                    {getSVG(item?.speciesColourCode)}
                                    {item.speciesName}{" "}
                                    <span className="layers_treecount">
                                      ({getLayerCount(option?.layerName, item)})
                                    </span>
                                  </span>
                                  {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      d="M7.2 12H8.8V7.2H7.2V12ZM8 5.6C8.22667 5.6 8.41667 5.52333 8.57 5.37C8.72333 5.21667 8.8 5.02667 8.8 4.8C8.8 4.57333 8.72333 4.38333 8.57 4.23C8.41667 4.07667 8.22667 4 8 4C7.77333 4 7.58333 4.07667 7.43 4.23C7.27667 4.38333 7.2 4.57333 7.2 4.8C7.2 5.02667 7.27667 5.21667 7.43 5.37C7.58333 5.52333 7.77333 5.6 8 5.6ZM8 16C6.89333 16 5.85333 15.79 4.88 15.37C3.90667 14.95 3.06 14.38 2.34 13.66C1.62 12.94 1.05 12.0933 0.63 11.12C0.21 10.1467 0 9.10667 0 8C0 6.89333 0.21 5.85333 0.63 4.88C1.05 3.90667 1.62 3.06 2.34 2.34C3.06 1.62 3.90667 1.05 4.88 0.63C5.85333 0.21 6.89333 0 8 0C9.10667 0 10.1467 0.21 11.12 0.63C12.0933 1.05 12.94 1.62 13.66 2.34C14.38 3.06 14.95 3.90667 15.37 4.88C15.79 5.85333 16 6.89333 16 8C16 9.10667 15.79 10.1467 15.37 11.12C14.95 12.0933 14.38 12.94 13.66 13.66C12.94 14.38 12.0933 14.95 11.12 15.37C10.1467 15.79 9.10667 16 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z"
                                      fill="#868686"
                                    />
                                  </svg> */}
                                </div>
                              ))}

                              <>
                                <div className="maplayers_clrbtn">
                                  {selectedLayerGuid?.includes(
                                    option?.layerGuid
                                  ) ? (
                                    <a
                                      onClick={(e) => handleClearAll(option)}
                                      className={`layersinfo_tooltipcont`}
                                      onMouseEnter={() =>
                                        handleToggleMouseEnter(option)
                                      }
                                      onMouseLeave={() =>
                                        handleToggleMouseLeave()
                                      }
                                    >
                                      <i className="fa-filter" />
                                      {showTooltipLayerGuid ==
                                        option?.layerGuid && (
                                        <div className="layersinfo_tooltip">
                                          Clear All
                                        </div>
                                      )}
                                    </a>
                                  ) : (
                                    <a
                                      onClick={(e) =>
                                        handleLayersSelection(true, option)
                                      }
                                      className={`layersinfo_tooltipcont`}
                                      onMouseEnter={() =>
                                        handleToggleMouseEnter(option)
                                      }
                                      onMouseLeave={() =>
                                        handleToggleMouseLeave()
                                      }
                                    >
                                      <i className="fa-filter" />
                                      {showTooltipLayerGuid ==
                                        option?.layerGuid && (
                                        <div className="layersinfo_tooltip">
                                          Select All
                                        </div>
                                      )}
                                    </a>
                                  )}
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}
            </nav>
          </aside>
          <div
            className={`proinfotooltip_cont ${
              isModalInfoTooltipOpen ? "toggleproinfotooltip" : ""
            }`}
          >
            <a
              className="tooltipcloseicon_btn"
              onClick={() => handleModalTootip(false, {})}
            >
              <img src={CloseIcon} alt="close_icon" />
            </a>

            <div className="projdetinfoiconpopu_cont">
              <span>{layerDetails?.layerName}</span>
              <p>{layerDetails?.layerInfo}</p>
            </div>
          </div>
        </div>
        <div className="legendscont_wrap">
          {projectLayers != null &&
            projectLayers?.map((option) => {
              if (option?.isSelected && typeof option?.url === "string")
                return renderLegendData(option);
            })}
        </div>
      </GoogleMap>
    </div>
  );
}

const mapStyle = {
  map_container: {
    width: "100%",
    height: "100vh",
  },
};
