import React, { Component } from "react";
import { connect } from 'react-redux';

class searchboxcom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        };
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.mounted = true;
        
    }
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside)

    }

    componentWillUnmount() {
        this.mounted = false;
        document.removeEventListener('click', this.handleClickOutside)
    }
    toggleOptions = evt => {
        evt.preventDefault()
        this.setState(state => ({ isVisible: !state.isVisible }))
        document.documentElement.classList.toggle("menu-open");

    }
    handleClick = (choice, evt) => {
        evt.preventDefault()
        this.props.handleMenuChoice(choice)
        this.setState({ isVisible: false })
    }
    handleClickOutside = evt => {
        if (!this.node.contains(evt.target)) {
            this.setState({ isVisible: false })
            document.documentElement.classList.remove("menu-open");
        }
        document.documentElement.classList.remove("nav-open");
    }

    handleOutsideClick(e) {
        e.preventDefault();

    }
    render() {
        return(
            <div className="search_cont" ref={el => (this.node = el)}>
                <a href="#" onClick={this.toggleOptions}>
                    <i className="fa fa-search"></i>
                    <i className="fas fa-times"></i>
                </a>
                {this.state.isVisible
                    ? <div className="searchbox_cont">
                        <input type="text" placeholder="Search" />
                        <button type="button" aria-label="SearchButton" id="btnSearchMaster" ><i class="fa fa-search"></i></button>
                    </div>
                : null}
            </div>
        )
    }

}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUserData: data => dispatch({ type: "SET_USER_DETAILS", data: data }),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(searchboxcom);