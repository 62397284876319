import React, { useEffect, useState } from "react";
import config from "../../../../utils/config";

export default function PartnershipsView(props) {
  const [partnershipData, setPartnershipData] = useState({});
  const [ngoUserList, setNGOUserList] = useState([]);
  const [associatedUserList, setAssociatedUserList] = useState([]);
  const ASSOCIATED_WITH_EXECUTION_PARTNER =
    "c82a9c95-7152-4d51-83bf-4e0fef59b218";

  useEffect(() => {
    GetPartnershipList();
    GetNGOUserList();
    GetCommonMasters();
  }, [props.projectId]);

  const GetPartnershipList = () => {
    let url;
    if (props.projectId) {
      url = `${config.API_HOST_URL}/api/Ecosystem/GetPartnership/${props.projectId}`;
    }
    fetch(url, {
      method: "Get",
      headers: {
        // // WebsiteGuid: this.props.websiteGUID,
        // // LanguageGuid: this.props.languageGUID,
        // // Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data != null && data.length > 0) {
          setPartnershipData(data[0]);
          console.log(partnershipData, data[0]);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetNGOUserList = () => {
    let url = `${config.API_HOST_URL}/api/Login/GetNGOUserList`;
    fetch(url, {
      method: "Get",
      headers: {
        // WebsiteGuid: this.props.websiteGUID,
        // LanguageGuid: this.props.languageGUID,
        // Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = JSON.parse(data.NGOUserList);
        setNGOUserList(responseData);
        // console.log("setNGOUserList", responseData);
        //this.GetCommonMasters();
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetCommonMasters = () => {
    let masterType = "AssociationType";
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMaster/${masterType}`;
    fetch(url, {
      method: "Get",
      headers: {
        // WebsiteGuid: this.props.websiteGUID,
        // LanguageGuid: this.props.languageGUID,
        // Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAssociatedUserList(data);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  return (
    <div className="row prosumcordtabscont_wrap partshpreportcont_wrap">
      <div className="col-12">
        <div className="tabs-content exepartnrs_tabscontent">
          <div className="partnerstab_content onlyclspartnerstab_content">
            <div className="executivepartnerstrust_wrap">
              {partnershipData?.execution_Partners != null &&
                partnershipData?.execution_Partners.map((eachPartner, i) => {
                  return (
                    <>
                      <div className="partshpreport_heading">
                        Execution Partner (NGO)
                      </div>
                      <div className="executivepatners_trust">
                        <>
                          {/* <div
                            className="exectveprtnetrst_shrtnme"
                            style={{
                              backgroundColor:
                                BgColorCodes[i % BgColorCodes.length],
                            }}
                          >
                            {ngoUserList
                              ?.filter(
                                (ngoUser) =>
                                  ngoUser.UserId ===
                                  eachPartner.execution_PartnerId
                              )[0]
                              ?.NameOfNGO.slice(0, 1)}
                          </div> */}
                          <div className="exectveprtnetrst_right">
                            <div className="exectveprtnetrst_fllnme">
                              {
                                ngoUserList?.filter(
                                  (ngoUser) =>
                                    ngoUser.UserId ===
                                    eachPartner.execution_PartnerId
                                )[0]?.NameOfNGO
                              }
                            </div>
                            {/* <div className="exectveprtnetrst_content">
                              <>
                                <span>
                                  SPOC:
                                  {eachPartner.isSpoc ? "Yes" : "No"}
                                </span>
                                <span>Personnel:</span>
                                <div className="exectveprtnetrst_spocswrap">
                                  {eachPartner.pS_EP_Personnel != null &&
                                    eachPartner.pS_EP_Personnel.map(
                                      (eachPartner, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="exectveprtnetrst_spocsitem blue"
                                          >
                                            <div
                                              className="exectveprtnetrst_spocssrtnme"
                                              style={{
                                                backgroundColor:
                                                  BgColorCodes[
                                                    index % BgColorCodes.length
                                                  ],
                                              }}
                                            >
                                              {eachPartner.name.slice(0, 1)}
                                            </div>
                                            <div className="spocshover_content">
                                              <div className="spocs_name">
                                                {eachPartner.name}
                                              </div>
                                              <div className="spocsinfo">
                                                <span>
                                                  {eachPartner.project_Role}
                                                </span>
                                                <span>
                                                  {eachPartner.mobile}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </>
                            </div> */}
                            <div className="prosumacordinertbl_wrap">
                              <table className="prosumacord_inertbl">
                                <thead>
                                  <tr>
                                    <th>Personnel</th>
                                    <th>Designation</th>
                                    <th>Contact</th>
                                    <th>SPOC</th>
                                    <th>Project Role</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {eachPartner.pS_EP_Personnel != null &&
                                    eachPartner.pS_EP_Personnel.map(
                                      (eachPartner) => {
                                        return (
                                          <tr>
                                            <td>{eachPartner.name}</td>
                                            <td>{eachPartner.design}</td>
                                            <td>{eachPartner.mobile}</td>
                                            <td>
                                              {eachPartner.isSpoc
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>{eachPartner.project_Role}</td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="tabs-content instspnrs_tabscontent">
          <div className="partnerstab_content institlnaltabs_cont">
            <div className="executivepartnerstrust_wrap">
              {partnershipData?.institutional_Sponsors != null &&
                partnershipData?.institutional_Sponsors.map(
                  (eachPartner, i) => {
                    return (
                      <>
                        <div className="partshpreport_heading">
                          Institutional Sponsor
                        </div>
                        <div className="executivepatners_trust">
                          <>
                            <div className="exectveprtnetrst_right">
                              <div className="exectveprtnetrst_fllnme">
                                {
                                  ngoUserList?.filter(
                                    (ngoUser) =>
                                      ngoUser.UserId ===
                                      eachPartner.institutional_SponsorId
                                  )[0]?.NameOfNGO
                                }
                              </div>
                              <div className="institutionaltabs_content">
                                <div className="institutetabs_item">
                                  <span>Association Type</span>
                                  <span>
                                    :&nbsp;
                                    {
                                      associatedUserList.filter(
                                        (x) =>
                                          x.mainGuId ===
                                          eachPartner.associationTypeId
                                      )[0]?.description
                                    }
                                  </span>
                                </div>
                                <div className="institutetabs_item">
                                  <span>Execution Partner (NGO)</span>
                                  <span>
                                    :&nbsp;
                                    {eachPartner.associationTypeId ===
                                    ASSOCIATED_WITH_EXECUTION_PARTNER
                                      ? ` ${
                                          ngoUserList.filter(
                                            (x) =>
                                              x.UserId ===
                                              eachPartner.execution_PartnerId
                                          )[0]?.NameOfNGO
                                        }`
                                      : ""}
                                  </span>
                                </div>
                              </div>

                              {/* <div className="exectveprtnetrst_content">
                                <span>Personnel:</span>
                                <div className="exectveprtnetrst_spocswrap">
                                  {eachPartner.pS_IS_Personnel != null &&
                                    eachPartner.pS_IS_Personnel.map(
                                      (eachPartner1, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="exectveprtnetrst_spocsitem blue"
                                          >
                                            <div
                                              className="exectveprtnetrst_spocssrtnme"
                                              style={{
                                                backgroundColor:
                                                  BgColorCodes[
                                                    index % BgColorCodes.length
                                                  ],
                                              }}
                                            >
                                              {eachPartner1.name.slice(0, 1)}
                                            </div>
                                            <div className="spocshover_content">
                                              <div className="spocs_name">
                                                {eachPartner1.name}
                                              </div>
                                              <div className="spocsinfo">
                                                <span>
                                                  {eachPartner1.project_Role}
                                                </span>
                                                <span>
                                                  {eachPartner1.mobile}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div> */}
                              <div className="prosumacordinertbl_wrap">
                                <table className="prosumacord_inertbl">
                                  <thead>
                                    <tr>
                                      <th>Personnel</th>
                                      <th>Designation</th>
                                      <th>Contact</th>
                                      <th>SPOC</th>
                                      <th>Project Role</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {eachPartner.pS_IS_Personnel != null &&
                                      eachPartner.pS_IS_Personnel.map(
                                        (eachPartner) => {
                                          return (
                                            <tr>
                                              <td>{eachPartner.name}</td>
                                              <td>{eachPartner.design}</td>
                                              <td>{eachPartner.mobile}</td>
                                              <td>
                                                {eachPartner.isSpoc
                                                  ? "Yes"
                                                  : "No"}
                                              </td>
                                              <td>
                                                {eachPartner.project_Role}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        </div>
                      </>
                    );
                  }
                )}
            </div>
          </div>
        </div>

        <div className="tabs-content indialspnrs_tabscontent">
          <div className="partnerstab_content institlnaltabs_cont">
            <div className="executivepartnerstrust_wrap">
              <div className="partshpreport_heading">Individual Sponsor</div>
              {partnershipData?.individual_Sponsors != null &&
              partnershipData?.individual_Sponsors.length > 0 ? (
                partnershipData?.individual_Sponsors.map((eachPartner2, i) => (
                  <div key={i} className="executivepatners_trust">
                    <div className="exectveprtnetrst_right">
                      <div className="exectveprtnetrst_fllnme">
                        {
                          ngoUserList?.filter(
                            (ngoUser) =>
                              ngoUser.UserId ===
                              eachPartner2.individual_SponsorId
                          )[0]?.NameOfNGO
                        }
                      </div>
                      <div className="institutionaltabs_content">
                        <div className="institutetabs_item">
                          <span>Association Type</span>
                          <span>
                            :&nbsp;
                            {
                              associatedUserList.filter(
                                (ngoUser) =>
                                  ngoUser.mainGuId ===
                                  eachPartner2.associationTypeId
                              )[0]?.description
                            }
                          </span>
                        </div>
                        <div className="institutetabs_item">
                          <span>Execution Partner</span>
                          <span>
                            :&nbsp;
                            {
                              ngoUserList.filter(
                                (ngoUser) =>
                                  ngoUser.UserId ===
                                  eachPartner2.execution_PartnerId
                              )[0]?.NameOfNGO
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div key="norecord" className="executivepatners_trust">
                  <div className="exectveprtnetrst_right">
                    <div>No Record Found</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="tabs-content farmers_tabscontent">
          <div className="partnerstab_content institlnaltabs_cont">
            <div className="executivepartnerstrust_wrap">
              {partnershipData?.farmers != null &&
                partnershipData?.farmers.map((eachPartner, i) => {
                  return (
                    <>
                      <div className="partshpreport_heading">Farmer</div>
                      <div key={i} className="executivepatners_trust">
                        <div className="exectveprtnetrst_right">
                          <div className="exectveprtnetrst_fllnme">
                            {
                              ngoUserList?.filter(
                                (ngoUser) =>
                                  ngoUser.UserId === eachPartner.farmer_Id
                              )[0]?.NameOfNGO
                            }
                          </div>
                          <div className="institutionaltabs_content">
                            <div className="institutetabs_item">
                              <span>Association Type</span>
                              <span>
                                :&nbsp;
                                {
                                  associatedUserList.filter(
                                    (ngoUser) =>
                                      ngoUser.mainGuId ===
                                      eachPartner.associationType_farmer_Id
                                  )[0]?.description
                                }
                              </span>
                            </div>
                            <div className="institutetabs_item">
                              <span>Execution Partner</span>
                              <span>
                                :&nbsp;
                                {
                                  ngoUserList.filter(
                                    (ngoUser) =>
                                      ngoUser.UserId ===
                                      eachPartner.farmer_ExecPartnerId
                                  )[0]?.NameOfNGO
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="tabs-content goventy_tabscontent">
          <div className="partnerstab_content institlnaltabs_cont">
            <div className="executivepartnerstrust_wrap">
              {partnershipData?.government_Entities != null &&
                partnershipData?.government_Entities.map((eachPartner, i) => {
                  return (
                    <>
                      <div className="partshpreport_heading">
                        Government Entities
                      </div>
                      <div className="executivepatners_trust">
                        <>
                          <div className="exectveprtnetrst_right">
                            <div className="exectveprtnetrst_fllnme">
                              {
                                ngoUserList?.filter(
                                  (ngoUser) =>
                                    ngoUser.UserId ===
                                    eachPartner.govern_EntitiesId
                                )[0]?.NameOfNGO
                              }
                            </div>
                            <div className="institutionaltabs_content">
                              <div className="institutetabs_item">
                                <span>Association Type</span>
                                <span>
                                  :&nbsp;
                                  {
                                    associatedUserList.filter(
                                      (x) =>
                                        x.mainGuId ===
                                        eachPartner.associationType_GE_Id
                                    )[0]?.description
                                  }
                                </span>
                              </div>
                              <div className="institutetabs_item">
                                <span>Execution Partner (NGO)</span>
                                <span>
                                  :&nbsp;
                                  {eachPartner.associationType_GE_Id ===
                                  ASSOCIATED_WITH_EXECUTION_PARTNER
                                    ? ` ${
                                        ngoUserList.filter(
                                          (x) =>
                                            x.UserId ===
                                            eachPartner.ge_ExecPartnerId
                                        )[0]?.NameOfNGO
                                      }`
                                    : ""}
                                </span>
                              </div>
                            </div>

                            {/* <div className="exectveprtnetrst_content">
                              <span>Personnel:</span>
                              <div className="exectveprtnetrst_spocswrap">
                                {eachPartner.pS_GE_Personnel != null &&
                                  eachPartner.pS_GE_Personnel.map(
                                    (eachPartner1, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="exectveprtnetrst_spocsitem blue"
                                        >
                                          <div
                                            className="exectveprtnetrst_spocssrtnme"
                                            style={{
                                              backgroundColor:
                                                BgColorCodes[
                                                  index % BgColorCodes.length
                                                ],
                                            }}
                                          >
                                            {eachPartner1.name.slice(0, 1)}
                                          </div>
                                          <div className="spocshover_content">
                                            <div className="spocs_name">
                                              {eachPartner1.name}
                                            </div>
                                            <div className="spocsinfo">
                                              <span>
                                                {eachPartner1.project_Role}
                                              </span>
                                              <span>{eachPartner1.mobile}</span>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div> */}
                            <div className="prosumacordinertbl_wrap">
                              <table className="prosumacord_inertbl">
                                <thead>
                                  <tr>
                                    <th>Personnel</th>
                                    <th>Designation</th>
                                    <th>Contact</th>
                                    <th>SPOC for TreeTies</th>
                                    <th>Project Role</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {eachPartner.pS_GE_Personnel != null &&
                                    eachPartner.pS_GE_Personnel.map(
                                      (eachPartner) => {
                                        return (
                                          <tr>
                                            <td>{eachPartner.name}</td>
                                            <td>{eachPartner.design}</td>
                                            <td>{eachPartner.mobile}</td>
                                            <td>
                                              {eachPartner.isSpoc
                                                ? "Yes"
                                                : "No"}
                                            </td>
                                            <td>{eachPartner.project_Role}</td>
                                          </tr>
                                        );
                                      }
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="tabs-content legsrvprods_tabscontent">
          <div className="partnerstab_content institlnaltabs_cont">
            <div className="executivepartnerstrust_wrap">
              {partnershipData?.legal_Service_Providers != null &&
                partnershipData?.legal_Service_Providers.map(
                  (eachPartner, i) => {
                    return (
                      <>
                        <div className="partshpreport_heading">
                          Legal Service Provider
                        </div>
                        <div className="executivepatners_trust">
                          <>
                            <div className="exectveprtnetrst_right">
                              <div className="exectveprtnetrst_fllnme">
                                {
                                  ngoUserList?.filter(
                                    (ngoUser) =>
                                      ngoUser.UserId === eachPartner.lsp_Id
                                  )[0]?.NameOfNGO
                                }
                              </div>
                              <div className="institutionaltabs_content">
                                <div className="institutetabs_item">
                                  <span>Association Type</span>
                                  <span>
                                    :&nbsp;
                                    {
                                      associatedUserList.filter(
                                        (x) =>
                                          x.mainGuId ===
                                          eachPartner.associationType_lsp_Id
                                      )[0]?.description
                                    }
                                  </span>
                                </div>
                                <div className="institutetabs_item">
                                  <span>Execution Partner (NGO)</span>
                                  <span>
                                    :&nbsp;
                                    {eachPartner.associationType_lsp_Id ===
                                    ASSOCIATED_WITH_EXECUTION_PARTNER
                                      ? ` ${
                                          ngoUserList.filter(
                                            (x) =>
                                              x.UserId ===
                                              eachPartner.lsp_ExecPartnerId
                                          )[0]?.NameOfNGO
                                        }`
                                      : ""}
                                  </span>
                                </div>
                              </div>

                              {/* <div className="exectveprtnetrst_content">
                                <span>Personnel:</span>
                                <div className="exectveprtnetrst_spocswrap">
                                  {eachPartner.pS_LSP_Personnel != null &&
                                    eachPartner.pS_LSP_Personnel.map(
                                      (eachPartner1, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="exectveprtnetrst_spocsitem blue"
                                          >
                                            <div
                                              className="exectveprtnetrst_spocssrtnme"
                                              style={{
                                                backgroundColor:
                                                  BgColorCodes[
                                                    index % BgColorCodes.length
                                                  ],
                                              }}
                                            >
                                              {eachPartner1.name.slice(0, 1)}
                                            </div>
                                            <div className="spocshover_content">
                                              <div className="spocs_name">
                                                {eachPartner1.name}
                                              </div>
                                              <div className="spocsinfo">
                                                <span>
                                                  {eachPartner1.project_Role}
                                                </span>
                                                <span>
                                                  {eachPartner1.mobile}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div> */}
                              <div className="prosumacordinertbl_wrap">
                                <table className="prosumacord_inertbl">
                                  <thead>
                                    <tr>
                                      <th>Personnel</th>
                                      <th>Designation</th>
                                      <th>Contact</th>
                                      <th>SPOC for TreeTies</th>
                                      <th>Project Role</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {eachPartner.pS_LSP_Personnel != null &&
                                      eachPartner.pS_LSP_Personnel.map(
                                        (eachPartner) => {
                                          return (
                                            <tr>
                                              <td>{eachPartner.name}</td>
                                              <td>{eachPartner.design}</td>
                                              <td>{eachPartner.mobile}</td>
                                              <td>
                                                {eachPartner.isSpoc
                                                  ? "Yes"
                                                  : "No"}
                                              </td>
                                              <td>
                                                {eachPartner.project_Role}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        </div>
                      </>
                    );
                  }
                )}
            </div>
          </div>
        </div>
        <div className="tabs-content audtsrvprods_tabscontent">
          <div className="partnerstab_content institlnaltabs_cont">
            <div className="executivepartnerstrust_wrap">
              {partnershipData?.audit_Service_Providers != null &&
                partnershipData?.audit_Service_Providers.map(
                  (eachPartner, i) => {
                    return (
                      <>
                        <div className="partshpreport_heading">
                          Audit Service Provider
                        </div>
                        <div className="executivepatners_trust">
                          <>
                            <div className="exectveprtnetrst_right">
                              <div className="exectveprtnetrst_fllnme">
                                {
                                  ngoUserList?.filter(
                                    (ngoUser) =>
                                      ngoUser.UserId === eachPartner.asp_Id
                                  )[0]?.NameOfNGO
                                }
                              </div>
                              <div className="institutionaltabs_content">
                                <div className="institutetabs_item">
                                  <span>Association Type</span>
                                  <span>
                                    :&nbsp;
                                    {
                                      associatedUserList.filter(
                                        (x) =>
                                          x.mainGuId ===
                                          eachPartner.associationType_asp_Id
                                      )[0]?.description
                                    }
                                  </span>
                                </div>
                                <div className="institutetabs_item">
                                  <span>Execution Partner (NGO)</span>
                                  <span>
                                    :&nbsp;
                                    {eachPartner.associationType_asp_Id ===
                                    ASSOCIATED_WITH_EXECUTION_PARTNER
                                      ? ` ${
                                          ngoUserList.filter(
                                            (x) =>
                                              x.UserId ===
                                              eachPartner.asp_ExecPartnerId
                                          )[0]?.NameOfNGO
                                        }`
                                      : ""}
                                  </span>
                                </div>
                              </div>

                              {/* <div className="exectveprtnetrst_content">
                                <span>Personnel:</span>
                                <div className="exectveprtnetrst_spocswrap">
                                  {eachPartner.pS_ASP_Personnel != null &&
                                    eachPartner.pS_ASP_Personnel.map(
                                      (eachPartner1, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="exectveprtnetrst_spocsitem blue"
                                          >
                                            <div
                                              className="exectveprtnetrst_spocssrtnme"
                                              style={{
                                                backgroundColor:
                                                  BgColorCodes[
                                                    index % BgColorCodes.length
                                                  ],
                                              }}
                                            >
                                              {eachPartner1.name.slice(0, 1)}
                                            </div>
                                            <div className="spocshover_content">
                                              <div className="spocs_name">
                                                {eachPartner1.name}
                                              </div>
                                              <div className="spocsinfo">
                                                <span>
                                                  {eachPartner1.project_Role}
                                                </span>
                                                <span>
                                                  {eachPartner1.mobile}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div> */}
                              <div className="prosumacordinertbl_wrap">
                                <table className="prosumacord_inertbl">
                                  <thead>
                                    <tr>
                                      <th>Personnel</th>
                                      <th>Designation</th>
                                      <th>Contact</th>
                                      <th>SPOC for TreeTies</th>
                                      <th>Project Role</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {eachPartner.pS_ASP_Personnel != null &&
                                      eachPartner.pS_ASP_Personnel.map(
                                        (eachPartner) => {
                                          return (
                                            <tr>
                                              <td>{eachPartner.name}</td>
                                              <td>{eachPartner.design}</td>
                                              <td>{eachPartner.mobile}</td>
                                              <td>
                                                {eachPartner.isSpoc
                                                  ? "Yes"
                                                  : "No"}
                                              </td>
                                              <td>
                                                {eachPartner.project_Role}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        </div>
                      </>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
