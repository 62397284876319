/**
 * Author: Ranjeet Gupta
 * Description: A middleware to maintain a copy of the redux state in the local storage, which can be retrieved if the page is refreshed and the redux state is lost.
 */

 export default class {
    loadState = () => {
        try {
            let serializedState = localStorage.getItem("reduxState") || this.initializeState();
            return JSON.parse(serializedState);
        }
        catch (err) {
            return this.initializeState();
        }
    }

    saveState = (state) => {
        try {
            localStorage.setItem("reduxState", JSON.stringify(state));
        } catch (err) {
            console.log("Error while saving state", err);
        }
    }

    initializeState = () => {
        return { }
    }
}