import { useEffect, useState } from "react";
import config from "../../../utils/config";

export default function CollabarationView(props) {
  const [colData, setColData] = useState({});

  useEffect(() => {
    if (props.projectId) {
      GetCollabarationData(props.projectId);
    }
  }, [props.projectId, props]);

  const GetCollabarationData = (ProjectId) => {
    let url = `${config.API_HOST_URL}/api/Projects/GetCollaborationsByProjectId/${ProjectId}`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 5000,
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        if (responseData !== null) {
          setColData(responseData?.[0]);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="prosummarylist">
          <div className="prosummarylist_item">
            <div className="produmitem_title">
              Advisors<abbr>:&nbsp;</abbr>
            </div>
            <div className="produmitem_content">
              {colData?.advisors ? colData?.advisors : "-"}
            </div>
          </div>
          <div className="prosummarylist_item">
            <div className="produmitem_title">
              Implemented by<abbr>:&nbsp;</abbr>
            </div>
            <div className="produmitem_content">
              {colData?.implementedby ? colData?.implementedby : "-"}
            </div>
          </div>
          <div className="prosummarylist_item">
            <div className="produmitem_title">
              Funded by<abbr>:&nbsp;</abbr>
            </div>
            <div className="produmitem_content">
              {colData?.fundedby ? colData?.fundedby : "-"}
            </div>
          </div>
          <div className="prosummarylist_item">
            <div className="produmitem_title">
              TreeTies POC<abbr>:&nbsp;</abbr>
            </div>
            <div className="produmitem_content">
              {colData?.treeTiesPOC ? colData?.treeTiesPOC : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
