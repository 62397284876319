import React, { useEffect, useState, useCallback } from "react";
import config from "../../../utils/config";
import "font-awesome/css/font-awesome.min.css";
import Container from "react-bootstrap/Container";
import Pagination from "react-js-pagination";
import AddLayerMaster from "./AddLayerMaster";
import { Modal, Button, Form } from "@patternfly/react-core";
import { Scrollbars } from "react-custom-scrollbars";
import ToastNotification from "../../Common/ToastNotification/ToastNotification";

export default function LayerMaster() {
  const [count, setCount] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [IsPopUpStatus, setIsPopUpStatus] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [layerMstrLst, setLayerMstrLst] = useState([]);
  const [editLayer, setEditLayer] = useState([]);
  const [IsOpen, setIsOpen] = useState(false);

  const GetLayerMaster = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetLayerMaster`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 5000, // Timeout in milliseconds
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data;
        if (responseData.length > 0) {
          setLayerMstrLst(responseData);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  useEffect(() => {
    setIsModalOpen(false);
    if (IsOpen === false) {
      GetLayerMaster();
    }
  }, [IsOpen]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    // Fetch data for the new page
  };

  const handleSortClick = (field) => {
    const newSortOrder =
      sortBy === field && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(field);
    setSortOrder(newSortOrder);

    const sortedList = [...layerMstrLst].sort((a, b) => {
      const aField = field === "layerName" ? `${a.layerName}` : a[field];
      const bField = field === "layerType" ? `${b.layerType}` : b[field];

      if (aField < bField) return newSortOrder === "asc" ? -1 : 1;
      if (aField > bField) return newSortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setLayerMstrLst(sortedList);
  };

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleModalToggle = () => {
    setIsPopUpStatus(true);
  };

  const handleEdit = (layers) => {
    setEditMode(true);
    setEditLayer(layers);
    handleModalToggle();
  };

  const handleDelete = (layerMstId) => {
    if (window.confirm("Are you sure you want to delete this Layer?")) {
      let url = `${config.API_HOST_URL}/api/Ecosystem/DeleteLayerMaster/${layerMstId}`;
      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            ToastNotification.ShowSuccess("Deleted Successfully");
            GetLayerMaster();
            setLayerMstrLst((prevList) =>
              prevList.filter((item) => item.layerMstId !== layerMstId)
            );
            setCount((prevCount) => prevCount - 1);
          } else {
            ToastNotification.ShowError("Not Deleted Successfully");
            console.error("Failed to delete layer");
          }
        })
        .catch((error) => {
          console.error("Error in request", error);
        });
    }
  };

  //Search Functionality
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    GetSearchList(e.target.value);
    if (e.target.value === "" || e.target.value === null) {
      GetLayerMaster();
    }
  };

  const handleSearchByKeyPress = (e) => {
    if (e.key === "Enter") {
      setSearchText(e.target.value);
      GetSearchList(e.target.value);
    }
  };

  const GetSearchList = (e) => {
    const searchRecord = layerMstrLst.filter(
      (x) =>
        x.layerName?.toLowerCase().includes(e?.toLowerCase()) ||
        x.layerType?.toLowerCase().includes(e?.toLowerCase()) ||
        x.fileType?.toLowerCase().includes(e?.toLowerCase())
    );
    setLayerMstrLst(searchRecord);
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      innerWidth: 4,
      backgroundColor: "#50b041",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const CustomScrollbars = (props) => (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      {...props}
    />
  );

  return (
    <>
      <div className="headbottom_cont">
        <ul className="headbottom_content fieldofficertop_cont">
          <li className="heading_cont">Layer Master List</li>
          <li className="search_container">{/* <Searchcomp /> */}</li>
          <li className="addpro_btn addfieldofficer_btn">
            <a onClick={handleModalToggle}>
              Add Layer Master<i className="fa fa-plus"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="growalarm_container projectlist_cont fieldofficertbl_cont addlayermastertbl_cont">
        <Container>
          <div className="growalarm_content">
            <div className="commontbl_wrap">
              <CustomScrollbars
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
              >
                <table className="commontbl_cont fieldofficer_tbl addlayermaster_tbl">
                  <thead>
                    <tr>
                      <th>
                        Layer Name
                        <div
                          className="sortArrow"
                          onClick={() => handleSortClick("layerName")}
                        >
                          <i
                            className={`fas fa-sort-up ${
                              sortBy === "layerName" && sortOrder === "asc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                          <i
                            className={`fas fa-sort-down ${
                              sortBy === "layerName" && sortOrder === "desc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                        </div>
                      </th>
                      <th>
                        File Type
                        <div
                          className="sortArrow"
                          onClick={() => handleSortClick("fileType")}
                        >
                          <i
                            className={`fas fa-sort-up ${
                              sortBy === "fileType" && sortOrder === "asc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                          <i
                            className={`fas fa-sort-down ${
                              sortBy === "fileType" && sortOrder === "desc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                        </div>
                      </th>
                      <th>
                        Layer Type
                        <div
                          className="sortArrow"
                          onClick={() => handleSortClick("layerType")}
                        >
                          <i
                            className={`fas fa-sort-up ${
                              sortBy === "layerType" && sortOrder === "asc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                          <i
                            className={`fas fa-sort-down ${
                              sortBy === "layerType" && sortOrder === "desc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                        </div>
                      </th>
                      <th>
                        Layer Info
                        <div
                          className="sortArrow"
                          onClick={() => handleSortClick("layerInfo")}
                        >
                          <i
                            className={`fas fa-sort-up ${
                              sortBy === "layerInfo" && sortOrder === "asc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                          <i
                            className={`fas fa-sort-down ${
                              sortBy === "layerInfo" && sortOrder === "desc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                        </div>
                      </th>
                      <th>
                        Is Show Count
                        <div
                          className="sortArrow"
                          onClick={() => handleSortClick("showCount")}
                        >
                          <i
                            className={`fas fa-sort-up ${
                              sortBy === "showCount" && sortOrder === "asc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                          <i
                            className={`fas fa-sort-down ${
                              sortBy === "showCount" && sortOrder === "desc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                        </div>
                      </th>
                      <th>
                        Is Filter Apply
                        <div
                          className="sortArrow"
                          onClick={() => handleSortClick("isFilterApply")}
                        >
                          <i
                            className={`fas fa-sort-up ${
                              sortBy === "isFilterApply" && sortOrder === "asc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                          <i
                            className={`fas fa-sort-down ${
                              sortBy === "isFilterApply" && sortOrder === "desc"
                                ? "active"
                                : ""
                            }`}
                          ></i>
                        </div>
                      </th>
                      <th className="action_cont">Action</th>
                    </tr>
                  </thead>
                  <tbody id="myUL">
                    <tr className="tblsearch_cont">
                      <td colSpan="7">
                        <div className="tblsearch">
                          <i className="fa fa-search"></i>
                          <input
                            autoFocus
                            type="text"
                            id="SearchText"
                            placeholder="Search for Layer Name"
                            value={searchText}
                            onKeyPress={(e) => handleSearchByKeyPress(e)}
                            onChange={(e) => handleSearchChange(e)}
                          />
                        </div>
                      </td>
                    </tr>

                    {layerMstrLst && layerMstrLst.length > 0 ? (
                      layerMstrLst?.map((item, index) => (
                        <tr className="tabel-data" key={index}>
                          <td className="projnametd">{item.layerName}</td>
                          <td>{item.fileType}</td>
                          <td>{item.layerType}</td>
                          <td>{item.layerInfo}</td>
                          <td>{item.showCount ? "Yes" : "No"}</td>
                          <td>{item.isFilterApply ? "Yes" : "No"}</td>
                          <td className="action_cont">
                            <Button onClick={() => handleEdit(item)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M4.09942 15.6C3.62017 15.6 3.21718 15.4367 2.89046 15.1099C2.56375 14.7832 2.40039 14.3802 2.40039 13.901V4.09905C2.40039 3.6198 2.56375 3.21681 2.89046 2.89009C3.21718 2.56337 3.62017 2.40002 4.09942 2.40002H9.25856C9.46481 2.40002 9.6195 2.46525 9.72262 2.59571C9.82575 2.72617 9.87731 2.86776 9.87731 3.02047C9.87731 3.17318 9.82613 3.3142 9.72377 3.44353C9.62142 3.57285 9.47115 3.63752 9.27298 3.63752H4.09942C3.98405 3.63752 3.87828 3.68559 3.78212 3.78174C3.68597 3.8779 3.63789 3.98367 3.63789 4.09905V13.901C3.63789 14.0164 3.68597 14.1221 3.78212 14.2183C3.87828 14.3144 3.98405 14.3625 4.09942 14.3625H13.9014C14.0167 14.3625 14.1225 14.3144 14.2187 14.2183C14.3148 14.1221 14.3629 14.0164 14.3629 13.901V8.66974C14.3629 8.46349 14.4281 8.3088 14.5586 8.20567C14.689 8.10255 14.8306 8.05099 14.9833 8.05099C15.1361 8.05099 15.2771 8.10255 15.4064 8.20567C15.5357 8.3088 15.6004 8.46349 15.6004 8.66974V13.901C15.6004 14.3802 15.437 14.7832 15.1103 15.1099C14.7836 15.4367 14.3806 15.6 13.9014 15.6H4.09942ZM7.25664 9.89426V9.1356C7.25664 8.9062 7.30238 8.68455 7.39385 8.47065C7.48532 8.25675 7.60929 8.07215 7.76578 7.91685L13.8898 1.7928C14.0225 1.66011 14.1606 1.56852 14.3043 1.51803C14.4479 1.46756 14.5963 1.44232 14.7495 1.44232C14.8994 1.44232 15.0525 1.47068 15.2088 1.52741C15.365 1.58415 15.5071 1.67405 15.635 1.79713L16.1499 2.28752C16.2797 2.4202 16.3747 2.56549 16.4348 2.72338C16.4949 2.88127 16.5249 3.04088 16.5249 3.20223C16.5249 3.36357 16.4978 3.51793 16.4436 3.6653C16.3894 3.81268 16.2949 3.9515 16.16 4.08176L10.0114 10.2491C9.8528 10.4077 9.66706 10.5298 9.45423 10.6154C9.24142 10.701 9.02091 10.7438 8.7927 10.7438H8.10615C7.86961 10.7438 7.66889 10.6613 7.50399 10.4964C7.33909 10.3315 7.25664 10.1308 7.25664 9.89426ZM8.49414 9.50627H8.97587L13.7499 4.75099L13.509 4.49137L13.2206 4.22165L8.49414 8.9481V9.50627Z"
                                  fill="#6C6C6C"
                                ></path>
                              </svg>
                            </Button>
                            <Button
                              onClick={() => handleDelete(item.layerMstId)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                fill="none"
                              >
                                <mask
                                  id="mask0_3269_1382"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="19"
                                  height="19"
                                >
                                  <rect
                                    x="0.958008"
                                    y="0.839844"
                                    width="18"
                                    height="18"
                                    fill="#D9D9D9"
                                  />
                                </mask>
                                <g mask="url(#mask0_3269_1382)">
                                  <path
                                    d="M6.43882 16.2149C6.06482 16.2149 5.74532 16.0825 5.48032 15.8176C5.21545 15.5526 5.08301 15.2331 5.08301 14.8591V5.33989H4.33301V4.21489H7.70801V3.55151H12.208V4.21489H15.583V5.33989H14.833V14.8591C14.833 15.238 14.7018 15.5586 14.4393 15.8211C14.1768 16.0836 13.8561 16.2149 13.4772 16.2149H6.43882ZM13.708 5.33989H6.20801V14.8591C6.20801 14.9265 6.22963 14.9818 6.27288 15.025C6.31613 15.0683 6.37145 15.0899 6.43882 15.0899H13.4772C13.5349 15.0899 13.5878 15.0658 13.6358 15.0177C13.6839 14.9697 13.708 14.9168 13.708 14.8591V5.33989ZM8.01101 13.5899H9.13582V6.83989H8.01101V13.5899ZM10.7802 13.5899H11.905V6.83989H10.7802V13.5899Z"
                                    fill="#405660"
                                  />
                                </g>
                              </svg>
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No Records Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </CustomScrollbars>
            </div>
            {/* <div className="wrapper">
              {count > 0 ? (
                count > pageSize && (
                  <div className="paginationDiv">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={pageSize}
                      totalItemsCount={count}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      nextPageText={<i className="fa fa-angle-right"></i>}
                      prevPageText={<i className="fa fa-angle-left"></i>}
                      lastPageText={
                        <i className="fa fa-angle-double-right"></i>
                      }
                      firstPageText={
                        <i className="fa fa-angle-double-left"></i>
                      }
                    />
                  </div>
                )
              ) : (
                <div>No Results Found</div>
              )}
            </div> */}
          </div>
        </Container>
      </div>
      {IsPopUpStatus && (
        <AddLayerMaster
          IsOpen={true}
          editLayer={editLayer}
          setEditLayer={setEditLayer}
          editMode={editMode}
          setEditMode={setEditMode}
          GetLayerMaster={GetLayerMaster}
          setIsPopUpStatus={setIsPopUpStatus}
        />
      )}
    </>
  );
}
