import React from "react";
import { store } from "../../App";
import config from "../../utils/config";

export default function AsanaDashboard(props) {
  const loggedIn = store.getState().user.userData !== null;
  const userInfo = store.getState().user.userData;

  return (
    <div className="powebidashboard_cont">
      <h3 className="newdashboard_heading">Dashboard</h3>

      <iframe
        title="NGO Page"
        width="100%"
        height="100vh"
        src={
          "https://app.powerbi.com/view?r=eyJrIjoiNTY1ZDBhMjktMTlhNC00YzU5LTgzZWMtMzQ5Mjg5Zjk5ZjQxIiwidCI6Ijc5MzQ2MGExLTRjNmMtNGI3MS04ZjliLWZhZjE4ZmVhYjhlMiJ9"
        }
        frameborder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
}
