import React, { useEffect, useState, useRef } from "react";
import config from "../../utils/config";
import { navigate } from "@reach/router";
import { globalHistory } from "@reach/router";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import "../Test/tree-ties-add-project-forms1-img.css";
import ToastNotification from "../Common/ToastNotification/ToastNotification";
import Pagination from "react-js-pagination";

function TreeTiesAddProjectForms1ImageUpload(props) {
  const [fileNamesControl, setFileNamesControl] = useState([]);
  const fileInputRef = useRef(null);
  const [uploadfile, setUploadfile] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [pagesize, setPagesize] = useState(6);
  const [pageRange, setPageRange] = useState(6);

  const initialState = {
    fileName: [],
  };

  const [totalCount, setTotalCount] = useState(0);
  const [layerData, setLayerData] = useState(initialState);

  const [state, setState] = useState({
    uploadedImages: [],
  });

  useEffect(() => {
    setActivePage(1);
    setPagesize(6);
    setPageRange(4);
    let currentPath = globalHistory.location.pathname;
    let Id = currentPath.split(":")[1].replace("/", "");
    setProjectId(Id);
    GetProjectImages(Id);
  }, [projectId]);

  useEffect(() => {
    if (activePage) GetProjectImages(projectId);
  }, [activePage]);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    let validFiles = [];
    let invalidFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileType = file.type;
      const acceptedTypes = ["image/jpeg", "image/png", "image/jpg"];

      if (acceptedTypes.includes(fileType) && file.size <= 2 * 1024 * 1024) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file.name);
      }
    }

    if (invalidFiles.length > 0) {
      ToastNotification.ShowError(
        `File(s) ${invalidFiles.join(
          ", "
        )} is not a valid file type or larger than 2MB`
      );
    }

    if (validFiles.length > 0) {
      setUploadfile((prevFiles = []) => [...prevFiles, ...validFiles]);
      setLayerData((prevData) => ({
        ...prevData,
        fileName: [
          ...prevData.fileName,
          ...validFiles.map((file) => file.name),
        ],
      }));
      setFileNamesControl((prevFileNames = []) => [
        ...prevFileNames,
        ...validFiles.map((file) => file.name),
      ]);
    }
  };

  const UploadProjectImages = async () => {
    const formData = new FormData();
    uploadfile.forEach((file) => {
      formData.append("FormFiles", file);
    });

    const uri = `${config.API_HOST_URL}/api/ProjectTreeTypes/UploadMultipleDocument/${projectId}`;
    const fetchOptions = {
      method: "POST",
      body: formData,
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        Accept: "application/json",
      },
    };

    try {
      const response = await fetch(uri, fetchOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (data.success) {
        // const newImages = data.data.uploadedImages.map((image) => ({
        //   id: image.split("_")[0],
        //   name: image.replace(
        //     /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}_/,
        //     ""
        //   ),
        // }));

        // setUploadedImages((prevImages) => [...newImages]);

        setUploadfile([]);
        setFileNamesControl([]);
        ToastNotification.ShowSuccess("File(s) uploaded successfully");
        window.location.reload();
      } else {
        ToastNotification.ShowError(data.message);
      }
    } catch (error) {
      console.log("Request failed", error);
      ToastNotification.ShowError("Failed to upload file(s)");
    }
  };

  const handleSubmitClick = (event) => {
    event.preventDefault();
    if (uploadfile.length === 0) {
      ToastNotification.ShowError("Please select an image to upload");
      return;
    }
    UploadProjectImages();
  };

  const handleRemoveFile = (index) => (event) => {
    event.preventDefault();
    event.stopPropagation();

    setFileNamesControl((prevFileNames) => {
      const newFileNames = [...prevFileNames];
      newFileNames.splice(index, 1);
      return newFileNames;
    });

    setUploadfile((prevUploadfile) => {
      const newUploadfile = [...prevUploadfile];
      newUploadfile.splice(index, 1);
      return newUploadfile;
    });

    setLayerData((prevData) => {
      const newFileName = [...prevData.fileName];
      newFileName.splice(index, 1);
      return { ...prevData, fileName: newFileName };
    });
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    //setUploadedImages([]);
  };

  const GetProjectImages = async (projectId) => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetFilterProjectsImages/${projectId}/${activePage}/${pagesize}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 5000,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((text) => {
        if (!text) {
          throw new Error("Response body is empty");
        }
        return JSON.parse(text);
      })
      .then((data) => {
        let responseData = data?.data;
        let ImagesNames = responseData?.uploadedImages;
        let totalCount = responseData?.totalCount;
        setTotalCount(totalCount);
        setUploadedImages(ImagesNames);
        setState({ uploadedImages: ImagesNames });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const deleteImage = async (imageId) => {
    try {
      const response = await fetch(
        `${config.API_HOST_URL}/api/Projects/DeleteProjectImage/${projectId}/${imageId}`,
        {
          method: "POST",
          headers: {
            WebsiteGuid: props.websiteGUID,
            LanguageGuid: props.languageGUID,
            Authorization: `Bearer ${props.token}`,
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.success) {
        ToastNotification.ShowSuccess("File(s) deleted successfully");

        const index = uploadedImages.findIndex((image) => image.id === imageId);
        setUploadedImages((prevImages) =>
          prevImages.filter((image, i) => i !== index)
        );
        GetProjectImages(projectId);
      }
    } catch (error) {
      console.log("Request failed", error);
    }
  };

  const Back = () => {
    navigate("/TreeTiesAddProjectForms1Lay/:" + projectId, {
      state: { activeMenu: 10 },
    });
  };

  return (
    <>
      <div className="treeties-addproject-forms1-loc ecosyscom_cont">
        {/* <Header/> */}
        <div className="main-content-area6">
          {/* <LeftNavbar/>  */}
          <div className="middle-content-area6">
            <div className="container-18 ecoprosumry_cont">
              <ProjectSummary />
            </div>
            <div className="ecosys_middlecont exoloctab_cont">
              <LeftTabMenuContainer
                activeMenu={props.location.state?.activeMenu}
              />
              <div className="ecosys_rightcont addproformloc_cont">
                <div class="ecosysprosimguplds_contianer">
                  <ul className="addproject_form">
                    <form>
                      <li className="form-group">
                        <label for="forinventory">Project images upload</label>
                      </li>
                      <li className="form-group inventory_cont">
                        <div className="input-group divUploadFile">
                          <div className="qq-upload-drop-area">
                            <span>Drag and drop files here or Upload</span>
                          </div>
                          <div className="qq-upload-button">
                            <div className="uploadfield_icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="51"
                                height="51"
                                viewBox="0 0 51 51"
                                fill="none"
                              >
                                <path
                                  d="M22.9632 34.1135V17.1344L17.5465 22.551L14.6299 19.5302L25.0465 9.11353L35.4632 19.5302L32.5465 22.551L27.1299 17.1344V34.1135H22.9632ZM12.5465 42.4469C11.4007 42.4469 10.4202 42.0392 9.60488 41.2239C8.7896 40.4087 8.38127 39.4274 8.37988 38.2802V32.0302H12.5465V38.2802H37.5465V32.0302H41.7132V38.2802C41.7132 39.426 41.3056 40.4073 40.4903 41.2239C39.675 42.0406 38.6938 42.4482 37.5465 42.4469H12.5465Z"
                                  fill="#727272"
                                />
                              </svg>
                            </div>
                            <div className="prolvlimgs_updtxt">
                              Drag and drop files here or Upload
                              <span className="redMandat">
                                File: .JPG, .JPEG, .png | Size: 2 MB
                              </span>
                            </div>
                            <div className="prolvlimgs_uploadbtn">Upload</div>
                          </div>
                          <input
                            className="text-input-field-319"
                            type="file"
                            id="fileInput"
                            name="fileName"
                            accept=".jpg, .jpeg, .png"
                            multiple
                            ref={fileInputRef}
                            onChange={handleFileChange}
                          />
                        </div>
                        <div className="proimgsupld_list">
                          {fileNamesControl.map((name, index) => (
                            <div key={index} className="file-name-container">
                              <div className="file-name">
                                <div className="uploadedfile_icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                  >
                                    <path
                                      d="M20 23.3147H4V2.3147H15L20 7.3147V23.3147Z"
                                      fill="#90CAF9"
                                    />
                                    <path
                                      d="M19.25 7.8147H14.5V3.0647L19.25 7.8147Z"
                                      fill="#E1F5FE"
                                    />
                                    <path
                                      d="M10.5 12.3147L7 17.3147H14L10.5 12.3147Z"
                                      fill="#1565C0"
                                    />
                                    <path
                                      d="M14 14.0146L11.5 17.3146H16.5L14 14.0146Z"
                                      fill="#1976D2"
                                    />
                                    <path
                                      d="M15 13.0647C15 13.2636 15.079 13.4544 15.2197 13.595C15.3603 13.7357 15.5511 13.8147 15.75 13.8147C15.9489 13.8147 16.1397 13.7357 16.2803 13.595C16.421 13.4544 16.5 13.2636 16.5 13.0647C16.5 12.8658 16.421 12.675 16.2803 12.5344C16.1397 12.3937 15.9489 12.3147 15.75 12.3147C15.5511 12.3147 15.3603 12.3937 15.2197 12.5344C15.079 12.675 15 12.8658 15 13.0647Z"
                                      fill="#1976D2"
                                    />
                                  </svg>
                                </div>
                                {name}
                              </div>
                              <button
                                className="remove-file-button"
                                onClick={(event) =>
                                  handleRemoveFile(index)(event)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="21"
                                  viewBox="0 0 20 21"
                                  fill="none"
                                >
                                  <path
                                    d="M9.99967 10.256L5.83301 6.08936M9.99967 10.256L14.1663 14.4227M9.99967 10.256L14.1663 6.08936M9.99967 10.256L5.83301 14.4227"
                                    stroke="#7E7E7E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          ))}
                        </div>
                      </li>
                      <li className="form-group addpro_btn">
                        <div className="prolvlimgsbtn_wrap">
                          <button
                            className="canecl_btn"
                            onClick={(event) => {
                              event.preventDefault();
                              setFileNamesControl([]);
                              setUploadfile([]);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="submit_btn"
                            onClick={handleSubmitClick}
                          >
                            Add
                          </button>
                        </div>
                      </li>
                    </form>
                  </ul>

                  {/* //new design  */}
                  <div className="uploadedimagestbl_wrap">
                    <label for="forinventory">Uploaded Files</label>
                    <table className="uploadedimgtbl">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>File Name</th>
                          <th>Format</th>
                          <th>Upload Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uploadedImages.map((image, index) => (
                          <tr key={index}>
                            <td>
                              <div className="uploadedimgwrap">
                                <img
                                  src={`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/ProjectLevelImages/${projectId}/${image.imageName}`}
                                  alt={image.imageName}
                                />
                              </div>
                            </td>
                            <td>
                              <span>
                                {image.imageName
                                  .split("_")[1]
                                  .replace(/\.[^\.]+$/, "")}
                              </span>
                            </td>
                            <td>
                              <span>
                                {image.imageName.split(".").pop().toUpperCase()}{" "}
                              </span>
                            </td>
                            <td>
                              <span>
                                {new Date(
                                  image.uploadedDate
                                ).toLocaleDateString("en-GB", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })}
                              </span>
                            </td>
                            <td>
                              <div className="upldimgactionswrap">
                                <a
                                  href={`${config.Amazon_WebService_CDNUrl}/Websites/Uploads/UserFiles/SysAdmin/ProjectLevelImages/${projectId}/${image.imageName}`}
                                  target="_blank"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="19"
                                    viewBox="0 0 18 19"
                                    fill="none"
                                  >
                                    <mask
                                      id="mask0_3269_1355"
                                      maskUnits="userSpaceOnUse"
                                      x="0"
                                      y="0"
                                      width="18"
                                      height="19"
                                    >
                                      <rect
                                        y="0.810791"
                                        width="18"
                                        height="18"
                                        fill="#D9D9D9"
                                      />
                                    </mask>
                                    <g mask="url(#mask0_3269_1355)">
                                      <path
                                        d="M9.00113 12.4935C9.85101 12.4935 10.5728 12.1961 11.1666 11.6012C11.7603 11.0064 12.0572 10.284 12.0572 9.4341C12.0572 8.58423 11.7598 7.86242 11.1649 7.26867C10.57 6.67492 9.84763 6.37804 8.99776 6.37804C8.14788 6.37804 7.42607 6.67548 6.83232 7.27035C6.23857 7.86523 5.9417 8.5876 5.9417 9.43748C5.9417 10.2874 6.23913 11.0092 6.83401 11.6029C7.42888 12.1967 8.15126 12.4935 9.00113 12.4935ZM8.99945 11.4608C8.43695 11.4608 7.95882 11.2639 7.56507 10.8702C7.17132 10.4764 6.97445 9.99829 6.97445 9.43579C6.97445 8.87329 7.17132 8.39517 7.56507 8.00142C7.95882 7.60767 8.43695 7.41079 8.99945 7.41079C9.56195 7.41079 10.0401 7.60767 10.4338 8.00142C10.8276 8.39517 11.0244 8.87329 11.0244 9.43579C11.0244 9.99829 10.8276 10.4764 10.4338 10.8702C10.0401 11.2639 9.56195 11.4608 8.99945 11.4608ZM9.00038 14.6858C7.27576 14.6858 5.70432 14.21 4.28607 13.2585C2.86782 12.3072 1.82357 11.0329 1.15332 9.43579C1.82357 7.83867 2.86745 6.56442 4.28495 5.61304C5.70257 4.66154 7.27376 4.18579 8.99851 4.18579C10.7231 4.18579 12.2946 4.66154 13.7128 5.61304C15.1311 6.56442 16.1753 7.83867 16.8456 9.43579C16.1753 11.0329 15.1314 12.3072 13.7139 13.2585C12.2963 14.21 10.7251 14.6858 9.00038 14.6858ZM8.99945 13.5608C10.4119 13.5608 11.7088 13.1889 12.8901 12.4452C14.0713 11.7014 14.9744 10.6983 15.5994 9.43579C14.9744 8.17329 14.0713 7.17017 12.8901 6.42642C11.7088 5.68267 10.4119 5.31079 8.99945 5.31079C7.58695 5.31079 6.29007 5.68267 5.10882 6.42642C3.92757 7.17017 3.02445 8.17329 2.39945 9.43579C3.02445 10.6983 3.92757 11.7014 5.10882 12.4452C6.29007 13.1889 7.58695 13.5608 8.99945 13.5608Z"
                                        fill="#405660"
                                      />
                                    </g>
                                  </svg>
                                </a>

                                <a
                                  className="upldimg_deletebtn"
                                  onClick={() =>
                                    deleteImage(image.imageName.split("_")[0])
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 19 19"
                                    fill="none"
                                  >
                                    <mask
                                      id="mask0_3269_1382"
                                      maskUnits="userSpaceOnUse"
                                      x="0"
                                      y="0"
                                      width="19"
                                      height="19"
                                    >
                                      <rect
                                        x="0.958008"
                                        y="0.839844"
                                        width="18"
                                        height="18"
                                        fill="#D9D9D9"
                                      />
                                    </mask>
                                    <g mask="url(#mask0_3269_1382)">
                                      <path
                                        d="M6.43882 16.2149C6.06482 16.2149 5.74532 16.0825 5.48032 15.8176C5.21545 15.5526 5.08301 15.2331 5.08301 14.8591V5.33989H4.33301V4.21489H7.70801V3.55151H12.208V4.21489H15.583V5.33989H14.833V14.8591C14.833 15.238 14.7018 15.5586 14.4393 15.8211C14.1768 16.0836 13.8561 16.2149 13.4772 16.2149H6.43882ZM13.708 5.33989H6.20801V14.8591C6.20801 14.9265 6.22963 14.9818 6.27288 15.025C6.31613 15.0683 6.37145 15.0899 6.43882 15.0899H13.4772C13.5349 15.0899 13.5878 15.0658 13.6358 15.0177C13.6839 14.9697 13.708 14.9168 13.708 14.8591V5.33989ZM8.01101 13.5899H9.13582V6.83989H8.01101V13.5899ZM10.7802 13.5899H11.905V6.83989H10.7802V13.5899Z"
                                        fill="#405660"
                                      />
                                    </g>
                                  </svg>
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="uploadedimgs_pagination">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pagesize}
                        totalItemsCount={totalCount}
                        pageRangeDisplayed={pageRange}
                        nextPageText={<i className="fa fa-angle-right"></i>}
                        prevPageText={<i className="fa fa-angle-left"></i>}
                        onChange={(pageNumber) => {
                          handlePageChange(pageNumber);
                        }}
                      />
                    </div>
                    {/* <ul className="uploadedimgs_pagination">
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                        >
                          <mask
                            id="mask0_3272_1475"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="19"
                            height="19"
                          >
                            <rect
                              x="0.333984"
                              y="0.897949"
                              width="18"
                              height="18"
                              fill="#D9D9D9"
                            />
                          </mask>
                          <g mask="url(#mask0_3272_1475)">
                            <path
                              d="M10.834 14.3979L6.33398 9.89795L10.834 5.39795L11.884 6.44795L8.43398 9.89795L11.884 13.3479L10.834 14.3979Z"
                              fill="#2E3B41"
                            />
                          </g>
                        </svg>
                      </li>
                      <li>1</li>
                      <li>2</li>
                      <li>...</li>
                      <li>9</li>
                      <li>10</li>
                      <li>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                        >
                          <mask
                            id="mask0_3272_1478"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="19"
                            height="19"
                          >
                            <rect
                              x="0.000976562"
                              y="0.897949"
                              width="18"
                              height="18"
                              fill="#D9D9D9"
                            />
                          </mask>
                          <g mask="url(#mask0_3272_1478)">
                            <path
                              d="M9.45098 9.89795L6.00098 6.44795L7.05098 5.39795L11.551 9.89795L7.05098 14.3979L6.00098 13.3479L9.45098 9.89795Z"
                              fill="#2E3B41"
                            />
                          </g>
                        </svg>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <div className="action-buttons pt-4">
                  <button className="cta1">
                    <div className="back" on onClick={Back}>
                      Back
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TreeTiesAddProjectForms1ImageUpload;
