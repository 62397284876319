import { Link } from "@reach/router";
import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../../utils/config";
import NewMenuIconSVG from "./getMenuIconSVG";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipVisible: false,
      openSubMenus: {},
      navOpen: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({ isTooltipVisible: true });
  };

  handleMouseLeave = () => {
    this.setState({ isTooltipVisible: false });
  };

  toggleSubMenu = (menuItem) => {
    const { openSubMenus } = this.state;
    const currentMenuId = menuItem.mainMenu.menuId;

    // Close all other submenus
    Object.keys(openSubMenus).forEach((menuId) => {
      if (menuId !== currentMenuId) {
        openSubMenus[menuId] = false;
      }
    });
    this.setState((prevState) => ({
      openSubMenus: {
        ...prevState.openSubMenus,
        [currentMenuId]: !prevState.openSubMenus[currentMenuId],
      },
    }));
    this.toggleSidebar();
  };

  toggleSidebar = () => {
    this.setState((prevState) => ({
      navOpen: !prevState.navOpen,
    }));
    document.documentElement.classList.toggle("nav-open");
  };

  closeSidebar = () => {
    this.setState({ navOpen: false });
    document.documentElement.classList.remove("nav-open");
  };

  render() {
    const { userMenuData, userData } = this.props;
    const { isTooltipVisible, openSubMenus, navOpen } = this.state;
    const getMenuIcon = (menuName) => {
      switch (menuName) {
        case "Approval":
          return <NewMenuIconSVG.ApprovalIcon />;
        case "Projects":
        case "Project List":
        case "Grow Alarm":
          return <NewMenuIconSVG.ProjectListIcon />;
        case "Admin Dashboard":
          return <NewMenuIconSVG.SubDashboard />;
        case "Dashboards":
        case "NGO Dashboard":
          return <NewMenuIconSVG.MainDashboard />;
        case "Master Data":
          return <NewMenuIconSVG.MasterList />;
        case "CO2 Emissions":
          return <NewMenuIconSVG.CO2EmissionIcon />;
        //case "Tree Type List":
        //case "Payments":
        case "Project Management":
          return <NewMenuIconSVG.SubDashboard />;
        case "NGO Dashboard":
          return <NewMenuIconSVG.MasterList />;
        case "Approve Ground Data":
          return <NewMenuIconSVG.ApplicationListIcon />;
        case "Application List":
          return <NewMenuIconSVG.ApplicationListIcon />;
        case "Calculator":
          return <NewMenuIconSVG.CalculatorIcon />;
        case "Add Field Officer":
          return <NewMenuIconSVG.FieldOfficerList />;
        case "Add Time Series Images":
          return <NewMenuIconSVG.UploadTimeSeriesIcon />;
        case "Add Layer Master":
          return <NewMenuIconSVG.LayerMasterList />;
        case "Tree Type List":
          return <NewMenuIconSVG.AddTreeTypeIcon />;
        case "Species List":
          return <NewMenuIconSVG.AddTreeTypeIcon />;
        case "Partnership List":
          return <NewMenuIconSVG.PartnerShipListIcon />;
        case "Payments":
          return <NewMenuIconSVG.PaymentListIcon />;
        default:
          return null;
      }
    };
    return (
      <div
        className={`sidebar_cont sidebar-responsive ${
          navOpen ? "nav-open" : ""
        }`}
        id="sidebar"
      >
        <div className="sidebarinner_cont">
          <ul className="sidebar_menu">
            {userMenuData?.map((menuItem, index) => (
              <li
                key={index}
                className={`submenu_main ${
                  menuItem.subMenus?.length === 0
                    ? "nosidebar_submenu"
                    : openSubMenus[menuItem?.mainMenu?.menuId]
                    ? "submenusidebar_active"
                    : ""
                }`}
              >
                {menuItem.subMenus?.length === 0 ? (
                  <Link
                    to={menuItem.mainMenu.route}
                    className="submenumain_menu"
                    onClick={(e) => {
                      this.toggleSidebar();
                      this.setState((prevState) => ({
                        navOpen: !prevState.navOpen,
                      }));
                      document.documentElement.classList.remove("nav-open");
                      e.stopPropagation();
                    }}
                  >
                    {getMenuIcon(menuItem?.mainMenu?.menuName)}
                    <span>{menuItem?.mainMenu?.menuName}</span>
                    {menuItem?.subMenus?.length > 0 && (
                      <div
                        className={`submenu_downarrow ${
                          openSubMenus[menuItem?.mainMenu?.menuId] ? "open" : ""
                        }`}
                      >
                        <NewMenuIconSVG.DownArrowIcon />
                      </div>
                    )}
                  </Link>
                ) : (
                  <div
                    className="submenumain_menu"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.toggleSubMenu(menuItem);
                      document.documentElement.classList.add("nav-open");
                    }}
                  >
                    {getMenuIcon(menuItem?.mainMenu?.menuName)}
                    <span>{menuItem?.mainMenu?.menuName}</span>
                    {menuItem?.subMenus?.length > 0 && (
                      <div
                        className={`submenu_downarrow ${
                          openSubMenus[menuItem?.mainMenu?.menuId] ? "open" : ""
                        }`}
                      >
                        <NewMenuIconSVG.DownArrowIcon />
                      </div>
                    )}
                  </div>
                )}
                {menuItem?.subMenus?.length > 0 &&
                  openSubMenus[menuItem?.mainMenu?.menuId] && (
                    <ul className="sidebar_submenu">
                      {menuItem?.subMenus?.map((subMenu, subIndex) => (
                        <li key={subIndex} className="project-list">
                          <Link to={subMenu?.route} onClick={this.closeSidebar}>
                            {getMenuIcon(subMenu?.menuName)}
                            <span>{subMenu?.menuName}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
              </li>
            ))}
          </ul>
          <div
            className="footersitelogo"
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <a
              id="tooltip-target"
              href={
                userData.roleId === 2 ? "/NGODashboardNew" : "/NGODashboardNew"
              }
            >
              <img
                src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/bottomLogoIcon.png`}
                alt="growdashlogo"
              />
            </a>
            {isTooltipVisible && (
              <div className="footerlogo_toltip">Powered By TreeTies</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
    userMenuData: state.user.userMenuData,
  };
}

export default connect(mapStateToProps)(Sidebar);
