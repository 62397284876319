import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalVariant } from "@patternfly/react-core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import StackholderlistIcon from "../../assets/OurstackholdersImg.png";
import backtbn from "../../assets/backbtn.png";

class Approach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isShowMSG: false,
      scrollCount: 0,
    };
    this.mounted = true;
    this.winScroll = this.winScroll.bind(this);
  }
  componentWillUnmount() {
    this.mounted = false;
    document.body.classList.remove("about_us_bg");
  }
  componentDidMount() {
    this.mounted = true;
    window.addEventListener("scroll", this.winScroll);
    document.body.classList.add("about_us_bg");
  }
  OpenEnquiryDetail = (e) => {
    if (this.mounted) this.setState({ isModalOpen: !this.state.isModalOpen });
  };
  CloseEnquiryData = () => {
    if (this.mounted) {
      this.setState({ isModalOpen: !this.state.isModalOpen, isShowMSG: false });
    }
  };
  ShowMSG = () => {
    if (this.mounted) this.setState({ isShowMSG: true });
  };

  winScroll = () => {
    this.setState({ scrollCount: window.scrollY });
  };
  goBack() {
    window.history.go(-2);
  }
  render() {
    return (
      <>
        <Button className="backbtn" onClick={this.goBack}>
          <img src={backtbn} alt={backtbn} />
          <span>Back</span>
        </Button>

        <div className="about-us-page">
          <div className="lesslrpadd_cont aboutourstory_cont">
            <div className="treeties_container">
              <h1>Our 4 PATHWAYS TO RIGHT RESTORATION OF ECOSYSTEMS</h1>
              <div className="ecosystem_list ">
                <div className="row">
                  <div className="col-md-3 col-sm-6 text-center">
                    <h2 className="treeties_heading mb-3 fw-bold">1</h2>
                    <h5 className="p-0">Awareness and advocacy</h5>
                    <span>amongst corporates, citizens and communities</span>
                  </div>
                  <div className="col-md-3 col-sm-6 text-center">
                    <h2 className="treeties_heading mb-3 fw-bold">2</h2>
                    <h5 className="p-0"> Building knowledge systems</h5>
                    <span>
                      through science and partnership with research institutes
                    </span>
                  </div>
                  <div className="col-md-3 col-sm-6 text-center">
                    <h2 className="treeties_heading mb-3 fw-bold">3</h2>
                    <h5 className="p-0">
                      {" "}
                      Orchestrate restoration using digital platform and
                      technology
                    </h5>
                    <span>
                      for collaboration, mobilising stakeholder networks, track
                      & trace, governance and impact assessment
                    </span>
                  </div>
                  <div className="col-md-3 col-sm-6 text-center">
                    <h2 className="treeties_heading mb-3 fw-bold">4</h2>
                    <h5 className="p-0">
                      Implementing restoration projects at scale
                    </h5>
                    <span>through partner network</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lesslrpadd_cont paddingbotm">
            <div className="treeties_container">
              <h1 className="mb-5">OUR stakeholders</h1>
              <div className="stakeholders_list">
                <div className="row">
                  <div className="col-lg-10 col-sm-8">
                    <div className="row">
                      <div className="col-lg-4 col-sm-6 mb-5 ">
                        <h5 className="p-0 ">
                          Internal <span className="d-block"></span>{" "}
                          Stakeholders
                        </h5>
                        <div className="stakeholders_box">
                          <ul>
                            <li> Board Members</li>
                            <li>Employees</li>
                            <li>Advisories</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 mb-5 ">
                        <h5 className="p-0 ">
                          International <span className="d-block"></span>{" "}
                          Partners
                        </h5>
                        <div className="stakeholders_box">
                          <ul>
                            <li> World Eco Forum</li>
                            <li>Carbon Offset</li>
                            <li>Climate Init. Orgs</li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-6 mb-5 ">
                        <h5 className="p-0 ">
                          Execution <span className="d-block"></span> Partners
                        </h5>
                        <div className="stakeholders_box">
                          <ul>
                            <li>NGOs</li>
                            <li>Local Communities</li>
                            <li>Farmers</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 mb-5 ">
                        <h5 className="p-0 ">
                          Anchor <span className="d-block"></span> Partners
                        </h5>
                        <div className="stakeholders_box">
                          <ul>
                            <li>Technology</li>
                            <li>Infrastructure</li>
                            <li>Investors</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 mb-5 ">
                        <h5 className="p-0 ">
                          Funding <span className="d-block"></span> Partners
                        </h5>
                        <div className="stakeholders_box">
                          <ul>
                            <li>Corporates</li>
                            <li>Citizens</li>
                            <li>Sponsors</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 mb-5 ">
                        <h5 className="p-0 ">
                          Expert ORGS/ <span className="d-block"></span> SAGES
                        </h5>
                        <div className="stakeholders_box">
                          <ul>
                            <li>Satellite Data</li>
                            <li>Drone Cos.</li>
                            <li>Scientist</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-2 curlybacketimg col-sm-4 d-sm-block">
                    <div className="stackholersicons_cont">
                      {/* <img className="bigcurlybrac" src={IconShaping} alt="" /> */}
                      <img
                        className="stackholderlist_icon"
                        src={StackholderlistIcon}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lesslrpadd_cont paddingbotm">
            <div className="treeties_container">
              <h1 className="mb-5">
                Guided by 8 principles of
                <br /> ecological underpinning
                <br /> ecological restoration{" "}
              </h1>

              <div className="ecosystem_list ">
                <div className="row">
                  <div className="col-lg-3 col-md-4 col-sm-6 mb-4 text-center">
                    <h2 className="treeties_heading mb-3 fw-bold">1.</h2>

                    <span>
                      Engages
                      <br /> Stakeholders
                    </span>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 mb-4 text-center">
                    <h2 className="treeties_heading mb-3 fw-bold">3.</h2>

                    <span>
                      Is Informed by Native
                      <br /> Reference Ecosystems,
                      <br /> While Considering
                      <br /> Environmental Change
                    </span>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 text-center">
                    <h2 className="treeties_heading mb-3 fw-bold">5.</h2>

                    <span>
                      Is Assessed Against
                      <br /> Clear Goals and
                      <br /> Objectives Using
                      <br /> Measurable Indicators
                    </span>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 mb-4 text-center">
                    <h2 className="treeties_heading mb-3 fw-bold">7.</h2>

                    <span>
                      Is Part of a Continuum
                      <br /> of Restorative Activities
                    </span>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 mb-4 text-center">
                    <h2 className="treeties_heading mb-3 fw-bold">2.</h2>

                    <span>
                      Draws on Many
                      <br /> Types of Knowledge
                    </span>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 mb-4 text-center">
                    <h2 className="treeties_heading mb-3 fw-bold">4.</h2>

                    <span>
                      Supports Ecosystem
                      <br /> Recovery Progresses
                    </span>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 mb-4 text-center">
                    <h2 className="treeties_heading mb-3 fw-bold">6.</h2>

                    <span>
                      Seeks the Highest Level
                      <br /> of Recovery Possible
                    </span>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 mb-4 text-center">
                    <h2 className="treeties_heading mb-3 fw-bold">8.</h2>

                    <span>
                      Gains Cumulative Value
                      <br /> When Applied at Large Scales
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
    registrationData: state.registration.registrationData,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Approach);
