import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Modal, Button, Form } from "@patternfly/react-core";
import config from "../../../utils/config";
import Loader from "../../Common/Loader/Loader";
import { globalHistory } from "@reach/router";
import ToastNotification from "./../../Common/ToastNotification/ToastNotification";
import { v4 as uuidv4 } from "uuid";
import { SketchPicker } from "react-color";
import { Color } from "@amcharts/amcharts5";
class AddTreeSpeciesMaster extends Component {
  constructor(props) {
    console.log("propsss", props);
    super(props);
    this.state = {
      MainGuId: "",
      Type: "",
      isModalOpen: props.IsOpen,
      errors: {},
      SpeciesName: "",
      ColorCode: "#FFFFFF",
      IsLoader: false,
      IsUpdate: props.AddOrUpdate === "Update" ? true : false,
      SpeciesGuid: "",
      Applicationlist: props.data,
      files: [],
      userFiles: [],
      MaxImageSizeInMb: 0,
      floraType: "",
      floraSpecies: "",
      commonMastersList: [], // Assuming this will be populated with data
      errors: {
        floraType: "",
        floraSpecies: "",
      },
    };
    this.handleType = this.handleType.bind(this);
    this.handleSpicies = this.handleSpicies.bind(this);
    this.mounted = true;
    this.handleModalToggle = () => {
      this.setState(({ isModalOpen }) => ({
        isModalOpen: !isModalOpen,
      }));
    };
  }
  componentDidMount() {
    this.mounted = true;
    if (this.state.IsUpdate === true) {
      this.setState({
        Description: this.state.Applicationlist.Description,
        ColorCode: this.state.Applicationlist.ColorCode,
        Type: this.state.Applicationlist.Type,
        MainGuId: this.state.Applicationlist.MainGuId,
        IsUpdate: true,
        SubGuId: this.state.Applicationlist.SubGuId,
      });
    }
    this.GetSettingsImageSize("treetype");
    this.GetCommonMasters();
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  mobileSidebarToggle = (e) => {
    document.documentElement.classList.toggle("nav-open");
    e.stopPropagation();
  };

  MapKeyInArray = (responseData, keyname) => {
    let myArray = responseData.find((obj) => obj.key === keyname);
    if (myArray) {
      return myArray.value;
    }
  };

  handleColorChange = (color) => {
    let errors = { ...this.state.errors };
    errors["ColorCode"] = "";
    this.setState({ ColorCode: color?.hex || "", errors: errors });
  };

  GetSettingsImageSize = (GroupName) => {
    let UserDetail = {
      GroupName: GroupName,
    };
    let uri = `${config.API_HOST_URL}/api/projects/GetGlobalGeoupValue`;
    let fetchOptions = {
      method: "POST",
      body: JSON.stringify(UserDetail),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch(uri, fetchOptions)
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => {
            if (data.success) {
              let responseData = data.data;
              if (this.mounted) {
                this.setState({
                  MaxImageSizeInMb: this.MapKeyInArray(
                    responseData,
                    "MaxImageSizeInMb"
                  ),
                });
              }
            } else {
              let errors = {};
              errors["message"] = data.message;
              if (this.mounted) {
                this.setState({ errors: errors });
              }
            }
          });
        } else {
          return response.json().then((data) => {
            let errors = {};
            errors["message"] = data.message;
            if (this.mounted) {
              this.setState({
                errors: errors,
              });
            }
          });
        }
      })
      .catch((error) => {
        if (this.mounted) {
          console.log(error);
          this.setState({
            errorMsg: error,
            IsLoader: false,
          });
        }
      });
  };

  GetCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMasters`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        var listOfMicrobes = data
          .filter((x) => x.type === "Microbes")
          .map((x) => x.description);

        this.setState({
          commonMastersList: data,
          ListofMicrobes: listOfMicrobes,
        });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };
  // This function do validate the Registration Form
  validateForm = (e) => {
    let fields;
    fields = {
      ColorCode: this.state.ColorCode,
      Type: this.state.Type,
      floraType: this.state.floraType,
      Description: this.state.Description,
      ImageName: this.state.ImageName,
    };
    let errors = {};
    let formIsValid = true;

    // Check if DB values have been assigned to inputs
    if (
      this.state.Type !== "" &&
      this.state.floraType !== "" &&
      this.state.Description !== "" &&
      this.state.ColorCode !== ""
    ) {
      // If DB values are assigned, skip validation for these fields
      delete fields.Type;
      delete fields.MainGuId;
      delete fields.Description;
      delete fields.ColorCode;
    }

    if (!fields["Description"]) {
      formIsValid = false;
      errors["Description"] = "Enter Tree Sepcies Name.";
    }
    if (!fields["ColorCode"]) {
      formIsValid = false;
      errors["ColorCode"] = "Enter Tree Species Colour Code.";
    }
    if (!fields["Type"]) {
      formIsValid = false;
      errors["Type"] = "Select a type (Flora, Fauna, or Microbes).";
    }
    // if (!fields["floraType"]) {
    //   formIsValid = false;
    //   errors["MainGuId"] = "Select a species type";
    // }
    // if (this.state.IsUpdate == false && this.state.files.length == 0) {
    //   formIsValid = false;
    //   errors["TreeSpeciesImage"] = "Please Select Tree Species Image.";
    // }

    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  };

  AddTreeSpecies = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      if (this.mounted) {
        let file = this.state.files;
        const commonMasters = {
          Type: this.state.Type,
          MainGuId: this.state.MainGuId,
          Description: this.state.Description,
          ColorCode: this.state.ColorCode,
          ImageName: this.state.ImageName,
          SubGuId: this.state.SubGuId,
        };

        let uri;
        if (this.state.IsUpdate) {
          uri = `${config.API_HOST_URL}/api/Ecosystem/UpdateSpeciesDetails`;
        } else {
          uri = `${config.API_HOST_URL}/api/Ecosystem/AddSpeciesDetails`;
        }

        let fetchOptions = {
          method: "POST",
          body: JSON.stringify(commonMasters),
          headers: {
            Authorization: `Bearer ${this.props.token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };
        this.setState({
          IsLoader: true,
        });
        fetch(uri, fetchOptions)
          .then((response) => {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => {
                if (data.success) {
                  this.setState({
                    IsLoader: false,
                    IsOpen: false,
                    Applicationlist: [],
                    TreeTypeName: "",
                  });
                  ToastNotification.ShowSuccess("Data saved successfully..");
                  this.handleModalToggle();
                  setTimeout(() => {
                    this.setState({}, this.props.parentMethod);
                  }, 800);
                } else {
                  let errors = {};
                  errors["message"] = data.message;
                  if (this.mounted) {
                    this.setState({
                      errors: errors,
                      IsLoader: false,
                    });
                  }
                }
              });
            } else {
              return response.json().then((data) => {
                let errors = {};
                errors["message"] = data.message;
                if (this.mounted) {
                  this.setState({
                    errors: errors,
                    IsLoader: false,
                  });
                }
              });
            }
          })
          .catch((error) => {
            if (this.mounted) {
              console.log(error);
              this.setState({
                errorMsg: error,
                IsLoader: false,
              });
            }
          });
      }
    }
  };
  // This function will set the state of input fields and validate them when user enters the value in input boxes

  handleUserInput = (e) => {
    let errors = { ...this.state.errors };

    if (this.mounted) {
      const value = e.target.value.replace(/^ /, "").replace(/[^A-Za-z ]/g, ""); // remove leading spaces and non-alphabet characters, allow spaces
      const newValue = value.replace(/\s{2,}/g, " "); // replace multiple spaces with a single space
      errors["SpeciesName"] = "";
      this.setState({ SpeciesName: newValue, errors: errors });
    }
  };

  setFile = (e) => {
    let file = e.target.files[0];
    if (file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      if (
        parseInt(file.size) / 1024 <=
        parseInt(this.state.MaxImageSizeInMb) * 1024
      ) {
        let errors = {};
        errors["TreeSpeciesImage"] = "";
        this.setState({ files: file, errors: errors });
      } else {
        ToastNotification.ShowWarning(
          "Maximum file size allowed is " + this.state.MaxImageSizeInMb + "MB"
        );
      }
    } else {
      let errors = {};
      errors["TreeSpeciesImage"] = "Select a valid image.";
      if (this.mounted) {
        this.setState({ errors: errors });
      }
    }
  };

  removeFileFromList = (e) => {
    var array = [...this.state.files]; // make a separate copy of the array
    array.splice(e.target.id, 1);
    this.setState({ files: array });
  };
  handleType(event) {
    if (event.target.name == "floratype")
      this.setState({ floraType: event.target.value });
  }
  handleSpicies(event) {
    const value = event.target.value;
    if (event.target.name == "ddFloraSpicies")
      this.setState({ floraSpecies: event.target.value });
  }
  handleImageChange = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${config.API_HOST_URL}/api/Ecosystem/UploadSpeciesImage/${this.state.Type}`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error("Failed to upload image");
      }
      const result = await response.json();
      if (result.success === true) {
        this.setState({ files: file, ImageName: result.data });

        ToastNotification.ShowSuccess(result.message);
      } else {
        ToastNotification.ShowError(result.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      ToastNotification.ShowError("Error uploading image");
      document.getElementById("upload-message").innerHTML =
        "Error uploading image!";
    }
  };

  render() {
    const { isModalOpen } = this.state;
    var UserFilesarr = [];
    if (this.state.Applicationlist !== null) {
      UserFilesarr.push(
        this.state.Applicationlist.ImageName +
          "," +
          this.state.Applicationlist.ImageName
      );
    }

    return (
      <div>
        {this.state.IsLoader ? (
          <Loader />
        ) : (
          <div className="addpro_btn">
            <Modal
              title={
                this.state.IsUpdate === true ? "Edit Species" : "Add Species"
              }
              isOpen={isModalOpen}
              onClose={this.handleModalToggle}
              className="addproject_modal addtreespeies_modal"
              actions={[
                <Button
                  key="submit"
                  className="modal_btn submit_btn"
                  onClick={this.AddTreeSpecies}
                >
                  Submit
                </Button>,
                <Button
                  key="cancel"
                  className="modal_btn cancel_btn"
                  onClick={this.handleModalToggle}
                >
                  Cancel
                </Button>,
              ]}
            >
              <div>
                <ul className="addproject_form">
                  <li className="form-group speciestyoe_frmgp">
                    <label htmlFor="treetype">
                      <sup className="mandatory">*</sup>Select Type
                    </label>
                    <div className="input-group radiobtns_wrap">
                      <div className="radio__input">
                        <span className="custom-radio">
                          <input
                            className="radio__input"
                            type="radio"
                            name="category"
                            value="Flora"
                            checked={this.state.Type === "Flora"}
                            onChange={(e) => {
                              this.setState({
                                Type: e.target.value,
                                MainGuId: "",
                                errors: { ...this.state.errors, Type: "" },
                              });
                            }}
                          />
                        </span>
                        <label className="radio__label">Flora</label>
                      </div>
                      <div className="radio__input">
                        <span className="custom-radio">
                          <input
                            className="radio__input"
                            type="radio"
                            name="category"
                            value="Fauna"
                            checked={this.state.Type === "Fauna"}
                            onChange={(e) => {
                              this.setState({
                                Type: e.target.value,
                                MainGuId: "",
                                errors: { ...this.state.errors, Type: "" },
                              });
                            }}
                          />
                        </span>
                        <label className="radio__label">Fauna</label>
                      </div>
                      <div className="radio__input">
                        <span className="custom-radio">
                          <input
                            className="radio__input"
                            type="radio"
                            name="category"
                            value="Microbes"
                            checked={this.state.Type === "Microbes"}
                            onChange={(e) => {
                              this.setState({
                                Type: e.target.value,
                                MainGuId: "",
                                errors: { ...this.state.errors, Type: "" },
                              });
                            }}
                          />
                        </span>
                        <label className="radio__label">Microbes</label>
                      </div>
                    </div>
                    <div className="mandatory has-error">
                      {this.state.errors.Type}
                    </div>
                  </li>

                  <li className="form-group">
                    <label htmlFor="treetype">
                      <sup className="mandatory">*</sup>Select Species type
                    </label>
                    <div>
                      <select
                        className="dropdown-113"
                        value={this.state.MainGuId}
                        onChange={(e) => {
                          if (!this.state.Type) {
                            this.setState({
                              errors: {
                                ...this.state.errors,
                                Type: "Please select a type first",
                              },
                            });
                            return;
                          }
                          this.setState({ MainGuId: e.target.value });
                        }}
                        name="floratype"
                      >
                        <option value="">Select</option>
                        {this.state.Type &&
                          [
                            ...new Map(
                              this.state.commonMastersList
                                .filter(
                                  (x) =>
                                    x.type === this.state.Type &&
                                    x.isSpeciesMainType === true
                                )
                                .map((item) => [item.mainGuId, item])
                            ).values(),
                          ].map((item) => (
                            <option key={item.mainGuId} value={item.mainGuId}>
                              {item.description}
                            </option>
                          ))}
                      </select>
                      <div className="mandatory has-error">
                        {this.state.errors.Type}
                        {this.state.errors.MainGuId}
                      </div>
                    </div>
                  </li>

                  <li className="form-group field">
                    <input
                      type="text"
                      id="treetype"
                      placeholder="Enter Tree Species Name"
                      name="Description"
                      value={this.state.Description}
                      onChange={(event) =>
                        this.setState({ Description: event.target.value })
                      }
                    />
                    <label htmlFor="treetype">
                      <sup className="mandatory">*</sup>Tree Species Name
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.Description}
                    </div>
                  </li>

                  <li className="form-group field">
                    <SketchPicker
                      color={this.state.ColorCode || "#FFFFFF"}
                      onChange={(color) => {
                        this.setState({ ColorCode: color.hex });
                      }}
                    />
                    <p>{this.state.ColorCode}</p>
                    <label htmlFor="ColorCode">
                      <sup className="mandatory">*</sup>Tree Species colour Code
                    </label>
                    <div className="mandatory has-error">
                      {this.state.errors.ColorCode}
                    </div>
                  </li>

                  <li className="form-group inventory_cont">
                    <label for="forinventory">
                      <sup className="mandatory"></sup>
                      Tree Species Image
                    </label>

                    <div className="input-group divUploadFile">
                      <div className="qq-upload-drop-area">
                        <span>Drop files here to upload</span>
                      </div>
                      <div className="qq-upload-button">
                        Drag and Drop Files
                        <span className="redMandat">
                          Upload file type jpg, jpeg, png, gif File size limit{" "}
                          {this.state.MaxImageSizeInMb} MB
                        </span>
                      </div>
                      <input
                        id="choose"
                        type="file"
                        name="files"
                        onChange={(e) => {
                          if (!this.state.Type) {
                            this.setState({
                              errors: {
                                TreeSpeciesImage: "Select type first.",
                              },
                            });
                          } else {
                            this.handleImageChange(e.target.files[0]);
                          }
                        }}
                      />
                    </div>
                    <div>
                      <ul id="fileList" className="ul-project-files">
                        {this.state.files && (
                          <li class="file-list-li">{this.state.files.name}</li>
                        )}
                      </ul>
                    </div>
                    <div className="mandatory has-error">
                      {this.state.errors.TreeSpeciesImage}
                    </div>
                  </li>
                  <div id="upload-message"></div>
                </ul>
              </div>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(AddTreeSpeciesMaster);
