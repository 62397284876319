import React from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import config from "../../utils/config";
import { globalHistory } from "@reach/router";
import { JsonData } from "@amcharts/amcharts5";
import ProjectSummaryMap from "./ProjectSummaryMap";

//const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyALfkhscuzAI4JBrQxUKzrjRDkNb6tXlvk`;

class ProjectSummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      singleProject: "",
      projectId: "",
      ProjectTypeGuId: "",
      ProjectSubTypeGuId: "",
      ProjectType: "",
      ProjectSubType: "",

      sponsorshipList: [],
      ProjectList: "",
      ProjectNmae: "",
      newandInitiated: "",
      isModalOpen: false,
      iT_EP_Speci_Spon: "",
      iT_EP_Spon_Amount: 0,
      iT_TT_Speci_Spon: "",
      iT_TT_Spon_Amount: 0,
      iV_EP_Speci_Spon: "",
      iV_EP_Spon_Amount: 0,
      iV_TT_Speci_Spon: "",
      iV_TT_Spon_Amount: 0,
      rowLandParcelList: [],
      rowWaterBodyList: [],
      noLandParcels: null,
      noWaterBodies: null,
      GoogleMapApiKey: "",
      LocationCords: [],
    };
    this.mounted = true;
  }
  componentDidMount() {
    let currentPath = globalHistory.location.pathname;
    let Id = currentPath.split(":")[1].replace("/", "");
    // let Index = currentPath.split(":")[2].replace("/", "");
    this.setState({ projectId: Id });
    this.GetProjectList(Id);
    this.GetCommonMasters();
    this.GetProjectLocation(Id);
    this.GetGlobalSettings("Google Map");
  }
  GetGlobalSettings = (GroupName) => {
    let UserDetail = {
      GroupName: GroupName,
    };
    let uri = `${config.API_HOST_URL}/api/projects/GetGlobalGeoupValue`;
    let fetchOptions = {
      method: "POST",
      body: JSON.stringify(UserDetail),
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    fetch(uri, fetchOptions)
      .then((response) => {
        return response.json().then((data) => {
          if (data.success) {
            let responseData = data.data;
            this.setState({
              GoogleMapApiKey: this.MapKeyInArray(
                responseData,
                "GoogleMapApiKey"
              ),
            });
          } else {
            let errors = {};
            errors["message"] = data.message;
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  MapKeyInArray = (responseData, keyname) => {
    let myArray = responseData.find((obj) => obj.key === keyname);
    if (myArray) {
      return myArray.value;
    }
  };
  GetProjectLocation = (ProjectId) => {
    let projId = { ProjectId: ProjectId };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectLocation`;
    fetch(url, {
      method: "Post",
      body: JSON.stringify(projId),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        // console.log("prolist",responseData)
        //this.setState({projectLocationList: responseData});
        if (responseData !== null) {
          this.setState({
            rowLandParcelList: responseData.landParcels,
            rowWaterBodyList: responseData.waterBodies,
            noLandParcels: responseData.noLandParcels,
            noWaterBodies: responseData.noWaterBodies,
          });
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  //    GetAllCommonMasters(ProjectId, ProjectTypeGuid, ProjectSubTypeGuid) {
  //   let id={ProjectId:ProjectId,ProjectTypeGuid : ProjectTypeGuid, ProjectSubTypeGuid: ProjectSubTypeGuid};
  //     let url = `${config.API_HOST_URL}/api/Ecosystem/GetAllCommonMasters`;
  //     fetch(url, {
  //         method: "POST",
  //         body:JSON.stringify(id),
  //         headers: {
  //             WebsiteGuid: this.props.websiteGUID,
  //             LanguageGuid: this.props.languageGUID,
  //             Authorization: `Bearer ${this.props.token}`,
  //             "Content-Type": "application/json",
  //             Accept: "application/json"
  //         }})
  //        .then(response =>  response.json())
  //        .then(data => {console.log("data",data);
  //            // let responseData = JSON.parse(data);
  //            let responseData = data;
  //            // console.log("commm", responseData);
  //             this.setState({commonMasterList: responseData});

  //             // if(this.state.commonMasterList.length > 0 )
  //             // {
  //             //    var PlaceList= this.state.commonMasterList.filter(x => x.type == "Place");
  //             //    console.log("PlaceList",PlaceList);
  //             //    this.setState({ placeDropdownList : PlaceList})
  //             // }
  //         })
  //        .catch(error => {
  //             console.log("Error in request", error);
  //         });
  // }

  GetProjectList = (ProjectId) => {
    let url = `${config.API_HOST_URL}/api/Projects/GetProjectSummaryByProjId`;
    let proj = {
      ProjectId: ProjectId,
    };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(proj),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // let responseData = JSON.parse(data.ProjectList);
        let responseData = data.data;
        this.setState({ ProjectList: responseData });
        this.setState({ projectId: responseData?.projectId });
        this.setState({ ProjectTypeGuId: responseData?.projectTypeGuId });
        this.setState({ ProjectSubTypeGuId: responseData?.projectSubTypeGuId });
        this.setState({ LocationCords: responseData?.locationCords });
        // this.GetAllCommonMasters(this.state.ProjectList.projectId,this.state.ProjectList.projectTypeGuId, this.state.ProjectList.projectSubTypeGuId);

        // this.setState({ ProjectList: responseData });
        // this.setState({ newandInitiated: responseData. });
        // this.setState({ ProjectList: responseData });

        //  console.log("projlist",this.state.ProjectList);
        this.GetCommonMasters();
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };
  //  noLandParcels=()=>{
  //   this.setState({noLandParcels:this.state.noLandParcels})
  // }

  GetCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetAllCommonMasters`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // debugger
        // console.log("data",data )
        //var projectType = data.filter(x =>  x.mainGuId === this.state.ProjectTypeGuId && x.type ==="ProjectType").map(x=>x.description)
        var projectType = data.filter(
          (x) =>
            x.mainGuId === this.state.ProjectTypeGuId &&
            x.type === "ProjectType"
        )[0]?.description;
        var projectSubType = data.filter(
          (x) =>
            x.subGuId === this.state.ProjectSubTypeGuId &&
            x.type === "ProjectSubType"
        )[0]?.description;
        //console.log("projectSubType", projectSubType);

        this.setState({
          commonMastersList: data,
          ProjectType: projectType,
          ProjectSubType: projectSubType,
          // typeList:listOfType,
        });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  GetSponsorship = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetSponsorship`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          // sponsorshipList: data,
        });
        console.log("data", data);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  render() {
    return (
      <div className="project-summary13">
        <div className="content65">
          <div className="hero-title12">
            <div className="project-summary14">Project Summary</div>
          </div>
          <div className="container6">
            <div className="column-13">
              <div className="content-title-text78">
                <div className="project-summary14">Name</div>
                <div className="aarey-forest-phase10">
                  {this.state.ProjectList?.projectName}
                  {/* // Aarey Forest Phase II - Mumbai */}
                </div>
              </div>
              <div className="content-title-text78">
                <div className="project-summary14">Type</div>
                <div className="aarey-forest-phase10">
                  {" "}
                  {this.state.ProjectType}
                </div>
              </div>
              <div className="content-title-text78">
                <div className="project-summary14">Sub-Type</div>
                <div className="aarey-forest-phase10">
                  {this.state.ProjectSubType}
                </div>
              </div>
            </div>
            <div className="column-13">
              <div className="content-title-text78">
                <div className="project-summary14">Location</div>
                <div className="aarey-forest-phase10">
                  {this.state.ProjectList?.location}
                </div>
              </div>
              <div className="content-title-text78">
                <div className="project-summary14">
                  New or Initiated Already?
                </div>
                <div className="aarey-forest-phase10">
                  {/* Initiated Already */}
                  {this.state.ProjectList?.newOrInitiatedAlready}
                </div>
              </div>
              {/* <div className="content-title-text78">
                      <div className="project-summary14">Water Bodies Title</div>
                      <div className="aarey-forest-phase10"></div>
                    </div> */}
            </div>
            {/* <div className="column-13">
                    <div className="content-title-text78">
                      <div className="project-summary14">Land Parcels Title</div>
                      <div className="aarey-forest-phase10"></div>
                    </div>
                    <div className="content-title-text78">
                      <div className="project-summary14">Disturbance Type of Cause</div>
                      <div className="aarey-forest-phase10"></div>
                    </div>
                    <div className="content-title-text78">
                      <div className="project-summary14">Exact Cause</div>
                      <div className="aarey-forest-phase10"></div>
                    </div>
                   
                  </div> */}
          </div>
        </div>
        {this.state.GoogleMapApiKey && (
          <div class="img-icon">
            <ProjectSummaryMap
              noLandParcels={this.state.noLandParcels}
              landParcelList={this.state.rowLandParcelList}
              GoogleMapApiKey={this.state.GoogleMapApiKey}
              LocationCords={this.state.LocationCords}
            ></ProjectSummaryMap>
          </div>
        )}
        {/* <img className="img-icon" alt="" src="/img@3x.png" /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.user.userData,
    token: state.configuration.token,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setReducer: (data, type) =>
      dispatch({
        type: type,
        data: data,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSummaryContainer);
