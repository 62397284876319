import React, { Component } from "react";
import { connect } from "react-redux";
import config from "../../utils/config";
import { navigate } from "@reach/router";
import "../EcosystemRestorationProject/global.css";
import "../EcosystemRestorationProject/ecosystabsall.css";
import ProjectSummary from "../EcosystemCommon/ProjectSummaryContainer";
import LeftTabMenuContainer from "../EcosystemCommon/LeftTabMenuContainer";
import Header from "../EcosystemCommon/Header";
import LeftNavbar from "../EcosystemCommon/LeftNavbar";
import { globalHistory } from "@reach/router";
import moment from "moment";
import ToastNotification from "../Common/ToastNotification/ToastNotification";

class TreeTiesAddProjectForms1Obj extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      IsLoader: false,
      ProjectObjectives_Id: "",

      Goal_Type: "",
      Project_Goal: "",
      ProjectId: "",
      Scope_Restoration: "",
      Scope_for_TreeTies: "",

      commonMastersList: [],
      selectedTargetEcosystemType: "",
      selectedEcosystemSubType: "",

      Year_of_Reference: "",
      Sources: "",
      Remarks: "",
      pre_Dis_Microbes_Id: "",
      microbesType: "",
      microbesSpecies: " ",
      microbesNumber_Population: " ",
      microbesInvasive_Specie: " ",
      microbesOrigin: " ",
      microbesSpec_Recom_Restoration: " ",
      microbesProd_per_Year: " ",
      ListObjMicrobes: [
        {
          pre_Dis_Microbes_Id: "",
          microbesType: "",
          microbesSpecies: "",
          microbesNumber_Population: " ",
          MicrobesInvasive_Specie: " ",
          microbesOrigin: " ",
          microbesSpec_Recom_Restoration: " ",
          microbesProd_per_Year: " ",
        },
      ],
      pre_Dis_Fauna_Id: "",
      faunaType: "",
      faunaSpecies: " ",
      faunaNumber_Population: " ",
      faunaInvasive_Specie: " ",
      faunaOrigin: " ",
      faunaSpec_Recom_Restoration: " ",
      faunaProd_per_Year: " ",
      ListObjFauna: [
        {
          Pre_Dis_Fauna_Id: "",
          faunaType: "",
          faunaSpecies: " ",
          faunaNumber_Population: " ",
          faunaInvasive_Specie: " ",
          faunaOrigin: " ",
          faunaSpec_Recom_Restoration: " ",
          faunaProd_per_Year: " ",
        },
      ],

      pre_Dis_Flora_Id: "",
      floraType: "",
      floraSpecies: "",
      floraNumber_Population: " ",
      floraInvasive_Specie: " ",
      floraOrigin: " ",
      floraSpec_Recom_Restoration: " ",
      floraProd_per_Year: " ",
      ListObjFlora: [
        {
          pre_Dis_Flora_Id: "",
          floraType: "",
          floraSpecies: "",
          floraNumber_Population: " ",
          floraInvasive_Specie: " ",
          floraOrigin: " ",
          floraSpec_Recom_Restoration: " ",
          floraProd_per_Year: " ",
        },
      ],

      ClimateType: "",
      Avg_Temp_Summer: "",
      Avg_Temp_Winter: "",
      Air_Quality_Index: "",
      Humidity: "",
      Avg_Yearly_Rainfall: "",
      Avg_Wind_Speed: "",
      Avg_No_Hours_Sunlight_Avail_Summer: "",
      Avg_No_Hours_Sunlight_Avail_Winter: "",

      Turbidity: "",
      Temperature: "",
      Color: "",
      Taste_Odor: "",
      WElectrical_Conductivity: "",
      PH: "",
      Hardness: "",
      Dissolved_Oxygen: "",
      Biochem_Oxygen_Demand: "",
      Chem_Oxygen_Demand: "",

      Toxic_InorganicSubstance_Name: "",
      Toxic_InorganicDescription: "",

      Toxic_OrganicSubstance_Name: "",
      Toxic_OrganicDescription: "",

      RadioactiveSubstance_Name: "",
      RadioactiveDescription: "",

      Soil_Textures: "",
      Soil_Structure: "",
      Soil_Porosity: "",
      Soil_pH: "",
      Nitrate: "",
      Phosphate: "",
      Potassium: "",
      SElectrical_Conductivity: "",
      Soil_Color: "",
      Soil_Moisture_Level: "",
      Organic_Carbon: "",

      Tot_Carbon_Offset_Capacity: "",

      No_Families: "",
      Avg_Family_Size: "",
      Gender: "",
      Literacy_Rate_Overall: "",
      Literacy_Rate_Female: "",
      Avg_Annual_Inc_Family_Farming: "",
      Avg_Annual_Inc_Family_Other_Sources: "",
    };
    this.state.ListObjFlora.splice(0, 1);
    this.state.ListObjFauna.splice(0, 1);
    this.state.ListObjMicrobes.splice(0, 1);
    this.handleGoalType = this.handleGoalType.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleScopeRestoration = this.handleScopeRestoration.bind(this);
    this.handleTargetEcosystemType = this.handleTargetEcosystemType.bind(this);
    this.handleTargetEcosystemSubType =
      this.handleTargetEcosystemSubType.bind(this);
    this.handleScopeforTreeTies = this.handleScopeforTreeTies.bind(this);

    this.handleType = this.handleType.bind(this);
    this.handleSpicies = this.handleSpicies.bind(this);
    this.handleNumberPopulation = this.handleNumberPopulation.bind(this);
    this.handleInvasiveSpecie = this.handleInvasiveSpecie.bind(this);
    this.handleOrigin = this.handleOrigin.bind(this);
    this.handleSpecRecomRestoration =
      this.handleSpecRecomRestoration.bind(this);
    this.handleProdperYear = this.handleProdperYear.bind(this);

    this.handleTypeFauna = this.handleTypeFauna.bind(this);
    this.handleSpiciesFauna = this.handleSpiciesFauna.bind(this);
    this.handleNumberPopulationFauna =
      this.handleNumberPopulationFauna.bind(this);
    this.handleInvasiveSpecieFauna = this.handleInvasiveSpecieFauna.bind(this);
    this.handleOriginFauna = this.handleOriginFauna.bind(this);
    this.handleSpecRecomRestorationFauna =
      this.handleSpecRecomRestorationFauna.bind(this);
    this.handleProdperYearFauna = this.handleProdperYearFauna.bind(this);

    this.handleTypeMicrobes = this.handleTypeMicrobes.bind(this);
    this.handleSpiciesMicrobes = this.handleSpiciesMicrobes.bind(this);
    this.handleNumberPopulationMicrobes =
      this.handleNumberPopulationMicrobes.bind(this);
    this.handleInvasiveSpecieMicrobes =
      this.handleInvasiveSpecieMicrobes.bind(this);
    this.handleOriginMicrobes = this.handleOriginMicrobes.bind(this);
    this.handleSpecRecomRestorationMicrobes =
      this.handleSpecRecomRestorationMicrobes.bind(this);
    this.handleProdperYearMicrobes = this.handleProdperYearMicrobes.bind(this);

    this.handleClimateType = this.handleClimateType.bind(this);

    //water
    this.handleTurbidity = this.handleTurbidity.bind(this);
    this.handleHardness = this.handleHardness.bind(this);
    this.handleWElectricalConductivity =
      this.handleWElectricalConductivity.bind(this);

    //soil

    this.handleSoilTextures = this.handleSoilTextures.bind(this);
    this.handleSoilStructure = this.handleSoilStructure.bind(this);
    this.handleSoilPorosity = this.handleSoilPorosity.bind(this);

    this.mounted = true;
  }
  componentDidMount() {
    let currentPath = globalHistory.location.pathname;
    let Id = currentPath.split(":")[1].replace("/", "");
    this.setState({ ProjectId: Id });
    this.GetProjectObjective(Id);
    this.GetCommonMasters();
  }

  handleDate(event) {
    if (this.mounted) this.setState({ Year_of_Reference: event.target.value });
  }
  //soil

  handleSoilTextures(event) {
    this.setState({ Soil_Textures: event.target.value });
  }
  handleSoilStructure(event) {
    this.setState({ Soil_Structure: event.target.value });
  }
  handleSoilPorosity(event) {
    this.setState({ Soil_Porosity: event.target.value });
  }

  //water
  handleTurbidity(event) {
    this.setState({ Turbidity: event.target.value });
  }
  handleHardness(event) {
    this.setState({ Hardness: event.target.value });
  }
  handleWElectricalConductivity(event) {
    this.setState({ WElectrical_Conductivity: event.target.value });
  }

  handleGoalType(event) {
    this.setState({ Goal_Type: event.target.value });
  }

  handleScopeRestoration(event) {
    this.setState({ Scope_Restoration: event.target.value });
  }

  handleScopeforTreeTies(event) {
    this.setState({ Scope_for_TreeTies: event.target.value });
  }
  handleProdperYearMicrobes(event) {
    this.setState({ microbesProd_per_Year: event.target.value });
  }
  handleSpecRecomRestorationMicrobes(event) {
    this.setState({ microbesSpec_Recom_Restoration: event.target.value });
  }
  handleOriginMicrobes(event) {
    this.setState({ microbesOrigin: event.target.value });
  }
  handleInvasiveSpecieMicrobes(event) {
    this.setState({ microbesInvasive_Specie: event.target.value });
  }
  handleTypeMicrobes(event) {
    if (event.target.name == "ddmicrobestype")
      this.setState({ microbesType: event.target.value });
  }
  handleSpiciesMicrobes(event) {
    if (event.target.name == "ddMicrobesSpecies")
      this.setState({ microbesSpecies: event.target.value });
  }
  handleNumberPopulationMicrobes(event) {
    this.setState({ microbesNumber_Population: event.target.value });
  }
  handleMicrobes = (e) => {
    e.preventDefault();
    if (this.validateMicrobes()) {
      if (this.mounted) {
        if (this.state.pre_Dis_Microbes_Id == "") {
          let newItem = {
            // pre_Dis_Microbes_Id:this.state.pre_Dis_Microbes_Id,
            microbesType: this.state.microbesType,
            microbesSpecies: this.state.microbesSpecies,
            microbesNumber_Population: this.state.microbesNumber_Population,
            microbesInvasive_Specie: this.state.microbesInvasive_Specie,
            microbesOrigin: this.state.microbesOrigin,
            microbesSpec_Recom_Restoration:
              this.state.microbesSpec_Recom_Restoration,
            microbesProd_per_Year: this.state.microbesProd_per_Year,
          };
          let { ListObjMicrobes } = this.state;
          ListObjMicrobes.push(newItem);
          this.setState({
            ListObjMicrobes: ListObjMicrobes,
          });

          this.ClearMicrobes();
        } else {
          const newState = this.state.ListObjMicrobes.map((obj) => {
            if (obj.pre_Dis_Microbes_Id === this.state.pre_Dis_Microbes_Id) {
              return {
                ...obj,
                pre_Dis_Microbes_Id: this.state.pre_Dis_Microbes_Id,
                microbesType: this.state.microbesType,
                microbesSpecies: this.state.microbesSpecies,
                microbesNumber_Population: this.state.microbesNumber_Population,
                microbesInvasive_Specie: this.state.microbesInvasive_Specie,
                microbesOrigin: this.state.microbesOrigin,
                microbesSpec_Recom_Restoration:
                  this.state.microbesSpec_Recom_Restoration,
                microbesProd_per_Year: this.state.microbesProd_per_Year,
              };
            }
            return obj;
          });
          this.setState({ ListObjMicrobes: newState });
          this.ClearMicrobes();
        }
      }
    }
  };
  ClearMicrobes = (e) => {
    this.setState({
      microbesType: "",
      microbesSpecies: "",
      microbesNumber_Population: "",
      microbesInvasive_Specie: "",
      microbesOrigin: "",
      microbesSpec_Recom_Restoration: "",
      microbesProd_per_Year: "",
    });
  };
  handleProdperYearFauna(event) {
    this.setState({ faunaProd_per_Year: event.target.value });
  }
  handleSpecRecomRestorationFauna(event) {
    this.setState({ faunaSpec_Recom_Restoration: event.target.value });
  }
  handleOriginFauna(event) {
    this.setState({ faunaOrigin: event.target.value });
  }
  handleInvasiveSpecieFauna(event) {
    this.setState({ faunaInvasive_Specie: event.target.value });
  }
  handleTypeFauna(event) {
    if (event.target.name == "faunatype")
      this.setState({ faunaType: event.target.value });
  }
  handleSpiciesFauna(event) {
    if (event.target.name == "ddFaunaSpicies")
      this.setState({ faunaSpecies: event.target.value });
  }
  handleNumberPopulationFauna(event) {
    this.setState({ faunaNumber_Population: event.target.value });
  }
  handleFauna = (e) => {
    e.preventDefault();
    if (this.validateFauna()) {
      if (this.mounted) {
        if (this.state.pre_Dis_Fauna_Id == "") {
          let newItem = {
            // Pre_Dis_Fauna_Id:this.state.Pre_Dis_Fauna_Id,
            faunaType: this.state.faunaType,
            faunaSpecies: this.state.faunaSpecies,
            faunaType: this.state.faunaType,
            faunaSpecies: this.state.faunaSpecies,
            faunaNumber_Population: this.state.faunaNumber_Population,
            faunaInvasive_Specie: this.state.faunaInvasive_Specie,
            faunaOrigin: this.state.faunaOrigin,
            faunaSpec_Recom_Restoration: this.state.faunaSpec_Recom_Restoration,
            faunaProd_per_Year: this.state.faunaProd_per_Year,
          };
          let { ListObjFauna } = this.state;
          ListObjFauna.push(newItem);
          this.setState({
            ListObjFauna: ListObjFauna,
          });
          this.ClearFauna();
        } else {
          const newState = this.state.ListObjFauna.map((obj) => {
            if (obj.pre_Dis_Fauna_Id === this.state.pre_Dis_Fauna_Id) {
              return {
                ...obj,

                pre_Dis_Fauna_Id: this.state.pre_Dis_Fauna_Id,
                faunaType: this.state.faunaType,
                faunaSpecies: this.state.faunaSpecies,
                faunaType: this.state.faunaType,
                faunaSpecies: this.state.faunaSpecies,
                faunaNumber_Population: this.state.faunaNumber_Population,
                faunaInvasive_Specie: this.state.faunaInvasive_Specie,
                faunaOrigin: this.state.faunaOrigin,
                faunaSpec_Recom_Restoration:
                  this.state.faunaSpec_Recom_Restoration,
                faunaProd_per_Year: this.state.faunaProd_per_Year,
              };
            }
            return obj;
          });
          this.setState({ ListObjFauna: newState });
          this.ClearFauna();
        }
      }
    }
  };

  ClearFauna = (e) => {
    this.setState({
      faunaType: "",
      faunaSpecies: "",
      faunaNumber_Population: "",
      faunaInvasive_Specie: "",
      faunaOrigin: "",
      faunaSpec_Recom_Restoration: "",
      faunaProd_per_Year: "",
    });
  };
  handleProdperYear(event) {
    this.setState({ floraProd_per_Year: event.target.value });
  }
  handleSpecRecomRestoration(event) {
    this.setState({ floraSpec_Recom_Restoration: event.target.value });
  }
  handleOrigin(event) {
    this.setState({ floraOrigin: event.target.value });
  }
  handleInvasiveSpecie(event) {
    this.setState({ floraInvasive_Specie: event.target.value });
  }
  handleType(event) {
    if (event.target.name == "floratype")
      this.setState({ floraType: event.target.value });
  }
  handleSpicies(event) {
    const value = event.target.value;
    if (event.target.name == "ddFloraSpicies")
      this.setState({ floraSpecies: event.target.value });
  }
  handleNumberPopulation(event) {
    this.setState({ floraNumber_Population: event.target.value });
  }
  handleFlora = (e) => {
    e.preventDefault();
    if (this.validateFlora()) {
      if (this.mounted) {
        if (this.state.pre_Dis_Flora_Id == "") {
          let newItem = {
            // pre_Dis_Flora_Id: this.state.pre_Dis_Flora_Id,
            floraType: this.state.floraType,
            floraSpecies: this.state.floraSpecies,
            floraNumber_Population: this.state.floraNumber_Population,
            floraInvasive_Specie: this.state.floraInvasive_Specie,
            floraOrigin: this.state.floraOrigin,
            floraSpec_Recom_Restoration: this.state.floraSpec_Recom_Restoration,
            floraProd_per_Year: this.state.floraProd_per_Year,
          };

          console.log(newItem);
          let { ListObjFlora } = this.state;
          ListObjFlora.push(newItem);
          this.setState({
            ListObjFlora: ListObjFlora,
          });
          console.log("floranew", this.state.ListObjFlora);
          this.ClearFlora();
        } else {
          const newState = this.state.ListObjFlora.map((obj) => {
            if (obj.pre_Dis_Flora_Id === this.state.pre_Dis_Flora_Id) {
              return {
                ...obj,
                pre_Dis_Flora_Id: this.state.pre_Dis_Flora_Id,
                floraType: this.state.floraType,
                floraSpecies: this.state.floraSpecies,
                floraNumber_Population: this.state.floraNumber_Population,
                floraInvasive_Specie: this.state.floraInvasive_Specie,
                floraOrigin: this.state.floraOrigin,
                floraSpec_Recom_Restoration:
                  this.state.floraSpec_Recom_Restoration,
                floraProd_per_Year: this.state.floraProd_per_Year,
              };
            }
            return obj;
          });
          this.setState({ ListObjFlora: newState });
          this.ClearFlora();
        }
      }
    }
  };
  ClearFlora = (e) => {
    this.setState({
      floraType: "",
      floraSpecies: "",
      floraNumber_Population: "",
      floraInvasive_Specie: "",
      floraOrigin: "",
      floraSpec_Recom_Restoration: "",
      floraProd_per_Year: "",
    });
  };

  handleClimateType(event) {
    this.setState({ ClimateType: event.target.value });
  }
  GetCommonMasters = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMasters`;
    fetch(url, {
      method: "Get",
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        var listOfMicrobes = data
          .filter((x) => x.type === "Microbes")
          .map((x) => x.description);

        this.setState({
          commonMastersList: data,
          ListofMicrobes: listOfMicrobes,
        });
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };
  handleTargetEcosystemType(event) {
    const value = event.target.value;

    if (event.target.name == "tetype")
      this.setState({ selectedTargetEcosystemType: event.target.value });
  }
  handleTargetEcosystemSubType(event) {
    const value = event.target.value;
    if (event.target.name == "ddlsubtype")
      this.setState({ selectedEcosystemSubType: event.target.value });
  }
  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value.replace(/[^0-9]/g, "");
    this.setState({ [name]: value });
  };
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (this.mounted) {
      this.setState({ [name]: value });
    }
  };
  handleAlphaInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    if (value === "" || alphanumericRegex.test(value)) {
      this.setState({ [name]: value });
    }
  };
  deleteFlora = (i) => {
    this.state.ListObjFlora.splice(i, 1);
    this.setState({ ListObjFlora: this.state.ListObjFlora });
  };
  deleteFauna = (i) => {
    this.state.ListObjFauna.splice(i, 1);
    this.setState({ ListObjFauna: this.state.ListObjFauna });
  };
  deleteMicrobes = (i) => {
    this.state.ListObjMicrobes.splice(i, 1);
    this.setState({ ListObjMicrobes: this.state.ListObjMicrobes });
  };
  editFlora = (i) => {
    this.setState({
      pre_Dis_Flora_Id: this.state.ListObjFlora[i].pre_Dis_Flora_Id,
      floraType: this.state.ListObjFlora[i].floraType,
      floraSpecies: this.state.ListObjFlora[i].floraSpecies,
      floraNumber_Population: this.state.ListObjFlora[i].floraNumber_Population,
      floraOrigin: this.state.ListObjFlora[i].floraOrigin,
      floraSpec_Recom_Restoration:
        this.state.ListObjFlora[i].floraSpec_Recom_Restoration,
      floraProd_per_Year: this.state.ListObjFlora[i].floraProd_per_Year,
      floraInvasive_Specie: this.state.ListObjFlora[i].floraInvasive_Specie,
    });
  };
  editMicrobes = (i) => {
    this.setState({
      pre_Dis_Microbes_Id: this.state.ListObjMicrobes[i].pre_Dis_Microbes_Id,
      microbesType: this.state.ListObjMicrobes[i].microbesType,
      microbesSpecies: this.state.ListObjMicrobes[i].microbesSpecies,
      microbesNumber_Population:
        this.state.ListObjMicrobes[i].microbesNumber_Population,
      microbesOrigin: this.state.ListObjMicrobes[i].microbesOrigin,
      microbesSpec_Recom_Restoration:
        this.state.ListObjMicrobes[i].microbesSpec_Recom_Restoration,
      microbesProd_per_Year:
        this.state.ListObjMicrobes[i].microbesProd_per_Year,
      microbesInvasive_Specie:
        this.state.ListObjMicrobes[i].microbesInvasive_Specie,
    });
  };
  editFauna = (i) => {
    this.setState({
      pre_Dis_Fauna_Id: this.state.ListObjFauna[i].pre_Dis_Fauna_Id,
      faunaType: this.state.ListObjFauna[i].faunaType,
      faunaSpecies: this.state.ListObjFauna[i].faunaSpecies,
      faunaNumber_Population: this.state.ListObjFauna[i].faunaNumber_Population,
      faunaOrigin: this.state.ListObjFauna[i].faunaOrigin,
      faunaSpec_Recom_Restoration:
        this.state.ListObjFauna[i].faunaSpec_Recom_Restoration,
      faunaProd_per_Year: this.state.ListObjFauna[i].faunaProd_per_Year,
      faunaInvasive_Specie: this.state.ListObjFauna[i].faunaInvasive_Specie,
    });
  };
  // All the data be taken from Project Objective
  GetProjectObjective = (ProjectId) => {
    let projId = { ProjectId: ProjectId };
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetProjectObjectives`;
    fetch(url, {
      method: "Post",
      body: JSON.stringify(projId),
      headers: {
        WebsiteGuid: this.props.websiteGUID,
        LanguageGuid: this.props.languageGUID,
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        this.setState({
          ProjectId: responseData.projectId,
          ProjectObjectives_Id: responseData.projectObjectives_Id,
          ListObjFlora: responseData.pre_DisturbanceDetails.pre_Dis_Flora,
          ListObjFauna: responseData.pre_DisturbanceDetails.pre_Dis_Fauna,
          ListObjMicrobes: responseData.pre_DisturbanceDetails.pre_Dis_Microbes,
          Goal_Type: responseData.goal_Type,
          Project_Goal: responseData.project_Goal,
          Scope_Restoration: responseData.scope_Restoration,
          Scope_for_TreeTies: responseData.scope_for_TreeTies,
          selectedTargetEcosystemType: responseData.target_Ecosystem_Type,
          selectedEcosystemSubType: responseData.target_Ecosystem_Sub_Type,
          Year_of_Reference:
            responseData.pre_DisturbanceDetails.pre_Dis_Ref_YearOfReference !=
            null
              ? responseData.pre_DisturbanceDetails.pre_Dis_Ref_YearOfReference
                  .slice(0, -1)
                  .split("T")[0]
              : "",
          // Year_of_Reference:(responseData.pre_DisturbanceDetails.pre_Dis_Ref_YearOfReference).slice(0, -1).split('T')[0],
          Sources: responseData.pre_DisturbanceDetails.pre_Dis_Ref_Sources,
          Remarks: responseData.pre_DisturbanceDetails.pre_Dis_Ref_Remarks,
          ClimateType: responseData.pre_DisturbanceDetails.climate.climateType,
          Avg_Temp_Summer:
            responseData.pre_DisturbanceDetails.climate.avg_Temp_Summer,
          Avg_Temp_Winter:
            responseData.pre_DisturbanceDetails.climate.avg_Temp_Winter,
          Air_Quality_Index:
            responseData.pre_DisturbanceDetails.climate.air_Quality_Index,
          Humidity: responseData.pre_DisturbanceDetails.climate.humidity,
          Avg_Yearly_Rainfall:
            responseData.pre_DisturbanceDetails.climate.avg_Yearly_Rainfall,
          Avg_Wind_Speed:
            responseData.pre_DisturbanceDetails.climate.avg_Wind_Speed,
          Avg_No_Hours_Sunlight_Avail_Summer:
            responseData.pre_DisturbanceDetails.climate
              .avg_No_Hours_Sunlight_Avail_Summer,
          Avg_No_Hours_Sunlight_Avail_Winter:
            responseData.pre_DisturbanceDetails.climate
              .avg_No_Hours_Sunlight_Avail_Winter,

          Turbidity: responseData.pre_DisturbanceDetails.water.turbidity,
          Temperature: responseData.pre_DisturbanceDetails.water.temperature,
          Color: responseData.pre_DisturbanceDetails.water.color,
          Taste_Odor: responseData.pre_DisturbanceDetails.water.taste_Odor,
          WElectrical_Conductivity:
            responseData.pre_DisturbanceDetails.water.electrical_Conductivity,
          PH: responseData.pre_DisturbanceDetails.water.ph,
          Hardness: responseData.pre_DisturbanceDetails.water.hardness,
          Dissolved_Oxygen:
            responseData.pre_DisturbanceDetails.water.dissolved_Oxygen,
          Biochem_Oxygen_Demand:
            responseData.pre_DisturbanceDetails.water.biochem_Oxygen_Demand,
          Chem_Oxygen_Demand:
            responseData.pre_DisturbanceDetails.water.chem_Oxygen_Demand,

          Toxic_InorganicSubstance_Name:
            responseData.pre_DisturbanceDetails.water.toxic_Inorganic_Subs
              .toxicInorganicSName,
          Toxic_InorganicDescription:
            responseData.pre_DisturbanceDetails.water.toxic_Inorganic_Subs
              .toxicInorganicDescription,

          Toxic_OrganicSubstance_Name:
            responseData.pre_DisturbanceDetails.water.toxic_Organic_Subs
              .toxicOrganicSName,
          Toxic_OrganicDescription:
            responseData.pre_DisturbanceDetails.water.toxic_Organic_Subs
              .toxicOrganicDescription,

          RadioactiveSubstance_Name:
            responseData.pre_DisturbanceDetails.water.radioactive_Subs
              .radioactiveSName,
          RadioactiveDescription:
            responseData.pre_DisturbanceDetails.water.radioactive_Subs
              .radioactiveDescription,
          Tot_Carbon_Offset_Capacity:
            responseData.pre_DisturbanceDetails.tot_Carbon_Offset_Capacity,
          Soil_Textures: responseData.pre_DisturbanceDetails.soil.soil_Textures,
          Soil_Structure:
            responseData.pre_DisturbanceDetails.soil.soil_Structure,
          Soil_Porosity: responseData.pre_DisturbanceDetails.soil.soil_Porosity,
          Soil_pH: responseData.pre_DisturbanceDetails.soil.soil_pH,
          Nitrate: responseData.pre_DisturbanceDetails.soil.nitrate,
          Phosphate: responseData.pre_DisturbanceDetails.soil.phosphate,
          Potassium: responseData.pre_DisturbanceDetails.soil.potassium,
          SElectrical_Conductivity:
            responseData.pre_DisturbanceDetails.soil.electrical_Conductivity,
          Soil_Color: responseData.pre_DisturbanceDetails.soil.soil_Color,
          Soil_Moisture_Level:
            responseData.pre_DisturbanceDetails.soil.soil_Moisture_Level,
          Organic_Carbon:
            responseData.pre_DisturbanceDetails.soil.organic_Carbon,

          No_Families: responseData.pre_DisturbanceDetails.social.no_Families,
          Avg_Family_Size:
            responseData.pre_DisturbanceDetails.social.avg_Family_Size,
          Gender: responseData.pre_DisturbanceDetails.social.gender,
          Literacy_Rate_Overall:
            responseData.pre_DisturbanceDetails.social.literacy_Rate_Overall,
          Literacy_Rate_Female:
            responseData.pre_DisturbanceDetails.social.literacy_Rate_Female,
          Avg_Annual_Inc_Family_Farming:
            responseData.pre_DisturbanceDetails.social
              .avg_Annual_Inc_Family_Farming,
          Avg_Annual_Inc_Family_Other_Sources:
            responseData.pre_DisturbanceDetails.social
              .avg_Annual_Inc_Family_Other_Sources,
        });
        console.log("V", this.state.ListObjFlora);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  Save = (e) => {
    e.preventDefault();
    //if (this.validateForm()) {
    if (this.mounted) {
      let Pre_Dis_FloraList = [];
      this.state.ListObjFlora.forEach((element) => {
        let newItem = {
          pre_Dis_Flora_Id: element.pre_Dis_Flora_Id,
          FloraType: element.floraType,
          FloraSpecies: element.floraSpecies,
          floraNumber_Population: element.floraNumber_Population,
          floraInvasive_Specie: element.floraInvasive_Specie,
          floraOrigin: element.floraOrigin,
          floraSpec_Recom_Restoration: element.floraSpec_Recom_Restoration,
          floraProd_per_Year: element.floraProd_per_Year,
        };
        Pre_Dis_FloraList.push(newItem);
      });
      let Pre_Dis_FaunaList = [];
      this.state.ListObjFauna.forEach((element) => {
        let newFauna = {
          pre_Dis_Fauna_Id: element.pre_Dis_Fauna_Id,
          faunaType: element.faunaType,
          faunaSpecies: element.faunaSpecies,
          faunaNumber_Population: element.faunaNumber_Population,
          faunaInvasive_Specie: element.faunaInvasive_Specie,
          faunaOrigin: element.faunaOrigin,
          faunaSpec_Recom_Restoration: element.faunaSpec_Recom_Restoration,
          faunaProd_per_Year: element.faunaProd_per_Year,
        };
        Pre_Dis_FaunaList.push(newFauna);
      });
      let Pre_Dis_MicrobesList = [];
      this.state.ListObjMicrobes.forEach((element) => {
        let newMicrobes = {
          pre_Dis_Microbes_Id: element.pre_Dis_Microbes_Id,
          microbesType: element.microbesType,
          microbesSpecies: element.microbesSpecies,
          microbesNumber_Population: element.microbesNumber_Population,
          microbesInvasive_Specie: element.microbesInvasive_Specie,
          microbesOrigin: element.microbesOrigin,
          microbesSpec_Recom_Restoration:
            element.microbesSpec_Recom_Restoration,
          microbesProd_per_Year: element.microbesProd_per_Year,
        };
        Pre_Dis_MicrobesList.push(newMicrobes);
      });
      let newClimate = {
        ClimateType: this.state.ClimateType,
        Avg_Temp_Summer: this.state.Avg_Temp_Summer,
        Avg_Temp_Winter: this.state.Avg_Temp_Winter,
        Air_Quality_Index: this.state.Air_Quality_Index,
        Humidity: this.state.Humidity,
        Avg_Yearly_Rainfall: this.state.Avg_Yearly_Rainfall,
        Avg_Wind_Speed: this.state.Avg_Wind_Speed,
        Avg_No_Hours_Sunlight_Avail_Summer:
          this.state.Avg_No_Hours_Sunlight_Avail_Summer,
        Avg_No_Hours_Sunlight_Avail_Winter:
          this.state.Avg_No_Hours_Sunlight_Avail_Winter,
      };

      let newToxic_Inorganic_Subs = {
        ToxicInorganicSName: this.state.Toxic_InorganicSubstance_Name,
        ToxicInorganicDescription: this.state.Toxic_InorganicDescription,
      };
      let newToxic_Organic_Subs = {
        ToxicOrganicSName: this.state.Toxic_OrganicSubstance_Name,
        ToxicOrganicDescription: this.state.Toxic_OrganicDescription,
      };
      let newRadioactive_Subs = {
        RadioactiveSName: this.state.RadioactiveSubstance_Name,
        RadioactiveDescription: this.state.RadioactiveDescription,
      };
      let newWater = {
        Turbidity: this.state.Turbidity,
        Temperature: this.state.Temperature,
        Color: this.state.Color,
        Taste_Odor: this.state.Taste_Odor,
        Electrical_Conductivity: this.state.WElectrical_Conductivity,
        PH: this.state.PH,
        Hardness: this.state.Hardness,
        Dissolved_Oxygen: this.state.Dissolved_Oxygen,
        Biochem_Oxygen_Demand: this.state.Biochem_Oxygen_Demand,
        Chem_Oxygen_Demand: this.state.Chem_Oxygen_Demand,
        Toxic_Inorganic_Subs: newToxic_Inorganic_Subs,
        Toxic_Organic_Subs: newToxic_Organic_Subs,
        Radioactive_Subs: newRadioactive_Subs,
      };
      let newSoil = {
        Soil_Textures: this.state.Soil_Textures,
        Soil_Structure: this.state.Soil_Structure,
        Soil_Porosity: this.state.Soil_Porosity,
        Soil_pH: this.state.Soil_pH,
        Nitrate: this.state.Nitrate,
        Phosphate: this.state.Phosphate,
        Potassium: this.state.Potassium,
        Electrical_Conductivity: this.state.SElectrical_Conductivity,
        Soil_Color: this.state.Soil_Color,
        Soil_Moisture_Level: this.state.Soil_Moisture_Level,
        Organic_Carbon: this.state.Organic_Carbon,
      };

      let newSocial = {
        No_Families: this.state.No_Families,
        Avg_Family_Size: this.state.Avg_Family_Size,
        Gender: this.state.Gender,
        Literacy_Rate_Overall: this.state.Literacy_Rate_Overall,
        Literacy_Rate_Female: this.state.Literacy_Rate_Female,
        Avg_Annual_Inc_Family_Farming: this.state.Avg_Annual_Inc_Family_Farming,
        Avg_Annual_Inc_Family_Other_Sources:
          this.state.Avg_Annual_Inc_Family_Other_Sources,
      };

      let ydata = {
        // Pre_Dis_Ref_YearOfReference: (new Date(this.state.Year_of_Reference)).toUTCString(),
        Pre_Dis_Ref_YearOfReference:
          this.state.Year_of_Reference != ""
            ? new Date(this.state.Year_of_Reference).toUTCString()
            : "",
        Pre_Dis_Ref_Sources: this.state.Sources,
        Pre_Dis_Ref_Remarks: this.state.Remarks,
        Pre_Dis_Flora: Pre_Dis_FloraList,
        Pre_Dis_Fauna: Pre_Dis_FaunaList,
        Pre_Dis_Microbes: Pre_Dis_MicrobesList,
        Climate: newClimate,
        Water: newWater,
        Soil: newSoil,

        Tot_Carbon_Offset_Capacity: this.state.Tot_Carbon_Offset_Capacity,

        Social: newSocial,
      };
      console.log("ydata", ydata);

      let data = {
        ProjectId: this.state.ProjectId,
        ProjectObjectives_Id: this.state.ProjectObjectives_Id,

        Pre_DisturbanceDetails: ydata,

        Goal_Type: this.state.Goal_Type,
        Project_Goal: this.state.Project_Goal,
        Scope_Restoration: this.state.Scope_Restoration,
        Target_Ecosystem_Type: this.state.selectedTargetEcosystemType,
        Target_Ecosystem_Sub_Type: this.state.selectedEcosystemSubType,
        Scope_for_TreeTies: this.state.Scope_for_TreeTies,
      };
      let url = `${config.API_HOST_URL}/api/Ecosystem/AddProjectObjectives`;
      fetch(url, {
        method: "POST",
        headers: {
          WebsiteGuid: this.props.websiteGUID,
          LanguageGuid: this.props.languageGUID,
          Authorization: `Bearer ${this.props.token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },

        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((resp) => {
          if (resp.success === true) {
            //this.clearFields();
            ToastNotification.ShowSuccess(resp.message);
            this.Nextpage();
          } else {
            ToastNotification.ShowError(resp.message);
          }
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    }
    // }
  };
  // This function do validate the Project objective form
  validateForm = (e) => {
    let fields;
    fields = {
      organic_Carbon: this.state.Organic_Carbon,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["organic_Carbon"]) {
      formIsValid = false;
      errors["organic_Carbon"] = "Enter organic Carbon";
      errors["organic_Carbon"] = ToastNotification.ShowError(
        "Enter organic Carbon"
      );
    }

    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  };
  // This function do validate Microbes
  validateMicrobes = (e) => {
    let fields;
    fields = {
      microbesType: this.state.microbesType,
      // microbesSpecies:this.state.microbesSpecies,
    };
    let errors = {};
    let formIsValid = true;

    if (!fields["microbesType"]) {
      formIsValid = false;
      errors["microbesType"] = "Enter  MicrobesType";
      ToastNotification.ShowError("Enter Microbes Type");
    }

    //errors["microbesType"] = ToastNotification.ShowError("Enter  MicrobesType");
    //   if (!fields["microbesSpecies"]) {
    //     formIsValid = false;
    //     errors["microbesSpecies"] = "Enter MicrobesSpecies";
    // }

    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  };
  // This function do validate Fauna
  validateFauna = (e) => {
    let fields;
    fields = {
      faunaType: this.state.faunaType,
      //  faunaSpecies:this.state.faunaSpecies,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["faunaType"]) {
      formIsValid = false;

      ToastNotification.ShowError("Enter Fauna Type");
    }
    //   if (!fields["faunaSpecies"]) {
    //     formIsValid = false;
    //     errors["faunaSpecies"] = "Enter FaunaSpecies";

    // }

    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  };
  // This function do validate Flora
  validateFlora = (e) => {
    let fields;
    fields = {
      floraType: this.state.floraType,
      floraSpecies: this.state.floraSpecies,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["floraType"]) {
      formIsValid = false;
      // errors["floraType"] = "Enter Flora Type";
      // errors["floraType"] = ToastNotification.ShowError("Enter  Flora Type");
      ToastNotification.ShowError("Enter Flora Type");
    }

    if (!fields["floraSpecies"]) {
      formIsValid = false;
      ToastNotification.ShowError("Enter Flora Species");
    }
    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  };

  // clearFields = (e) => {
  //   if (this.mounted) {
  //       this.setState({
  //         Goal_Type : "",
  //           Project_Goal : "",
  //           ProjectId : "",
  //           Scope_Restoration : "",
  //           Scope_for_TreeTies : "",

  //           Target_Ecosystem_Type : "",

  //           microbesType:"",
  //           microbesSpecies: " ",
  //           microbesNumber_Population:" ",
  //           microbesInvasive_Specie:" ",
  //           microbesOrigin:" ",
  //           microbesSpec_Recom_Restoration:" ",
  //           microbesProd_per_Year:" ",
  //           ListObjMicrobes: [
  //              {
  //               microbesType:"",
  //               microbesSpecies: "",
  //               microbesNumber_Population:" ",
  //               microbesInvasive_Specie:" ",
  //               microbesOrigin:" ",
  //               microbesSpec_Recom_Restoration:" ",
  //               microbesProd_per_Year:" ",
  //           }
  //         ],
  //           faunaType:"",
  //           faunaSpecies: " ",
  //           faunaNumber_Population:" ",
  //           faunaInvasive_Specie:" ",
  //           faunaOrigin:" ",
  //           faunaSpec_Recom_Restoration:" ",
  //           faunaProd_per_Year:" ",
  //           ListObjFauna: [
  //              {

  //              faunaType:"",
  //              faunaSpecies: " ",
  //               faunaNumber_Population:" ",
  //               faunaInvasive_Specie:" ",
  //               faunaOrigin:" ",
  //               faunaSpec_Recom_Restoration:" ",
  //               faunaProd_per_Year:" ",
  //           }
  //         ],

  //           floraType:"",
  //           floraSpecies:"",
  //           floraNumber_Population:" ",
  //           floraInvasive_Specie:" ",
  //           floraOrigin:" ",
  //           floraSpec_Recom_Restoration:" ",
  //           floraProd_per_Year:" ",
  //           ListObjFlora: [
  //              {

  //               floraType:"",
  //               floraSpecies:"",

  //               floraNumber_Population:" ",
  //               floraInvasive_Specie:" ",
  //               floraOrigin:" ",
  //               floraSpec_Recom_Restoration:" ",
  //               floraProd_per_Year:" ",
  //           }
  //         ],

  //         ClimateType : "",
  //         Avg_Temp_Summer : "",
  //         Avg_Temp_Winter : "",
  //         Air_Quality_Index : "",
  //         Humidity : "",
  //         Avg_Yearly_Rainfall : "",
  //         Avg_Wind_Speed : "",
  //         Avg_No_Hours_Sunlight_Avail_Summer : "",
  //         Avg_No_Hours_Sunlight_Avail_Winter: "",

  //         Turbidity: "",
  //         Temperature : "",
  //         Color: "",
  //         Taste_Odor: "",
  //         Electrical_Conductivity: "",
  //         PH: "",
  //         Hardness: "",
  //         Dissolved_Oxygen: "",
  //         Biochem_Oxygen_Demand: "",
  //         Chem_Oxygen_Demand : "",

  //         Toxic_InorganicSubstance_Name: "",
  //         Toxic_InorganicDescription: "",

  //         Toxic_OrganicSubstance_Name: "",
  //         Toxic_OrganicDescription: "",

  //         RadioactiveSubstance_Name: "",
  //         RadioactiveDescription: "",

  //         Soil_Textures: "",
  //         Soil_Structure : "",
  //         Soil_Porosity : "",
  //         Soil_pH : "",
  //         Nitrate : "",
  //         Phosphate : "",
  //         Potassium : "",
  //         Electrical_Conductivity : "",
  //         Soil_Color : "",
  //         Soil_Moisture_Level: "",
  //         Organic_Carbon: "",

  //         Tot_Carbon_Offset_Capacity : "",

  //         No_Families : "",
  //         Avg_Family_Size :"",
  //         Gender: "",
  //         Literacy_Rate_Overall : "",
  //         Literacy_Rate_Female: "",
  //         Avg_Annual_Inc_Family_Farming : "",
  //         Avg_Annual_Inc_Family_Other_Sources : ""

  //       });
  //   }
  // }

  Nextpage = () => {
    navigate("/TreeTiesAddProjectForms1Ris/:" + this.state.ProjectId, {
      state: { activeMenu: 8 },
    });
  };

  Back = () => {
    navigate("/TreeTiesAddProjectForms1Sit/:" + this.state.ProjectId, {
      state: { activeMenu: 6, activesubSite: true },
    });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return (
      <>
        <div className="ecosysobjtab_cont">
          <div className="main-content-area3">
            <div className="middle-content-area3">
              <div className="container-18 ecoprosumry_cont">
                <ProjectSummary />
              </div>
              <div className="ecosys_middlecont">
                <LeftTabMenuContainer
                  activeMenu={this.props.location.state?.activeMenu}
                />
                <div className="ecosys_rightcont">
                  <div className="hero-title7 pb-4">
                    <b className="project-summary7">Objectives</b>
                  </div>
                  <div className="input-field-text25">
                    <div className="content-title-text50">
                      <div className="hero-title6">
                        <div className="project-summary6">Project Goal</div>
                      </div>
                      <input
                        className="text-input-field-36"
                        required
                        pattern="[a-zA-Z0-9]+"
                        type="text"
                        name="Project_Goal"
                        value={this.state.Project_Goal}
                        onChange={(event) => this.handleAlphaInput(event)}
                      />
                    </div>
                    <div className="content-title-text51">
                      <div className="hero-title6">
                        <div className="project-summary6">Goal Type</div>
                      </div>
                      <select
                        className="dropdown-18"
                        value={this.state.Goal_Type}
                        onChange={this.handleGoalType}
                      >
                        <option value="text">Select</option>
                        <option value="Social">Social</option>
                        <option value="Environmental">Environmental</option>
                      </select>
                    </div>
                    <div className="content-title-text51">
                      <div className="hero-title6">
                        <div className="project-summary6">
                          Scope of Restoration
                        </div>
                      </div>
                      <select
                        className="dropdown-18"
                        value={this.state.Scope_Restoration}
                        onChange={this.handleScopeRestoration}
                      >
                        <option value="text">Select</option>
                        <option value="Original Ecosystem Restoration">
                          Original Ecosystem Restoration
                        </option>
                        <option value="Creation of Different Ecosystem Type/Sub-type">
                          Creation of Different Ecosystem Type/Sub-type
                        </option>
                        <option value="Not Applicable">Not Applicable</option>
                      </select>
                    </div>
                  </div>
                  <div className="input-field-text25">
                    <div className="content-title-text51">
                      <div className="hero-title6">
                        <div className="project-summary6">
                          Target Ecosystem Type
                        </div>
                      </div>

                      <select
                        className="dropdown-18"
                        value={this.state.selectedTargetEcosystemType}
                        onChange={this.handleTargetEcosystemType}
                        ref="selectedGroup"
                        name="tetype"
                      >
                        <option value="" selected="selected">
                          Select{" "}
                        </option>
                        {this.state.commonMastersList
                          .filter((x) => x.type === "ProjectType")
                          .map((item) => (
                            <option value={item.mainGuId}>
                              {item.description}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="content-title-text51">
                      <div className="hero-title6">
                        <div className="project-summary6">
                          Target Ecosystem Sub Type
                        </div>
                      </div>

                      <select
                        className="dropdown-18"
                        value={this.state.selectedEcosystemSubType}
                        onChange={this.handleTargetEcosystemSubType}
                        ref="selectedGroup"
                        name="ddlsubtype"
                      >
                        <option value="" selected="selected">
                          Select
                        </option>

                        {this.state.commonMastersList
                          .filter(
                            (x) =>
                              x.type === "ProjectSubType" &&
                              x.mainGuId ===
                                this.state.selectedTargetEcosystemType
                          )
                          .map((item) => (
                            <option value={item.subGuId}>
                              {item.description}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="content-title-text51">
                      <div className="hero-title6">
                        <div className="project-summary6">
                          Scope for TreeTies
                        </div>
                      </div>
                      <select
                        className="dropdown-18"
                        value={this.state.Scope_for_TreeTies}
                        onChange={this.handleScopeforTreeTies}
                      >
                        <option value="text">Select</option>
                        <option value="PaaS">PaaS</option>
                        <option value="Project Management">
                          Project Management
                        </option>
                        <option value="Fund Management">Fund Management</option>
                        <option value="Carbon Credits Consultancy">
                          Carbon Credits Consultancy
                        </option>
                        <option value="Ecosystem Management Services">
                          Ecosystem Management Services
                        </option>
                        <option value="Advocacy and Awareness">
                          Advocacy and Awareness
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="hero-title7 pb-4">
                    <b className="project-summary7">Reference</b>
                  </div>
                  <div className="input-field-text10">
                    <div className="content-title-text65">
                      <div className="hero-title8">
                        <div className="project-summary8">
                          Year of Reference
                        </div>
                      </div>

                      <input
                        type="date"
                        name="date"
                        value={this.state.Year_of_Reference}
                        onChange={(event) => this.handleDate(event)}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className="content-title-text69">
                      <div className="hero-title8">
                        <div className="project-summary8">Sources</div>
                      </div>
                      <input
                        className="text-input-field-37"
                        required
                        pattern="[a-zA-Z0-9]+"
                        type="text"
                        name="Sources"
                        placeholder="Enter Sources"
                        value={this.state.Sources}
                        onChange={(event) => this.handleAlphaInput(event)}
                      />
                    </div>
                    <div className="content-title-text69">
                      <div className="hero-title8">
                        <div className="project-summary8">Remarks</div>
                      </div>
                      <input
                        className="text-input-field-37"
                        required
                        pattern="[a-zA-Z0-9]+"
                        type="text"
                        name="Remarks"
                        placeholder="Enter Remarks"
                        value={this.state.Remarks}
                        onChange={(event) => this.handleAlphaInput(event)}
                      />
                    </div>
                  </div>
                  {/* <div className="table-content-1"> */}
                  <div className="text-container pt-4">
                    <div className="flora-details">Flora Details</div>
                    {/* <div className="cta-add-more1">
                            <div className="flora-details">plus</div>
                            <div className="add-more1">Add more</div>
                          </div> */}
                  </div>
                  <table className="newTable">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Spicies</th>
                        <th>No. of Population</th>
                        <th>Invasive?</th>
                        <th>Origin</th>
                        <th>Recommended for Restoration</th>
                        <th>Produce/Year</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.floraType}
                              onChange={this.handleType}
                              name="floratype"
                            >
                              <option value="">Select </option>
                              {this.state.commonMastersList
                                .filter((x) => x.type === "Flora")
                                .map((item) => (
                                  <option value={item.mainGuId}>
                                    {item.description}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="mandatory has-error">
                            {this.state.errors.floraType}
                          </div>
                        </td>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.floraSpecies}
                              onChange={this.handleSpicies}
                              name="ddFloraSpicies"
                            >
                              <option value="">Select </option>

                              {this.state.commonMastersList
                                .filter(
                                  (x) =>
                                    x.type === "FloraSubType" &&
                                    x.mainGuId === this.state.floraType
                                )
                                .map((item) => (
                                  <option value={item.subGuId}>
                                    {item.description}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="mandatory has-error">
                            {this.state.errors.floraSpecies}
                          </div>
                        </td>
                        <td>
                          <div>
                            <div className="content-title-text69">
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                maxLength={50}
                                placeholder="Enter No. of Population"
                                name="floraNumber_Population"
                                value={this.state.floraNumber_Population}
                                onChange={(event) => this.handleInput(event)}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.floraInvasive_Specie}
                              onChange={this.handleInvasiveSpecie}
                            >
                              <option value="">Select </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.floraOrigin}
                              onChange={this.handleOrigin}
                            >
                              <option value="">Select </option>
                              <option value="Native">Native</option>
                              <option value="Foreign">Foreign</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.floraSpec_Recom_Restoration}
                              onChange={this.handleSpecRecomRestoration}
                            >
                              <option value="">Select </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div className="content-title-text69">
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                maxLength={50}
                                placeholder="Enter Produce per Year"
                                name="floraProd_per_Year"
                                onChange={(event) => this.handleInput(event)}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="dropdown-113">
                            <button onClick={this.handleFlora}> Add</button>
                          </div>
                        </td>
                      </tr>
                      {this.state.ListObjFlora &&
                        this.state.ListObjFlora.map((eachItem, i) => (
                          <tr key={i}>
                            <td>
                              {" "}
                              {this.state.commonMastersList
                                .filter(
                                  (x) =>
                                    x.mainGuId === eachItem.floraType &&
                                    x.type === "Flora"
                                )
                                .map((item) => item.description)}
                            </td>

                            <td>
                              {" "}
                              {this.state.commonMastersList
                                .filter(
                                  (x) =>
                                    x.subGuId === eachItem.floraSpecies &&
                                    x.type === "FloraSubType"
                                )
                                .map((item) => item.description)}
                            </td>

                            <td>
                              {" "}
                              <div>{eachItem.floraNumber_Population} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div> {eachItem.floraInvasive_Specie} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>{eachItem.floraOrigin} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>
                                {" "}
                                {eachItem.floraSpec_Recom_Restoration}{" "}
                              </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div> {eachItem.floraProd_per_Year} </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div>
                                <a>
                                  <i
                                    className="fas fa-edit"
                                    onClick={() => this.editFlora(i)}
                                  ></i>
                                </a>
                                <a>
                                  <i
                                    className="fas fa-trash"
                                    onClick={() => this.deleteFlora(i)}
                                  ></i>
                                </a>
                              </div>{" "}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {/* <div className="table-content-1"> */}
                  <div className="text-container pt-4">
                    <div className="flora-details"> Fauna Details</div>
                    {/* <div className="cta-add-more1">
                            <div className="flora-details">plus</div>
                            <div className="add-more1">Add more</div>
                          </div> */}
                  </div>
                  <table className="newTable">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Spicies</th>
                        <th>No. of Population</th>
                        <th>Invasive?</th>
                        <th>Origin</th>
                        <th>Recommended for Restoration</th>
                        <th>Produce/Year</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.faunaType}
                              onChange={this.handleTypeFauna}
                              name="faunatype"
                            >
                              <option value="">Select </option>
                              {this.state.commonMastersList
                                .filter((x) => x.type === "Fauna")
                                .map((item) => (
                                  <option value={item.mainGuId}>
                                    {item.description}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="mandatory has-error">
                            {this.state.errors.faunaType}
                          </div>
                        </td>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.faunaSpecies}
                              onChange={this.handleSpiciesFauna}
                              name="ddFaunaSpicies"
                            >
                              <option value="">Select</option>

                              {this.state.commonMastersList
                                .filter(
                                  (x) =>
                                    x.type === "FaunaSubType" &&
                                    x.mainGuId === this.state.faunaType
                                )
                                .map((item) => (
                                  <option value={item.subGuId}>
                                    {item.description}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {/* <div className="mandatory has-error">{this.state.errors.faunaSpecies}</div> */}
                        </td>
                        <td>
                          <div>
                            <div className="content-title-text69">
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                maxLength={50}
                                name="faunaNumber_Population"
                                placeholder="Enter FaunaNumber Population"
                                value={this.state.faunaNumber_Population}
                                onChange={(event) => this.handleInput(event)}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.faunaInvasive_Specie}
                              onChange={this.handleInvasiveSpecieFauna}
                            >
                              <option value="">Select </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.faunaOrigin}
                              onChange={this.handleOriginFauna}
                            >
                              <option value="">Select </option>
                              <option value="Native">Native</option>
                              <option value="Foreign">Foreign</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.faunaSpec_Recom_Restoration}
                              onChange={this.handleSpecRecomRestorationFauna}
                            >
                              <option value="">Select </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div className="content-title-text69">
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                maxLength={50}
                                placeholder="Enter Produce per Year"
                                name="faunaProd_per_Year"
                                value={this.state.faunaProd_per_Year}
                                onChange={(event) => this.handleInput(event)}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="dropdown-113">
                            <button onClick={this.handleFauna}> Add</button>
                          </div>
                        </td>
                      </tr>
                      {this.state.ListObjFauna.map((eachItem, i) => (
                        <tr key={i}>
                          <td>
                            {" "}
                            {this.state.commonMastersList
                              .filter(
                                (x) =>
                                  x.mainGuId === eachItem.faunaType &&
                                  x.type === "Fauna"
                              )
                              .map((item) => item.description)}
                          </td>

                          <td>
                            {" "}
                            {this.state.commonMastersList
                              .filter(
                                (x) =>
                                  x.subGuId === eachItem.faunaSpecies &&
                                  x.type === "FaunaSubType"
                              )
                              .map((item) => item.description)}
                          </td>

                          <td>
                            {" "}
                            <div>{eachItem.faunaNumber_Population} </div>{" "}
                          </td>
                          <td>
                            {" "}
                            <div> {eachItem.faunaInvasive_Specie} </div>{" "}
                          </td>
                          <td>
                            {" "}
                            <div>{eachItem.faunaOrigin} </div>{" "}
                          </td>
                          <td>
                            {" "}
                            <div>
                              {" "}
                              {eachItem.faunaSpec_Recom_Restoration}{" "}
                            </div>{" "}
                          </td>
                          <td>
                            {" "}
                            <div> {eachItem.faunaProd_per_Year} </div>{" "}
                          </td>
                          <td>
                            {" "}
                            <div>
                              <a>
                                <i
                                  className="fas fa-edit"
                                  onClick={() => this.editFauna(i)}
                                ></i>
                              </a>
                              <a>
                                <i
                                  className="fas fa-trash"
                                  onClick={() => this.deleteFauna(i)}
                                ></i>
                              </a>
                            </div>{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* </div> */}
                  {/* <div className="table-content-1"> */}
                  <div className="text-container pt-4">
                    <div className="flora-details">Microbes Details</div>
                    {/* <div className="cta-add-more1">
                            <div className="flora-details">plus</div>
                            <div className="add-more1">Add more</div>
                          </div> */}
                  </div>
                  <table className="newTable">
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Spicies</th>
                        <th>No. of Population</th>
                        <th>Invasive?</th>
                        <th>Origin</th>
                        <th>Recommended for Restoration</th>
                        <th>Produce/Year</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.microbesType}
                              onChange={this.handleTypeMicrobes}
                              name="ddmicrobestype"
                            >
                              <option value="">Select </option>
                              {this.state.commonMastersList
                                .filter((x) => x.type === "Microbes")
                                .map((item) => (
                                  <option value={item.mainGuId}>
                                    {item.description}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="mandatory has-error">
                            {this.state.errors.microbesType}
                          </div>
                        </td>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.microbesSpecies}
                              onChange={this.handleSpiciesMicrobes}
                              name="ddMicrobesSpecies"
                            >
                              <option value="">Select </option>

                              {this.state.commonMastersList
                                .filter(
                                  (x) =>
                                    x.type === "MicrobesSubType" &&
                                    x.mainGuId === this.state.microbesType
                                )
                                .map((item) => (
                                  <option value={item.subGuId}>
                                    {item.description}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {/* <div className="mandatory has-error" >{this.state.errors.microbesSpecies}</div> */}
                        </td>
                        <td>
                          <div>
                            <div className="content-title-text69">
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                maxLength={50}
                                name="microbesNumber_Population"
                                placeholder="Enter MicrobesNumber Population"
                                value={this.state.microbesNumber_Population}
                                onChange={(event) => this.handleInput(event)}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.microbesInvasive_Specie}
                              onChange={this.handleInvasiveSpecieMicrobes}
                            >
                              <option value="">Select </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.microbesOrigin}
                              onChange={this.handleOriginMicrobes}
                            >
                              <option value="">Select </option>
                              <option value="Native">Native</option>
                              <option value="Foreign">Foreign</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div>
                            <select
                              className="dropdown-113"
                              variant="flushed"
                              placeholder="Select"
                              backgroundColor="#e7f0ff"
                              borderColor="#d9d9d9"
                              focusBorderColor="#d9d9d9"
                              value={this.state.microbesSpec_Recom_Restoration}
                              onChange={this.handleSpecRecomRestorationMicrobes}
                            >
                              <option value="">Select </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div className="content-title-text69">
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                maxLength={50}
                                placeholder="Enter Produce per Year"
                                name="microbesProd_per_Year"
                                value={this.state.microbesProd_per_Year}
                                onChange={(event) => this.handleInput(event)}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="dropdown-113">
                            <button onClick={this.handleMicrobes}> Add</button>
                          </div>
                        </td>
                      </tr>
                      {this.state.ListObjMicrobes.map((eachItem, i) => (
                        <tr key={i}>
                          <td>
                            {" "}
                            {this.state.commonMastersList
                              .filter(
                                (x) =>
                                  x.mainGuId === eachItem.microbesType &&
                                  x.type === "Microbes"
                              )
                              .map((item) => item.description)}
                          </td>

                          <td>
                            {" "}
                            {this.state.commonMastersList
                              .filter(
                                (x) =>
                                  x.subGuId === eachItem.microbesSpecies &&
                                  x.type === "MicrobesSubType"
                              )
                              .map((item) => item.description)}
                          </td>

                          <td>
                            {" "}
                            <div>
                              {eachItem.microbesNumber_Population}{" "}
                            </div>{" "}
                          </td>
                          <td>
                            {" "}
                            <div> {eachItem.microbesInvasive_Specie} </div>{" "}
                          </td>
                          <td>
                            {" "}
                            <div>{eachItem.microbesOrigin} </div>{" "}
                          </td>
                          <td>
                            {" "}
                            <div>
                              {" "}
                              {eachItem.microbesSpec_Recom_Restoration}{" "}
                            </div>{" "}
                          </td>
                          <td>
                            {" "}
                            <div> {eachItem.microbesProd_per_Year} </div>{" "}
                          </td>
                          <td>
                            {" "}
                            <div>
                              <a>
                                <i
                                  className="fas fa-edit"
                                  onClick={() => this.editMicrobes(i)}
                                ></i>
                              </a>
                              <a>
                                <i
                                  className="fas fa-trash"
                                  onClick={() => this.deleteMicrobes(i)}
                                ></i>
                              </a>
                            </div>{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* </div> */}
                  <div className="land-parcels-container columnlapacont pt-4">
                    <div className="land-parecel-content-area">
                      <div className="left-content">
                        <div className="hero-title8">
                          <b className="flora-details">Climate</b>
                        </div>
                        <div className="column-content2">
                          <div className="column-112">
                            <div className="content-title-text71">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Climate Type
                                </div>
                              </div>
                              <select
                                className="dropdown-113"
                                value={this.state.ClimateType}
                                onChange={this.handleClimateType}
                              >
                                <option value="text">Select</option>
                                <option value="Equable">Equable</option>
                                <option value="Extreme">Extreme</option>
                                <option value="Neither Equable Nor Extreme">
                                  Neither Equable Nor Extreme
                                </option>
                              </select>
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Air Quality Index
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                type="number"
                                min="0"
                                max="1000"
                                name="Air_Quality_Index"
                                value={this.state.Air_Quality_Index}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 1000)
                                  ) {
                                    value = Math.min(Math.max(value, 0), 1000);
                                  }
                                  if (value.length > 4) {
                                    value = value.slice(0, 4);
                                  }
                                  this.setState({
                                    Air_Quality_Index: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Avg. Wind Speed (km/hr)
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="1000"
                                name="Avg_Wind_Speed"
                                value={this.state.Avg_Wind_Speed}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 1000)
                                  ) {
                                    value = Math.min(Math.max(value, 0), 1000);
                                  }
                                  if (value.length > 4) {
                                    value = value.slice(0, 4);
                                  }
                                  this.setState({
                                    Avg_Wind_Speed: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                          <div className="column-112">
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Avg. Temp. Range in Summer
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                type="number"
                                min="0"
                                max="100"
                                name="Avg_Temp_Summer"
                                value={this.state.Avg_Temp_Summer}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (!/^(\d*\.?\d*)$/.test(value)) {
                                    return;
                                  }
                                  if (value.includes(".")) {
                                    const [integerPart, decimalPart] =
                                      value.split(".");
                                    if (decimalPart.length > 2) {
                                      value =
                                        integerPart +
                                        "." +
                                        decimalPart.slice(0, 2).toString();
                                    }
                                  }
                                  if (
                                    value !== "" &&
                                    (parseFloat(value) < 0 ||
                                      parseFloat(value) > 100)
                                  ) {
                                    value = Math.min(
                                      Math.max(parseFloat(value), 0),
                                      100
                                    );
                                  }
                                  this.setState({ Avg_Temp_Summer: value });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">Humidity</div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                type="number"
                                min="0"
                                max="100"
                                name="Humidity"
                                value={this.state.Humidity}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 100)
                                  ) {
                                    value = Math.min(Math.max(value, 0), 100);
                                  }
                                  if (value.length > 3) {
                                    value = value.slice(0, 3);
                                  }
                                  this.setState({ Humidity: value });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text71">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Avg. No of Hrs. of Sunlight Availability in
                                  Summer
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="12"
                                name="Avg_No_Hours_Sunlight_Avail_Summer"
                                placeholder="Enter  Avg. No of Hrs. of Sunlight Availability in Summer"
                                value={
                                  this.state.Avg_No_Hours_Sunlight_Avail_Summer
                                }
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 12)
                                  ) {
                                    value = Math.min(Math.max(value, 0), 12);
                                  }
                                  if (value.length > 2) {
                                    value = value.slice(0, 2);
                                  }
                                  this.setState({
                                    Avg_No_Hours_Sunlight_Avail_Summer: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                          <div className="column-112">
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Avg. Temp. Range in Winter
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                type="number"
                                min="0"
                                max="100"
                                name="Avg_Temp_Winter"
                                value={this.state.Avg_Temp_Winter}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (!/^(\d*\.?\d*)$/.test(value)) {
                                    return;
                                  }
                                  if (value.includes(".")) {
                                    const [integerPart, decimalPart] =
                                      value.split(".");
                                    if (decimalPart.length > 2) {
                                      value =
                                        integerPart +
                                        "." +
                                        decimalPart.slice(0, 2);
                                    }
                                  }
                                  if (
                                    value !== "" &&
                                    (parseFloat(value) < 0 ||
                                      parseFloat(value) > 100)
                                  ) {
                                    value = Math.min(
                                      Math.max(parseFloat(value), 0),
                                      100
                                    ).toString();
                                  }
                                  this.setState({
                                    Avg_Temp_Winter: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Avg. Yearly Rainfall (mm)
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="100000"
                                name="Avg_Yearly_Rainfall"
                                value={this.state.Avg_Yearly_Rainfall}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 100000)
                                  ) {
                                    value = Math.min(
                                      Math.max(value, 0),
                                      100000
                                    );
                                  }
                                  if (value.length > 6) {
                                    value = value.slice(0, 6);
                                  }
                                  this.setState({
                                    Avg_Yearly_Rainfall: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text71">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Avg. No of Hrs. of Sunlight Availability in
                                  Winter
                                </div>
                              </div>
                              <div className="content-title-text69">
                                <input
                                  className="text-input-field-37"
                                  type="number"
                                  min="0"
                                  name="Avg_No_Hours_Sunlight_Avail_Winter"
                                  placeholder="Enter Avg. No of Hrs. of Sunlight Availability in Winter"
                                  value={
                                    this.state
                                      .Avg_No_Hours_Sunlight_Avail_Winter
                                  }
                                  onInput={(e) => {
                                    let value = e.target.value;
                                    if (
                                      value !== "" &&
                                      (value < 0 || value > 12)
                                    ) {
                                      value = Math.min(Math.max(value, 0), 12);
                                    }
                                    if (value.length > 2) {
                                      value = value.slice(0, 2);
                                    }
                                    this.setState({
                                      Avg_No_Hours_Sunlight_Avail_Winter: value,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                      e.preventDefault();
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="land-parcels-container columnlapacont pt-4">
                    <div className="land-parecel-content-area">
                      <div className="left-content">
                        <div className="hero-title8">
                          <b className="flora-details">Water</b>
                        </div>
                        <div className="column-content2">
                          <div className="column-112">
                            <div className="content-title-text71">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Turbidity
                                </div>
                              </div>
                              <select
                                className="dropdown-113"
                                value={this.state.Turbidity}
                                onChange={this.handleTurbidity}
                              >
                                <option value="text">Select</option>
                                <option value="High">High</option>
                                <option value="Medium">Medium</option>
                                <option value="Low">Low</option>
                              </select>
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Temperature (Degree Celsius)
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="100"
                                name="Temperature"
                                value={this.state.Temperature}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (!/^(\d*\.?\d*)$/.test(value)) {
                                    return;
                                  }
                                  if (value.includes(".")) {
                                    const [integerPart, decimalPart] =
                                      value.split(".");
                                    if (decimalPart.length > 2) {
                                      value =
                                        integerPart +
                                        "." +
                                        decimalPart.slice(0, 2);
                                    }
                                  }
                                  if (
                                    value !== "" &&
                                    (parseFloat(value) < 0 ||
                                      parseFloat(value) > 100)
                                  ) {
                                    value = Math.min(
                                      Math.max(parseFloat(value), 0),
                                      100
                                    );
                                    value = parseFloat(value).toFixed(2);
                                  }
                                  this.setState({ Temperature: value });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">Color</div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                name="Color"
                                value={this.state.Color}
                                onChange={(event) =>
                                  this.handleAlphaInput(event)
                                }
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Chemical Oxygen Demand (COD) (in mgL)
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="1000"
                                name="Chem_Oxygen_Demand"
                                value={this.state.Chem_Oxygen_Demand}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 1000)
                                  ) {
                                    value = Math.min(Math.max(value, 0), 1000);
                                  }
                                  if (value.length > 4) {
                                    value = value.slice(0, 4);
                                  }
                                  this.setState({
                                    Chem_Oxygen_Demand: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                          <div className="column-112">
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Taste and Odor
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                name="Taste_Odor"
                                value={this.state.Taste_Odor}
                                onChange={(event) =>
                                  this.handleAlphaInput(event)
                                }
                              />
                            </div>

                            <div className="content-title-text71">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Electrical Conductivity
                                </div>
                              </div>
                              <select
                                className="dropdown-117"
                                value={this.state.WElectrical_Conductivity}
                                onChange={this.handleWElectricalConductivity}
                              >
                                <option value="text">Select</option>
                                <option value="High">High</option>
                                <option value="Medium">Medium</option>
                                <option value="Low">Low</option>
                              </select>
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">pH</div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                name="PH"
                                value={this.state.PH}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (!/^(\d*\.?\d*)$/.test(value)) {
                                    return;
                                  }
                                  if (value.includes(".")) {
                                    const [integerPart, decimalPart] =
                                      value.split(".");
                                    if (decimalPart.length > 2) {
                                      value =
                                        integerPart +
                                        "." +
                                        decimalPart.slice(0, 2);
                                    }
                                  }
                                  if (
                                    value !== "" &&
                                    (parseFloat(value) < 0 ||
                                      parseFloat(value) > 14)
                                  ) {
                                    value = Math.min(
                                      Math.max(parseFloat(value), 0),
                                      14
                                    );
                                    value = parseFloat(value).toFixed(2);
                                  }
                                  this.setState({
                                    PH: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                          <div className="column-112">
                            <div className="content-title-text71">
                              <div className="hero-title8">
                                <div className="project-summary8">Hardness</div>
                              </div>
                              <select
                                className="dropdown-117"
                                value={this.state.Hardness}
                                onChange={this.handleHardness}
                              >
                                <option value="text">Select</option>
                                <option value="High">High</option>
                                <option value="Medium">Medium</option>
                                <option value="Low">Low</option>
                              </select>
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Dissolved Oxygen (DO) (in mgL)
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="100"
                                name="Dissolved_Oxygen"
                                value={this.state.Dissolved_Oxygen}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (!/^(\d*\.?\d*)$/.test(value)) {
                                    return;
                                  }
                                  if (value.includes(".")) {
                                    const [integerPart, decimalPart] =
                                      value.split(".");
                                    if (decimalPart.length > 2) {
                                      value =
                                        integerPart +
                                        "." +
                                        decimalPart.slice(0, 2);
                                    }
                                  }
                                  if (
                                    value !== "" &&
                                    (parseFloat(value) < 0 ||
                                      parseFloat(value) > 100)
                                  ) {
                                    value = Math.min(
                                      Math.max(parseFloat(value), 0),
                                      100
                                    );
                                    value = parseFloat(value).toFixed();
                                  }
                                  this.setState({ Dissolved_Oxygen: value });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Biochemical Oxygen Demand (BOD) (in mgL)
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="1000"
                                name="Biochem_Oxygen_Demand"
                                value={this.state.Biochem_Oxygen_Demand}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 1000)
                                  ) {
                                    value = Math.min(Math.max(value, 0), 1000);
                                  }
                                  if (value.length > 4) {
                                    value = value.slice(0, 4);
                                  }
                                  this.setState({
                                    Biochem_Oxygen_Demand: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>

                            {/* <div className="content-title-text71">
                                  <div className="hero-title8">
                                    <div className="project-summary8">
                                      Avg. No of Hrs. of Sunlight Availability in Winter
                                    </div>
                                  </div>
                                  <select className="dropdown-117">
                                    <option value="text">Select</option>
                                    <option value="Lorem ipsum">Lorem ipsum</option>
                                    <option value="Lorem ipsum">Lorem ipsum</option>
                                    <option value="Lorem ipsum">Lorem ipsum</option>
                                  </select>
                                </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="land-parcels-container columnlapacont pt-4">
                    <div className="land-parecel-content-area">
                      <div className="left-content">
                        <div className="hero-title8">
                          <b className="flora-details">
                            Water Toxic Inorganic Substances
                          </b>
                        </div>
                        <div className="column-content2">
                          <div className="column-112">
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Substance Name
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                name="Toxic_InorganicSubstance_Name"
                                value={this.state.Toxic_InorganicSubstance_Name}
                                onChange={(event) =>
                                  this.handleAlphaInput(event)
                                }
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Description
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                name="Toxic_InorganicDescription"
                                value={this.state.Toxic_InorganicDescription}
                                onChange={(event) =>
                                  this.handleAlphaInput(event)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="land-parcels-container columnlapacont pt-4">
                    <div className="land-parecel-content-area">
                      <div className="left-content">
                        <div className="hero-title8">
                          <b className="flora-details">
                            Water Toxic Organic Substances
                          </b>
                        </div>
                        <div className="column-content2">
                          <div className="column-112">
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Substance Name
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                name="Toxic_OrganicSubstance_Name"
                                value={this.state.Toxic_OrganicSubstance_Name}
                                onChange={(event) =>
                                  this.handleAlphaInput(event)
                                }
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Description
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                name="Toxic_OrganicDescription"
                                value={this.state.Toxic_OrganicDescription}
                                onChange={(event) =>
                                  this.handleAlphaInput(event)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="land-parcels-container columnlapacont pt-4">
                    <div className="land-parecel-content-area">
                      <div className="left-content">
                        <div className="hero-title8">
                          <b className="flora-details">
                            Water Radioactive Substances
                          </b>
                        </div>
                        <div className="column-content2">
                          <div className="column-112">
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Substance Name
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                name="RadioactiveSubstance_Name"
                                value={this.state.RadioactiveSubstance_Name}
                                onChange={(event) =>
                                  this.handleAlphaInput(event)
                                }
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Description
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                name="RadioactiveDescription"
                                value={this.state.RadioactiveDescription}
                                onChange={(event) =>
                                  this.handleAlphaInput(event)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="land-parcels-container columnlapacont pt-4">
                    <div className="land-parecel-content-area">
                      <div className="left-content">
                        <div className="hero-title8">
                          <b className="flora-details">Soil</b>
                        </div>
                        <div className="column-content2">
                          <div className="column-112">
                            <div className="content-title-text71">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Soil Textures
                                </div>
                              </div>
                              <select
                                className="dropdown-113"
                                value={this.state.Soil_Textures}
                                onChange={this.handleSoilTextures}
                              >
                                <option value="text">Select</option>
                                <option value="Sand">Sand</option>
                                <option value="Silt">Silt</option>
                                <option value="Clay">Clay</option>
                              </select>
                            </div>
                            <div className="content-title-text71">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Soil Structure
                                </div>
                              </div>
                              <select
                                className="dropdown-113"
                                value={this.state.Soil_Structure}
                                onChange={this.handleSoilStructure}
                              >
                                <option value="text">Select</option>
                                <option value="friable(crumbly)">
                                  friable (crumbly)
                                </option>
                                <option value="coarse and very firm clods">
                                  coarse and very firm clods
                                </option>
                                <option value="no structure">
                                  no structure
                                </option>
                              </select>
                            </div>
                            <div className="content-title-text71">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Soil Porosity
                                </div>
                              </div>
                              <select
                                className="dropdown-113"
                                value={this.state.Soil_Porosity}
                                onChange={this.handleSoilPorosity}
                              >
                                <option value="text">Select</option>
                                <option value="High">High</option>
                                <option value="Medium">Medium</option>
                                <option value="Low">Low</option>
                              </select>
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">Soil pH</div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="14"
                                name="Soil_pH"
                                value={this.state.Soil_pH}
                                onInput={(event) => {
                                  let value = event.target.value;
                                  if (!/^(\d*\.?\d*)$/.test(value)) {
                                    return;
                                  }
                                  if (value.includes(".")) {
                                    const [integerPart, decimalPart] =
                                      value.split(".");
                                    if (decimalPart.length > 2) {
                                      value =
                                        integerPart +
                                        "." +
                                        decimalPart.slice(0, 2);
                                    }
                                  }
                                  if (
                                    value !== "" &&
                                    (parseFloat(value) < 0 ||
                                      parseFloat(value) > 14)
                                  ) {
                                    value = Math.min(
                                      Math.max(parseFloat(value), 0),
                                      14
                                    );
                                    value = parseFloat(value).toFixed(2);
                                  }
                                  this.setState({
                                    Soil_pH: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">Nitrate</div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="100"
                                name="Nitrate"
                                value={this.state.Nitrate}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 100)
                                  ) {
                                    value = Math.min(Math.max(value, 0), 100);
                                  }
                                  if (value.length > 3) {
                                    value = value.slice(0, 3);
                                  }
                                  this.setState({ Nitrate: value });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Phosphate
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="100"
                                name="Phosphate"
                                value={this.state.Phosphate}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 100)
                                  ) {
                                    value = Math.min(Math.max(value, 0), 100);
                                  }
                                  if (value.length > 3) {
                                    value = value.slice(0, 3);
                                  }
                                  this.setState({ Phosphate: value });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Potassium
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="100000"
                                name="Potassium"
                                value={this.state.Potassium}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 100000)
                                  ) {
                                    value = Math.min(
                                      Math.max(value, 0),
                                      100000
                                    );
                                  }
                                  if (value.length > 6) {
                                    value = value.slice(0, 6);
                                  }
                                  this.setState({ Potassium: value });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Electrical Conductivity
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="100"
                                name="SElectrical_Conductivity"
                                value={this.state.SElectrical_Conductivity}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 100)
                                  ) {
                                    value = Math.min(Math.max(value, 0), 100);
                                  }
                                  if (value.length > 3) {
                                    value = value.slice(0, 3);
                                  }
                                  this.setState({
                                    SElectrical_Conductivity: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>

                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Soil Color
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                name="Soil_Color"
                                value={this.state.Soil_Color}
                                onChange={(event) =>
                                  this.handleAlphaInput(event)
                                }
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Soil Moisture (In %age)
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="100"
                                name="Soil_Moisture_Level"
                                value={this.state.Soil_Moisture_Level}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (!/^(\d*\.?\d*)$/.test(value)) {
                                    return;
                                  }
                                  if (value.includes(".")) {
                                    const [integerPart, decimalPart] =
                                      value.split(".");
                                    if (decimalPart.length > 2) {
                                      value =
                                        integerPart +
                                        "." +
                                        decimalPart.slice(0, 2);
                                    }
                                  }
                                  if (
                                    value !== "" &&
                                    (parseFloat(value) < 0 ||
                                      parseFloat(value) > 100)
                                  ) {
                                    value = Math.min(
                                      Math.max(parseFloat(value), 0),
                                      100
                                    );
                                    value = parseFloat(value).toFixed(2);
                                  }
                                  this.setState({
                                    Soil_Moisture_Level: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>

                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Organic Carbon
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                required
                                pattern="[a-zA-Z0-9]+"
                                type="text"
                                name="Organic_Carbon"
                                value={this.state.Organic_Carbon}
                                onChange={(event) =>
                                  this.handleAlphaInput(event)
                                }
                              />
                              <div className="mandatory has-error">
                                {this.state.errors.organic_Carbon}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="land-parcels-container columnlapacont pt-4">
                    <div className="land-parecel-content-area">
                      <div className="left-content">
                        <div className="hero-title8">
                          <b className="flora-details">
                            Carbon Offset Potential
                          </b>
                        </div>
                        <div className="column-content2">
                          <div className="column-112">
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Total Carbon Offset Capacity (in Kg Eq. CO2)
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                name="Tot_Carbon_Offset_Capacity"
                                value={this.state.Tot_Carbon_Offset_Capacity}
                                onChange={(event) =>
                                  this.handleUserInput(event)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="land-parcels-container columnlapacont pt-4">
                    <div className="land-parecel-content-area">
                      <div className="left-content">
                        <div className="hero-title8">
                          <b className="flora-details">Social</b>
                        </div>
                        <div className="column-content2">
                          <div className="column-112">
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  No. of Families
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="100000"
                                name="No_Families"
                                value={this.state.No_Families}
                                onInput={(event) => {
                                  let value = event.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 100000)
                                  ) {
                                    value = Math.min(
                                      Math.max(value, 0),
                                      100000
                                    );
                                  }
                                  if (value.length > 6) {
                                    value = value.slice(0, 6);
                                  }
                                  this.setState({ No_Families: value });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Average Family Size
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="100000"
                                name="Avg_Family_Size"
                                value={this.state.Avg_Family_Size}
                                onInput={(event) => {
                                  let value = event.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 100000)
                                  ) {
                                    value = Math.min(
                                      Math.max(value, 0),
                                      100000
                                    );
                                  }
                                  if (value.length > 6) {
                                    value = value.slice(0, 6);
                                  }
                                  this.setState({ Avg_Family_Size: value });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Gender Ratio (Female/Male)
                                </div>
                              </div>
                              <select
                                className="dropdown-113"
                                name="Gender"
                                value={this.state.Gender}
                                onChange={this.handleUserInput}
                              >
                                <option value="">Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Literacy Rate - Overall
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                max="100"
                                name="Literacy_Rate_Overall"
                                value={this.state.Literacy_Rate_Overall}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 100)
                                  ) {
                                    value = Math.min(Math.max(value, 0), 100);
                                  }
                                  if (value.length > 3) {
                                    value = value.slice(0, 3);
                                  }
                                  this.setState({
                                    Literacy_Rate_Overall: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Literacy Rate - Female
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                name="Literacy_Rate_Female"
                                value={this.state.Literacy_Rate_Female}
                                onInput={(e) => {
                                  let value = e.target.value;
                                  if (
                                    value !== "" &&
                                    (value < 0 || value > 100)
                                  ) {
                                    value = Math.min(Math.max(value, 0), 100);
                                  }
                                  if (value.length > 3) {
                                    value = value.slice(0, 3);
                                  }
                                  this.setState({
                                    Literacy_Rate_Female: value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Average Annual Income per Family by Farming
                                  (for the land parcels included in this
                                  project)
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                name="Avg_Annual_Inc_Family_Farming"
                                value={this.state.Avg_Annual_Inc_Family_Farming}
                                onChange={(event) => this.handleInput(event)}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                            <div className="content-title-text69">
                              <div className="hero-title8">
                                <div className="project-summary8">
                                  Average Annual Income per Family by Other
                                  Sources
                                </div>
                              </div>
                              <input
                                className="text-input-field-37"
                                type="number"
                                min="0"
                                name="Avg_Annual_Inc_Family_Other_Sources"
                                value={
                                  this.state.Avg_Annual_Inc_Family_Other_Sources
                                }
                                onChange={(event) => this.handleInput(event)}
                                onKeyDown={(e) => {
                                  ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="action-buttons2 pt-4">
                    <button className="cta5">
                      <div className="back2" onClick={this.Back}>
                        Back
                      </div>
                    </button>
                    <button className="cta6" onClick={this.Save}>
                      <div className="save-continue3">{`Save & Continue`}</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="right-navbar3">
              <button className="plus-circle3">plus-circle</button>
              <button className="notifications-bell3">notifications-bell</button>
              <button className="notifications-bell3">email</button>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
  };
}

export default connect(mapStateToProps)(TreeTiesAddProjectForms1Obj);
