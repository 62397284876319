import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container, Row, Col } from "react-bootstrap";
import { Modal, Button, Form } from '@patternfly/react-core';
import config from "../../../utils/config";
import Loader from "../../Common/Loader/Loader";
import { globalHistory } from "@reach/router";
import ToastNotification from "./../../Common/ToastNotification/ToastNotification";
class AddTreeTypeMst extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: props.IsOpen,
            errors: {},
            TreeTypeName: "",
            IsLoader: false,
            IsUpdate: props.AddOrUpdate === "Update" ? true : false,
            TreeTypeId: "",
            Applicationlist: props.data,
            files: [],
            userFiles: [],
            MaxImageSizeInMb: 0
        }

        this.mounted = true;
        this.handleModalToggle = () => {
            this.setState(({ isModalOpen }) => ({
                isModalOpen: !isModalOpen
            }));
        };

    }
    componentDidMount() {
        this.mounted = true;
        if (this.state.IsUpdate === true) {
            this.setState({ TreeTypeId: this.state.Applicationlist.TreeTypeId, TreeTypeName: this.state.Applicationlist.TreeTypeName, TreeTypeImgPath: this.state.Applicationlist.TreeTypeImgPath, IsUpdate: true });
        }
        this.GetSettingsImageSize("treetype");
    }

    componentWillUnmount() {
        this.mounted = false;
    }
    mobileSidebarToggle = (e) => {
        document.documentElement.classList.toggle("nav-open");
        e.stopPropagation();
    };

    MapKeyInArray = (responseData, keyname) => {
        let myArray = responseData.find(obj => obj.key === keyname)
        if (myArray) {
            return myArray.value;
        }
    }

    GetSettingsImageSize = (GroupName) => {
        let UserDetail = {
            GroupName: GroupName
        }
        let uri = `${config.API_HOST_URL}/api/projects/GetGlobalGeoupValue`;
        let fetchOptions = {
            method: "POST",
            body: JSON.stringify(UserDetail),
            headers: {
                WebsiteGuid: this.props.websiteGUID,
                LanguageGuid: this.props.languageGUID,
                Authorization: `Bearer ${this.props.token}`,
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        };

        fetch(uri, fetchOptions)
            .then(response => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json().then(data => {
                        if (data.success) {
                            let responseData = data.data;
                            if (this.mounted) {
                                this.setState({ MaxImageSizeInMb: this.MapKeyInArray(responseData, "MaxImageSizeInMb") });
                            }
                        } else {
                            let errors = {};
                            errors["message"] = data.message;
                            if (this.mounted) {
                                this.setState({ errors: errors });
                            }
                        }
                    });
                } else {
                    return response.json().then(data => {
                        let errors = {};
                        errors["message"] = data.message;
                        if (this.mounted) {
                            this.setState({
                                errors: errors
                            });
                        }
                    });
                }

            })
            .catch(error => {
                if (this.mounted) {
                    console.log(error);
                    this.setState({
                        errorMsg: error,
                        IsLoader: false
                    })
                }
            });
    }

    // This function do validate the Registration Form
    validateForm = (e) => {
        let fields;
        fields = {
            TreeTypeName: this.state.TreeTypeName
        }
        let errors = {};
        let formIsValid = true;
        if (!fields["TreeTypeName"]) {
            formIsValid = false;
            errors["TreeTypeName"] = "Enter Tree Type.";
        }
        if (this.state.IsUpdate == false && this.state.files.length == 0) {
            formIsValid = false;
            errors["TreeImage"] = "Please select tree image.";
        }

        if (this.mounted) {
            this.setState({ errors: errors });
        }
        return formIsValid;
    }

    AddTreeTypes = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            if (this.mounted) {
                let file = this.state.files;
                const formData = new FormData();
                for (var index = 0; index < file.length; index++) {
                    var element = file[index];
                    formData.append('FormFiles', element);
                }
                formData.append('TreeTypeId', this.state.IsUpdate ? this.state.TreeTypeId : "");
                formData.append('TreeTypeName', this.state.TreeTypeName);
                let uri = `${config.API_HOST_URL}/api/TreeType/AddTreeType`;
                if (this.state.IsUpdate) {
                    uri = `${config.API_HOST_URL}/api/TreeType/UpdateTreeType`;
                }
                let fetchOptions = {
                    method: "POST",
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${this.props.token}`,
                        Accept: "application/json",
                        ContentType: 'multipart/form-data',
                    }
                };
                this.setState({
                    IsLoader: true
                });
                fetch(uri, fetchOptions)
                    .then(response => {
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1) {
                            return response.json().then(data => {
                                if (data.success) {
                                    this.setState({
                                        IsLoader: false,
                                        IsOpen: false,
                                        Applicationlist: [],
                                        TreeTypeName: ""
                                    });
                                    this.handleModalToggle();
                                    setTimeout(() => {
                                        this.setState({
                                        }, this.props.parentMethod)
                                    }, 800);
                                } else {
                                    let errors = {};
                                    errors["message"] = data.message;
                                    if (this.mounted) {
                                        this.setState({
                                            errors: errors,
                                            IsLoader: false
                                        });
                                    }
                                }
                            });
                        } else {
                            return response.json().then(data => {
                                let errors = {};
                                errors["message"] = data.message;
                                if (this.mounted) {
                                    this.setState({
                                        errors: errors,
                                        IsLoader: false
                                    });
                                }
                            });
                        }

                    })
                    .catch(error => {
                        if (this.mounted) {
                            console.log(error);
                            this.setState({
                                errorMsg: error,
                                IsLoader: false
                            })
                        }
                    });
            }
        }
    }

    // This function will set the state of input fields and validate them when user enters the value in input boxes
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (this.mounted) {
            this.setState({ [name]: value });
            this.validateForm();
        }
    }

    setFile = (e) => {
        let filesArr = e.target.files;
        for (var i = 0; i < filesArr.length; ++i) {
            if (filesArr[i].name.match(/\.(jpg|jpeg|png|gif)$/)) {
                if ((parseInt(filesArr[i].size) / 1024) <= (parseInt(this.state.MaxImageSizeInMb) * 1024))  // validation according to file size
                {
                    let myFilesList = this.state.files;
                    myFilesList.push(filesArr[i]);
                    let errors = {};
                    errors["TreeImage"] = "";
                    this.setState({ files: myFilesList, errors: errors });
                } else {
                    //alert("Maximum files size allowed is " + this.state.MaxImageSizeInMb + " MB");
                    ToastNotification.ShowWarning('Maximum files size allowed is ' + this.state.MaxImageSizeInMb + 'MB')
                }
            } else {
                let errors = {};
                errors["TreeImage"] = "Select a valid image.";
                if (this.mounted) {
                    this.setState({ errors: errors });
                }
            }
        }
    }

    removeFileFromList = (e) => {
        var array = [...this.state.files]; // make a separate copy of the array
        array.splice(e.target.id, 1);
        this.setState({ files: array });
    }

    handleUrlOpen = (DocumentName) => {
        let Documenturl = `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/TreeTypes/${DocumentName}`;
        window.open(Documenturl, "_blank")
    }



    render() {
        const { isModalOpen } = this.state;
        var UserFilesarr = [];
        if (this.state.Applicationlist !== null) {
            UserFilesarr.push(this.state.Applicationlist.TreeTypeImgPath + "," + this.state.Applicationlist.TreeTypeImgPath);
        }

        return (
            <div>
                {this.state.IsLoader ? <Loader /> :
                    <div className="addpro_btn">
                        <Modal title="Add Tree Type"
                            isOpen={isModalOpen}
                            onClose={this.handleModalToggle}
                            className="addproject_modal"
                            actions={[
                                <Button key="submit" className="modal_btn submit_btn" onClick={this.AddTreeTypes}>Submit</Button>,
                                <Button key="cancel" className="modal_btn cancel_btn" onClick={this.handleModalToggle}>Cancel</Button>
                            ]}>
                            <div>
                                <ul className="addproject_form">
                                    <li className="form-group field">
                                        <input type="text" id="treetype" placeholder="Enter Tree Type" name="TreeTypeName" value={this.state.TreeTypeName} onChange={(event) => this.handleUserInput(event)} />
                                        <label htmlFor="treetype">
                                            <sup className="mandatory">*</sup>Enter Tree Type</label>
                                        <div className="mandatory has-error">{this.state.errors.TreeTypeName}</div>
                                    </li>
                                    <li className="form-group inventory_cont">
                                        <label for="forinventory">
                                            <sup className="mandatory">*</sup>
                                            Tree Image
                                        </label>

                                        <div className="input-group divUploadFile">
                                            <div className="qq-upload-drop-area">
                                                <span>Drop files here to upload</span>
                                            </div>
                                            <div className="qq-upload-button">
                                                Drag and Drop Files
                                                <span className="redMandat">
                                                    Upload file type jpg, jpeg, png, gif File size limit {this.state.MaxImageSizeInMb} MB
                                                </span>
                                            </div>
                                            <input id="choose" type="file" name="files" onChange={e => this.setFile(e)} />
                                        </div>
                                        <div>
                                            <ul id="fileList" className="ul-project-files">
                                                {this.state.files !== null && this.state.files.map((item, index) => (
                                                    <li class="file-list-li">
                                                        <i class="fa fa-check"></i>{item.name}
                                                        <a class="remove-files-list" id={index} onClick={this.removeFileFromList}>Remove</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="mandatory has-error">{this.state.errors.TreeImage}</div>
                                    </li>
                                    {this.state.Applicationlist !== null && <li>
                                        <span className="applistpoplabel">Document(s)</span>
                                        {UserFilesarr !== null && UserFilesarr.map((item, index) => (
                                            <tr key={item}>
                                                <td className="docname">
                                                    <a onClick={() => this.handleUrlOpen(item.split(",")[1])}>{item.split(",")[0]}</a>
                                                </td>
                                            </tr>)

                                        )}

                                    </li>}
                                </ul>
                            </div>
                        </Modal>
                    </div>
                }
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData
    };
}

export default connect(
    mapStateToProps
)(AddTreeTypeMst);