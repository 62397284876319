import React, { useEffect, useState } from "react";
import ProListviewmediaImg from "./mediaImages/listviewpopupimg.jpg";
import { Carousel } from "react-responsive-carousel";
import config from "../../../utils/config";
import ReactPlayer from "react-player";

export default function ProjectListVideosPopup(props) {
  const [images, setImages] = useState({});
  const [treeData, setTreeData] = useState([]);
  useEffect(() => {
    console.log("eachTreeData:", props.eachTreeData);
    setTreeData(props.eachTreeData);
  }, [props.eachTreeData]);
  return (
    <div className="treedetails_cont">
      <div className="treedetimg">
        <Carousel>
          {treeData?.videos?.map((videos, i) => (
            <ReactPlayer
              key={i}
              url={
                `${config.Amazon_WebService_CDNUrl}/Websites/Uploads/TreesImages/${treeData?.projectID}/${treeData?.ec5_uuid}/` +
                videos
              }
              controls={true}
              height={450}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}
