import React, { Component, useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";

export default function TreeCountDroneBased({ treeCountArray, center }) {
  const [selectedIndex, setSelectedIndex] = useState(null);

  return (
    <>
      {treeCountArray?.length > 0 &&
        treeCountArray?.map((eachMarker, index) => {
          let singlecord = eachMarker.geometry?.coordinates;
          let cord = { lat: singlecord?.[1], lng: singlecord?.[0] };
          return (
            <div key={index}>
              <Marker
                position={cord}
                icon={
                  cord.lat === center.lat && cord.lng === center.lng
                    ? require("./markerPin.png")
                    : require("./brownDot.svg").default
                }
                onMouseOver={() => {
                  setSelectedIndex(index);
                }}
                onMouseOut={() => setSelectedIndex(null)}
              >
                {selectedIndex && selectedIndex == index && (
                  <InfoWindow position={cord}>
                    <div>
                      <p>
                        {" "}
                        Tree Name:{" "}
                        {eachMarker?.properties?.Type
                          ? eachMarker?.properties?.Type
                          : "NA"}
                      </p>
                      <p>Latitude: {cord.lat}</p>
                      <p>Longitude: {cord.lng}</p>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            </div>
          );
        })}
    </>
  );
}
