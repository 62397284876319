import React from "react";
import { Polygon } from "@react-google-maps/api";

export default function BasinLayer({ basinData }) {
  let POLYGON = {
    paths: [],
    options: {
      strokeWeight: 1,
      strokeOpacity: 1,
      strokeColor: "black",
      fillOpacity: 0.8,
      fillColor: "#c43c39",
    },
  };

  return (
    <>
      {basinData?.length > 0 &&
        basinData?.map((eachFeature, index) => {
          let multiPoly = eachFeature.geometry?.coordinates?.[0] || [];
          let newMultiCord = [];
          multiPoly?.map((item) => {
            let polygonCords = item;
            let newCords = [];
            polygonCords?.map((cord) => {
              let obj = {};
              obj["lat"] = parseFloat(cord?.[1]);
              obj["lng"] = parseFloat(cord?.[0]);
              newCords.push(obj);
            });
            newMultiCord.push(newCords);
          });

          POLYGON["paths"] = newMultiCord;
          return (
            <Polygon
              key={index}
              paths={POLYGON?.paths}
              options={POLYGON?.options}
            />
          );
        })}
    </>
  );
}
