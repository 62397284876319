import { useEffect, useState } from "react";
import config from "../../../utils/config";

export default function SponsershipView(props) {
  const [sponsershipData, setSponsershipData] = useState([]);
  const [instSponsorUserList, setInstSponsorUserList] = useState([]);
  const [showMore, setShowMore] = useState(false); // New state for toggling rows visibility
  const [individualSponsorUserList, setIndividualSponsorUserList] = useState(
    []
  );
  const [partnerIndivList, setpartnerIndivList] = useState([]);
  const [associationTypeList, setAssociationTypeList] = useState([]);
  const [partnerInsList, setPartnerInsList] = useState([]);
  useEffect(() => {
    if (props?.projectId) {
      GetSponsorship(props.projectId);
      GetNGOUserList();
      GetCommonMasters();
    }
  }, [props.projectId]);
  
  const GetSponsorship = (ProjectId) => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetSponsorship`;
    let proj = {
      ProjectId: ProjectId,
    };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(proj),
      headers: {
        WebsiteGuid: props.websiteGUID,
        LanguageGuid: props.languageGUID,
        Authorization: `Bearer ${props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = data.data;
        setSponsershipData(responseData);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
    GetPartnershipList();
  };
  const GetNGOUserList = () => {
    let url = `${config.API_HOST_URL}/api/Login/GetNGOUserList`;
    fetch(url, {
      method: "Get",
      headers: {
        // WebsiteGuid: this.props.websiteGUID,
        // LanguageGuid: this.props.languageGUID,
        // Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let responseData = JSON.parse(data.NGOUserList);
        if (responseData?.length > 0) {
          var instSponsorUserList = responseData.filter(
            (x) => x.TypeOfIncorporation === "InstitutionalSponsor"
          );
          setInstSponsorUserList(instSponsorUserList);

          var individualSponsorUserList = responseData.filter(
            (x) => x.TypeOfIncorporation === "IndividualSponsor"
          );
          setIndividualSponsorUserList(individualSponsorUserList);
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetPartnershipList = () => {
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetSponserWithPartner/${props.projectId}`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //Institutional
        if (data.InstSpon != null && data.InstSpon.length > 0) {
          setPartnerInsList(JSON.parse(data.InstSpon));
        }
        //Individual
        if (data.IndSpon != null && data.IndSpon.length > 0) {
          setpartnerIndivList(JSON.parse(data.IndSpon));
        }
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };

  const GetCommonMasters = () => {
    let masterType = "AssociationType";
    let url = `${config.API_HOST_URL}/api/Ecosystem/GetCommonMaster/${masterType}`;
    fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAssociationTypeList(data);
      })
      .catch((error) => {
        console.log("Error in request", error);
      });
  };
  const renderLandParcelsRows = () => {
    if (showMore && Array.isArray(sponsershipData?.iS_Assoc_EP)) {
      return sponsershipData?.iS_Assoc_EP.map((eachIS, i) => {
        return (
          <tr key={i}>
            <td>
              {
                instSponsorUserList?.filter(
                  (x) => x.UserId === eachIS.institutional_SponsorId
                )[0]?.NameOfNGO
              }
            </td>
            <td>{eachIS.iT_EP_Spon_Amount_Multiple}</td>
          </tr>
        );
      });
    } else if (!Array.isArray(sponsershipData?.iS_Assoc_EP)) {
      console.warn(
        "iS_Assoc_EP is not an array:",
        sponsershipData?.iS_Assoc_EP
      );
      return null;
    } else {
      return sponsershipData?.iS_Assoc_EP.slice(0, 4).map((eachIS, i) => {
        return (
          <tr key={i}>
            <td>
              {
                instSponsorUserList?.filter(
                  (x) => x.UserId === eachIS.institutional_SponsorId
                )[0]?.NameOfNGO
              }
            </td>
            <td>{eachIS.iT_EP_Spon_Amount_Multiple}</td>
          </tr>
        );
      });
    }
  };

  const individualRenderLandParcelsRows = () => {
    if (showMore && Array.isArray(sponsershipData?.inD_Assoc_TT)) {
      return sponsershipData?.inD_Assoc_TT.map((eachIS, i) => {
        return (
          <tr key={i}>
            <td>
              {
                individualSponsorUserList?.filter(
                  (x) => x.UserId === eachIS.individual_SponsorId
                )[0]?.NameOfNGO
              }
            </td>
            <td>{eachIS.iV_TT_Spon_Amount_Multiple}</td>
          </tr>
        );
      });
    } else if (!Array.isArray(sponsershipData?.inD_Assoc_TT)) {
      console.warn(
        "inD_Assoc_TT is not an array:",
        sponsershipData?.inD_Assoc_TT
      );
      return null;
    } else {
      return sponsershipData?.inD_Assoc_TT.slice(0, 4).map((eachIS, i) => {
        return (
          <tr key={i}>
            <td>
              {
                individualSponsorUserList?.filter(
                  (x) => x.UserId === eachIS.individual_SponsorId
                )[0]?.NameOfNGO
              }
            </td>
            <td>{eachIS.iV_TT_Spon_Amount_Multiple}</td>
          </tr>
        );
      });
    }
  };
  return (
    <div className="row partshprosumpacrd_content sponsorshipacord_content">
      <div className="row">
        {/* institution sponsor with associated with execution partner in Partnership    */}
        {partnerInsList.filter(
          (x) =>
            x.associationTypeId ===
            associationTypeList.filter(
              (x) => x.description === "Associated with Execution Partner (NGO)"
            )[0]?.mainGuId
        ).length > 0 ? (
          <div>
            <div className="hero-title20">
              <b className="project-summary20">
                Institutional Sponsorship : Associated with Execution Partner
                (NGO)
              </b>
            </div>
            <div className="prosummarylist">
              <div className="prosummarylist_item">
                <div className="produmitem_title">
                  Specific Sponsorship Details Available
                </div>
                <div className="produmitem_content">
                  &nbsp;{sponsershipData?.iT_EP_Speci_Spon}
                </div>
              </div>
            </div>
            <div className="prosumacordtbls_wrap">
              <div className="prosumacordinertbl_wrap">
                <table className="prosumacord_inertbl bluetheme_inertbl">
                  <thead>
                    <tr>
                      <th scope="col">Institutional Sponsor</th>
                      <th scope="col">Sponsorship Amount (in Rs.)</th>
                    </tr>
                  </thead>
                  <tbody>{renderLandParcelsRows()}</tbody>
                </table>
                {sponsershipData?.length > 4 && (
                  <button
                    onClick={() => setShowMore(!showMore)}
                    className={`viewmore ${
                      showMore ? "viewmoreless_cont" : ""
                    }`}
                  >
                    {showMore ? "View Less" : "View More"}
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="norecodfoundprosum_cont">No Record Found</div>
        )}

        {/* institution sponsor with Brought by TreeTies in Partnership    */}
        {partnerInsList.filter(
          (x) =>
            x.associationTypeId ===
            associationTypeList.filter(
              (x) => x.description === "Brought by TreeTies"
            )[0]?.mainGuId
        ).length >
          0 ===
          true && (
          <div>
            <div className="hero-title20">
              <b className="project-summary20">
                Institutional Sponsorship : Associated with Execution Partner
                (NGO)
              </b>
            </div>
            <div className="prosummarylist">
              <div className="prosummarylist_item">
                <div className="produmitem_title">
                  Specific Sponsorship Details Available
                </div>
                <div className="produmitem_content">
                  &nbsp;{sponsershipData?.iT_EP_Speci_Spon}
                </div>
              </div>
            </div>
            <div className="prosumacordtbls_wrap">
              <div className="prosumacordinertbl_wrap">
                <table className="prosumacord_inertbl bluetheme_inertbl">
                  <thead>
                    <tr>
                      <th scope="col">Institutional Sponsor</th>
                      <th scope="col">Sponsorship Amount (in Rs.)</th>
                    </tr>
                  </thead>
                  <tbody>{renderLandParcelsRows()}</tbody>
                </table>
                {sponsershipData?.length > 4 && (
                  <button
                    onClick={() => setShowMore(!showMore)}
                    className={`viewmore ${
                      showMore ? "viewmoreless_cont" : ""
                    }`}
                  >
                    {showMore ? "View Less" : "View More"}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {/*  Individual Sponsorship with associated with execution partner in Partnership    */}
        {partnerIndivList.filter(
          (x) =>
            x.associationType_Ind_Id ===
            associationTypeList.filter(
              (x) => x.description === "Associated with Execution Partner (NGO)"
            )[0]?.mainGuId
        ).length >
          0 ===
          true && (
          <div>
            <div className="hero-title20">
              <b className="project-summary20">
                Individual Sponsorship : Associated with TreeTies
              </b>
            </div>
            <div className="prosummarylist">
              <div className="prosummarylist_item">
                <div className="produmitem_title">
                  Specific Sponsorship Details Available
                </div>
                <div className="produmitem_content">
                  &nbsp;{sponsershipData?.iV_TT_Speci_Spon}
                </div>
              </div>
            </div>
            <div className="prosumacordtbls_wrap">
              <div className="prosumacordinertbl_wrap">
                <table className="prosumacord_inertbl orange_inertbl">
                  <thead>
                    <tr>
                      <th scope="col">Individual SponsorShip</th>
                      <th scope="col">Sponsorship Amount (in Rs.)</th>
                    </tr>
                  </thead>
                  <tbody>{individualRenderLandParcelsRows()}</tbody>
                </table>
                {sponsershipData?.length > 4 && (
                  <button
                    onClick={() => setShowMore(!showMore)}
                    className={`viewmore ${
                      showMore ? "viewmoreless_cont" : ""
                    }`}
                  >
                    {showMore ? "View Less" : "View More"}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {/*Individual Sponsorship with Brought by TreeTies in Partnership    */}
        {partnerIndivList.filter(
          (x) =>
            x.associationType_Ind_Id ===
            associationTypeList.filter(
              (x) => x.description === "Brought by TreeTies"
            )[0]?.mainGuId
        ).length >
          0 ===
          true && (
          <div>
            <div className="hero-title20">
              <b className="project-summary20">
                Individual Sponsorship : Associated with TreeTies
              </b>
            </div>
            <div className="prosummarylist">
              <div className="prosummarylist_item">
                <div className="produmitem_title">
                  Specific Sponsorship Details Available
                </div>
                <div className="produmitem_content">
                  &nbsp;{sponsershipData?.iV_TT_Speci_Spon}
                </div>
              </div>
            </div>
            <div className="prosumacordtbls_wrap">
              <div className="prosumacordinertbl_wrap">
                <table className="prosumacord_inertbl orange_inertbl">
                  <thead>
                    <tr>
                      <th scope="col">Individual SponsorShip</th>
                      <th scope="col">Sponsorship Amount (in Rs.)</th>
                    </tr>
                  </thead>
                  <tbody>{individualRenderLandParcelsRows()}</tbody>
                </table>
                {sponsershipData?.length > 4 && (
                  <button
                    onClick={() => setShowMore(!showMore)}
                    className={`viewmore ${
                      showMore ? "viewmoreless_cont" : ""
                    }`}
                  >
                    {showMore ? "View Less" : "View More"}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
