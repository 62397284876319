import React, { Component } from 'react';
import { Container } from "react-bootstrap";
import { connect } from 'react-redux';
import config from "../../../utils/config";
import ToastNotification from "./../../Common/ToastNotification/ToastNotification";

class Epicdata extends Component {
    constructor(props){
        super(props);
        this.state = { 
            epicdata:{
            clientid:"3857",
            clientsecret:"aXIuEK96HdOPQnoGYD3D2tSs4wTjTYJsRshujuC6",
            projectSlug:"goa-pilot-project",
            ttprojectId:"bf33b663-d37f-4be7-b886-a0c06f120eac",
            formreference:"b831a99c6b05409181ea7d8376457897_642d03d87d4cc",
            fromDate:"",
            toDate:""
            }
         } 
   }

componentDidMount() {
}

handleUserInput(e) {
   const name = e.target.name;
   const value = e.target.value;
   this.setState({epicdata:{ ...this.state.epicdata, [name]: value}});
}

onPullData = () => {
    if (window.confirm('Are you sure to Pull Data?')) this.EpicData();
}
onPullImage = () => {
    if (window.confirm('Are you sure to Pull Media?')) this.PullMedia();
  }

  //To Clear the fields
// clearFields = () => {
//     this.setState({epicdata:{ ...this.state.epicdata, fromDate: "", toDate:""}});
// }

EpicData = () => {
   const data =this.state.epicdata;
   let url = `${config.API_HOST_URL}/api/EpiCollect/GetFormData`;
        fetch(url, {
          method: "POST",
          headers: {
             Authorization: `Bearer ${this.props.token}`,
             "Content-Type": "application/json",
             Accept: "application/json"
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((resp) => {
            if (resp.success === true) {    
                    //this.clearFields();
                    //ToastNotification.ShowSuccess(resp.message);
                }
                else {
                    //ToastNotification.ShowError(resp.message);
                }
                alert(resp.message);
            })
          .catch((error) => {
            console.log("Request failed", error);
          });
  }

  PullMedia = () => {
    const data =this.state.epicdata;
    let url = `${config.API_HOST_URL}/api/EpiCollect/GetFormMedia`;
         fetch(url, {
           method: "POST",
           headers: {
              Authorization: `Bearer ${this.props.token}`,
              "Content-Type": "application/json",
              Accept: "application/json"
           },
           body: JSON.stringify(data),
         })
           .then((response) => response.json())
           .then((resp) => {
            if (resp.success === true) {    
                    //this.clearFields();
                    //ToastNotification.ShowSuccess(resp.message);
                }
                else {
                    //ToastNotification.ShowError(resp.message);
                }
                alert(resp.message);
            })
           .catch((error) => {
             console.log("Request failed", error);
           });
   }

   AddEpicDataToProjectTreeTypes = () => {
    const data =this.state.epicdata;
    let url = `${config.API_HOST_URL}/api/EpiCollect/AddEpicDataToProjectTreeTypes`;
         fetch(url, {
           method: "POST",
           headers: {
              Authorization: `Bearer ${this.props.token}`,
              "Content-Type": "application/json",
              Accept: "application/json"
           },
           body: JSON.stringify(data),
         })
           .then((response) => response.json())
           .then((resp) => {
            if (resp.success === true) {    
                    //this.clearFields();
                    //ToastNotification.ShowSuccess(resp.message);
                }
                else {
                    //ToastNotification.ShowError(resp.message);
                }
                alert(resp.message);
            })
           .catch((error) => {
             console.log("Request failed", error);
           });
   }

render() {
        return (
             <div className="growalarm_container applist_cont">
        <Container>
              <form>
               <div>
                    <label className='col-md-2'>Client ID</label>
                    <input className='col-md-5' type='password' value ={this.state.epicdata.clientid } onChange={(event) => this.handleUserInput(event)}/>
                </div>
                <div>
                    <label className='col-md-2'>Client Secret</label>
                    <input className='col-md-5' type='password' value ={this.state.epicdata.clientsecret} onChange={(event) => this.handleUserInput(event)}/>
                </div>
                <div>
                    <label className='col-md-2'>Project Slug</label>
                    <input className='col-md-5' type='text'value ={this.state.epicdata.projectSlug} onChange={(event) => this.handleUserInput(event)}/>
                </div>
                <div>
                    <label className='col-md-2'>TT ProjectId</label>
                    <input className='col-md-5' type='text'value ={this.state.epicdata.ttprojectId} onChange={(event) => this.handleUserInput(event)}/>
                </div>
                
                <div>
                    <label className='col-md-2'>Form Reference</label>
                    <input className='col-md-5' type='text'value ={this.state.epicdata.formreference} onChange={(event) => this.handleUserInput(event)}/>
                </div>
                <div>
                    <label className='col-md-2'>Filter From Date</label>
                    <input className='col-md-5' type='text' id="fromDate" value ={this.state.epicdata.fromDate} name="fromDate" placeholder="FromDate" onChange={(event) => this.handleUserInput(event)}/>
                </div>
                <div>
                    <label className='col-md-2'>Filter To Date</label>
                    <input className='col-md-5' type="text" id="toDate" value ={this.state.epicdata.toDate} name="toDate" placeholder="ToDate" onChange={(event) => this.handleUserInput(event)}/>
                </div>
                <div>
                        <p><a class="linkData" onClick={() => { this.onPullData()} }>Pull Data</a></p>
                        <p><a class="linkImage" onClick={() => { this.onPullImage()} }>Pull Media</a></p>
                        <p><a class="linkInsert" onClick={() => { this.AddEpicDataToProjectTreeTypes()} }>Insert Project Tree Type</a></p>
                </div>
               
            </form>
        </Container>
         </div>
        );
    }
}
 
function mapStateToProps(state) {
    return {
        token: state.configuration.token,
        userData: state.user.userData,
        
    };
}
function mapDispatchToProps(dispatch) {
    return {
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Epicdata);
