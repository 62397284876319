import React, { Component } from "react";
import config from "../../utils/config";
import ToastNotification from "../Common/ToastNotification/ToastNotification";
import { connect } from "react-redux";
class EnquiryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Mobile: "",
      Email: "",
      Purpose: "",
      fields: {},
      errors: {},
    };
  }
  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    const regmobno = /^[0-9\b]+$/;
    if (this.mounted) {
      if (name == "Mobile") {
        if (e.target.value === "" || regmobno.test(e.target.value))
          this.setState({ [name]: e.target.value, mobileno: e.target.value });
      } else this.setState({ [name]: value }, () => {});
      this.validateForm();
    }
  }
  onSelectCollaborate = (e) => {
    if (this.mounted) {
      this.setState({
        Purpose: e.target.value,
      });
    }
  };
  EnquiryDetails = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      if (this.mounted) {
        let type;
        if (window.location.pathname.includes("Registration"))
          type = window.location.pathname.split("/")[2];
        else type = "Grow";

        const formData = new FormData();
        formData.append("Name", this.state.Name);
        formData.append("Mobile", this.state.Mobile);
        formData.append("Email", this.state.Email);
        formData.append("Purpose", this.state.Purpose);
        formData.append("Type", type);

        let uri = `${config.API_HOST_URL}/api/EnquiryDetails/EnquiryDetails`;
        let fetchOptions = {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${this.props.token}`,
            Accept: "application/json",
            ContentType: "multipart/form-data",
          },
        };
        fetch(uri, fetchOptions)
          .then((response) => {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => {
                if (data.success && this.mounted == true) {
                  this.props.setRegisterData(true);
                  //ToastNotification.ShowSuccess(data.message)
                  let errors = {};
                  if (this.mounted) {
                    this.setState({
                      errors: errors,
                    });
                  }
                  this.click();
                  this.clearFields();
                } else {
                  ToastNotification.ShowSuccess(data.message);
                  let errors = {};
                  if (this.mounted) {
                    this.setState({
                      errors: errors,
                    });
                  }
                }
              });
            } else {
              return response.json().then((data) => {
                let errors = {};
                if (this.mounted) {
                  this.setState({
                    errors: errors,
                  });
                }
              });
            }
          })
          .catch((error) => {
            if (this.mounted) {
              console.log(error);
              this.setState({
                errorMsg: error,
              });
            }
          });
      }
    }
  };
  validateForm() {
    let fields;
    fields = {
      Name: this.state.Name,
      Email: this.state.Email,
    };
    let errors = {};
    let formIsValid = true;
    if (!fields["Name"]) {
      formIsValid = false;
      errors["Name"] = "Enter name";
    }
    if (!fields["Email"]) {
      formIsValid = false;
      errors["Email"] = "Enter your email address";
    }

    if (fields["Email"] && typeof fields["Email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["Email"])) {
        formIsValid = false;
        errors["Email"] = "Enter valid email address";
      }
    }
    if (this.mounted) {
      this.setState({ errors: errors });
    }
    return formIsValid;
  }
  clearFields = () => {
    if (this.mounted) {
      this.setState({
        Name: "",
        Mobile: "",
        Email: "",
        Purpose: "",
      });
    }
  };
  click = () => {
    this.props.ShowMSG(true);
  };
  render() {
    return (
      <div className="registartion_container">
        <div className="registation_head">
          <div className="section_heading">I am interested</div>
        </div>
        <div className="registartion_cont">
          <div className="registartioninner_wrap">
            <div className="section_heading"></div>
            <div className="mandatory_txt">
              All fields marked <span style={{ color: "red" }}>*</span> are
              mandatory
            </div>
          </div>
          <form>
            <ul className="register_form">
              <li className="form-group field">
                <input
                  type="text"
                  id="name"
                  name="Name"
                  placeholder="Enter your name"
                  required
                  value={this.state.Name}
                  onChange={(event) => this.handleUserInput(event)}
                />
                <label for="name">
                  <sup className="mandatory">*</sup>
                  Name
                </label>
                <div className="mandatory has-error">
                  {this.state.errors.Name}
                </div>
              </li>
              <li className="form-group field">
                <input
                  type="text"
                  id="Mobile"
                  name="Mobile"
                  placeholder="Enter your Mobile"
                  required
                  value={this.state.Mobile}
                  onChange={(event) => this.handleUserInput(event)}
                  maxLength={10}
                />
                <label for="Mobile">Mobile</label>
              </li>
              <li className="form-group field">
                <input
                  type="text"
                  id="email"
                  name="Email"
                  placeholder="Enter your Email"
                  required
                  value={this.state.Email}
                  onChange={(event) => this.handleUserInput(event)}
                />
                <label htmlFor="email">
                  <sup className="mandatory">*</sup>
                  Email
                </label>
                <div className="mandatory has-error">
                  {this.state.errors.Email}
                </div>
              </li>
              <li className="form-group field">
                {/* <input type="text" id="purpose" name="Purpose" placeholder="Enter your purpose" required value={this.state.Purpose} onChange={(event) => this.handleUserInput(event)} /> */}
                <select
                  className="selectCollaborate"
                  onChange={this.onSelectCollaborate}
                >
                  <option
                    value="Select"
                    selected={this.state.Purpose == "Select"}
                  >
                    Select
                  </option>
                  <option value="Retail">Retail</option>
                  <option value="Corporate">Corporate</option>
                  <option value="NGO">NGO</option>
                </select>
                <label for="collaborate">Collaborate as</label>
              </li>
              <li className="form-group submitbtn_wrap">
                <button
                  className="submit_btn"
                  type="submit"
                  onClick={this.EnquiryDetails}
                  name="Submit"
                  value="submit"
                >
                  Submit
                </button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.configuration.token,
    userData: state.user.userData,
    userMenuData: state.user.userMenuData,
    registrationData: state.registration.registrationData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRegisterData: (data) =>
      dispatch({ type: "SET_REGISTRATION_DETAILS", data: data }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnquiryDetails);
