import React, { Component } from "react";
import { connect } from "react-redux";
import { navigate, Link } from "@reach/router";
import { Redirect } from "react-router-dom";
import config from "../../../utils/config";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipVisible: false,
      year: new Date().getFullYear(),
    };
    this.mounted = true;
    this.handleContactUs = () => {
      window.open("/contactus");
    };
  }

  handleMouseEnter = () => {
    this.setState({ isTooltipVisible: true });
  };

  handleMouseLeave = () => {
    this.setState({ isTooltipVisible: false });
  };

  componentWillUnmount() {
    this.mounted = false;
    //document.getElementById("year").innerHTML = new Date().getFullYear();
  }
  componentDidMount() {
    this.mounted = true;
    this.updateYear();
  }

  updateYear() {
    if (this.mounted) {
      this.setState({ year: new Date().getFullYear() });
    }
  }

  render() {
    const { content } = this.props;
    const { isTooltipVisible, year } = this.state;
    return (
      <div className="footer_cont">
        <div className="footer_content">
          <span>
            Copyright © <span id="year">{year}</span> TreeTies. All rights
            reserved.{" "}
          </span>
          <a
            onClick={() => this.handleContactUs()}
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact TreeTies
          </a>
          {/* <div className="footersitelogo" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                  <a id="tooltip-target"  href={this.props.userData.roleId == 2 ? "/NGODashboard" : "/GrowHead"}><img src={`${config.Amazon_WebService_CDNUrl}/websites/staticimages/bottomLogoIcon.png`} alt="growdashlogo" /></a>
                  {isTooltipVisible && <div className="footerlogo_toltip">Powered By TreeTies</div>}
              </div> */}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: state.user.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearUserData: () => dispatch({ type: "CLEAR_USER_DETAILS" }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
