import config from "../../../../utils/config";

async function GetCollabarationData(ProjectId) {
  try {
    let url = `${config.API_HOST_URL}/api/Projects/GetCollaborationsByProjectId/${ProjectId}`;
    let res = await fetch(url, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      timeout: 5000,
    });
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    let data = await res.json();
    console.log("API response:", data);
    return data;
  } catch (error) {
    console.error("Error fetching layers data:", error);
    throw error;
  }
}
export default GetCollabarationData;
